import React from 'react'
import CustomNavbar from './Navbar/Navbar'
import FooterComp from './Footer/Footer'
import MenuLebel from './Navbar/MenuLabel'

function WithHeaderFooter({children}) {
  return (
    <div>
      <CustomNavbar />
      <MenuLebel />
     
        {children}
      <FooterComp />

    </div>
  )
}

export default WithHeaderFooter