import React from "react";
import CreditCard from "./CreditCard";

const PaymentForm = ({ paymentMethod = "CREDIT_CARD", setPaymentData ,paymentData}) => {
  return (
    <>
      {paymentMethod === "CREDIT_CARD" ? (
        <CreditCard
          paymentData={paymentData}
          setPaymentData={setPaymentData}
          
        />
      ) : null}
    </>
  );
};

export default PaymentForm;
