import React, { useEffect, useState } from "react";
import WithHeaderFooter from "../../components/WithHeaderFooter";
import HeroSection from "../flight/HeroSection";
import TicketReault from "../../components/Ticket/Index";
import { useNavigate, useLocation } from "react-router-dom";
import http, { FlightOffers } from "../../Api";
import moment from "moment";
function FlightSearch() {
  const [flights, setFlights] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname + location.search;
  const generateTravelerArray = (length, travelerType) => {
    const travelerArray = [];

    for (let i = 1; i <= length; i++) {
      const travelerObject = {
        travelerType: travelerType,
        fareOptions: ["STANDARD"],
      };
      travelerArray.push(travelerObject);
    }

    return travelerArray;
  };
  useEffect(() => {
    getData();
  }, [location.search]);
  const params = window.location.search;
  const search = new URLSearchParams(params).get("data");
  const data = JSON.parse(search);
  const getData = () => {
    setLoading(true);

    if (!search) return;
    console.log("dadfdfdfta", data);
    let travelers = [];
    travelers = generateTravelerArray(data.adults, "ADULT");
    travelers = [...travelers, ...generateTravelerArray(data.childs, "CHILD")].map(
      (it, ind) => ({ ...it, id: ind + 1 })
    );

    const odes = data.originDestinations.map((it, ind) => {
      return {
        id: ind + 1,
        originLocationCode: it.from.value,
        destinationLocationCode: it.to.value,
        departureDateTimeRange: {
          date: moment(it.date[0]).startOf('day').format("YYYY-MM-DD"),
          time: moment(it.date[0]).startOf('day').format("HH:mm:ss"),
        },
      };
    });

    const payload = {
      currencyCode: data.currency,
      originDestinations: odes,
      travelers,
      sources: [
        "GDS"
      ],
    
    };

    http
      .post("flight-search", payload)
      .then((resp) => {
        console.log("resp", resp);

        setFlights(resp.data);
      })
      .catch((err) => console.log("err", err))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <WithHeaderFooter>
      <HeroSection title={false} />

      <TicketReault searchParams={data} loading={loading} flights={flights} />
    </WithHeaderFooter>
  );
}

export default FlightSearch;
