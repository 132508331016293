import React, { useEffect, useState } from "react";
import FAQ2 from "../../components/Faq/FAQ";
import { Container } from "react-bootstrap";
import http from "../../Api";

function FAQsection({ type }) {
  const [faqs, setFaqs] = useState([]);
  useEffect(() => {
    getFaq();
  }, []);

  // fetch
  const getFaq = () => {
    http.get("get-faq", { params: { type } }).then(({ data }) => {
      setFaqs(data.data);
    });
  };

  return (
    <Container className="py-4">
      <h3 className="my-2">Frequently Asked Questions</h3>
      {faqs?.map((it) => {
        return (
          <FAQ2
            heading={it.question}
            body={<p style={{ color: "white" }}>{it.answer}</p>}
          />
        );
      })}
    </Container>
  );
}

export default FAQsection;
