import React from "react";
import { Image } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import Dropdown from "react-bootstrap/Dropdown";
import { useMediaQuery } from "../../hook/useMediaQuerry";
import ButtonAnimated from "../button/Index";
import http from "../../Api";

function CustomNavbar() {
  const expand = "md";
  const isMobileView = useMediaQuery();
  const navi = useNavigate();
  return (
    <Navbar expand={expand} className="py-3 pb-3 navbarstyle" sticky="top">
      <Container>
        <Navbar.Brand
          style={{
            cursor: "pointer",
          }}
        >
          <Link to={"/"}>
            <Image
              className="nav-icon-img"
              width={45}
              height={45}
              src="/assets/logo.jpg"
            />
          </Link>
        </Navbar.Brand>{" "}
        {reactLocalStorage.getObject("userData")?.userData?.isVerified === 0 && (
          <small className="text-white">
            Your email isn't verified !
            <Link className="m-2 text-danger" to="/verify-email">
              {" "}
              verify now
            </Link>
          </small>
        )}
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`}>
          <AiOutlineMenu color="white" />
        </Navbar.Toggle>
        <Navbar.Offcanvas
          className="d-flex flex-row-reverse"
          id={`offcanvasNavbar-expand-${expand}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
          placement="start"
        >
          
          <div style={{ width: "100%" }}>
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body className="flex-row-reverse">
              {isMobileView ? (
                reactLocalStorage.get("auth") ? (
                  <Container>
                    <Row>
                      <ButtonAnimated varient="B">
                        {
                          reactLocalStorage.getObject("userData")?.userData
                            .firstName
                        }
                      </ButtonAnimated>
                    </Row>
                    <Row>
                      <ButtonAnimated
                        onClick={() => navi("/bookings/flights")}
                        varient="C"
                      >
                        Your Flights
                      </ButtonAnimated>
                    </Row>
                    <Row>
                      <ButtonAnimated
                        onClick={() => navi("/bookings/hotels")}
                        varient="C"
                      >
                        Your Reservations
                      </ButtonAnimated>
                    </Row>
                    <Row>
                      <ButtonAnimated
                        onClick={() => {
                          let res = window.confirm("log out?");
                          if (!res) return;
                          http
                            .post("log-out", {}, { withCredentials: true })
                            .finally(() => {
                              navi("/");
                              reactLocalStorage.clear();
                            });
                        }}
                        varient="A"
                      >
                        Log out
                      </ButtonAnimated>
                    </Row>{" "}
                    <Row></Row>
                  </Container>
                ) : (
                  <Container>
                    <Row>
                      <ButtonAnimated
                        onClick={() => navi("/sign-up")}
                        varient="C"
                      >
                        <Link to="/bookings/flights">Sign Up</Link>
                      </ButtonAnimated>
                    </Row>
                    <Row>
                      <ButtonAnimated
                        onClick={() => navi("/log-in")}
                        varient="C"
                      >
                        <Link to="/bookings/hotels">Sign In</Link>
                      </ButtonAnimated>
                    </Row>
                  </Container>
                )
              ) : null}
              {!isMobileView ? (
                reactLocalStorage.get("auth") ? (
                  <>
                   

                    <Dropdown >
                      <Dropdown.Toggle style={{background:'none',border:'none'}}  variant="success" id="dropdown-basic">
                        <img className="nav-icon-img" style={{margin:"0px 5px"}} height={40} width={40} src="https://coreui.io/bootstrap/docs/assets/img/avatars/2.jpg"/>
                        {
                          reactLocalStorage.getObject("userData")?.userData?.firstName
                        }
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Profile</Dropdown.Item>
                        <Dropdown.Item>
                          <Link to="/bookings/flights">Your Flights</Link>
                        </Dropdown.Item>{" "}
                        <Dropdown.Item>
                          <Link to="/bookings/hotels">Your Reservations</Link>
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            let res = window.confirm("log out?");
                            if (!res) return;
                            http
                              .post("log-out", {}, { withCredentials: true })
                              .finally(() => {
                                navi("/");
                                reactLocalStorage.clear();
                              });
                          }}
                        >
                          Log out
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                ) : (
                  <>
                    {" "}
                    <Button
                      onClick={() => navi("/sign-up")}
                      variant="light"
                      className="mx-2"
                      style={{ color: "#003b95", fontWeight: "600" }}
                    >
                      Register
                    </Button>
                    <Button
                      onClick={() => navi("/log-in")}
                      variant="light"
                      style={{ color: "#003b95", fontWeight: "600" }}
                    >
                      Sign in
                    </Button>
                  </>
                )
              ) : null}
            </Offcanvas.Body>
          </div>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default CustomNavbar;
