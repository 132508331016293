import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const EmailModal = ({
  email,
  setEmail,
  submit,
  readOnly = false,
  children,
  btnDisabled = false,
  btnText = "Send Verification Mail",
  closeBtn = false,
  ...props
}) => {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton={closeBtn}>
        <Modal.Title id="contained-modal-title-vcenter">
          Enter Your Registered Email Adress
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children ? (
          children
        ) : (
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Recipient's username"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              disabled={readOnly}
            />
            <InputGroup.Text
              onClick={() => setEmail((prev) => prev + "@gmail.com")}
              style={{ cursor: "pointer" }}
              id="basic-addon2"
            >
              @gmail.com
            </InputGroup.Text>
          </InputGroup>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={btnDisabled} onClick={submit} variant="warning">
          {btnText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmailModal;
