import React, { memo, useRef, useState } from "react";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Picker from "../flight/Picker";
import HotelDropDown from "./HotelDropDown";
import { getCity } from "../../Api";
import moment from "moment";
import toast from "react-hot-toast";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import { FaCity } from "react-icons/fa6";

const LocationSelect2 = ({ searchCallback }) => {
  const params = new URLSearchParams(window.location.search);

  const [selectedLocations, setSelectedLocations] = useState({
    city: { value: params.get("cityCode"), label: params.get("cityName") },

    date: [
      params.get("checkInDate")
        ? moment(params.get("checkInDate", "YYYY-MM-DD"))
        : moment().format(),
      params.get("checkOutDate")
        ? moment(params.get("checkOutDate", "YYYY-MM-DD"))
        : moment().add(1, "day").format(),
    ],
    adult: 1,
    child: 0,
    room: 1,
  });

  const apitoref = useRef(null);

  const debounce = (func, delay) => {
    clearTimeout(apitoref.current);
    apitoref.current = setTimeout(func, delay);
  };
  const promiseOptions = (inputValue) => {
    if (!inputValue) return;
    return new Promise((resolve) => {
      debounce(() => {
        getCity(inputValue, "CITY").then((data) => {
          resolve(
            data?.data?.map((it) => ({
              value: it.iataCode,
              label: {
                label: it.name,
                country: it.address.countryName,
                region: it.address.regionCode,
              },
            }))
          );
        });
      }, 800);
    });
  };
  console.log("selectedLocations", selectedLocations);
  const navi = useNavigate();
  const getDate = (date) => {
    console.log("date", date);
    setSelectedLocations({ ...selectedLocations, date: date });
  };
  const getDetails = (adult, child, room) => {
    setSelectedLocations({ ...selectedLocations, adult, child, room });
  };

  const searchHotels = () => {
    navi(
      `/hotels-search?cityCode=${
        selectedLocations.city.value
      }&checkInDate=${moment(selectedLocations.date[0]).format(
        "YYYY-MM-DD"
      )}&checkOutDate=${moment(selectedLocations.date[1]).format(
        "YYYY-MM-DD"
      )}&adults=${selectedLocations.adult}&cityName=${
        selectedLocations.city.label
      }`
    );
    return;
  };

  return (
    <div className="d-flex mt-4 justify-content-start align-items-center flex-wrap gap-2">
      <AsyncSelect
        placeholder={"Search by place or hotel name"}
        loadOptions={promiseOptions}
        components={{
          Option: ({ innerProps, label }) => (
            <div {...innerProps} className="d-block p-2 opt-airport">
              <div>
                <FaCity /> {label.label}
              </div>
              <small className="text-muted">
                {label.country}, {label.region}
              </small>
            </div>
          ),
        }}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: "#f39c12",
            borderWidth: "1px",
            borderRadius: 10,
            height: 50,
            width: 300,
            background: "none",
          }),

          singleValue: (provided) => ({
            ...provided,
            color: "white", // Set your desired text color
          }),
          input: (provided, state) => ({
            ...provided,
            color: "white", // Change the color to your desired color
          }),
        }}
        value={selectedLocations.city.label?selectedLocations.city:null}
        // inputValue={selectedLocations.city.label}
        onChange={(e) => {
          console.log("e", e);
          setSelectedLocations({
            ...selectedLocations,
            city: { value: e.value, label: e.label.label },
          });
        }}
      />
      <DateRangePicker
        onChange={getDate}
        value={selectedLocations.date}
        className={"text-white date-p"}
      />
      <HotelDropDown getDetails={getDetails} />
      <button
        style={{
          height: 55,
          width: 120,
          borderRadius: 20,
          transition: "0.5s",
        }}
        className="my-2 search-btn"
        onClick={searchHotels}
      >
        Search
      </button>{" "}
    </div>
  );
};

export default memo(LocationSelect2);
