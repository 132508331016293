import React, { useEffect, useState, lazy, Suspense } from "react";
import WithHeaderFooter from "../../components/WithHeaderFooter";
import HotelsHero from "../hotels/HotelsHero";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import http from "../../Api";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import LoadSkeleton from "./LoadSkeleton";
import FallbackComp from "../../components/Fallback/Index";
const HotelCard = lazy(() => import('./HotelCard'));
const HotelResult = () => {
  const [hotels, setHotel] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  useEffect(() => {
    getHotelData();
  }, [location.search]);

  const getHotelData = () => {
    setLoading(true);
    const params = new URLSearchParams(window.location.search);
    const payload = {};
    params.forEach((value, key) => {
      if (key === "cityName") return;
      payload[key] = value;
    });

    http
      .post("hotel-search", payload)
      .then((res) => {
        setHotel(res.data.data);
      })
      .catch((err) => {
        toast.error("something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  console.log("hotels", hotels);
  return (
    <WithHeaderFooter>
      <Suspense fallback={<FallbackComp/>}>
        <HotelsHero />
        <Container>
          <Row>
            <Col xs={6} md={2}>

            </Col>
            <Col xs={12} md={10}>
              {loading ? (
                <>
                  <LoadSkeleton /> <LoadSkeleton /> <LoadSkeleton />
                </>
              ) : (
                hotels.map((it) => {
                  return <HotelCard data={it} />;
                })
              )}
            </Col>
          </Row>
        </Container>
      </Suspense>
    </WithHeaderFooter>
  );
};

export default HotelResult;
