import { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Slider from "react-slick";
import Image from "react-bootstrap/Image";
function AttractionInfo({ children, header, images = [], body, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <Button
        variant="outline-light"
        onClick={(e) => {
          e.stopPropagation();
          handleShow();
        }}
        className="me-2"
      >
        {children}
      </Button>
      <Offcanvas style={{ height: "70vh" }} show={show} onHide={handleClose} placement="bottom" {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{header}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {body}
          <Slider {...settings}>
            {images.map((it) => (
              <div>
                <Image
                  style={{
                    height: "40vh",
                    width: "97vw",
                    objectFit: "contain",
                  }}
                  src={it}
                  rounded
                />
              </div>
            ))}
          </Slider>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
export default AttractionInfo;
