import "./page.module.css";
import { lazy, Suspense } from 'react'
import WithHeaderFooter from "../../components/WithHeaderFooter";


import Container from "react-bootstrap/Container";
import FallbackComp from "../../components/Fallback/Index";
import PopularCities from "../hotels/PopularCities";

const HeroSection = lazy(() => import("./HeroSection"));
const PopularFlights = lazy(() => import("./PopularFlights"));
const TrendingCities = lazy(() => import("./TrendingCities"));
const JumboTron = lazy(() => import("./JumboTron"));
const TravelLocations = lazy(() => import("./TravelLocations"));
const FAQsection = lazy(() => import("./FAQsection"));



export const getClientCityInfo = async (callback) => {
  try {
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();

    
    callback(false, data);
  } catch (error) {
    callback(error, false);
  }
};
function Flight() {
  // Function to get client's city information

  return (
    <WithHeaderFooter>
    <Suspense fallback={<FallbackComp/>}>
      <HeroSection />

      <PopularFlights />
      
      <PopularCities/>

      <JumboTron />
      <Container className="mt-3">
        <p
          style={{
            color: "gray",
          }}
        >
          *Flexible plane tickets are available for an additional cost on
          selected airfares
        </p>
      </Container>
      <TravelLocations />

      <FAQsection type="flight" />
      </Suspense>
    </WithHeaderFooter>
  );
}

export default Flight;
