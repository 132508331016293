import React from "react";
import { currency_symbols } from "../checkout/Index";
import { MdFlightTakeoff } from "react-icons/md";
import moment from "moment";
import Badge from "react-bootstrap/Badge";
import { payment } from "../../Api/payment";
import FlightStatus from "./FlightStatus";
import http from "../../Api";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import toast from "react-hot-toast";
import { useState } from "react";
const ListHeader = ({ it, callBack }) => {
  console.log(it.orderDetails);
  const flightPath = [];
  it?.orderDetails?.flightOffers?.forEach((offer) => {
    offer?.itineraries?.forEach((itiner) => {
      itiner.segments.forEach((segments) => {
        flightPath.push({
          departure: segments.departure,
          arrival: segments.arrival,
        });
      });
    });
  });
  const airportCodes = [
    ...new Set(
      flightPath.flatMap((flight) => [
        flight.departure.iataCode,
        flight.arrival.iataCode,
      ])
    ),
  ];
  const dates = flightPath.flatMap((data) => [
    data.departure.at,
    data.arrival.at,
  ]);
  console.log("airportCodes", dates);
  return (
    <div className="ms-2 me-auto">
      <div className="fw-bold">{airportCodes.join(` > `)}</div>
      <div>
        {moment(dates[0]).format("MMM Do h:mm a")} -
        {moment(dates[dates.length - 1]).format("MMM Do h:mm a")}
      </div>
    </div>
  );
};
const ListBody = ({ it, callBack }) => (
  <div
    style={{ cursor: "pointer", fontSize: "1.2rem" }}
    className="d-flex flex-column align-items-center"
  >
    <div>
      <Badge bg="primary" className="m-1 p-2" pill>
        {moment(it.createdAt).fromNow()}
      </Badge>{" "}
      <FlightStatus
        ticketStatus={it.ticketStatus}
        datas={it.orderDetails.flightOffers[0].itineraries}
        placement="bottom"
      >
        <Badge bg="info" className="m-1 p-2" pill>
          check status
        </Badge>
      </FlightStatus>
    </div>
    <div className="d-flex">
      <Badge
        bg={
          it.ticketStatus === "CANCELED"
            ? "secondary"
            : it.paymentDetails === "PAID"
            ? "success"
            : "danger"
        }
        pill
        className="m-1 p-2"
        onClick={() => {
          if(it.ticketStatus==="CANCELED") return;
          if (it.paymentDetails === "NOT_PAID") {
            toast.loading("re-directing to payment page");
            const flightName = [];
            it.orderDetails.flightOffers.forEach((it) => {
              it.itineraries.forEach((itin) => {
                itin.segments.forEach((seg) => {
                  flightName.push(seg.departure?.iataCode);
                  flightName.push(seg.arrival?.iataCode);
                });
              });
            });

            const prodData = {
              currency: it.currency,
              name: it.orderDetails?.bookingId,
              price: it.amount,
              flightName: flightName.join(" > "),
              id: it.bookingId,
            };
            payment(prodData);
          }
        }}
      >
        <strong>
          {currency_symbols[it.orderDetails.flightOffers[0].price.currency]}{" "}
          {it.amount}
        </strong>{" "}
        (
        {it.paymentDetails === "NOT_PAID" ? (
          <a style={{ cursor: "pointer" }}>Pay Now</a>
        ) : (
          "Paid"
        )}
        )
      </Badge>
      <Confirm callBack={callBack} it={it} />
    </div>
  </div>
);
export { ListHeader, ListBody };

const Confirm = ({ it, callBack }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const CancelOrder = async (orderId) => {
    return new Promise((resolve, reject) => {
      toast.loading("requisting cancellation...");
      http
        .get("cancel-order/" + orderId, { withCredentials: true })
        .then((res) => {
          toast.dismiss();
          toast.success("Your Order Has Been Canceled!");
          callBack("get-order");
          resolve();
        })
        .catch((err) => {
          toast.dismiss();
          toast.error("Something Went Wrong!");
          reject();
        });
    });
  };
  return (
    <>
      {it.ticketStatus === "CONFIRM" ? (
        <Badge pill className="m-1 p-2" bg="danger" onClick={handleShow}>
          Cancel Ticket
        </Badge>
      ) : (
        <Badge pill className="m-1 p-2" bg="secondary">
          {it.ticketStatus.toLowerCase()}
        </Badge>
      )}
      {show && (
        <Modal show={true} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Are You Sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="fw-bold">
              Cancel Ticket From{" "}
              {
                it.orderDetails.flightOffers[0].itineraries[0]?.segments[0]
                  ?.departure?.iataCode
              }{" "}
              To{" "}
              {
                it.orderDetails.flightOffers[0].itineraries[0].segments[0]
                  .arrival.iataCode
              }
              <p>{it.bookingId}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() => {
                CancelOrder(it.bookingId).finally(() => {
                  handleClose();
                });
              }}
            >
              Cancel Ticket
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
