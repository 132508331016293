export const airport_name = new Map([
  [
      "AAA",
      "Anaa"
  ],
  [
      "AAE",
      "El Tarf"
  ],
  [
      "AAL",
      "Norresundby"
  ],
  [
      "AAM",
      "Mala Mala"
  ],
  [
      "AAN",
      "Ayn al Faydah"
  ],
  [
      "AAQ",
      "Novorossiysk"
  ],
  [
      "AAR",
      "Kolind"
  ],
  [
      "AAT",
      "Altay"
  ],
  [
      "AAX",
      "Araxá"
  ],
  [
      "AAY",
      "Al Ghaydah"
  ],
  [
      "ABA",
      "Abakan"
  ],
  [
      "ABC",
      "Albacete"
  ],
  [
      "ABD",
      "Abadan"
  ],
  [
      "ABE",
      "Allentown"
  ],
  [
      "ABI",
      "Abilene"
  ],
  [
      "ABJ",
      "Abidjan"
  ],
  [
      "ABK",
      "Kabri Dar"
  ],
  [
      "ABL",
      "Kiana"
  ],
  [
      "ABM",
      "Seisia"
  ],
  [
      "ABQ",
      "Albuquerque"
  ],
  [
      "ABR",
      "Aberdeen"
  ],
  [
      "ABS",
      "Abu Simbel"
  ],
  [
      "ABT",
      "Al Aqiq"
  ],
  [
      "ABV",
      "Gwagwa"
  ],
  [
      "ABX",
      "Albury"
  ],
  [
      "ABY",
      "Albany"
  ],
  [
      "ABZ",
      "Aberdeen"
  ],
  [
      "ACA",
      "Acapulco"
  ],
  [
      "ACC",
      "Accra"
  ],
  [
      "ACE",
      "San Bartolomé"
  ],
  [
      "ACH",
      "Altenrhein"
  ],
  [
      "ACI",
      "St. Peter Port"
  ],
  [
      "ACK",
      "Nantucket"
  ],
  [
      "ACP",
      "Sahand"
  ],
  [
      "ACS",
      "Achinsk"
  ],
  [
      "ACT",
      "Waco"
  ],
  [
      "ACV",
      "Mckinleyville"
  ],
  [
      "ACX",
      "Xingyi"
  ],
  [
      "ACY",
      "Egg Harbor City"
  ],
  [
      "ACZ",
      "Zabol"
  ],
  [
      "ADA",
      "Adana"
  ],
  [
      "ADB",
      "Azmir"
  ],
  [
      "ADD",
      "Addis Ababa"
  ],
  [
      "ADE",
      "Ash Shaykh Uthman"
  ],
  [
      "ADF",
      "Adiyaman"
  ],
  [
      "ADJ",
      "Amman"
  ],
  [
      "ADK",
      "Adak"
  ],
  [
      "ADL",
      "Adelaide"
  ],
  [
      "ADQ",
      "Kodiak"
  ],
  [
      "ADU",
      "Ardabil"
  ],
  [
      "ADX",
      "St. Andrews"
  ],
  [
      "ADZ",
      "San Andrés"
  ],
  [
      "AEH",
      "Abéché"
  ],
  [
      "AEP",
      "Buenos Aires"
  ],
  [
      "AER",
      "Sochi"
  ],
  [
      "AES",
      "Vigra"
  ],
  [
      "AET",
      "Allakaket"
  ],
  [
      "AEX",
      "Alexandria"
  ],
  [
      "AEY",
      "Akureyri"
  ],
  [
      "AFA",
      "San Rafael"
  ],
  [
      "AFL",
      "Alta Floresta"
  ],
  [
      "AFS",
      "Zarafshan"
  ],
  [
      "AFT",
      "Afutara"
  ],
  [
      "AFZ",
      "Sabzevar"
  ],
  [
      "AGA",
      "Agadir"
  ],
  [
      "AGF",
      "Laplume"
  ],
  [
      "AGH",
      "Angelholm"
  ],
  [
      "AGL",
      "Wanigela"
  ],
  [
      "AGM",
      "Angmassalik"
  ],
  [
      "AGN",
      "Angoon"
  ],
  [
      "AGP",
      "Malaga"
  ],
  [
      "AGR",
      "Agra"
  ],
  [
      "AGS",
      "Augusta"
  ],
  [
      "AGT",
      "Presidente Franco"
  ],
  [
      "AGU",
      "Aguascalientes"
  ],
  [
      "AGV",
      "Acarigua"
  ],
  [
      "AGX",
      "Agatti Island"
  ],
  [
      "AHB",
      "Hajlah"
  ],
  [
      "AHC",
      "Herlong"
  ],
  [
      "AHE",
      "Ahe"
  ],
  [
      "AHO",
      "Alghero"
  ],
  [
      "AHS",
      "Auas"
  ],
  [
      "AHU",
      "Al Hoceima"
  ],
  [
      "AIA",
      "Alliance"
  ],
  [
      "AIN",
      "Wainwright"
  ],
  [
      "AIT",
      "Aitutaki"
  ],
  [
      "AIU",
      "Atiu Island"
  ],
  [
      "AJA",
      "Ajaccio"
  ],
  [
      "AJF",
      "Sakakah"
  ],
  [
      "AJI",
      "Agrı"
  ],
  [
      "AJL",
      "Aizawl"
  ],
  [
      "AJN",
      "Anjouan"
  ],
  [
      "AJR",
      "Arvidsjaur"
  ],
  [
      "AJU",
      "Aracaju"
  ],
  [
      "AKA",
      "Ankang"
  ],
  [
      "AKB",
      "Atka"
  ],
  [
      "AKF",
      "Kufrah"
  ],
  [
      "AKI",
      "Bethel"
  ],
  [
      "AKJ",
      "Asahikawa-shi"
  ],
  [
      "AKK",
      "Kodiak"
  ],
  [
      "AKL",
      "Manukau City"
  ],
  [
      "AKN",
      "King Salmon"
  ],
  [
      "AKP",
      "Anaktuvuk Pass"
  ],
  [
      "AKS",
      "Kroonstad"
  ],
  [
      "AKU",
      "Aksu"
  ],
  [
      "AKV",
      "Chisasibi"
  ],
  [
      "AKX",
      "Aqtobe"
  ],
  [
      "AKY",
      "Sittwe"
  ],
  [
      "ALA",
      "Almaty"
  ],
  [
      "ALB",
      "Latham"
  ],
  [
      "ALC",
      "Elx"
  ],
  [
      "ALF",
      "Alta"
  ],
  [
      "ALG",
      "Algiers"
  ],
  [
      "ALH",
      "Albany"
  ],
  [
      "ALM",
      "Alamogordo"
  ],
  [
      "ALO",
      "Waterloo"
  ],
  [
      "ALP",
      "Djibrine"
  ],
  [
      "ALS",
      "Alamosa"
  ],
  [
      "ALW",
      "Walla Walla"
  ],
  [
      "ALY",
      "Alexandria"
  ],
  [
      "ALZ",
      "Alitak"
  ],
  [
      "AMA",
      "Amarillo"
  ],
  [
      "AMD",
      "Ahmedabad"
  ],
  [
      "AMH",
      "Arba Mintch"
  ],
  [
      "AMI",
      "Mataram"
  ],
  [
      "AMM",
      "Amman"
  ],
  [
      "AMQ",
      "Ambon"
  ],
  [
      "AMS",
      "Schiphol Zuid"
  ],
  [
      "AMV",
      "Nar'yan-Mar"
  ],
  [
      "AMY",
      "Ambatomainty"
  ],
  [
      "ANC",
      "Anchorage"
  ],
  [
      "ANE",
      "Seiches-sur-le-Loir"
  ],
  [
      "ANF",
      "Antofagasta"
  ],
  [
      "ANG",
      "Champniers"
  ],
  [
      "ANI",
      "Bethel"
  ],
  [
      "ANM",
      "Antalaha"
  ],
  [
      "ANR",
      "Antwerp"
  ],
  [
      "ANU",
      "Carlisle"
  ],
  [
      "ANV",
      "Anvik"
  ],
  [
      "ANX",
      "Andenes"
  ],
  [
      "AOC",
      "Altenburg"
  ],
  [
      "AOE",
      "Eskişehir"
  ],
  [
      "AOI",
      "Falconara Marittima"
  ],
  [
      "AOJ",
      "Aomori-shi"
  ],
  [
      "AOK",
      "Karpathos"
  ],
  [
      "AOO",
      "Martinsburg"
  ],
  [
      "AOR",
      "Kepala Batas"
  ],
  [
      "AOS",
      "Amook"
  ],
  [
      "APA",
      "Englewood"
  ],
  [
      "APF",
      "Naples"
  ],
  [
      "APK",
      "Apataki"
  ],
  [
      "APL",
      "Nampula"
  ],
  [
      "APN",
      "Alpena"
  ],
  [
      "APO",
      "Apartadó"
  ],
  [
      "APW",
      "Apia"
  ],
  [
      "AQG",
      "Anqing"
  ],
  [
      "AQI",
      "Qaisumah"
  ],
  [
      "AQJ",
      "Aqaba"
  ],
  [
      "AQP",
      "Arequipa"
  ],
  [
      "ARC",
      "Arctic Village"
  ],
  [
      "ARD",
      "Alor Island"
  ],
  [
      "ARH",
      "Arkhangel'sk"
  ],
  [
      "ARI",
      "Arica"
  ],
  [
      "ARK",
      "Arusha"
  ],
  [
      "ARM",
      "Armidale"
  ],
  [
      "ARN",
      "Märst"
  ],
  [
      "ART",
      "Dexter"
  ],
  [
      "ARU",
      "Araçatuba"
  ],
  [
      "ARV",
      "Woodruff"
  ],
  [
      "ARW",
      "Arad"
  ],
  [
      "ASA",
      "Assab"
  ],
  [
      "ASB",
      "Ashgabat"
  ],
  [
      "ASD",
      "Fresh Creek"
  ],
  [
      "ASE",
      "Aspen"
  ],
  [
      "ASF",
      "Astrakhan'"
  ],
  [
      "ASI",
      "Georgetown, Ascension Island"
  ],
  [
      "ASJ",
      "Amami O Shima"
  ],
  [
      "ASM",
      "Asmara"
  ],
  [
      "ASO",
      "Asosa"
  ],
  [
      "ASP",
      "Alice Springs"
  ],
  [
      "ASR",
      "Kayseri"
  ],
  [
      "ASU",
      "Colonia Mariano Roque Alonso"
  ],
  [
      "ASV",
      "Amboseli"
  ],
  [
      "ASW",
      "Aswan"
  ],
  [
      "ATB",
      "Atbara"
  ],
  [
      "ATC",
      "Arthur Town"
  ],
  [
      "ATD",
      "Atoifi"
  ],
  [
      "ATH",
      "Athens"
  ],
  [
      "ATK",
      "Atqasuk"
  ],
  [
      "ATL",
      "Atlanta"
  ],
  [
      "ATM",
      "Altamira"
  ],
  [
      "ATQ",
      "Raja Sansi"
  ],
  [
      "ATR",
      "Atar"
  ],
  [
      "ATT",
      "Atmautluak"
  ],
  [
      "ATW",
      "Appleton"
  ],
  [
      "ATY",
      "Watertown"
  ],
  [
      "ATZ",
      "Asyut"
  ],
  [
      "AUA",
      "Aruba"
  ],
  [
      "AUC",
      "Arauca"
  ],
  [
      "AUG",
      "Augusta"
  ],
  [
      "AUH",
      "Abu Dhabi"
  ],
  [
      "AUK",
      "Alakanuk"
  ],
  [
      "AUQ",
      "Atuona"
  ],
  [
      "AUR",
      "Aurillac"
  ],
  [
      "AUS",
      "Austin"
  ],
  [
      "AUX",
      "Araguaina"
  ],
  [
      "AUY",
      "Aneityum"
  ],
  [
      "AVL",
      "Fletcher"
  ],
  [
      "AVN",
      "Avignon"
  ],
  [
      "AVP",
      "Pittston"
  ],
  [
      "AVU",
      "Avu Avu"
  ],
  [
      "AVV",
      "Lara"
  ],
  [
      "AWB",
      "Awaba"
  ],
  [
      "AWD",
      "Aniwa"
  ],
  [
      "AWZ",
      "Ahvaz"
  ],
  [
      "AXA",
      "Anguilla"
  ],
  [
      "AXD",
      "Alexandroupolis"
  ],
  [
      "AXM",
      "La Tebaida"
  ],
  [
      "AXP",
      "Spring Point"
  ],
  [
      "AXR",
      "Arutua"
  ],
  [
      "AXT",
      "Akita-shi"
  ],
  [
      "AXU",
      "Axum"
  ],
  [
      "AYQ",
      "Ayers Rock"
  ],
  [
      "AYT",
      "Antalya"
  ],
  [
      "AZA",
      "Phoenix"
  ],
  [
      "AZD",
      "Yezd"
  ],
  [
      "AZN",
      "Andijon"
  ],
  [
      "AZO",
      "Kalamazoo"
  ],
  [
      "AZR",
      "Adrar"
  ],
  [
      "AZS",
      "Samana"
  ],
  [
      "BAG",
      "Baguio City"
  ],
  [
      "BAH",
      "Al Muharraq"
  ],
  [
      "BAL",
      "Batman"
  ],
  [
      "BAQ",
      "Soledad"
  ],
  [
      "BAS",
      "Balalae"
  ],
  [
      "BAU",
      "Bauru"
  ],
  [
      "BAV",
      "Baotou"
  ],
  [
      "BAX",
      "Barnaul"
  ],
  [
      "BAY",
      "Baia Mare"
  ],
  [
      "BBA",
      "Balmaceda"
  ],
  [
      "BBI",
      "Bhubaneswar"
  ],
  [
      "BBK",
      "Kasane"
  ],
  [
      "BBN",
      "Nanga Medamit"
  ],
  [
      "BBO",
      "Berbera"
  ],
  [
      "BBS",
      "Camberley"
  ],
  [
      "BBU",
      "Bucharest"
  ],
  [
      "BCA",
      "Baracoa"
  ],
  [
      "BCD",
      "Bacolod City"
  ],
  [
      "BCI",
      "Barcaldine"
  ],
  [
      "BCL",
      "Colorado"
  ],
  [
      "BCM",
      "Bacæu"
  ],
  [
      "BCN",
      "El Prat de Llobregat"
  ],
  [
      "BDA",
      "St. George"
  ],
  [
      "BDB",
      "Bundaberg"
  ],
  [
      "BDD",
      "Badu Island"
  ],
  [
      "BDH",
      "Bandar Abbas"
  ],
  [
      "BDJ",
      "Banjarmasin"
  ],
  [
      "BDL",
      "Windsor Locks"
  ],
  [
      "BDO",
      "Bandung"
  ],
  [
      "BDP",
      "Bhadrapur"
  ],
  [
      "BDQ",
      "Vadodara"
  ],
  [
      "BDR",
      "Stratford"
  ],
  [
      "BDS",
      "Brindisi"
  ],
  [
      "BDU",
      "Bardufoss"
  ],
  [
      "BEB",
      "Balivanich"
  ],
  [
      "BEG",
      "Surčin"
  ],
  [
      "BEL",
      "Belem"
  ],
  [
      "BEN",
      "Benghazi"
  ],
  [
      "BES",
      "Guipavas"
  ],
  [
      "BET",
      "Bethel"
  ],
  [
      "BEU",
      "Bedourie"
  ],
  [
      "BEW",
      "Beira"
  ],
  [
      "BEY",
      "Beirut"
  ],
  [
      "BFD",
      "Lewis Run"
  ],
  [
      "BFE",
      "Bielefeld"
  ],
  [
      "BFF",
      "Scottsbluff"
  ],
  [
      "BFI",
      "Seattle"
  ],
  [
      "BFL",
      "Bakersfield"
  ],
  [
      "BFN",
      "Bloemfontein"
  ],
  [
      "BFS",
      "Crumlin"
  ],
  [
      "BFV",
      "Buri Ram"
  ],
  [
      "BGA",
      "Bucaramanga"
  ],
  [
      "BGF",
      "Bangui"
  ],
  [
      "BGI",
      "Bridgetown"
  ],
  [
      "BGM",
      "Johnson City"
  ],
  [
      "BGO",
      "Blomsterdalen"
  ],
  [
      "BGR",
      "Bangor"
  ],
  [
      "BGW",
      "Baghdad"
  ],
  [
      "BGY",
      "Grassobbio"
  ],
  [
      "BHB",
      "Ellsworth"
  ],
  [
      "BHD",
      "Belfast"
  ],
  [
      "BHE",
      "Blenheim"
  ],
  [
      "BHG",
      "Brus Laguna"
  ],
  [
      "BHH",
      "Bisha"
  ],
  [
      "BHI",
      "Punta Alta"
  ],
  [
      "BHJ",
      "Bhuj"
  ],
  [
      "BHK",
      "Bukhara"
  ],
  [
      "BHM",
      "Birmingham"
  ],
  [
      "BHO",
      "Bhopal"
  ],
  [
      "BHQ",
      "Broken Hill"
  ],
  [
      "BHR",
      "Bharatpur"
  ],
  [
      "BHS",
      "Bathurst"
  ],
  [
      "BHU",
      "Bhavnagar"
  ],
  [
      "BHV",
      "Bahawalpur"
  ],
  [
      "BHX",
      "Birmingham"
  ],
  [
      "BHY",
      "Beihai"
  ],
  [
      "BIA",
      "Borgo"
  ],
  [
      "BID",
      "Block Island"
  ],
  [
      "BII",
      "Bikini Atoll"
  ],
  [
      "BIK",
      "Biak"
  ],
  [
      "BIL",
      "Billings"
  ],
  [
      "BIM",
      "Bailey's Town"
  ],
  [
      "BIO",
      "Loiu"
  ],
  [
      "BIQ",
      "Anglet"
  ],
  [
      "BIR",
      "Biratnagur"
  ],
  [
      "BIS",
      "Bismarck"
  ],
  [
      "BJA",
      "Bejaia"
  ],
  [
      "BJB",
      "Bojnord"
  ],
  [
      "BJF",
      "Baasfjord"
  ],
  [
      "BJI",
      "Bemidji"
  ],
  [
      "BJL",
      "Yundum"
  ],
  [
      "BJM",
      "Bujumbura"
  ],
  [
      "BJR",
      "Bahar Dar"
  ],
  [
      "BJV",
      "Bodrum"
  ],
  [
      "BJW",
      "Ende"
  ],
  [
      "BJX",
      "Silao"
  ],
  [
      "BJZ",
      "Badajoz"
  ],
  [
      "BKA",
      "Lyubertsy"
  ],
  [
      "BKC",
      "Buckland"
  ],
  [
      "BKI",
      "Kota Kinabalu"
  ],
  [
      "BKK",
      "Lak Si"
  ],
  [
      "BKL",
      "Cleveland"
  ],
  [
      "BKM",
      "Bakalalan"
  ],
  [
      "BKO",
      "Kalaban"
  ],
  [
      "BKQ",
      "Mount Enniskillen"
  ],
  [
      "BKS",
      "Bengkulu"
  ],
  [
      "BKW",
      "Beaver"
  ],
  [
      "BKY",
      "Bukavu"
  ],
  [
      "BLA",
      "Pariaguán"
  ],
  [
      "BLE",
      "Borlange"
  ],
  [
      "BLI",
      "Bellingham"
  ],
  [
      "BLJ",
      "Batna"
  ],
  [
      "BLK",
      "Blackpool"
  ],
  [
      "BLL",
      "Billund"
  ],
  [
      "BLQ",
      "Bologna"
  ],
  [
      "BLR",
      "Bangalore"
  ],
  [
      "BLT",
      "Baralaba"
  ],
  [
      "BLV",
      "Belleville"
  ],
  [
      "BLZ",
      "Lunzu"
  ],
  [
      "BMA",
      "Bromma"
  ],
  [
      "BME",
      "Broome"
  ],
  [
      "BMI",
      "Bloomington"
  ],
  [
      "BMK",
      "Borkum"
  ],
  [
      "BMO",
      "Bhamo"
  ],
  [
      "BMU",
      "Raba"
  ],
  [
      "BMV",
      "Banmethuot"
  ],
  [
      "BMW",
      "Bordj Badji Mokhtar"
  ],
  [
      "BMY",
      "Belep Island"
  ],
  [
      "BNA",
      "Nashville"
  ],
  [
      "BND",
      "Bandar Abbas"
  ],
  [
      "BNE",
      "Brisbane"
  ],
  [
      "BNI",
      "Benin City"
  ],
  [
      "BNJ",
      "Bonn"
  ],
  [
      "BNK",
      "Ballina"
  ],
  [
      "BNN",
      "Bronnoysund"
  ],
  [
      "BNS",
      "Barinas"
  ],
  [
      "BNX",
      "Banja Luka"
  ],
  [
      "BNY",
      "Bellona"
  ],
  [
      "BOB",
      "Papeete"
  ],
  [
      "BOC",
      "Bocas del Toro"
  ],
  [
      "BOD",
      "Merignac"
  ],
  [
      "BOG",
      "Fontibón"
  ],
  [
      "BOH",
      "Christchurch"
  ],
  [
      "BOI",
      "Boise"
  ],
  [
      "BOJ",
      "Burgas"
  ],
  [
      "BOM",
      "Mumbai"
  ],
  [
      "BON",
      "Kralendijk"
  ],
  [
      "BOO",
      "Bodo"
  ],
  [
      "BOS",
      "Boston"
  ],
  [
      "BOW",
      "Bartow"
  ],
  [
      "BOY",
      "Bobo-Dioulasso"
  ],
  [
      "BPN",
      "Balikpapan"
  ],
  [
      "BPS",
      "Porto Seguro"
  ],
  [
      "BPT",
      "Beaumont"
  ],
  [
      "BPX",
      "Qamdo"
  ],
  [
      "BPY",
      "Besalampy"
  ],
  [
      "BQK",
      "Brunswick"
  ],
  [
      "BQL",
      "Boulia"
  ],
  [
      "BQN",
      "Aguadilla"
  ],
  [
      "BQS",
      "Blagoveshchensk"
  ],
  [
      "BRA",
      "Barreiras"
  ],
  [
      "BRC",
      "San Carlos DeBariloche"
  ],
  [
      "BRD",
      "Brainerd"
  ],
  [
      "BRE",
      "Bremen"
  ],
  [
      "BRF",
      "Bradford"
  ],
  [
      "BRI",
      "Bari"
  ],
  [
      "BRK",
      "Bourke"
  ],
  [
      "BRL",
      "Burlington"
  ],
  [
      "BRM",
      "Barquisimeto"
  ],
  [
      "BRN",
      "Belp"
  ],
  [
      "BRO",
      "Brownsville"
  ],
  [
      "BRQ",
      "Brno"
  ],
  [
      "BRR",
      "Barra"
  ],
  [
      "BRS",
      "Bristol"
  ],
  [
      "BRU",
      "Bruxelles"
  ],
  [
      "BRV",
      "Bad Bederkesa"
  ],
  [
      "BRW",
      "Barrow"
  ],
  [
      "BSA",
      "Bossaso"
  ],
  [
      "BSB",
      "Lago Sul"
  ],
  [
      "BSC",
      "Bahía Solano"
  ],
  [
      "BSD",
      "Baoshan"
  ],
  [
      "BSG",
      "Bata"
  ],
  [
      "BSH",
      "Brighton"
  ],
  [
      "BSK",
      "Biskra"
  ],
  [
      "BSL",
      "St-Ludwig"
  ],
  [
      "BSO",
      "Basco"
  ],
  [
      "BSR",
      "Gawad"
  ],
  [
      "BSX",
      "Bassein"
  ],
  [
      "BTH",
      "Batam"
  ],
  [
      "BTI",
      "Kaktovik"
  ],
  [
      "BTJ",
      "Banda Aceh"
  ],
  [
      "BTK",
      "Bratsk"
  ],
  [
      "BTL",
      "Battle Creek"
  ],
  [
      "BTM",
      "Butte"
  ],
  [
      "BTR",
      "Baton Rouge"
  ],
  [
      "BTS",
      "Bratislava"
  ],
  [
      "BTT",
      "Bettles"
  ],
  [
      "BTU",
      "Bintulu"
  ],
  [
      "BTV",
      "South Burlington"
  ],
  [
      "BTZ",
      "Bursa"
  ],
  [
      "BUA",
      "Buka"
  ],
  [
      "BUC",
      "Burketown"
  ],
  [
      "BUD",
      "Budapest"
  ],
  [
      "BUF",
      "Buffalo"
  ],
  [
      "BUL",
      "Bulolo"
  ],
  [
      "BUN",
      "Buenaventura"
  ],
  [
      "BUO",
      "Burao"
  ],
  [
      "BUQ",
      "Bulawayo"
  ],
  [
      "BUR",
      "Burbank"
  ],
  [
      "BUS",
      "Batumi"
  ],
  [
      "BUX",
      "Bunia"
  ],
  [
      "BUZ",
      "Bandar-e Bushehr"
  ],
  [
      "BVA",
      "Beauvais"
  ],
  [
      "BVB",
      "Boa Vista"
  ],
  [
      "BVC",
      "Boa Vista"
  ],
  [
      "BVE",
      "Brive-la-Gaillarde"
  ],
  [
      "BVG",
      "Berlevaag"
  ],
  [
      "BVH",
      "Vilhena"
  ],
  [
      "BVI",
      "Birdsville"
  ],
  [
      "BWA",
      "Bhairawa"
  ],
  [
      "BWE",
      "Braunschweig"
  ],
  [
      "BWF",
      "Barrow in Furness"
  ],
  [
      "BWI",
      "Baltimore"
  ],
  [
      "BWK",
      "Bol"
  ],
  [
      "BWN",
      "Bandar Seri Begawan"
  ],
  [
      "BWT",
      "Doctors Rocks"
  ],
  [
      "BWW",
      "Santa Clara"
  ],
  [
      "BXR",
      "Bam"
  ],
  [
      "BXU",
      "Buenavista"
  ],
  [
      "BYM",
      "Bayamo"
  ],
  [
      "BYN",
      "Bayankhongor"
  ],
  [
      "BZE",
      "Hattieville"
  ],
  [
      "BZG",
      "Białe Błota"
  ],
  [
      "BZK",
      "Briansk"
  ],
  [
      "BZL",
      "Barisal"
  ],
  [
      "BZN",
      "Bozeman"
  ],
  [
      "BZO",
      "Botzen"
  ],
  [
      "BZR",
      "Villeneuve"
  ],
  [
      "BZV",
      "Brazzaville"
  ],
  [
      "BZZ",
      "Carterton"
  ],
  [
      "CAB",
      "Cabinda"
  ],
  [
      "CAC",
      "Cascavel"
  ],
  [
      "CAE",
      "West Columbia"
  ],
  [
      "CAG",
      "Sardara"
  ],
  [
      "CAH",
      "Ca Mau"
  ],
  [
      "CAI",
      "Cairo"
  ],
  [
      "CAK",
      "Canton"
  ],
  [
      "CAL",
      "Campbeltown"
  ],
  [
      "CAN",
      "Guangzhou"
  ],
  [
      "CAP",
      "Cap-Haïtien"
  ],
  [
      "CAX",
      "Carlisle"
  ],
  [
      "CAY",
      "Cayenne"
  ],
  [
      "CAZ",
      "Canbelego"
  ],
  [
      "CBB",
      "Cochabamba"
  ],
  [
      "CBG",
      "Cambridge"
  ],
  [
      "CBH",
      "Bechar"
  ],
  [
      "CBO",
      "Sultan Kudarat"
  ],
  [
      "CBQ",
      "Duke Town"
  ],
  [
      "CBR",
      "Canberra"
  ],
  [
      "CBT",
      "Catumbela"
  ],
  [
      "CCC",
      "Cayo Coco"
  ],
  [
      "CCF",
      "Carcassonne"
  ],
  [
      "CCJ",
      "Pallikkal"
  ],
  [
      "CCK",
      "West Island"
  ],
  [
      "CCM",
      "Forquilhinha"
  ],
  [
      "CCP",
      "Hualpencillo"
  ],
  [
      "CCS",
      "Catia la Mar"
  ],
  [
      "CCU",
      "Kolkata"
  ],
  [
      "CCV",
      "Craig Cove"
  ],
  [
      "CCZ",
      "Chub Cay"
  ],
  [
      "CDB",
      "Cold Bay"
  ],
  [
      "CDC",
      "Cedar City"
  ],
  [
      "CDG",
      "Le Mesnil-Amelot"
  ],
  [
      "CDR",
      "Chadron"
  ],
  [
      "CDV",
      "Cordova"
  ],
  [
      "CDW",
      "Fairfield"
  ],
  [
      "CEB",
      "Cebu"
  ],
  [
      "CEC",
      "Crescent City"
  ],
  [
      "CED",
      "Ceduna"
  ],
  [
      "CEE",
      "Cherepovets"
  ],
  [
      "CEG",
      "Chester"
  ],
  [
      "CEI",
      "Chiang Rai"
  ],
  [
      "CEK",
      "Zlatoust"
  ],
  [
      "CEM",
      "Circle"
  ],
  [
      "CEN",
      "Ciudad Obregón"
  ],
  [
      "CEZ",
      "Cortez"
  ],
  [
      "CFC",
      "Caçador"
  ],
  [
      "CFE",
      "Aulnat"
  ],
  [
      "CFK",
      "Chlef"
  ],
  [
      "CFN",
      "Mullaghduff"
  ],
  [
      "CFR",
      "Carpiquet"
  ],
  [
      "CFS",
      "Coffs Harbour"
  ],
  [
      "CFU",
      "Corcyra"
  ],
  [
      "CGA",
      "Craig"
  ],
  [
      "CGB",
      "Cuiaba"
  ],
  [
      "CGD",
      "Changde"
  ],
  [
      "CGH",
      "Sao Paulo"
  ],
  [
      "CGI",
      "Scott City"
  ],
  [
      "CGK",
      "Tangerang"
  ],
  [
      "CGM",
      "Camiguin"
  ],
  [
      "CGN",
      "Cologne"
  ],
  [
      "CGO",
      "Zhengzhou"
  ],
  [
      "CGP",
      "Chittagong"
  ],
  [
      "CGQ",
      "Changchun"
  ],
  [
      "CGR",
      "Campo Grande"
  ],
  [
      "CGY",
      "Cagayan de Oro"
  ],
  [
      "CHA",
      "Chattanooga"
  ],
  [
      "CHC",
      "Christchurch"
  ],
  [
      "CHO",
      "Earlysville"
  ],
  [
      "CHQ",
      "Canea"
  ],
  [
      "CHS",
      "North Charleston"
  ],
  [
      "CHT",
      "Chatham Island"
  ],
  [
      "CHU",
      "Chuathbaluk"
  ],
  [
      "CHY",
      "Choiseul Bay"
  ],
  [
      "CIA",
      "Rome"
  ],
  [
      "CIC",
      "Chico"
  ],
  [
      "CID",
      "Cedar Rapids"
  ],
  [
      "CIF",
      "Chifeng"
  ],
  [
      "CIH",
      "Changzhi"
  ],
  [
      "CIJ",
      "Cobija"
  ],
  [
      "CIK",
      "Chalkyitsik"
  ],
  [
      "CIP",
      "Chipata"
  ],
  [
      "CIT",
      "Shimkent"
  ],
  [
      "CIU",
      "Kincheloe"
  ],
  [
      "CIW",
      "Canouan Island"
  ],
  [
      "CIX",
      "Chiclayo"
  ],
  [
      "CJA",
      "Cajamarca"
  ],
  [
      "CJB",
      "Coimbatore"
  ],
  [
      "CJC",
      "Calama"
  ],
  [
      "CJJ",
      "Naesu-Eup"
  ],
  [
      "CJL",
      "Chitral"
  ],
  [
      "CJS",
      "Ciudad Juarez"
  ],
  [
      "CJU",
      "Jeju-Si"
  ],
  [
      "CKB",
      "Clarksburg"
  ],
  [
      "CKD",
      "Aniak"
  ],
  [
      "CKG",
      "Chongqing"
  ],
  [
      "CKH",
      "Chokurdah"
  ],
  [
      "CKS",
      "Carajas"
  ],
  [
      "CKX",
      "Tok"
  ],
  [
      "CKY",
      "Conakry"
  ],
  [
      "CKZ",
      "Canakkale"
  ],
  [
      "CLD",
      "Carlsbad"
  ],
  [
      "CLE",
      "Cleveland"
  ],
  [
      "CLJ",
      "Clausemburgo"
  ],
  [
      "CLL",
      "College Station"
  ],
  [
      "CLM",
      "Port Angeles"
  ],
  [
      "CLO",
      "Obando"
  ],
  [
      "CLP",
      "Clarks Point"
  ],
  [
      "CLQ",
      "Cuauhtémoc"
  ],
  [
      "CLT",
      "Charlotte"
  ],
  [
      "CLY",
      "Calenzana"
  ],
  [
      "CMA",
      "Cunnamulla"
  ],
  [
      "CMB",
      "Gampaha"
  ],
  [
      "CME",
      "Carmen Olorón"
  ],
  [
      "CMF",
      "La Motte"
  ],
  [
      "CMG",
      "Corumba"
  ],
  [
      "CMH",
      "Columbus"
  ],
  [
      "CMI",
      "Savoy"
  ],
  [
      "CMN",
      "Casablanca"
  ],
  [
      "CMU",
      "Kundiawa"
  ],
  [
      "CMW",
      "Camagüey"
  ],
  [
      "CMX",
      "Hancock"
  ],
  [
      "CNB",
      "Coonamble"
  ],
  [
      "CNC",
      "Coconut Island"
  ],
  [
      "CND",
      "Kustenje"
  ],
  [
      "CNF",
      "Confins"
  ],
  [
      "CNJ",
      "Cloncurry"
  ],
  [
      "CNM",
      "Carlsbad"
  ],
  [
      "CNP",
      "Neerlerit Inaat"
  ],
  [
      "CNQ",
      "Corrientes"
  ],
  [
      "CNS",
      "Cairns"
  ],
  [
      "CNX",
      "Chiang Mai"
  ],
  [
      "CNY",
      "Thompson"
  ],
  [
      "COD",
      "Cody/Yellowstone"
  ],
  [
      "COK",
      "Kochi"
  ],
  [
      "COL",
      "Isle of coll"
  ],
  [
      "COO",
      "Cotonou"
  ],
  [
      "COQ",
      "Choibalsan"
  ],
  [
      "COR",
      "Cordoba"
  ],
  [
      "COS",
      "Colorado Springs"
  ],
  [
      "COU",
      "Columbia"
  ],
  [
      "CPC",
      "San Martin DeLos Andes"
  ],
  [
      "CPD",
      "Coober Pedy"
  ],
  [
      "CPE",
      "Campeche"
  ],
  [
      "CPH",
      "Kastrup"
  ],
  [
      "CPO",
      "Copiapó"
  ],
  [
      "CPQ",
      "Campinas"
  ],
  [
      "CPR",
      "Casper"
  ],
  [
      "CPT",
      "Cape Town"
  ],
  [
      "CPV",
      "Campina Grande"
  ],
  [
      "CPX",
      "Culebra"
  ],
  [
      "CQD",
      "Shahre-Kord"
  ],
  [
      "CRA",
      "Croiova"
  ],
  [
      "CRD",
      "Comodoro Rivadavia"
  ],
  [
      "CRI",
      "Colonel Hill"
  ],
  [
      "CRK",
      "Mabalacat"
  ],
  [
      "CRL",
      "Charleroi"
  ],
  [
      "CRM",
      "Catarman"
  ],
  [
      "CRP",
      "Corpus Christi"
  ],
  [
      "CRW",
      "Charleston"
  ],
  [
      "CSA",
      "Isle Of Colonsay"
  ],
  [
      "CSG",
      "Columbus"
  ],
  [
      "CSH",
      "Solovetsky"
  ],
  [
      "CSK",
      "Cap Skirring"
  ],
  [
      "CSX",
      "Changsha"
  ],
  [
      "CSY",
      "Cheboksary"
  ],
  [
      "CTA",
      "Catania"
  ],
  [
      "CTC",
      "San Isidro"
  ],
  [
      "CTG",
      "La Boquilla"
  ],
  [
      "CTL",
      "Charleville"
  ],
  [
      "CTM",
      "Chetumal"
  ],
  [
      "CTS",
      "Chitose-shi"
  ],
  [
      "CTU",
      "Chengdu"
  ],
  [
      "CUC",
      "Cúcuta"
  ],
  [
      "CUE",
      "Cuenca"
  ],
  [
      "CUF",
      "Fossano"
  ],
  [
      "CUK",
      "Caye Caulker"
  ],
  [
      "CUL",
      "Culiacan"
  ],
  [
      "CUM",
      "Cumaná"
  ],
  [
      "CUN",
      "Cancun"
  ],
  [
      "CUP",
      "El Pilar"
  ],
  [
      "CUR",
      "Curacao"
  ],
  [
      "CUU",
      "Chihuahua"
  ],
  [
      "CUZ",
      "San Sebastián"
  ],
  [
      "CVG",
      "Hebron"
  ],
  [
      "CVM",
      "Güémez"
  ],
  [
      "CVN",
      "Texico"
  ],
  [
      "CVQ",
      "Carnarvon"
  ],
  [
      "CVT",
      "Coventry"
  ],
  [
      "CVU",
      "Corvo"
  ],
  [
      "CWA",
      "Mosinee"
  ],
  [
      "CWB",
      "Sao Jose dos Pinhais"
  ],
  [
      "CWC",
      "Chernivtsi"
  ],
  [
      "CWL",
      "Barry"
  ],
  [
      "CXB",
      "Cox's Bazar"
  ],
  [
      "CXH",
      "Vancouver"
  ],
  [
      "CXI",
      "Christmas Island"
  ],
  [
      "CXJ",
      "Caxias do Sul"
  ],
  [
      "CXR",
      "Nha Trang"
  ],
  [
      "CYB",
      "Cayman Brac Is"
  ],
  [
      "CYF",
      "Chefornak"
  ],
  [
      "CYI",
      "Chiayi City"
  ],
  [
      "CYO",
      "Cayo Largo Del Sur"
  ],
  [
      "CYP",
      "Calbayog"
  ],
  [
      "CYS",
      "Cheyenne"
  ],
  [
      "CYU",
      "Cuyo"
  ],
  [
      "CYX",
      "Cherskiy"
  ],
  [
      "CYZ",
      "Cauayan"
  ],
  [
      "CZE",
      "La Vela de Coro"
  ],
  [
      "CZH",
      "Corozal"
  ],
  [
      "CZL",
      "Constantine"
  ],
  [
      "CZM",
      "San Miguel de Cozumel"
  ],
  [
      "CZN",
      "Gakona"
  ],
  [
      "CZS",
      "Cruzeiro Do Sul"
  ],
  [
      "CZU",
      "Morroa"
  ],
  [
      "CZX",
      "Changzhou"
  ],
  [
      "DAB",
      "Daytona Beach"
  ],
  [
      "DAC",
      "Dhaka"
  ],
  [
      "DAD",
      "Tourane"
  ],
  [
      "DAL",
      "Dallas"
  ],
  [
      "DAM",
      "Damascus"
  ],
  [
      "DAR",
      "Dar es Salaam"
  ],
  [
      "DAT",
      "Datong"
  ],
  [
      "DAU",
      "Daru"
  ],
  [
      "DAX",
      "Daxian"
  ],
  [
      "DAY",
      "Dayton"
  ],
  [
      "DBA",
      "Dalbandin"
  ],
  [
      "DBO",
      "Dubbo"
  ],
  [
      "DBQ",
      "Dubuque"
  ],
  [
      "DBV",
      "Dubrovnik"
  ],
  [
      "DCA",
      "Arlington"
  ],
  [
      "DCF",
      "Portsmouth"
  ],
  [
      "DCM",
      "Labruguière"
  ],
  [
      "DDC",
      "Dodge City"
  ],
  [
      "DDG",
      "Dandong"
  ],
  [
      "DEA",
      "Dera Ghazi Khan"
  ],
  [
      "DEC",
      "Decatur"
  ],
  [
      "DED",
      "Dehra Dun"
  ],
  [
      "DEF",
      "Dezful"
  ],
  [
      "DEL",
      "New Delhi"
  ],
  [
      "DEN",
      "Denver"
  ],
  [
      "DEZ",
      "Dayr az Zawr"
  ],
  [
      "DFW",
      "Dallas"
  ],
  [
      "DGA",
      "Dangriga"
  ],
  [
      "DGE",
      "Apple Tree Flat"
  ],
  [
      "DGM",
      "Dongguan"
  ],
  [
      "DGO",
      "Durango"
  ],
  [
      "DGT",
      "Sibulan"
  ],
  [
      "DHM",
      "Dharamsala"
  ],
  [
      "DHN",
      "Dothan"
  ],
  [
      "DIB",
      "Dikhari"
  ],
  [
      "DIE",
      "Antseranana"
  ],
  [
      "DIG",
      "Diqing"
  ],
  [
      "DIK",
      "Dickinson"
  ],
  [
      "DIL",
      "Dili"
  ],
  [
      "DIN",
      "Dien Bien Phu"
  ],
  [
      "DIR",
      "Dire Dawa"
  ],
  [
      "DIS",
      "Loubomo"
  ],
  [
      "DIU",
      "Diu"
  ],
  [
      "DIY",
      "Diyaribakir"
  ],
  [
      "DJB",
      "Jambi"
  ],
  [
      "DJE",
      "Hawmat as Suq"
  ],
  [
      "DJG",
      "Illizi"
  ],
  [
      "DJJ",
      "Jayapura"
  ],
  [
      "DKR",
      "Ngor"
  ],
  [
      "DLA",
      "Douala"
  ],
  [
      "DLC",
      "Dalian"
  ],
  [
      "DLG",
      "Dillingham"
  ],
  [
      "DLH",
      "Duluth"
  ],
  [
      "DLI",
      "Lam Dong"
  ],
  [
      "DLM",
      "Ortaca"
  ],
  [
      "DLU",
      "Dali City"
  ],
  [
      "DLY",
      "Dillons Bay"
  ],
  [
      "DLZ",
      "Dalanzadgad"
  ],
  [
      "DMD",
      "Doomadgee"
  ],
  [
      "DME",
      "Podol'sk"
  ],
  [
      "DMK",
      "Don Muang"
  ],
  [
      "DMM",
      "Khuwaylidiyah"
  ],
  [
      "DMU",
      "Dimapur"
  ],
  [
      "DND",
      "Dundee"
  ],
  [
      "DNH",
      "Dunhuang"
  ],
  [
      "DNK",
      "Dnipropetrovs'k"
  ],
  [
      "DNR",
      "Pleurtuit"
  ],
  [
      "DNZ",
      "Denizli"
  ],
  [
      "DOG",
      "Dongola"
  ],
  [
      "DOH",
      "Doha"
  ],
  [
      "DOK",
      "Donets'k"
  ],
  [
      "DOM",
      "Marigot"
  ],
  [
      "DOP",
      "Dolpa"
  ],
  [
      "DOU",
      "Dourados"
  ],
  [
      "DOY",
      "Dongying"
  ],
  [
      "DPL",
      "Polanco"
  ],
  [
      "DPO",
      "Devonport"
  ],
  [
      "DPS",
      "Denpasar"
  ],
  [
      "DRG",
      "Deering"
  ],
  [
      "DRO",
      "Durango"
  ],
  [
      "DRS",
      "Ottendorf-Okrilla"
  ],
  [
      "DRT",
      "Del Rio"
  ],
  [
      "DRW",
      "Darwin"
  ],
  [
      "DSA",
      "Doncaster Sheffield"
  ],
  [
      "DSM",
      "Des Moines"
  ],
  [
      "DSN",
      "Dongsheng"
  ],
  [
      "DTM",
      "Dortmund"
  ],
  [
      "DTW",
      "Detroit"
  ],
  [
      "DUB",
      "Cloghran"
  ],
  [
      "DUD",
      "Outram"
  ],
  [
      "DUE",
      "Dundo"
  ],
  [
      "DUJ",
      "Reynoldsville"
  ],
  [
      "DUQ",
      "Duncan"
  ],
  [
      "DUR",
      "Durban"
  ],
  [
      "DUS",
      "Dusseldorf"
  ],
  [
      "DUT",
      "Unalaska"
  ],
  [
      "DVL",
      "Devils Lake"
  ],
  [
      "DVO",
      "Davao City"
  ],
  [
      "DWB",
      "Soalala"
  ],
  [
      "DWD",
      "Dawadmi"
  ],
  [
      "DXB",
      "Dubai"
  ],
  [
      "DYG",
      "Dayong"
  ],
  [
      "DYR",
      "Anadyr'"
  ],
  [
      "DYU",
      "Dushanbe"
  ],
  [
      "DZA",
      "Mamoudzou"
  ],
  [
      "DZN",
      "Zhezqazghan"
  ],
  [
      "EAA",
      "Tok"
  ],
  [
      "EAE",
      "Emae"
  ],
  [
      "EAL",
      "Kwajalein Atoll"
  ],
  [
      "EAM",
      "Najran"
  ],
  [
      "EAR",
      "Kearney"
  ],
  [
      "EAS",
      "Hondarribia"
  ],
  [
      "EAT",
      "East Wenatchee"
  ],
  [
      "EAU",
      "Eau Claire"
  ],
  [
      "EBA",
      "Pianosa"
  ],
  [
      "EBB",
      "Entebbe"
  ],
  [
      "EBD",
      "Al Ubayyid"
  ],
  [
      "EBJ",
      "Esbjerg"
  ],
  [
      "EBL",
      "Erbil"
  ],
  [
      "ECN",
      "Nicosia"
  ],
  [
      "EDA",
      "Ketchikan"
  ],
  [
      "EDI",
      "Edinburgh"
  ],
  [
      "EDL",
      "Nakuru"
  ],
  [
      "EDO",
      "Edremit"
  ],
  [
      "EDR",
      "Edward River"
  ],
  [
      "EEK",
      "Eek"
  ],
  [
      "EFL",
      "Dhilianata"
  ],
  [
      "EGC",
      "Bergerac"
  ],
  [
      "EGE",
      "Gypsum"
  ],
  [
      "EGN",
      "Geneina"
  ],
  [
      "EGO",
      "Belgorod"
  ],
  [
      "EGS",
      "Egilsstadir"
  ],
  [
      "EGV",
      "Eagle River"
  ],
  [
      "EGX",
      "Egegik"
  ],
  [
      "EIB",
      "Eisenach"
  ],
  [
      "EIE",
      "Eniseysk"
  ],
  [
      "EIK",
      "Tonsberg"
  ],
  [
      "EIN",
      "Eindhoven"
  ],
  [
      "EIS",
      "Beef Island"
  ],
  [
      "EJA",
      "Barrancabermeja"
  ],
  [
      "EJH",
      "Wedjh"
  ],
  [
      "EKO",
      "Elko"
  ],
  [
      "EKS",
      ""
  ],
  [
      "ELC",
      "Darwin"
  ],
  [
      "ELF",
      "El Fasher"
  ],
  [
      "ELG",
      "El Golea"
  ],
  [
      "ELH",
      "The Bluff"
  ],
  [
      "ELI",
      "Elim"
  ],
  [
      "ELM",
      "Horseheads"
  ],
  [
      "ELP",
      "El Paso"
  ],
  [
      "ELQ",
      "Al Qara'"
  ],
  [
      "ELS",
      "East London"
  ],
  [
      "ELU",
      "El Oued"
  ],
  [
      "ELV",
      "Elfin Cove"
  ],
  [
      "ELY",
      "Ely"
  ],
  [
      "EMA",
      "Derby"
  ],
  [
      "EMD",
      "Emerald"
  ],
  [
      "EME",
      "Emden"
  ],
  [
      "EMK",
      "Alakanuk"
  ],
  [
      "ENA",
      "Kenai"
  ],
  [
      "ENE",
      "Ende"
  ],
  [
      "ENF",
      "Leppäjärvi"
  ],
  [
      "ENH",
      "Enshi"
  ],
  [
      "ENI",
      "El Nido"
  ],
  [
      "ENS",
      "Enschede"
  ],
  [
      "ENU",
      "Enugu"
  ],
  [
      "ENW",
      "Kenosha"
  ],
  [
      "ENY",
      "Yan'an"
  ],
  [
      "EOH",
      "Medellin"
  ],
  [
      "EOZ",
      "Elorza"
  ],
  [
      "EPR",
      "Gibson"
  ],
  [
      "EQS",
      "Esquel"
  ],
  [
      "ERC",
      "Erzincan"
  ],
  [
      "ERF",
      "Erfurt"
  ],
  [
      "ERH",
      "Errachidia"
  ],
  [
      "ERI",
      "Erie"
  ],
  [
      "ERM",
      "Erechim"
  ],
  [
      "ERS",
      "Windhoek"
  ],
  [
      "ERZ",
      "Erzurum"
  ],
  [
      "ESB",
      "Çubuk"
  ],
  [
      "ESC",
      "Escanaba"
  ],
  [
      "ESD",
      "Eastsound"
  ],
  [
      "ESM",
      "Tachina"
  ],
  [
      "ESR",
      "Diego de Almagro"
  ],
  [
      "ESS",
      "Mulheim-on-Ruhr"
  ],
  [
      "ESU",
      "Essaouira"
  ],
  [
      "ETH",
      "Elat"
  ],
  [
      "ETZ",
      "Verny"
  ],
  [
      "EUA",
      "Eua"
  ],
  [
      "EUG",
      "Eugene"
  ],
  [
      "EUM",
      "Bordesholm"
  ],
  [
      "EUN",
      "Laayoune"
  ],
  [
      "EUX",
      "Oranjestad"
  ],
  [
      "EVE",
      "Tarnstad"
  ],
  [
      "EVG",
      "Sveg"
  ],
  [
      "EVN",
      "Yerevan"
  ],
  [
      "EVV",
      "Evansville"
  ],
  [
      "EWB",
      "New Bedford"
  ],
  [
      "EWD",
      "Wildman Lake"
  ],
  [
      "EWN",
      "New Bern"
  ],
  [
      "EWR",
      "Newark"
  ],
  [
      "EXT",
      "Exeter"
  ],
  [
      "EYP",
      "Yopal"
  ],
  [
      "EYW",
      "Key West"
  ],
  [
      "EZE",
      "Ezeiza"
  ],
  [
      "EZS",
      "Elazığ"
  ],
  [
      "FAB",
      "Farnborough"
  ],
  [
      "FAE",
      "Faroe Islands"
  ],
  [
      "FAI",
      "Fairbanks"
  ],
  [
      "FAO",
      "Faro"
  ],
  [
      "FAR",
      "Fargo"
  ],
  [
      "FAT",
      "Fresno"
  ],
  [
      "FAV",
      "Fakarava"
  ],
  [
      "FAY",
      "Fayetteville"
  ],
  [
      "FBM",
      "Lubumbashi"
  ],
  [
      "FCA",
      "Kalispell"
  ],
  [
      "FCN",
      "Cuxhaven"
  ],
  [
      "FCO",
      "Rome"
  ],
  [
      "FDE",
      "Bygstad"
  ],
  [
      "FDF",
      "Ducos"
  ],
  [
      "FDH",
      "Meckenbeuren"
  ],
  [
      "FEG",
      "Fergana"
  ],
  [
      "FEN",
      "Vila dos Remédios"
  ],
  [
      "FEZ",
      "Fez"
  ],
  [
      "FGU",
      "Fangatau"
  ],
  [
      "FHZ",
      "Fakahina"
  ],
  [
      "FIH",
      "Kinshasa"
  ],
  [
      "FJR",
      "Al Fujayrah"
  ],
  [
      "FKB",
      "Rheinmünster"
  ],
  [
      "FKI",
      "Kisangani"
  ],
  [
      "FKL",
      "Franklin"
  ],
  [
      "FKQ",
      "Fak Fak"
  ],
  [
      "FKS",
      "Sukagawa-shi"
  ],
  [
      "FLA",
      "Florencia"
  ],
  [
      "FLG",
      "Flagstaff"
  ],
  [
      "FLL",
      "Dania Beach"
  ],
  [
      "FLN",
      "Florianopolis"
  ],
  [
      "FLO",
      "Florence"
  ],
  [
      "FLR",
      "Florence"
  ],
  [
      "FLW",
      "Flores Island"
  ],
  [
      "FMA",
      "Formosa"
  ],
  [
      "FMM",
      "Memmingen"
  ],
  [
      "FMN",
      "Farmington"
  ],
  [
      "FMO",
      "Greven"
  ],
  [
      "FMY",
      "Fort Myers"
  ],
  [
      "FNA",
      "Freetown"
  ],
  [
      "FNC",
      "Madeira"
  ],
  [
      "FNI",
      "St-Gilles-du-Gard"
  ],
  [
      "FNJ",
      "Sunan"
  ],
  [
      "FNL",
      "Loveland"
  ],
  [
      "FNT",
      "Flint"
  ],
  [
      "FOC",
      "Fuzhou"
  ],
  [
      "FOD",
      "Fort Dodge"
  ],
  [
      "FOG",
      "Foggia"
  ],
  [
      "FOR",
      "Fortaleza"
  ],
  [
      "FPO",
      "Freeport City"
  ],
  [
      "FRA",
      "Frankfurt"
  ],
  [
      "FRC",
      "Franca"
  ],
  [
      "FRD",
      "Friday Harbor"
  ],
  [
      "FRE",
      "Fera Island"
  ],
  [
      "FRL",
      "Forli"
  ],
  [
      "FRO",
      "Floro"
  ],
  [
      "FRS",
      "Flores"
  ],
  [
      "FRU",
      "Bishkek"
  ],
  [
      "FRW",
      "Francistown"
  ],
  [
      "FSC",
      "Figari"
  ],
  [
      "FSD",
      "Sioux Falls"
  ],
  [
      "FSM",
      "Fort Smith"
  ],
  [
      "FSP",
      "St Pierre"
  ],
  [
      "FTA",
      "Futuna Island"
  ],
  [
      "FTE",
      "El Calafate"
  ],
  [
      "FTU",
      "Faradofay"
  ],
  [
      "FUE",
      "Antigua"
  ],
  [
      "FUG",
      "Fuyang"
  ],
  [
      "FUJ",
      "Goto-shi"
  ],
  [
      "FUK",
      "Fukuoka-shi"
  ],
  [
      "FUN",
      "Funafuti"
  ],
  [
      "FUT",
      "Futuna Island"
  ],
  [
      "FWA",
      "Fort Wayne"
  ],
  [
      "FWM",
      "Fort William"
  ],
  [
      "FYU",
      "Fort Yukon"
  ],
  [
      "FZO",
      "Bristol"
  ],
  [
      "GAE",
      "Gabes"
  ],
  [
      "GAF",
      "Gafsa"
  ],
  [
      "GAJ",
      "Higashine-shi"
  ],
  [
      "GAL",
      "Galena"
  ],
  [
      "GAM",
      "Gambell"
  ],
  [
      "GAN",
      "Hithadhoo"
  ],
  [
      "GAO",
      "Guantanamo"
  ],
  [
      "GAU",
      "Gawahati"
  ],
  [
      "GAX",
      "Gamba"
  ],
  [
      "GAY",
      "Gaya"
  ],
  [
      "GBD",
      "Great Bend"
  ],
  [
      "GBE",
      "Tlokweng"
  ],
  [
      "GBJ",
      "Grand-Bourg"
  ],
  [
      "GBT",
      "Gorgan"
  ],
  [
      "GCC",
      "Gillette"
  ],
  [
      "GCI",
      "St. Peter Port"
  ],
  [
      "GCK",
      "Pierceville"
  ],
  [
      "GCM",
      "Georgetown"
  ],
  [
      "GDE",
      "Gode/Iddidole"
  ],
  [
      "GDL",
      "Tlajomulco de Zúñiga"
  ],
  [
      "GDN",
      "Gdansk"
  ],
  [
      "GDO",
      "Barinas"
  ],
  [
      "GDQ",
      "Azezo"
  ],
  [
      "GDT",
      "Grand Turk"
  ],
  [
      "GDX",
      "Magadan"
  ],
  [
      "GEA",
      "Noumea"
  ],
  [
      "GEG",
      "Spokane"
  ],
  [
      "GEL",
      "Santo Angelo"
  ],
  [
      "GEO",
      "Hyde Park"
  ],
  [
      "GER",
      "Nueva Gerona"
  ],
  [
      "GES",
      "General Santos City"
  ],
  [
      "GET",
      "Geraldton"
  ],
  [
      "GEV",
      "Koskullskulle"
  ],
  [
      "GFF",
      "Griffith"
  ],
  [
      "GFK",
      "Grand Forks"
  ],
  [
      "GFN",
      "Grafton"
  ],
  [
      "GGG",
      "Longview"
  ],
  [
      "GGT",
      "George Town"
  ],
  [
      "GHA",
      "Ghardaia"
  ],
  [
      "GHB",
      "Governor Harbour"
  ],
  [
      "GHT",
      "Ghat"
  ],
  [
      "GIB",
      "Gibraltar"
  ],
  [
      "GIC",
      "Kubin Village"
  ],
  [
      "GIG",
      "Rio de Janeiro"
  ],
  [
      "GIL",
      "Gilgit"
  ],
  [
      "GIS",
      "Gisborne"
  ],
  [
      "GIZ",
      "Jizan"
  ],
  [
      "GJA",
      "Guanaja"
  ],
  [
      "GJL",
      "Jijel"
  ],
  [
      "GJT",
      "Grand Junction"
  ],
  [
      "GKA",
      "Goroka"
  ],
  [
      "GLA",
      "Paisley"
  ],
  [
      "GLF",
      "Palmar Sur"
  ],
  [
      "GLH",
      "Greenville"
  ],
  [
      "GLK",
      "Gaalkacyo"
  ],
  [
      "GLN",
      "Goulimime"
  ],
  [
      "GLO",
      "Cheltenham"
  ],
  [
      "GLT",
      "Gladstone"
  ],
  [
      "GLV",
      "Golovin"
  ],
  [
      "GMA",
      "Gemena"
  ],
  [
      "GMB",
      "Gambela"
  ],
  [
      "GMP",
      "Seoul"
  ],
  [
      "GMR",
      "Gambier Is"
  ],
  [
      "GMZ",
      "Alajeró"
  ],
  [
      "GNB",
      "Sillans"
  ],
  [
      "GND",
      "Grenada"
  ],
  [
      "GNU",
      "Goodnews Bay"
  ],
  [
      "GNV",
      "Gainesville"
  ],
  [
      "GOA",
      "Genoa"
  ],
  [
      "GOH",
      "Nuuk"
  ],
  [
      "GOI",
      "Vasco Da Gama"
  ],
  [
      "GOJ",
      "Dzerzinsk"
  ],
  [
      "GOM",
      "Goma"
  ],
  [
      "GOP",
      "Gorakhpur"
  ],
  [
      "GOQ",
      "Golmud"
  ],
  [
      "GOT",
      "Härryda"
  ],
  [
      "GOU",
      "Garoua"
  ],
  [
      "GOV",
      "Gove"
  ],
  [
      "GPA",
      "Lakkopetra"
  ],
  [
      "GPI",
      "Guapí"
  ],
  [
      "GPS",
      "Puerto Ayora"
  ],
  [
      "GPT",
      "Gulfport"
  ],
  [
      "GRB",
      "Green Bay"
  ],
  [
      "GRJ",
      "George"
  ],
  [
      "GRK",
      "Killeen"
  ],
  [
      "GRO",
      "Vilobí d'Onyar"
  ],
  [
      "GRQ",
      "Eelde"
  ],
  [
      "GRR",
      "Grand Rapids"
  ],
  [
      "GRU",
      "Guarulhos"
  ],
  [
      "GRV",
      "Groznyy"
  ],
  [
      "GRW",
      "Graciosa Island"
  ],
  [
      "GRX",
      "Chauchina"
  ],
  [
      "GRY",
      "Akureyri"
  ],
  [
      "GRZ",
      "Feldkirchen"
  ],
  [
      "GSE",
      "Kyrkobyn"
  ],
  [
      "GSM",
      "Gheshm"
  ],
  [
      "GSO",
      "Greensboro"
  ],
  [
      "GSP",
      "Greer"
  ],
  [
      "GST",
      "Gustavus"
  ],
  [
      "GSY",
      "Market Rasen"
  ],
  [
      "GTA",
      "Gatokae"
  ],
  [
      "GTE",
      "Darwin"
  ],
  [
      "GTF",
      "Great Falls"
  ],
  [
      "GTO",
      "Gorontalo"
  ],
  [
      "GTR",
      "Columbus"
  ],
  [
      "GTS",
      "Granites"
  ],
  [
      "GUA",
      "Guatemala City"
  ],
  [
      "GUC",
      "Gunnison"
  ],
  [
      "GUM",
      "Hagåtña"
  ],
  [
      "GUR",
      "Alotau"
  ],
  [
      "GUW",
      "Atyrau"
  ],
  [
      "GVA",
      "Geneva"
  ],
  [
      "GVR",
      "Governador Valadares"
  ],
  [
      "GWD",
      "Gawadar"
  ],
  [
      "GWL",
      "Gwalior"
  ],
  [
      "GWT",
      "Westerland"
  ],
  [
      "GWY",
      "Carnmore"
  ],
  [
      "GXF",
      "Seiyun"
  ],
  [
      "GXG",
      "Negage"
  ],
  [
      "GYA",
      "Guayaramerín"
  ],
  [
      "GYD",
      "Baku"
  ],
  [
      "GYE",
      "Guayaquil"
  ],
  [
      "GYL",
      "Argyle"
  ],
  [
      "GYM",
      "Guaymas"
  ],
  [
      "GYN",
      "Goiania"
  ],
  [
      "GZO",
      "Gizo"
  ],
  [
      "GZT",
      "Oğuzeli"
  ],
  [
      "HAA",
      "Hasvik"
  ],
  [
      "HAC",
      "Hachijo-machi"
  ],
  [
      "HAD",
      "Hamstad"
  ],
  [
      "HAE",
      "Havasupai"
  ],
  [
      "HAH",
      "Hahaia"
  ],
  [
      "HAJ",
      "Langenhagen"
  ],
  [
      "HAK",
      "Haikou"
  ],
  [
      "HAM",
      "Hamburg"
  ],
  [
      "HAN",
      "Hanoi"
  ],
  [
      "HAQ",
      "Hanimaadhoo"
  ],
  [
      "HAS",
      "Ha'il"
  ],
  [
      "HAU",
      "Avaldsnes"
  ],
  [
      "HAV",
      "Wajay"
  ],
  [
      "HBA",
      "Hobart"
  ],
  [
      "HBE",
      "Alexandria"
  ],
  [
      "HBT",
      "Hafr Albatin"
  ],
  [
      "HBX",
      "Hubli"
  ],
  [
      "HCN",
      "Hengchun"
  ],
  [
      "HCR",
      "Holy Cross"
  ],
  [
      "HDB",
      "Heidelberg"
  ],
  [
      "HDD",
      "Hyderabad"
  ],
  [
      "HDF",
      "Heringsdorf"
  ],
  [
      "HDM",
      "Hamadan"
  ],
  [
      "HDN",
      "Hayden"
  ],
  [
      "HDS",
      "Phalaborwa"
  ],
  [
      "HDY",
      "Hat Yai"
  ],
  [
      "HEA",
      "Herat"
  ],
  [
      "HEH",
      "Heho"
  ],
  [
      "HEI",
      "Heide-Buesum"
  ],
  [
      "HEK",
      "Heihe"
  ],
  [
      "HEL",
      "Vantaa"
  ],
  [
      "HER",
      "Iraklio"
  ],
  [
      "HET",
      "Hohhot"
  ],
  [
      "HFA",
      "Hefa"
  ],
  [
      "HFE",
      "Hefei"
  ],
  [
      "HFS",
      "Hagfors"
  ],
  [
      "HFT",
      "Hammerfest"
  ],
  [
      "HGA",
      "Hargeysa"
  ],
  [
      "HGD",
      "Hughenden"
  ],
  [
      "HGH",
      "Hangzhou"
  ],
  [
      "HGL",
      "Helgoland"
  ],
  [
      "HGN",
      "Mae Hong Son"
  ],
  [
      "HGU",
      "Mount Hagen"
  ],
  [
      "HHH",
      "Hilton Head Island"
  ],
  [
      "HHN",
      "Dickenschied"
  ],
  [
      "HHQ",
      "Hua Hin"
  ],
  [
      "HHZ",
      "Hikueru"
  ],
  [
      "HIB",
      "Hibbing"
  ],
  [
      "HID",
      "Horn Island"
  ],
  [
      "HIJ",
      "Mihara-shi"
  ],
  [
      "HIL",
      "Shillavo"
  ],
  [
      "HIN",
      "Sacheon-Si"
  ],
  [
      "HIR",
      "Honiara"
  ],
  [
      "HIS",
      "Hayman Island"
  ],
  [
      "HJJ",
      "Zhi Jiang"
  ],
  [
      "HJR",
      "Khajuraho"
  ],
  [
      "HKB",
      "Healy Lake"
  ],
  [
      "HKD",
      "Hakodate-shi"
  ],
  [
      "HKG",
      "Hong Kong"
  ],
  [
      "HKK",
      "Hokitika"
  ],
  [
      "HKN",
      "Kimbe"
  ],
  [
      "HKT",
      "Thalang"
  ],
  [
      "HLA",
      "Johannesburg"
  ],
  [
      "HLD",
      "Hailar"
  ],
  [
      "HLH",
      "Ulan Hot"
  ],
  [
      "HLN",
      "Helena"
  ],
  [
      "HLY",
      "Holyhead"
  ],
  [
      "HLZ",
      "Hamilton"
  ],
  [
      "HMA",
      "Khanty-Mansiysk"
  ],
  [
      "HME",
      "Ouargla"
  ],
  [
      "HMO",
      "Hermosillo"
  ],
  [
      "HMV",
      "Hemavan"
  ],
  [
      "HNA",
      "Hanamaki-shi"
  ],
  [
      "HND",
      "Tokyo"
  ],
  [
      "HNH",
      "Hoonah"
  ],
  [
      "HNL",
      "Honolulu"
  ],
  [
      "HNM",
      "Hana"
  ],
  [
      "HNS",
      "Haines"
  ],
  [
      "HOB",
      "Hobbs"
  ],
  [
      "HOD",
      "Al Hudaydah"
  ],
  [
      "HOE",
      "Houeisay"
  ],
  [
      "HOF",
      "Alahsa"
  ],
  [
      "HOG",
      "San Pedro de Cacocum"
  ],
  [
      "HOH",
      "Hohenems"
  ],
  [
      "HOI",
      "Papeete"
  ],
  [
      "HOM",
      "Homer"
  ],
  [
      "HON",
      "Huron"
  ],
  [
      "HOQ",
      "Hof"
  ],
  [
      "HOR",
      "Horta"
  ],
  [
      "HOU",
      "Houston"
  ],
  [
      "HOV",
      "Hovdebygda"
  ],
  [
      "HPA",
      "Pangai"
  ],
  [
      "HPB",
      "Hooper Bay"
  ],
  [
      "HPH",
      "Häi Phòng"
  ],
  [
      "HPN",
      "Purchase"
  ],
  [
      "HRB",
      "Harbin"
  ],
  [
      "HRE",
      "Harare"
  ],
  [
      "HRG",
      "Bor Safajah"
  ],
  [
      "HRK",
      "Kharkiv"
  ],
  [
      "HRL",
      "Harlingen"
  ],
  [
      "HRT",
      "Harrogate"
  ],
  [
      "HSG",
      "Saga"
  ],
  [
      "HSL",
      "Huslia"
  ],
  [
      "HSN",
      "Shenjiamen Town"
  ],
  [
      "HSV",
      "Huntsville"
  ],
  [
      "HTA",
      "Chita"
  ],
  [
      "HTG",
      "Dudinka"
  ],
  [
      "HTI",
      "Hamilton Island"
  ],
  [
      "HTN",
      "Hotan"
  ],
  [
      "HTS",
      "Huntington"
  ],
  [
      "HUH",
      "Papeete"
  ],
  [
      "HUI",
      "Hue"
  ],
  [
      "HUN",
      "Hualien City"
  ],
  [
      "HUQ",
      "Houn"
  ],
  [
      "HUS",
      "Hughes"
  ],
  [
      "HUV",
      "Hudiksvall"
  ],
  [
      "HUX",
      "Santo Domingo de Morelos"
  ],
  [
      "HUY",
      "Ulceby"
  ],
  [
      "HUZ",
      "Huizhou"
  ],
  [
      "HVA",
      "Analalava"
  ],
  [
      "HVB",
      "Hervey Bay"
  ],
  [
      "HVD",
      "Khovd"
  ],
  [
      "HVG",
      "Honningsvåg"
  ],
  [
      "HVN",
      "East Haven"
  ],
  [
      "HVR",
      "Havre"
  ],
  [
      "HYA",
      "Hyannis"
  ],
  [
      "HYD",
      "Hyderabad"
  ],
  [
      "HYG",
      "Hydaburg"
  ],
  [
      "HYL",
      "Hollis"
  ],
  [
      "HYN",
      "Huangyan"
  ],
  [
      "HYS",
      "Hays"
  ],
  [
      "HZG",
      "Hanzhong"
  ],
  [
      "HZH",
      "Liping City"
  ],
  [
      "IAA",
      "Igarka"
  ],
  [
      "IAD",
      "Washington"
  ],
  [
      "IAG",
      "Niagara Falls"
  ],
  [
      "IAH",
      "Houston"
  ],
  [
      "IAM",
      "Illizi"
  ],
  [
      "IAN",
      "Kiana"
  ],
  [
      "IAS",
      "Ilasi"
  ],
  [
      "IBA",
      "Ibadan"
  ],
  [
      "IBE",
      "Ibague"
  ],
  [
      "IBZ",
      "San José"
  ],
  [
      "ICI",
      "Cicia"
  ],
  [
      "ICN",
      "Incheon"
  ],
  [
      "ICT",
      "Wichita"
  ],
  [
      "IDA",
      "Idaho Falls"
  ],
  [
      "IDR",
      "Indore"
  ],
  [
      "IEG",
      "Babimost"
  ],
  [
      "IEV",
      "Kiev"
  ],
  [
      "IFJ",
      "Isafjordur"
  ],
  [
      "IFN",
      "Esfahan"
  ],
  [
      "IFO",
      "Ivano-Frankivs'k"
  ],
  [
      "IFP",
      "Bullhead City"
  ],
  [
      "IGA",
      "Matthew Town"
  ],
  [
      "IGG",
      "Igiugig"
  ],
  [
      "IGM",
      "Kingman"
  ],
  [
      "IGR",
      "Puerto Esperanza"
  ],
  [
      "IGS",
      "Ingolstadt-Manching"
  ],
  [
      "IGU",
      "Foz do Iguacu"
  ],
  [
      "IHR",
      "Iran Shahr"
  ],
  [
      "IIL",
      "Ilaam"
  ],
  [
      "IJK",
      "Izhevsk"
  ],
  [
      "IKA",
      "Tehran"
  ],
  [
      "IKO",
      "Nikolski"
  ],
  [
      "IKS",
      "Tiksi"
  ],
  [
      "IKT",
      "Irkutsk"
  ],
  [
      "ILF",
      "Nelson House"
  ],
  [
      "ILI",
      "Iliamna"
  ],
  [
      "ILM",
      "Wilmington"
  ],
  [
      "ILN",
      "Wilmington"
  ],
  [
      "ILO",
      "Iloilo"
  ],
  [
      "ILP",
      "Vao"
  ],
  [
      "ILR",
      "Ilorin"
  ],
  [
      "ILY",
      "Glenegedale"
  ],
  [
      "ILZ",
      "Bytča"
  ],
  [
      "IMF",
      "Lilong (Imphal West)"
  ],
  [
      "IMK",
      "Simikot"
  ],
  [
      "IMP",
      "Imperatriz"
  ],
  [
      "IMT",
      "Kingsford"
  ],
  [
      "INC",
      "Yinchuan"
  ],
  [
      "IND",
      "Indianapolis"
  ],
  [
      "INH",
      "Inhambane"
  ],
  [
      "INI",
      "Nis"
  ],
  [
      "INL",
      "International Falls"
  ],
  [
      "INN",
      "Innsbruck"
  ],
  [
      "INT",
      "Winston-Salem"
  ],
  [
      "INU",
      "Yaren"
  ],
  [
      "INV",
      "Inverness"
  ],
  [
      "INZ",
      "In Salah"
  ],
  [
      "IOA",
      "Ioannina"
  ],
  [
      "IOM",
      "Castletown"
  ],
  [
      "ION",
      "Impfondo"
  ],
  [
      "IOS",
      "Ilhéus"
  ],
  [
      "IPA",
      "Ipota"
  ],
  [
      "IPC",
      "Easter Island"
  ],
  [
      "IPH",
      "Ipoh"
  ],
  [
      "IPI",
      "Ipiales"
  ],
  [
      "IPL",
      "Imperial"
  ],
  [
      "IPN",
      "Santana do Paraíso"
  ],
  [
      "IPT",
      "Montoursville"
  ],
  [
      "IPW",
      "Ipswich"
  ],
  [
      "IQM",
      "Qiemo"
  ],
  [
      "IQN",
      "Qingyang"
  ],
  [
      "IQQ",
      "Alto Hospicio"
  ],
  [
      "IQT",
      "Iquitos"
  ],
  [
      "IRA",
      "Kirakira"
  ],
  [
      "IRC",
      "Circle"
  ],
  [
      "IRJ",
      "Chamical"
  ],
  [
      "IRM",
      "Irma"
  ],
  [
      "ISA",
      "Mount Isa"
  ],
  [
      "ISB",
      "Råwalpindi"
  ],
  [
      "ISC",
      "Rams Valley"
  ],
  [
      "ISG",
      "Ishigaki-shi"
  ],
  [
      "ISN",
      "Williston"
  ],
  [
      "ISO",
      "Kinston"
  ],
  [
      "ISP",
      "Ronkonkoma"
  ],
  [
      "IST",
      "Bakırköy"
  ],
  [
      "ITH",
      "Ithaca"
  ],
  [
      "ITM",
      "Itami-shi"
  ],
  [
      "ITO",
      "Hilo"
  ],
  [
      "IUE",
      "Niue Island"
  ],
  [
      "IVC",
      "Invercargill"
  ],
  [
      "IVL",
      "Ivalo"
  ],
  [
      "IVR",
      "Inverell"
  ],
  [
      "IWD",
      "Ironwood"
  ],
  [
      "IWJ",
      "Iwami"
  ],
  [
      "IXA",
      "Gandhigram"
  ],
  [
      "IXB",
      "Bagdogra"
  ],
  [
      "IXC",
      "Bhabat"
  ],
  [
      "IXD",
      "Allahabad"
  ],
  [
      "IXE",
      "Mulur"
  ],
  [
      "IXG",
      "Kangrali"
  ],
  [
      "IXI",
      "Lilabari"
  ],
  [
      "IXJ",
      "Jammu Cantt"
  ],
  [
      "IXL",
      "Leh"
  ],
  [
      "IXM",
      "Harveypatti"
  ],
  [
      "IXR",
      "Ranchi"
  ],
  [
      "IXS",
      "Tarapur"
  ],
  [
      "IXU",
      "Aurangabad"
  ],
  [
      "IXW",
      "Jamshedpur"
  ],
  [
      "IXY",
      "Gandhidham"
  ],
  [
      "IXZ",
      "Port Blair"
  ],
  [
      "IYK",
      "Inyokern"
  ],
  [
      "IZO",
      "Hikawa-cho"
  ],
  [
      "JAC",
      "Jackson"
  ],
  [
      "JAI",
      "Jaipur"
  ],
  [
      "JAL",
      "Jalapa"
  ],
  [
      "JAN",
      "Pearl"
  ],
  [
      "JAV",
      "Ilulissat"
  ],
  [
      "JAX",
      "Jacksonville"
  ],
  [
      "JCB",
      "Joaçaba"
  ],
  [
      "JCH",
      "Qasigiannguit"
  ],
  [
      "JCK",
      "Julia Creek"
  ],
  [
      "JCU",
      "Ceuta"
  ],
  [
      "JDF",
      "Juiz de Fora"
  ],
  [
      "JDH",
      "Jodhpur"
  ],
  [
      "JDO",
      "Juazeiro do Norte"
  ],
  [
      "JDZ",
      "Jingdezhen"
  ],
  [
      "JED",
      "Jeddah"
  ],
  [
      "JEF",
      "Holts Summit"
  ],
  [
      "JEG",
      "Aasiaat"
  ],
  [
      "JEJ",
      "Jeh"
  ],
  [
      "JER",
      "St. Peter"
  ],
  [
      "JFK",
      "Jamaica"
  ],
  [
      "JFR",
      "Paamiut"
  ],
  [
      "JGA",
      "Jamnagar"
  ],
  [
      "JGC",
      "Williams"
  ],
  [
      "JGN",
      "Jiayuguan"
  ],
  [
      "JGO",
      "Qeqertarsuaq"
  ],
  [
      "JGS",
      "Ji An"
  ],
  [
      "JHB",
      "Senai"
  ],
  [
      "JHG",
      "Jinghong"
  ],
  [
      "JHM",
      "Lahaina"
  ],
  [
      "JHS",
      "Sisimiut"
  ],
  [
      "JHW",
      "Jamestown"
  ],
  [
      "JIB",
      "Djibouti City"
  ],
  [
      "JIJ",
      "Jijiga"
  ],
  [
      "JIK",
      "Evdilos"
  ],
  [
      "JIM",
      "Jimma"
  ],
  [
      "JIU",
      "Jiujiang"
  ],
  [
      "JJN",
      "Jinjiang"
  ],
  [
      "JJU",
      "Qaqortoq"
  ],
  [
      "JKG",
      "Jonkoping"
  ],
  [
      "JKH",
      "Khios"
  ],
  [
      "JKL",
      "Jackson"
  ],
  [
      "JLD",
      "Landskrona"
  ],
  [
      "JLN",
      "Webb City"
  ],
  [
      "JLR",
      "Bilpura"
  ],
  [
      "JMK",
      "Mikonos"
  ],
  [
      "JMS",
      "Jamestown"
  ],
  [
      "JMU",
      "Jiamusi"
  ],
  [
      "JNB",
      "Johannesburg"
  ],
  [
      "JNN",
      "Nanortalik"
  ],
  [
      "JNS",
      "Narsaq"
  ],
  [
      "JNU",
      "Juneau"
  ],
  [
      "JNX",
      "Naxos"
  ],
  [
      "JNZ",
      "Jinzhou"
  ],
  [
      "JOE",
      "Ylämylly"
  ],
  [
      "JOG",
      "Yogyakarta"
  ],
  [
      "JOI",
      "Joinville"
  ],
  [
      "JOL",
      "Jolo"
  ],
  [
      "JPA",
      "Santa Rita"
  ],
  [
      "JPR",
      "Ji-Paraná"
  ],
  [
      "JQA",
      "Qaarsut"
  ],
  [
      "JRB",
      "New York"
  ],
  [
      "JRH",
      "Senchoa Gaon"
  ],
  [
      "JRO",
      "Sanya"
  ],
  [
      "JSA",
      "Jaisalmer"
  ],
  [
      "JSH",
      "Seteia"
  ],
  [
      "JSI",
      "Skiathos"
  ],
  [
      "JSR",
      "Jessore"
  ],
  [
      "JST",
      "Johnstown"
  ],
  [
      "JSU",
      "Maitsoq"
  ],
  [
      "JSY",
      "Ano Siros"
  ],
  [
      "JTR",
      "Emborion"
  ],
  [
      "JTY",
      "Astypalaea"
  ],
  [
      "JUB",
      "Juba"
  ],
  [
      "JUJ",
      "Santa Catalina"
  ],
  [
      "JUL",
      "Juliaca"
  ],
  [
      "JUM",
      "Jumla"
  ],
  [
      "JUV",
      "Upernavik"
  ],
  [
      "JUZ",
      "Juzhou"
  ],
  [
      "JVA",
      "Ankavandra"
  ],
  [
      "JYR",
      "Jiroft"
  ],
  [
      "JYV",
      "Tikkakoski"
  ],
  [
      "JZH",
      "Song Pan"
  ],
  [
      "KAB",
      "Kariba"
  ],
  [
      "KAC",
      "Al Qamishli"
  ],
  [
      "KAD",
      "Afaka"
  ],
  [
      "KAJ",
      "Paltaniemi"
  ],
  [
      "KAL",
      "Kaltag"
  ],
  [
      "KAN",
      ""
  ],
  [
      "KAO",
      "Kuusamo"
  ],
  [
      "KAT",
      "Kaitaia"
  ],
  [
      "KAW",
      "Kawthaung"
  ],
  [
      "KAX",
      "Kalbarri"
  ],
  [
      "KBC",
      "Birch Creek"
  ],
  [
      "KBL",
      "Kabul"
  ],
  [
      "KBP",
      "Kiev"
  ],
  [
      "KBR",
      "Kota Baharu"
  ],
  [
      "KBV",
      "Krabi"
  ],
  [
      "KCA",
      "Kuqa"
  ],
  [
      "KCC",
      "Coffman Cove"
  ],
  [
      "KCF",
      "Kadanwari"
  ],
  [
      "KCG",
      "Chignik Lagoon"
  ],
  [
      "KCH",
      "Kuching"
  ],
  [
      "KCL",
      "Chignik Lagoon"
  ],
  [
      "KCM",
      "Kahramanmaraş"
  ],
  [
      "KCQ",
      "Chignik"
  ],
  [
      "KCZ",
      "Nankoku-shi"
  ],
  [
      "KDH",
      "Alaqadari Daman"
  ],
  [
      "KDI",
      "Kendari"
  ],
  [
      "KDL",
      "Kerdlya"
  ],
  [
      "KDM",
      "Kaadedhdhoo"
  ],
  [
      "KDO",
      "Kadhdhoo"
  ],
  [
      "KDU",
      "Skardu"
  ],
  [
      "KDV",
      "Kandavu"
  ],
  [
      "KEB",
      "Nanwalek"
  ],
  [
      "KEF",
      "Reykjavik"
  ],
  [
      "KEJ",
      "Kemerovo"
  ],
  [
      "KEK",
      "Ekwok"
  ],
  [
      "KEM",
      "Kemi"
  ],
  [
      "KEP",
      "Nepalganj"
  ],
  [
      "KER",
      "Kerman"
  ],
  [
      "KET",
      "Keng Tung"
  ],
  [
      "KEW",
      "Keewaywin"
  ],
  [
      "KFA",
      "Kiffa"
  ],
  [
      "KFP",
      "False Pass"
  ],
  [
      "KGA",
      "Kananga"
  ],
  [
      "KGC",
      "Kingscote"
  ],
  [
      "KGD",
      "Kaliningrad"
  ],
  [
      "KGE",
      "Kagau"
  ],
  [
      "KGF",
      "Qaraghandy"
  ],
  [
      "KGI",
      "Kalgoorlie"
  ],
  [
      "KGK",
      "New Koliganek"
  ],
  [
      "KGL",
      "Kigali"
  ],
  [
      "KGP",
      "Kogalym"
  ],
  [
      "KGS",
      "Antimacheia"
  ],
  [
      "KGX",
      "Grayling"
  ],
  [
      "KHG",
      "Kashi"
  ],
  [
      "KHH",
      "Kaohsiung City"
  ],
  [
      "KHI",
      "Karachi"
  ],
  [
      "KHM",
      "Khamti"
  ],
  [
      "KHN",
      "Nanchang"
  ],
  [
      "KHS",
      "Khasab"
  ],
  [
      "KHV",
      "Khabarovsk"
  ],
  [
      "KHY",
      "Khoy"
  ],
  [
      "KHZ",
      "Kauehi"
  ],
  [
      "KID",
      "Tollarp"
  ],
  [
      "KIF",
      "Casummit Lake"
  ],
  [
      "KIH",
      "Bandar Abbas"
  ],
  [
      "KIJ",
      "Niigata-shi"
  ],
  [
      "KIK",
      "Kirkuk"
  ],
  [
      "KIM",
      "Kimberley"
  ],
  [
      "KIN",
      "Kingston"
  ],
  [
      "KIR",
      "Farranfore"
  ],
  [
      "KIS",
      "Kisumu"
  ],
  [
      "KIT",
      "Potamos Kythiron"
  ],
  [
      "KIV",
      "Chisinau"
  ],
  [
      "KIX",
      "Tajiri-cho"
  ],
  [
      "KJA",
      "Kansk"
  ],
  [
      "KKA",
      "Koyuk"
  ],
  [
      "KKB",
      "Kitoi Bay"
  ],
  [
      "KKC",
      "Khon Kaen"
  ],
  [
      "KKD",
      "Kokoda"
  ],
  [
      "KKE",
      "Kerikeri"
  ],
  [
      "KKH",
      "Kongiganak"
  ],
  [
      "KKI",
      "Akiachak"
  ],
  [
      "KKJ",
      "Kita Kyushu"
  ],
  [
      "KKN",
      "Hesseng"
  ],
  [
      "KKR",
      "Kaukura Atoll"
  ],
  [
      "KKU",
      "Clarks Point"
  ],
  [
      "KLG",
      "Kalskag"
  ],
  [
      "KLH",
      "Kolhapur"
  ],
  [
      "KLL",
      "Levelock"
  ],
  [
      "KLN",
      "Larsen Bay"
  ],
  [
      "KLO",
      "Kalibo"
  ],
  [
      "KLR",
      "Kalmar"
  ],
  [
      "KLU",
      "Celovec"
  ],
  [
      "KLV",
      "Carlsbad"
  ],
  [
      "KLW",
      "Klawock"
  ],
  [
      "KLX",
      "Kalamae"
  ],
  [
      "KMA",
      "Kerema"
  ],
  [
      "KMC",
      "King Khalid Mil. City"
  ],
  [
      "KME",
      "Kamembe"
  ],
  [
      "KMG",
      "Kunming"
  ],
  [
      "KMI",
      "Miyazaki-shi"
  ],
  [
      "KMJ",
      "Kikuyo-machi"
  ],
  [
      "KMO",
      "Manokotak"
  ],
  [
      "KMQ",
      "Komatsu-shi"
  ],
  [
      "KMS",
      "New Tafo"
  ],
  [
      "KMV",
      "Kalemyo"
  ],
  [
      "KMY",
      "Moser Bay"
  ],
  [
      "KND",
      "Kindu"
  ],
  [
      "KNF",
      "Kings Lynn"
  ],
  [
      "KNG",
      "Kaimana"
  ],
  [
      "KNH",
      "Kinmen"
  ],
  [
      "KNK",
      "Kakhonak"
  ],
  [
      "KNQ",
      "Kone"
  ],
  [
      "KNS",
      "King Island"
  ],
  [
      "KNU",
      "Kanpur"
  ],
  [
      "KNW",
      "New Stuyahok"
  ],
  [
      "KNX",
      "Durack"
  ],
  [
      "KOA",
      "Kailua Kona"
  ],
  [
      "KOC",
      "Koumac"
  ],
  [
      "KOE",
      "Kupang"
  ],
  [
      "KOI",
      "Kirkwall"
  ],
  [
      "KOJ",
      "Kirishima-shi"
  ],
  [
      "KOK",
      "Kruunupyy"
  ],
  [
      "KOP",
      "Nakhon Phanom"
  ],
  [
      "KOT",
      "Kotlik"
  ],
  [
      "KOU",
      "Koulamoutou"
  ],
  [
      "KOW",
      "Ganzhou"
  ],
  [
      "KOY",
      "Olga Bay"
  ],
  [
      "KOZ",
      "Ouzinkie"
  ],
  [
      "KPB",
      "Point Baker"
  ],
  [
      "KPC",
      "Brevig Mission"
  ],
  [
      "KPN",
      "Kipnuk"
  ],
  [
      "KPO",
      "Pohang-Si"
  ],
  [
      "KPR",
      "Port Williams"
  ],
  [
      "KPV",
      "Perryville"
  ],
  [
      "KPY",
      "Port Bailey"
  ],
  [
      "KQA",
      "Akutan"
  ],
  [
      "KRF",
      "Nyland"
  ],
  [
      "KRI",
      "Kikori"
  ],
  [
      "KRK",
      "Zabierzów"
  ],
  [
      "KRL",
      "Korla"
  ],
  [
      "KRN",
      "Kiruna"
  ],
  [
      "KRP",
      "Kårup"
  ],
  [
      "KRR",
      "Krasnodar"
  ],
  [
      "KRS",
      "Kjevic"
  ],
  [
      "KRT",
      "Khartoum"
  ],
  [
      "KRY",
      "Karamay"
  ],
  [
      "KSA",
      "Tofol"
  ],
  [
      "KSC",
      "Kosice"
  ],
  [
      "KSD",
      "Karlstad"
  ],
  [
      "KSF",
      "Kalden"
  ],
  [
      "KSH",
      "Kermanshah"
  ],
  [
      "KSJ",
      "St. Marina"
  ],
  [
      "KSL",
      "Kassala"
  ],
  [
      "KSM",
      "Saint Marys"
  ],
  [
      "KSN",
      "Qostanay"
  ],
  [
      "KSO",
      "Argos Orestiko"
  ],
  [
      "KSQ",
      "Qarshi"
  ],
  [
      "KSU",
      "Kristiansund Nord"
  ],
  [
      "KSY",
      "Kars"
  ],
  [
      "KSZ",
      "Vel'sk"
  ],
  [
      "KTA",
      "Karratha"
  ],
  [
      "KTB",
      "Thorne Bay"
  ],
  [
      "KTE",
      "Kerteh"
  ],
  [
      "KTM",
      "Kathmandu"
  ],
  [
      "KTN",
      "Ketchikan"
  ],
  [
      "KTS",
      "Teller Mission"
  ],
  [
      "KTT",
      "Kittila"
  ],
  [
      "KTW",
      "Ożarowice"
  ],
  [
      "KUA",
      "Gambang"
  ],
  [
      "KUD",
      "Kudat"
  ],
  [
      "KUF",
      "Syzran'"
  ],
  [
      "KUG",
      "Kubin Island"
  ],
  [
      "KUH",
      "Kushiro"
  ],
  [
      "KUK",
      "Kasigluk"
  ],
  [
      "KUL",
      "Sepang"
  ],
  [
      "KUN",
      "Kovno"
  ],
  [
      "KUO",
      "Toivala"
  ],
  [
      "KUS",
      "Kulusuk"
  ],
  [
      "KUT",
      "Kutaisi"
  ],
  [
      "KUU",
      "Bhuntar"
  ],
  [
      "KUV",
      "Gunsan-Si"
  ],
  [
      "KVA",
      "Khrysoupolis"
  ],
  [
      "KVB",
      "Väring"
  ],
  [
      "KVC",
      "King Cove"
  ],
  [
      "KVD",
      "Gyandzha"
  ],
  [
      "KVG",
      "Kavieng"
  ],
  [
      "KVK",
      "Kirovsk"
  ],
  [
      "KVL",
      "Kivalina"
  ],
  [
      "KVR",
      "Carpiquet"
  ],
  [
      "KWA",
      "Kwajalein"
  ],
  [
      "KWE",
      "Guiyang"
  ],
  [
      "KWI",
      "Kuwait City"
  ],
  [
      "KWJ",
      "Gwangju"
  ],
  [
      "KWK",
      "Kwigillingok"
  ],
  [
      "KWL",
      "Guilin"
  ],
  [
      "KWM",
      "Kowanyama"
  ],
  [
      "KWN",
      "Quinhagak"
  ],
  [
      "KWP",
      "West Point"
  ],
  [
      "KWT",
      "Kwethluk"
  ],
  [
      "KWZ",
      "Kolwezi"
  ],
  [
      "KXA",
      "Kasaan"
  ],
  [
      "KXF",
      "Koro Island"
  ],
  [
      "KXK",
      "Komsomol'sk-na-Amure"
  ],
  [
      "KXU",
      "Katiu"
  ],
  [
      "KYA",
      "Konya"
  ],
  [
      "KYK",
      "Kodiak"
  ],
  [
      "KYN",
      "Milton Keynes"
  ],
  [
      "KYP",
      "Kyaukpyu"
  ],
  [
      "KYS",
      "Kayes"
  ],
  [
      "KYU",
      "Koyukuk"
  ],
  [
      "KYZ",
      "Kyzyl"
  ],
  [
      "KZB",
      "Zachar Bay"
  ],
  [
      "KZI",
      "Kozani"
  ],
  [
      "KZN",
      "Zelenodol'sk"
  ],
  [
      "KZO",
      "Kzyl-Orda"
  ],
  [
      "KZS",
      "Kastelorizo"
  ],
  [
      "LAD",
      "Luanda"
  ],
  [
      "LAE",
      "Lae"
  ],
  [
      "LAI",
      "Lannion"
  ],
  [
      "LAJ",
      "Lages"
  ],
  [
      "LAK",
      "Hay River"
  ],
  [
      "LAN",
      "Lansing"
  ],
  [
      "LAO",
      "San Nicolas"
  ],
  [
      "LAP",
      "La Paz"
  ],
  [
      "LAQ",
      "Beida"
  ],
  [
      "LAR",
      "Laramie"
  ],
  [
      "LAS",
      "Las Vegas"
  ],
  [
      "LAU",
      "Lamu"
  ],
  [
      "LAW",
      "Lawton"
  ],
  [
      "LAX",
      "Los Angeles"
  ],
  [
      "LBA",
      "Leeds"
  ],
  [
      "LBB",
      "Lubbock"
  ],
  [
      "LBC",
      "Lubeque"
  ],
  [
      "LBD",
      "Khudzhand"
  ],
  [
      "LBE",
      "Latrobe"
  ],
  [
      "LBF",
      "North Platte"
  ],
  [
      "LBJ",
      "Ende"
  ],
  [
      "LBL",
      "Liberal"
  ],
  [
      "LBP",
      "Long Banga"
  ],
  [
      "LBS",
      "Labasa"
  ],
  [
      "LBU",
      "Victoria"
  ],
  [
      "LBV",
      "Libreville"
  ],
  [
      "LCA",
      "Larnaca"
  ],
  [
      "LCE",
      "La Ceiba"
  ],
  [
      "LCG",
      "S Esteban"
  ],
  [
      "LCH",
      "Lake Charles"
  ],
  [
      "LCJ",
      "Lodz"
  ],
  [
      "LCK",
      "Columbus"
  ],
  [
      "LCR",
      "La Chorrera"
  ],
  [
      "LCX",
      "Longyan"
  ],
  [
      "LCY",
      "London"
  ],
  [
      "LDB",
      "Londrina"
  ],
  [
      "LDE",
      "Juillan"
  ],
  [
      "LDG",
      "Leshukonskoye"
  ],
  [
      "LDH",
      "Lord Howe Island"
  ],
  [
      "LDN",
      "Lamidanda"
  ],
  [
      "LDU",
      "Lahad Datu"
  ],
  [
      "LDV",
      "Landivisiau"
  ],
  [
      "LDY",
      "Londonderry"
  ],
  [
      "LEA",
      "Learmonth"
  ],
  [
      "LEB",
      "West Lebanon"
  ],
  [
      "LED",
      "St. Petersburg"
  ],
  [
      "LEH",
      "Le Havre"
  ],
  [
      "LEI",
      "Almeria"
  ],
  [
      "LEJ",
      "Schkeuditz"
  ],
  [
      "LEN",
      "Leon"
  ],
  [
      "LER",
      "Sir Samuel"
  ],
  [
      "LET",
      "Leticia"
  ],
  [
      "LEV",
      "Bureta"
  ],
  [
      "LEX",
      "Lexington"
  ],
  [
      "LFM",
      "Lamerd"
  ],
  [
      "LFT",
      "Lafayette"
  ],
  [
      "LFW",
      "Lome"
  ],
  [
      "LGA",
      "Flushing"
  ],
  [
      "LGB",
      "Long Beach"
  ],
  [
      "LGG",
      "Velroux"
  ],
  [
      "LGI",
      "Deadmans Cay"
  ],
  [
      "LGK",
      "Kuah"
  ],
  [
      "LGL",
      "Long Lellang"
  ],
  [
      "LGP",
      "Daraga"
  ],
  [
      "LGQ",
      "Lago Agrio"
  ],
  [
      "LGW",
      "Horley"
  ],
  [
      "LHE",
      "Lahore"
  ],
  [
      "LHG",
      "Lightning Ridge"
  ],
  [
      "LHR",
      "Hounslow"
  ],
  [
      "LHW",
      "Lanzhou"
  ],
  [
      "LIF",
      "Wé"
  ],
  [
      "LIG",
      "Limoges"
  ],
  [
      "LIH",
      "Lihue"
  ],
  [
      "LIL",
      "Fretin"
  ],
  [
      "LIM",
      "Ventanilla"
  ],
  [
      "LIN",
      "Peschiera Borromeo"
  ],
  [
      "LIO",
      "Pto. Limon"
  ],
  [
      "LIR",
      "Liberia"
  ],
  [
      "LIS",
      "Lisbon"
  ],
  [
      "LIT",
      "Little Rock"
  ],
  [
      "LIW",
      "Loi-kaw"
  ],
  [
      "LJG",
      "Lijiang City"
  ],
  [
      "LJU",
      "Ljubljana"
  ],
  [
      "LKA",
      "Larantuka"
  ],
  [
      "LKB",
      "Lakeba"
  ],
  [
      "LKE",
      "Seattle"
  ],
  [
      "LKG",
      "Lokichoggio"
  ],
  [
      "LKH",
      "Long Akah"
  ],
  [
      "LKL",
      "Lakeselv"
  ],
  [
      "LKN",
      "Leknes"
  ],
  [
      "LKO",
      "Lucknow"
  ],
  [
      "LLA",
      "Lulea"
  ],
  [
      "LLF",
      "LingLing"
  ],
  [
      "LLI",
      "Lalibela"
  ],
  [
      "LLU",
      "Alluitsup Paa"
  ],
  [
      "LLW",
      "Lumbadzi"
  ],
  [
      "LMA",
      "Lake Minchumina"
  ],
  [
      "LMC",
      "San Juan de Arama"
  ],
  [
      "LMM",
      "Ahome"
  ],
  [
      "LMN",
      "Limbang"
  ],
  [
      "LMP",
      "Caltabellotta"
  ],
  [
      "LMT",
      "Klamath Falls"
  ],
  [
      "LMY",
      "Lake Murray"
  ],
  [
      "LNB",
      "Lamen Bay"
  ],
  [
      "LNE",
      "Panngi"
  ],
  [
      "LNJ",
      "Lincang"
  ],
  [
      "LNK",
      "Lincoln"
  ],
  [
      "LNO",
      "Leonora"
  ],
  [
      "LNV",
      "Lihir Island"
  ],
  [
      "LNY",
      "Lanai City"
  ],
  [
      "LNZ",
      "Hoersching"
  ],
  [
      "LOD",
      "Longana"
  ],
  [
      "LOH",
      "Loja"
  ],
  [
      "LOS",
      "Ikeja"
  ],
  [
      "LOU",
      "Louisville"
  ],
  [
      "LOV",
      "Frontera"
  ],
  [
      "LPA",
      "Telde"
  ],
  [
      "LPB",
      "La Paz"
  ],
  [
      "LPD",
      "La Pedrera"
  ],
  [
      "LPI",
      "Linkoping"
  ],
  [
      "LPK",
      "Lipetsk"
  ],
  [
      "LPL",
      "Liverpool"
  ],
  [
      "LPM",
      "Lamap"
  ],
  [
      "LPP",
      "Lappeenranta"
  ],
  [
      "LPQ",
      "Louangphrabang"
  ],
  [
      "LPS",
      "Lopez Island"
  ],
  [
      "LPT",
      "Lampang"
  ],
  [
      "LPX",
      "Liepaya"
  ],
  [
      "LPY",
      "Le Puy"
  ],
  [
      "LQM",
      "Puerto Leguízamo"
  ],
  [
      "LRD",
      "Laredo"
  ],
  [
      "LRE",
      "Longreach"
  ],
  [
      "LRH",
      "La Rochelle"
  ],
  [
      "LRM",
      "La Romana"
  ],
  [
      "LRR",
      "Lar"
  ],
  [
      "LRS",
      "Lero"
  ],
  [
      "LRT",
      "Ploemeur"
  ],
  [
      "LSA",
      "Losuia"
  ],
  [
      "LSC",
      "Compañía Alta"
  ],
  [
      "LSE",
      "La Crosse"
  ],
  [
      "LSH",
      "Lashio"
  ],
  [
      "LSI",
      "Shetland"
  ],
  [
      "LSP",
      "Las Piedras"
  ],
  [
      "LSS",
      "Trois-Rivières"
  ],
  [
      "LST",
      "Blessington"
  ],
  [
      "LSY",
      "Lismore"
  ],
  [
      "LTD",
      "Ghadames"
  ],
  [
      "LTI",
      "Altai"
  ],
  [
      "LTK",
      "Djeble"
  ],
  [
      "LTN",
      "Luton"
  ],
  [
      "LTO",
      "Comondú"
  ],
  [
      "LTT",
      "Grimaud"
  ],
  [
      "LTX",
      "Latacunga"
  ],
  [
      "LUA",
      "Solukhumbu"
  ],
  [
      "LUD",
      "Luderitz"
  ],
  [
      "LUF",
      "Webb"
  ],
  [
      "LUG",
      "Agno"
  ],
  [
      "LUM",
      "Luxi"
  ],
  [
      "LUN",
      "Lusaka"
  ],
  [
      "LUO",
      "Luena"
  ],
  [
      "LUP",
      "Kalaupapa"
  ],
  [
      "LUQ",
      "Villa General Roca"
  ],
  [
      "LUR",
      "Point Hope"
  ],
  [
      "LUV",
      "Langgur"
  ],
  [
      "LUX",
      "Sandweiler"
  ],
  [
      "LVI",
      "Livingstone"
  ],
  [
      "LVO",
      "Laverton"
  ],
  [
      "LWB",
      "Lewisburg"
  ],
  [
      "LWE",
      "Lewoleba"
  ],
  [
      "LWN",
      "Gyumri"
  ],
  [
      "LWO",
      "L'viv"
  ],
  [
      "LWS",
      "Lewiston"
  ],
  [
      "LWT",
      "Lewistown"
  ],
  [
      "LWY",
      "Lawas"
  ],
  [
      "LXA",
      "Lhasa"
  ],
  [
      "LXG",
      "Luang Namtha"
  ],
  [
      "LXR",
      "Luxor"
  ],
  [
      "LXS",
      "Moudhros"
  ],
  [
      "LYA",
      "Luoyang"
  ],
  [
      "LYB",
      "Little Cayman"
  ],
  [
      "LYC",
      "Lyoksele"
  ],
  [
      "LYG",
      "Lianyungang"
  ],
  [
      "LYH",
      "Lynchburg"
  ],
  [
      "LYI",
      "Linyi"
  ],
  [
      "LYP",
      "Shah Faisalabad"
  ],
  [
      "LYR",
      "Longyearbyen"
  ],
  [
      "LYS",
      "Colombier"
  ],
  [
      "LZC",
      "Arteaga"
  ],
  [
      "LZH",
      "Linzhou"
  ],
  [
      "LZN",
      "Nangan"
  ],
  [
      "LZO",
      "Luzhou"
  ],
  [
      "MAA",
      "Kanchipuram"
  ],
  [
      "MAB",
      "Marabá"
  ],
  [
      "MAD",
      "Madrid"
  ],
  [
      "MAF",
      "Midland"
  ],
  [
      "MAG",
      "Madang"
  ],
  [
      "MAH",
      "Mao"
  ],
  [
      "MAJ",
      "Majuro"
  ],
  [
      "MAK",
      "Malakal"
  ],
  [
      "MAM",
      "Matamoros"
  ],
  [
      "MAN",
      "Manchester"
  ],
  [
      "MAO",
      "Manaus"
  ],
  [
      "MAR",
      "Maracaibo"
  ],
  [
      "MAS",
      "Lorengau"
  ],
  [
      "MAU",
      "Papeete"
  ],
  [
      "MAZ",
      "Mayaguez"
  ],
  [
      "MBA",
      "Mombasa"
  ],
  [
      "MBD",
      "Mmabatho"
  ],
  [
      "MBE",
      "Monbetsu-shi"
  ],
  [
      "MBH",
      "Maryborough"
  ],
  [
      "MBJ",
      "Montego Bay"
  ],
  [
      "MBL",
      "Manistee"
  ],
  [
      "MBS",
      "Freeland"
  ],
  [
      "MBT",
      "Masbate"
  ],
  [
      "MBU",
      "Mbambanakira"
  ],
  [
      "MCE",
      "Merced"
  ],
  [
      "MCG",
      "Mcgrath"
  ],
  [
      "MCI",
      "Kansas City"
  ],
  [
      "MCK",
      "Mccook"
  ],
  [
      "MCM",
      "Monaco-Ville"
  ],
  [
      "MCN",
      "Macon"
  ],
  [
      "MCO",
      "Orlando"
  ],
  [
      "MCP",
      "Macapá"
  ],
  [
      "MCT",
      "Muscat"
  ],
  [
      "MCV",
      "Mcarthur River"
  ],
  [
      "MCW",
      "Clear Lake"
  ],
  [
      "MCX",
      "Khasavyurt"
  ],
  [
      "MCY",
      "Mudjimba"
  ],
  [
      "MCZ",
      "Maceio"
  ],
  [
      "MDC",
      "Manado"
  ],
  [
      "MDE",
      "Ríonegro"
  ],
  [
      "MDG",
      "Mudanjiang"
  ],
  [
      "MDK",
      "Mbandaka"
  ],
  [
      "MDL",
      "Mandalay"
  ],
  [
      "MDQ",
      "Mar del Plata"
  ],
  [
      "MDS",
      "Lorimers"
  ],
  [
      "MDT",
      "Middletown"
  ],
  [
      "MDU",
      "Mendi"
  ],
  [
      "MDW",
      "Chicago"
  ],
  [
      "MDZ",
      "Mendoza"
  ],
  [
      "MEA",
      "Macae"
  ],
  [
      "MEC",
      "Manta"
  ],
  [
      "MED",
      "Al Madinah"
  ],
  [
      "MEE",
      "Tadine"
  ],
  [
      "MEG",
      "Malanje"
  ],
  [
      "MEH",
      "Mehavn"
  ],
  [
      "MEI",
      "Meridian"
  ],
  [
      "MEL",
      "Melbourne"
  ],
  [
      "MEM",
      "Memphis"
  ],
  [
      "MES",
      "Medan"
  ],
  [
      "MEX",
      "Mexico City"
  ],
  [
      "MEY",
      "Meghauli"
  ],
  [
      "MFE",
      "Mcallen"
  ],
  [
      "MFJ",
      "Moala"
  ],
  [
      "MFK",
      "Matsu"
  ],
  [
      "MFM",
      "Macau"
  ],
  [
      "MFR",
      "Central Point"
  ],
  [
      "MFU",
      "Mfuwe"
  ],
  [
      "MGA",
      "Tipitapa"
  ],
  [
      "MGB",
      "Mount Gambier"
  ],
  [
      "MGF",
      "Maringa"
  ],
  [
      "MGH",
      "Port Shepstone"
  ],
  [
      "MGM",
      "Montgomery"
  ],
  [
      "MGQ",
      "Mogadishu"
  ],
  [
      "MGS",
      "Mangaia Island"
  ],
  [
      "MGT",
      "Milingimbi"
  ],
  [
      "MGW",
      "Morgantown"
  ],
  [
      "MGZ",
      "Mergui"
  ],
  [
      "MHD",
      "Mashhad"
  ],
  [
      "MHG",
      "Mannheim"
  ],
  [
      "MHH",
      "Marsh Harbour"
  ],
  [
      "MHK",
      "Manhattan"
  ],
  [
      "MHP",
      "Minsk"
  ],
  [
      "MHQ",
      "Maarianhamina"
  ],
  [
      "MHR",
      "Mather"
  ],
  [
      "MHT",
      "Manchester"
  ],
  [
      "MIA",
      "Miami"
  ],
  [
      "MID",
      "Mérida"
  ],
  [
      "MIG",
      "Mian Yang"
  ],
  [
      "MII",
      "Marilia"
  ],
  [
      "MIM",
      "Merimbula"
  ],
  [
      "MIR",
      "Sidi al Ghudamisi"
  ],
  [
      "MIS",
      "Misima Island"
  ],
  [
      "MJA",
      "Manja"
  ],
  [
      "MJD",
      "Mohenjodaro"
  ],
  [
      "MJF",
      "Mosjoen"
  ],
  [
      "MJI",
      "Mitiga"
  ],
  [
      "MJK",
      "Monkey Mia"
  ],
  [
      "MJL",
      "Mouila"
  ],
  [
      "MJM",
      "Mbuji Mayi"
  ],
  [
      "MJN",
      "Mahajanga"
  ],
  [
      "MJT",
      "Mitilini"
  ],
  [
      "MJV",
      "San Javier"
  ],
  [
      "MJZ",
      "Mirnyj"
  ],
  [
      "MKC",
      "Kansas City"
  ],
  [
      "MKE",
      "Milwaukee"
  ],
  [
      "MKG",
      "Muskegon"
  ],
  [
      "MKK",
      "Hoolehua"
  ],
  [
      "MKM",
      "Mukah"
  ],
  [
      "MKP",
      "Makemo"
  ],
  [
      "MKQ",
      "Merauke"
  ],
  [
      "MKR",
      "Kumarina"
  ],
  [
      "MKU",
      "Makokou"
  ],
  [
      "MKW",
      "Manokwari"
  ],
  [
      "MKY",
      "Mackay"
  ],
  [
      "MLA",
      "Curmi"
  ],
  [
      "MLB",
      "Melbourne"
  ],
  [
      "MLE",
      "Male"
  ],
  [
      "MLG",
      "Malang"
  ],
  [
      "MLH",
      "Basel Mulhouse Freiburg"
  ],
  [
      "MLI",
      "Coal Valley"
  ],
  [
      "MLL",
      "Marshall"
  ],
  [
      "MLM",
      "Álvaro Obregón"
  ],
  [
      "MLN",
      "Melilla"
  ],
  [
      "MLO",
      "Apollonia"
  ],
  [
      "MLU",
      "Monroe"
  ],
  [
      "MLW",
      "Monrovia"
  ],
  [
      "MLX",
      "Arga"
  ],
  [
      "MLY",
      "Manley Hot Springs"
  ],
  [
      "MMB",
      "Ozora-cho"
  ],
  [
      "MME",
      "Darlington"
  ],
  [
      "MMG",
      "Mount Magnet"
  ],
  [
      "MMH",
      "Mammoth Lakes"
  ],
  [
      "MMK",
      "Apatity"
  ],
  [
      "MMO",
      "Vila do Maio"
  ],
  [
      "MMX",
      "Svedala"
  ],
  [
      "MMY",
      "Miyako Jima"
  ],
  [
      "MNF",
      "Mana Island"
  ],
  [
      "MNG",
      "Maningrida"
  ],
  [
      "MNJ",
      "Mananjary"
  ],
  [
      "MNL",
      "Parañaque"
  ],
  [
      "MNT",
      "Minto"
  ],
  [
      "MNU",
      "Moulmein"
  ],
  [
      "MOA",
      "Moa"
  ],
  [
      "MOB",
      "Mobile"
  ],
  [
      "MOC",
      "Montes Claros"
  ],
  [
      "MOD",
      "Modesto"
  ],
  [
      "MOF",
      "Maumere"
  ],
  [
      "MOG",
      "Mong Hsat"
  ],
  [
      "MOI",
      "Mitiaro Island"
  ],
  [
      "MOL",
      "Bolsøya"
  ],
  [
      "MOQ",
      "Morondava"
  ],
  [
      "MOT",
      "Minot"
  ],
  [
      "MOU",
      "Mountain Village"
  ],
  [
      "MOV",
      "Moranbah"
  ],
  [
      "MOZ",
      "Papeete"
  ],
  [
      "MPA",
      "Mpacha"
  ],
  [
      "MPH",
      "Caticlan"
  ],
  [
      "MPL",
      "Mauguio"
  ],
  [
      "MPM",
      "Maputo"
  ],
  [
      "MPN",
      "Mount Pleasant"
  ],
  [
      "MQF",
      "Chelyabinsk"
  ],
  [
      "MQL",
      "Mildura"
  ],
  [
      "MQM",
      "Mardin"
  ],
  [
      "MQN",
      "Skonseng"
  ],
  [
      "MQP",
      "Nelspruit"
  ],
  [
      "MQT",
      "Marquette"
  ],
  [
      "MQX",
      "Makale"
  ],
  [
      "MRA",
      "Misratah"
  ],
  [
      "MRD",
      "Ejido"
  ],
  [
      "MRE",
      "Mara Lodges"
  ],
  [
      "MRS",
      "Marignane"
  ],
  [
      "MRU",
      "Mahebourg"
  ],
  [
      "MRV",
      "Mineralnye Vody"
  ],
  [
      "MRY",
      "Monterey"
  ],
  [
      "MRZ",
      "Moree"
  ],
  [
      "MSA",
      "Muskrat Dam"
  ],
  [
      "MSE",
      "Manston"
  ],
  [
      "MSJ",
      "Misawa-shi"
  ],
  [
      "MSL",
      "Muscle Shoals"
  ],
  [
      "MSN",
      "Madison"
  ],
  [
      "MSO",
      "Missoula"
  ],
  [
      "MSP",
      "St. Paul"
  ],
  [
      "MSQ",
      "Minsk"
  ],
  [
      "MSR",
      "Mush"
  ],
  [
      "MSS",
      "Massena"
  ],
  [
      "MST",
      "Maastricht-Airport"
  ],
  [
      "MSU",
      "Maseru"
  ],
  [
      "MSW",
      "Massawa"
  ],
  [
      "MSY",
      "Kenner"
  ],
  [
      "MSZ",
      "Namibe"
  ],
  [
      "MTJ",
      "Montrose"
  ],
  [
      "MTM",
      "Metlakatla"
  ],
  [
      "MTR",
      "Los Garzones"
  ],
  [
      "MTS",
      "Manzini"
  ],
  [
      "MTT",
      "Minatitlan"
  ],
  [
      "MTV",
      "Mota Lava"
  ],
  [
      "MTY",
      "Pesquería"
  ],
  [
      "MUA",
      "Munda"
  ],
  [
      "MUB",
      "Maun"
  ],
  [
      "MUC",
      "Oberding"
  ],
  [
      "MUE",
      "Kamuela"
  ],
  [
      "MUH",
      "Marsa Matruh"
  ],
  [
      "MUK",
      "Mauke Island"
  ],
  [
      "MUN",
      "Aguasay"
  ],
  [
      "MUR",
      "Miri"
  ],
  [
      "MUX",
      "Multan"
  ],
  [
      "MUZ",
      "Musoma"
  ],
  [
      "MVB",
      "Franceville"
  ],
  [
      "MVD",
      "Montevideo"
  ],
  [
      "MVP",
      "Mitu"
  ],
  [
      "MVR",
      "Maroua"
  ],
  [
      "MVS",
      "Mucuri"
  ],
  [
      "MVT",
      "Mataiva"
  ],
  [
      "MVY",
      "Vineyard Haven"
  ],
  [
      "MWA",
      "Marion"
  ],
  [
      "MWF",
      "Maewo"
  ],
  [
      "MWN",
      "Mwadui"
  ],
  [
      "MWQ",
      "Magwe"
  ],
  [
      "MWZ",
      "Ilemera"
  ],
  [
      "MXH",
      "Moro"
  ],
  [
      "MXL",
      "Mexicali"
  ],
  [
      "MXM",
      "Morombe"
  ],
  [
      "MXN",
      "Morlaix"
  ],
  [
      "MXP",
      "Cardano al Campo"
  ],
  [
      "MXT",
      "Maintirano"
  ],
  [
      "MXV",
      "Mörön"
  ],
  [
      "MXX",
      "Mora"
  ],
  [
      "MXZ",
      "Meixian"
  ],
  [
      "MYA",
      "Bingie"
  ],
  [
      "MYD",
      "Mombasa"
  ],
  [
      "MYE",
      "Miyake-mura"
  ],
  [
      "MYG",
      "Abraham Bay"
  ],
  [
      "MYI",
      "Murray Island"
  ],
  [
      "MYJ",
      "Matsuyama-shi"
  ],
  [
      "MYL",
      "Mccall"
  ],
  [
      "MYR",
      "Myrtle Beach"
  ],
  [
      "MYT",
      "Myitkyina"
  ],
  [
      "MYU",
      "Mekoryuk"
  ],
  [
      "MYW",
      "Ziwani"
  ],
  [
      "MYY",
      "Miri"
  ],
  [
      "MZG",
      "Makung City"
  ],
  [
      "MZH",
      "Merzifon"
  ],
  [
      "MZI",
      "Mopti"
  ],
  [
      "MZL",
      "Villamaría"
  ],
  [
      "MZO",
      "Manzanillo"
  ],
  [
      "MZR",
      "Mazar-i-Sharif"
  ],
  [
      "MZT",
      "Mazatlán"
  ],
  [
      "MZV",
      "Mulu"
  ],
  [
      "NAA",
      "Bohena Creek"
  ],
  [
      "NAC",
      "Naracoorte"
  ],
  [
      "NAG",
      "Nagpur"
  ],
  [
      "NAJ",
      "Nakhichevan"
  ],
  [
      "NAN",
      "Nadi"
  ],
  [
      "NAO",
      "Nanchong"
  ],
  [
      "NAP",
      "Naples"
  ],
  [
      "NAQ",
      "Qaanaaq"
  ],
  [
      "NAS",
      "Nassau"
  ],
  [
      "NAT",
      "Natal"
  ],
  [
      "NAU",
      "Napuka Island"
  ],
  [
      "NAV",
      "Nevsehir"
  ],
  [
      "NAW",
      "Narathiwat"
  ],
  [
      "NBC",
      "Naberevnye Chelny"
  ],
  [
      "NBO",
      "Nairobi"
  ],
  [
      "NBW",
      "Caimanera"
  ],
  [
      "NBX",
      "Nabire"
  ],
  [
      "NCA",
      "Bottle Creek Settlements"
  ],
  [
      "NCE",
      "Nice"
  ],
  [
      "NCL",
      "Newcastle"
  ],
  [
      "NCN",
      "New Chenega"
  ],
  [
      "NCU",
      "Nukus"
  ],
  [
      "NCY",
      "Pringy"
  ],
  [
      "NDB",
      "Port-Etienne"
  ],
  [
      "NDG",
      "Qiqihar"
  ],
  [
      "NDJ",
      "N'Djamena"
  ],
  [
      "NDR",
      "Nador"
  ],
  [
      "NER",
      "Neryungri"
  ],
  [
      "NEV",
      "Nevis"
  ],
  [
      "NFO",
      ""
  ],
  [
      "NGB",
      "Jiangshan"
  ],
  [
      "NGE",
      "Ngaoundéré"
  ],
  [
      "NGI",
      "Ngau Island"
  ],
  [
      "NGO",
      "Tokoname-shi"
  ],
  [
      "NGS",
      "Omura-shi"
  ],
  [
      "NHA",
      "Nha Trang"
  ],
  [
      "NHV",
      "Nuku Hiva"
  ],
  [
      "NIB",
      "Nikolai"
  ],
  [
      "NIM",
      "Niamey"
  ],
  [
      "NIP",
      "Jacksonville"
  ],
  [
      "NIU",
      "Honolulu"
  ],
  [
      "NJC",
      "Nizhnevartovsk"
  ],
  [
      "NKC",
      "Nouakchott"
  ],
  [
      "NKG",
      "Nanjing"
  ],
  [
      "NKI",
      "Naukiti"
  ],
  [
      "NKM",
      "Toyoyama-cho"
  ],
  [
      "NLA",
      "Ndola"
  ],
  [
      "NLD",
      "Nuevo Laredo"
  ],
  [
      "NLF",
      "Kubin Village"
  ],
  [
      "NLG",
      "Nelson Lagoon"
  ],
  [
      "NLK",
      "Kingston"
  ],
  [
      "NLV",
      "Mykolayiv"
  ],
  [
      "NMA",
      "Namangan"
  ],
  [
      "NME",
      "Nightmute"
  ],
  [
      "NNB",
      "Santa Ana"
  ],
  [
      "NNG",
      "Wuxu"
  ],
  [
      "NNL",
      "Nondalton"
  ],
  [
      "NNM",
      "Naryan-Mar"
  ],
  [
      "NNT",
      "Nan"
  ],
  [
      "NNY",
      "Nanyang"
  ],
  [
      "NOB",
      "Nosara"
  ],
  [
      "NOC",
      "Knock"
  ],
  [
      "NOJ",
      "Nojabrxsk"
  ],
  [
      "NOS",
      "Hell-Ville"
  ],
  [
      "NOU",
      ""
  ],
  [
      "NOV",
      "Huambo"
  ],
  [
      "NOZ",
      "Novokuznetsk"
  ],
  [
      "NPE",
      "Napier"
  ],
  [
      "NPL",
      "New Plymouth"
  ],
  [
      "NQN",
      "Neuquen"
  ],
  [
      "NQU",
      "Nuquí"
  ],
  [
      "NQY",
      "Newquay"
  ],
  [
      "NRA",
      "Narrandera"
  ],
  [
      "NRK",
      "Norrkoping"
  ],
  [
      "NRN",
      "Weeze"
  ],
  [
      "NRT",
      "Narita-shi"
  ],
  [
      "NSH",
      "Now Shahr"
  ],
  [
      "NSI",
      "Yaounde"
  ],
  [
      "NSK",
      "Kansk"
  ],
  [
      "NSN",
      "Nelson"
  ],
  [
      "NST",
      "Phra Phrom"
  ],
  [
      "NTE",
      "Bouguenais"
  ],
  [
      "NTG",
      "Nantong"
  ],
  [
      "NTL",
      "Ferodale"
  ],
  [
      "NTN",
      "Normanton"
  ],
  [
      "NTQ",
      "Anamizu-machi"
  ],
  [
      "NTT",
      "Niuatoputapu"
  ],
  [
      "NUE",
      "Nuremberg"
  ],
  [
      "NUI",
      "Nuiqsut"
  ],
  [
      "NUK",
      "Nukutavake"
  ],
  [
      "NUL",
      "Nulato"
  ],
  [
      "NUP",
      "Nunapitchuk"
  ],
  [
      "NUS",
      "Norsup"
  ],
  [
      "NUX",
      "Novy Urengoy"
  ],
  [
      "NVA",
      "Neiva"
  ],
  [
      "NVI",
      "Navoi"
  ],
  [
      "NVK",
      "Narvik"
  ],
  [
      "NVR",
      "Novgorod"
  ],
  [
      "NVT",
      "Navegantes"
  ],
  [
      "NWA",
      "Moheli"
  ],
  [
      "NWI",
      "Norwich"
  ],
  [
      "NYA",
      "Norwood Young America"
  ],
  [
      "NYK",
      "Nyeri"
  ],
  [
      "NYM",
      "Nadym"
  ],
  [
      "NYO",
      "Nykoping"
  ],
  [
      "NYU",
      "Nyaung-u"
  ],
  [
      "NZH",
      "Manzhouli"
  ],
  [
      "OAG",
      "Arthurville"
  ],
  [
      "OAJ",
      "Richlands"
  ],
  [
      "OAK",
      "Oakland"
  ],
  [
      "OAM",
      "Oamaru"
  ],
  [
      "OAX",
      "San Bernardo Mixtepec"
  ],
  [
      "OBN",
      "Oban"
  ],
  [
      "OBO",
      "Obihiro-shi"
  ],
  [
      "OBU",
      "Kobuk"
  ],
  [
      "OBX",
      "Obo"
  ],
  [
      "OCC",
      "Chontapunta"
  ],
  [
      "ODN",
      "Long Seridan"
  ],
  [
      "ODS",
      "Odesa"
  ],
  [
      "ODW",
      "Oak Harbor"
  ],
  [
      "ODY",
      "Oudomxay"
  ],
  [
      "OER",
      "Husum"
  ],
  [
      "OFI",
      "Ouango Fitini"
  ],
  [
      "OGG",
      "Kahului"
  ],
  [
      "OGN",
      "Yonaguni-cho"
  ],
  [
      "OGS",
      "Ogdensburg"
  ],
  [
      "OGX",
      "Ouargla"
  ],
  [
      "OGZ",
      "Mozdok"
  ],
  [
      "OHD",
      "Ohrid"
  ],
  [
      "OHE",
      "Hamburg"
  ],
  [
      "OHO",
      "Okhotsk"
  ],
  [
      "OIM",
      "Oshima-machi"
  ],
  [
      "OIT",
      "Kunisaki-shi"
  ],
  [
      "OKA",
      "Naha-shi"
  ],
  [
      "OKC",
      "Oklahoma City"
  ],
  [
      "OKD",
      "Sapporo-shi"
  ],
  [
      "OKJ",
      "Okayama-shi"
  ],
  [
      "OKR",
      "Yorke Island"
  ],
  [
      "OKY",
      "Oakey"
  ],
  [
      "OLA",
      "Orland"
  ],
  [
      "OLB",
      "Terranova"
  ],
  [
      "OLF",
      "Wolf Point"
  ],
  [
      "OLH",
      "Old Harbor"
  ],
  [
      "OLJ",
      "Olpoi"
  ],
  [
      "OLP",
      "Roxby Downs"
  ],
  [
      "OMA",
      "Omaha"
  ],
  [
      "OMB",
      "Omboue"
  ],
  [
      "OMC",
      "Ormoc"
  ],
  [
      "OMD",
      "Oranjemund"
  ],
  [
      "OME",
      "Nome"
  ],
  [
      "OMH",
      "Urmieh"
  ],
  [
      "OMO",
      "Mostar"
  ],
  [
      "OMR",
      "Oradea-Mare"
  ],
  [
      "OMS",
      "Omsk"
  ],
  [
      "OND",
      "Ondangwa"
  ],
  [
      "ONG",
      "Mornington"
  ],
  [
      "ONJ",
      "Kitakita-shi"
  ],
  [
      "ONL",
      "O'neill"
  ],
  [
      "ONT",
      "Ontario"
  ],
  [
      "OOK",
      "Toksook Bay"
  ],
  [
      "OOL",
      "Gold Coast"
  ],
  [
      "OPF",
      "Opa-Locka"
  ],
  [
      "OPO",
      "Maia"
  ],
  [
      "OPS",
      "Sinop"
  ],
  [
      "OPU",
      "Balimo"
  ],
  [
      "ORB",
      "Orebro"
  ],
  [
      "ORD",
      "Chicago"
  ],
  [
      "ORF",
      "Norfolk"
  ],
  [
      "ORH",
      "Worcester"
  ],
  [
      "ORI",
      "Port Lions"
  ],
  [
      "ORJ",
      "Rera"
  ],
  [
      "ORK",
      "Fivemilebridge"
  ],
  [
      "ORM",
      "Northampton"
  ],
  [
      "ORN",
      "Oran Rp"
  ],
  [
      "ORV",
      "Noorvik"
  ],
  [
      "ORY",
      "Paris"
  ],
  [
      "OSD",
      "Frösö"
  ],
  [
      "OSI",
      "Osijek"
  ],
  [
      "OSK",
      "Fårbo"
  ],
  [
      "OSL",
      "Gardermoen"
  ],
  [
      "OSM",
      "Mosul"
  ],
  [
      "OSR",
      "Mošnov"
  ],
  [
      "OSS",
      "Osh"
  ],
  [
      "OST",
      "Oostende"
  ],
  [
      "OSW",
      "Orsk"
  ],
  [
      "OSY",
      "Namsos"
  ],
  [
      "OSZ",
      "Sianów"
  ],
  [
      "OTH",
      "North Bend"
  ],
  [
      "OTP",
      "Bucharest"
  ],
  [
      "OTR",
      "Coto 47"
  ],
  [
      "OTZ",
      "Kotzebue"
  ],
  [
      "OUA",
      "Ouagadougou"
  ],
  [
      "OUD",
      "Oujda"
  ],
  [
      "OUE",
      "Ouesso"
  ],
  [
      "OUL",
      "Oulunsalo"
  ],
  [
      "OUZ",
      "Zouerate"
  ],
  [
      "OVB",
      "Novosibirsk"
  ],
  [
      "OVD",
      "Castrillón"
  ],
  [
      "OVS",
      "Boscobel"
  ],
  [
      "OXB",
      "Bissau"
  ],
  [
      "OXF",
      "Kidlington"
  ],
  [
      "OXR",
      "Oxnard"
  ],
  [
      "OYE",
      "Oyem"
  ],
  [
      "OYG",
      "Moyo"
  ],
  [
      "OZC",
      "Ozamis City"
  ],
  [
      "OZH",
      "Zaporizhzhya"
  ],
  [
      "OZZ",
      "Ouarzazate"
  ],
  [
      "PAD",
      "Büren"
  ],
  [
      "PAH",
      "West Paducah"
  ],
  [
      "PAI",
      "Pailin"
  ],
  [
      "PAP",
      "Port Au Prince"
  ],
  [
      "PAS",
      "Levkai"
  ],
  [
      "PAT",
      "Patna"
  ],
  [
      "PAZ",
      "Castillo de Teayo"
  ],
  [
      "PBC",
      "Tlaltenango"
  ],
  [
      "PBD",
      "Porbandar"
  ],
  [
      "PBG",
      "Plattsburgh"
  ],
  [
      "PBH",
      "Paro"
  ],
  [
      "PBI",
      "West Palm Beach"
  ],
  [
      "PBJ",
      "Paama"
  ],
  [
      "PBM",
      "Sabakoe"
  ],
  [
      "PBO",
      "Paraburdoo"
  ],
  [
      "PBP",
      "Punta Islita"
  ],
  [
      "PBU",
      "Putao"
  ],
  [
      "PCE",
      "Painter Creek"
  ],
  [
      "PCL",
      "Callaria"
  ],
  [
      "PCR",
      "Puerto Carreño"
  ],
  [
      "PDA",
      "Guaviare"
  ],
  [
      "PDB",
      "Pedro Bay"
  ],
  [
      "PDG",
      "Padang"
  ],
  [
      "PDL",
      "Ponta Delgada"
  ],
  [
      "PDP",
      "Punta del Este"
  ],
  [
      "PDS",
      "Piedras Negras"
  ],
  [
      "PDT",
      "Pendleton"
  ],
  [
      "PDX",
      "Portland"
  ],
  [
      "PEC",
      "Pelican"
  ],
  [
      "PED",
      "Pardubice"
  ],
  [
      "PEE",
      "Perm'"
  ],
  [
      "PEG",
      "Assisi"
  ],
  [
      "PEI",
      "Pereira"
  ],
  [
      "PEK",
      "Shunyi"
  ],
  [
      "PEM",
      "Puerto Maldonado"
  ],
  [
      "PEN",
      "Batu Maung"
  ],
  [
      "PER",
      "Perth"
  ],
  [
      "PES",
      "Petrozavodsk"
  ],
  [
      "PET",
      "Pelotas"
  ],
  [
      "PEU",
      "Puerto Lempira"
  ],
  [
      "PEW",
      "Peshawar"
  ],
  [
      "PEX",
      "Ukhta"
  ],
  [
      "PEZ",
      "Penza"
  ],
  [
      "PFB",
      "Passo Fundo"
  ],
  [
      "PFN",
      "Panama City"
  ],
  [
      "PFO",
      "Paphos"
  ],
  [
      "PFQ",
      "Parsabad"
  ],
  [
      "PGA",
      "Page"
  ],
  [
      "PGF",
      "Perpignan"
  ],
  [
      "PGK",
      "Pangkalpinang"
  ],
  [
      "PGM",
      "Port Graham"
  ],
  [
      "PGU",
      "Asalouyeh"
  ],
  [
      "PGV",
      "Greenville"
  ],
  [
      "PGX",
      "St-Pierre"
  ],
  [
      "PHC",
      "Port Harcourt"
  ],
  [
      "PHE",
      "Port Hedland"
  ],
  [
      "PHF",
      "Newport News"
  ],
  [
      "PHG",
      "Port Harcourt"
  ],
  [
      "PHL",
      "Philadelphia"
  ],
  [
      "PHO",
      "Point Hope"
  ],
  [
      "PHS",
      "Phitsanulok"
  ],
  [
      "PHW",
      "Phalaborwa"
  ],
  [
      "PHX",
      "Phoenix"
  ],
  [
      "PIA",
      "Peoria"
  ],
  [
      "PIB",
      "Moselle"
  ],
  [
      "PIE",
      "Clearwater"
  ],
  [
      "PIF",
      "Pingtung"
  ],
  [
      "PIH",
      "Pocatello"
  ],
  [
      "PIK",
      "Prestwick"
  ],
  [
      "PIN",
      "Parintins"
  ],
  [
      "PIP",
      "Pilot Point"
  ],
  [
      "PIR",
      "Pierre"
  ],
  [
      "PIS",
      "Vouneuil-sous-Biard"
  ],
  [
      "PIT",
      "Coraopolis"
  ],
  [
      "PIU",
      "Piura"
  ],
  [
      "PIW",
      "Nelson House"
  ],
  [
      "PIX",
      "Madalena"
  ],
  [
      "PIZ",
      "Point Lay"
  ],
  [
      "PJA",
      "Pajala"
  ],
  [
      "PJG",
      "Panjgur"
  ],
  [
      "PJM",
      "Puerto Jiménez"
  ],
  [
      "PKA",
      "Napaskiak"
  ],
  [
      "PKB",
      "Williamstown"
  ],
  [
      "PKC",
      "Elizovo"
  ],
  [
      "PKE",
      "Parkes"
  ],
  [
      "PKG",
      "Pangkor"
  ],
  [
      "PKK",
      "Pakokku"
  ],
  [
      "PKP",
      "Puka Puka"
  ],
  [
      "PKR",
      "Pokhara"
  ],
  [
      "PKU",
      "Pekanbaru"
  ],
  [
      "PKY",
      "Buntok"
  ],
  [
      "PKZ",
      "Pakxe"
  ],
  [
      "PLD",
      "Nicoya"
  ],
  [
      "PLH",
      "Plymouth"
  ],
  [
      "PLJ",
      "Placencia"
  ],
  [
      "PLM",
      "Palembang"
  ],
  [
      "PLN",
      "Pellston"
  ],
  [
      "PLO",
      "Port Lincoln"
  ],
  [
      "PLQ",
      "Klaipeda/Palanga"
  ],
  [
      "PLS",
      "The Bight Settlements"
  ],
  [
      "PLU",
      "Belo Horizonte"
  ],
  [
      "PLW",
      "Palu"
  ],
  [
      "PLX",
      "Semipalatinsk"
  ],
  [
      "PLZ",
      "Port Elizabeth"
  ],
  [
      "PMA",
      "Pemba"
  ],
  [
      "PMC",
      "Los Quemas"
  ],
  [
      "PMD",
      "Palmdale"
  ],
  [
      "PME",
      "Portsmouth"
  ],
  [
      "PMF",
      "Parma"
  ],
  [
      "PMI",
      "Palma"
  ],
  [
      "PML",
      "Port Moller"
  ],
  [
      "PMO",
      "Cinisi"
  ],
  [
      "PMR",
      "Palmerston North"
  ],
  [
      "PMV",
      "Pampatar"
  ],
  [
      "PMW",
      "Palmas"
  ],
  [
      "PMY",
      "Puerto Madryn"
  ],
  [
      "PMZ",
      "Palmar Sur"
  ],
  [
      "PNA",
      "Noáin"
  ],
  [
      "PND",
      "Punta Gorda"
  ],
  [
      "PNH",
      "Phnom Penh"
  ],
  [
      "PNI",
      "Palikir"
  ],
  [
      "PNK",
      "Pontianak"
  ],
  [
      "PNL",
      "Pantelleria"
  ],
  [
      "PNP",
      "Popondetta"
  ],
  [
      "PNQ",
      "Pune"
  ],
  [
      "PNR",
      "Pointe-Noire"
  ],
  [
      "PNS",
      "Pensacola"
  ],
  [
      "PNZ",
      "Petrolina"
  ],
  [
      "POA",
      "Porto Alegre"
  ],
  [
      "POG",
      "Port-Gentil"
  ],
  [
      "POL",
      "Pemba"
  ],
  [
      "POM",
      "Port Moresby"
  ],
  [
      "POP",
      "San Felipe de Puerto Plata"
  ],
  [
      "POR",
      "Pori"
  ],
  [
      "POS",
      "Trinidad"
  ],
  [
      "POZ",
      "Poznan"
  ],
  [
      "PPB",
      "Presidente Prudente"
  ],
  [
      "PPE",
      "Pto. Penasco"
  ],
  [
      "PPG",
      "Pago Pago"
  ],
  [
      "PPK",
      "Petropavlovsk"
  ],
  [
      "PPL",
      "Phaplu"
  ],
  [
      "PPN",
      "Popayán"
  ],
  [
      "PPP",
      "Brandy Creek"
  ],
  [
      "PPS",
      "Puerto Princesa"
  ],
  [
      "PPT",
      "Papeete"
  ],
  [
      "PPV",
      "Port Protection"
  ],
  [
      "PQC",
      "Kien Giang"
  ],
  [
      "PQI",
      "Presque Isle"
  ],
  [
      "PQQ",
      "Port Macquarie"
  ],
  [
      "PQS",
      "Pilot Station"
  ],
  [
      "PRC",
      "Prescott"
  ],
  [
      "PRG",
      "Prague 6"
  ],
  [
      "PRI",
      "Praslin Island"
  ],
  [
      "PRN",
      "Prishtina"
  ],
  [
      "PSA",
      "Pisa"
  ],
  [
      "PSC",
      "Pasco"
  ],
  [
      "PSE",
      "Coto Laurel"
  ],
  [
      "PSG",
      "Petersburg"
  ],
  [
      "PSO",
      "Chachagüí"
  ],
  [
      "PSP",
      "Palm Springs"
  ],
  [
      "PSR",
      "Pescara"
  ],
  [
      "PSS",
      "Posadas"
  ],
  [
      "PSZ",
      "Puerto Suárez"
  ],
  [
      "PTA",
      "Port Alsworth"
  ],
  [
      "PTF",
      "Malololailai"
  ],
  [
      "PTG",
      "Pietersburg"
  ],
  [
      "PTH",
      "Port Heiden"
  ],
  [
      "PTP",
      "Les Abymes"
  ],
  [
      "PTU",
      "Platinum"
  ],
  [
      "PTY",
      "Tocumen"
  ],
  [
      "PUB",
      "Pueblo"
  ],
  [
      "PUF",
      "Lescar"
  ],
  [
      "PUJ",
      "Salvaleón de Higüey"
  ],
  [
      "PUK",
      "Pukarua"
  ],
  [
      "PUQ",
      "Punta Arenas"
  ],
  [
      "PUS",
      "Busan"
  ],
  [
      "PUU",
      "Puerto Asís"
  ],
  [
      "PUW",
      "Pullman"
  ],
  [
      "PUY",
      "Pluj"
  ],
  [
      "PVA",
      "San Andrés"
  ],
  [
      "PVC",
      "Provincetown"
  ],
  [
      "PVD",
      "Warwick"
  ],
  [
      "PVG",
      "Huinan"
  ],
  [
      "PVH",
      "Pôrto Velho"
  ],
  [
      "PVK",
      "Paliambela"
  ],
  [
      "PVR",
      "Puerto Vallarta"
  ],
  [
      "PWE",
      "Anadyr'"
  ],
  [
      "PWK",
      "Wheeling"
  ],
  [
      "PWM",
      "Portland"
  ],
  [
      "PWQ",
      "Pavlodar"
  ],
  [
      "PXM",
      "San Pedro Juchatengo"
  ],
  [
      "PXO",
      "Porto Santo"
  ],
  [
      "PXU",
      "Gia Lai"
  ],
  [
      "PYH",
      "Maroa"
  ],
  [
      "PYJ",
      "Polyarnyj"
  ],
  [
      "PZB",
      "Pietermaritzburg"
  ],
  [
      "PZE",
      "Penzance"
  ],
  [
      "PZI",
      "Pan Zhi Hua"
  ],
  [
      "PZO",
      "Ciudad Guayana"
  ],
  [
      "PZU",
      "Port Sudan"
  ],
  [
      "QBC",
      "Masset"
  ],
  [
      "QDH",
      "Ashford"
  ],
  [
      "QDU",
      "Dusseldorf"
  ],
  [
      "QFB",
      "Freiburg"
  ],
  [
      "QFZ",
      "Sarrebruck"
  ],
  [
      "QJY",
      "Jeju-Si"
  ],
  [
      "QJZ",
      "Nantes"
  ],
  [
      "QKL",
      "Cologne"
  ],
  [
      "QOW",
      "Owerri"
  ],
  [
      "QQD",
      "Dover"
  ],
  [
      "QQH",
      "Harwich"
  ],
  [
      "QQK",
      "London"
  ],
  [
      "QQM",
      "Manchester"
  ],
  [
      "QQN",
      "Birmingham"
  ],
  [
      "QQP",
      "London"
  ],
  [
      "QQS",
      "Britrail Rail Zone S"
  ],
  [
      "QQU",
      "London"
  ],
  [
      "QQW",
      "London"
  ],
  [
      "QQX",
      "Bath"
  ],
  [
      "QQY",
      "York"
  ],
  [
      "QRH",
      "Rotterdam"
  ],
  [
      "QRO",
      "Queretaro"
  ],
  [
      "QRW",
      "Warri"
  ],
  [
      "QSF",
      "Setif"
  ],
  [
      "QUL",
      "Qulin"
  ],
  [
      "QWB",
      "Sao Jose dos Pinhais"
  ],
  [
      "QXB",
      "Aix-les-milles"
  ],
  [
      "QXG",
      "Angers"
  ],
  [
      "QYU",
      "Gefle"
  ],
  [
      "QYX",
      "Uppsala"
  ],
  [
      "RAB",
      "Rabaul"
  ],
  [
      "RAE",
      "Arar"
  ],
  [
      "RAH",
      "Rafha"
  ],
  [
      "RAI",
      "Praia"
  ],
  [
      "RAJ",
      "Rajkot"
  ],
  [
      "RAK",
      "Marrakesh"
  ],
  [
      "RAO",
      "Ribeirão Prêto"
  ],
  [
      "RAP",
      "Rapid City"
  ],
  [
      "RAR",
      "Avarua"
  ],
  [
      "RAS",
      "Rasht"
  ],
  [
      "RBA",
      "Rabat"
  ],
  [
      "RBH",
      "Brooks Lodge"
  ],
  [
      "RBQ",
      "Rurrenabaque"
  ],
  [
      "RBR",
      "Rio Branco"
  ],
  [
      "RBV",
      "Ramata"
  ],
  [
      "RBX",
      "Roundup"
  ],
  [
      "RBY",
      "Ruby"
  ],
  [
      "RCB",
      "Empangeni"
  ],
  [
      "RCE",
      "Friday Harbor"
  ],
  [
      "RCH",
      "Ríohacha"
  ],
  [
      "RCL",
      "Redcliffe"
  ],
  [
      "RCM",
      "Bellfield"
  ],
  [
      "RCP",
      "Cinder River"
  ],
  [
      "RDB",
      "Red Dog"
  ],
  [
      "RDD",
      "Redding"
  ],
  [
      "RDM",
      "Redmond"
  ],
  [
      "RDN",
      "Redang"
  ],
  [
      "RDU",
      "Raleigh/Durham"
  ],
  [
      "RDV",
      "Red Devil"
  ],
  [
      "RDZ",
      "Marcillac"
  ],
  [
      "REA",
      "Reao"
  ],
  [
      "REC",
      "Recife"
  ],
  [
      "REG",
      "Reggio di Calabria"
  ],
  [
      "REL",
      "Trelew"
  ],
  [
      "REN",
      "Orenburg"
  ],
  [
      "REP",
      "Siemrap"
  ],
  [
      "RES",
      "Makallé"
  ],
  [
      "REU",
      "Reus"
  ],
  [
      "REX",
      "Reynosa"
  ],
  [
      "RFD",
      "Rockford"
  ],
  [
      "RFP",
      "Papeete"
  ],
  [
      "RGA",
      "Río Grande"
  ],
  [
      "RGI",
      "Papeete"
  ],
  [
      "RGL",
      "Río Gallegos"
  ],
  [
      "RGN",
      "Insein"
  ],
  [
      "RHI",
      "Rhinelander"
  ],
  [
      "RHO",
      "Rodhos"
  ],
  [
      "RIA",
      "Santa Maria"
  ],
  [
      "RIB",
      "Riberalta"
  ],
  [
      "RIC",
      "Richmond"
  ],
  [
      "RIG",
      "Rio Grande"
  ],
  [
      "RIS",
      "Rishirifuji-cho"
  ],
  [
      "RIV",
      "Alessandro"
  ],
  [
      "RIW",
      "Riverton"
  ],
  [
      "RIX",
      "Marupe"
  ],
  [
      "RIY",
      "Shuhayr"
  ],
  [
      "RJA",
      "Kapavaram"
  ],
  [
      "RJK",
      "Rijeka"
  ],
  [
      "RJL",
      "Logrono"
  ],
  [
      "RJN",
      "Rafsanjan"
  ],
  [
      "RKD",
      "Owls Head"
  ],
  [
      "RKS",
      "Rock Springs"
  ],
  [
      "RKT",
      ""
  ],
  [
      "RKV",
      "Reykjavik"
  ],
  [
      "RLG",
      "Rostock-laage"
  ],
  [
      "RMA",
      "Blythdale"
  ],
  [
      "RMF",
      "Marsa Alam"
  ],
  [
      "RMI",
      "Rimini"
  ],
  [
      "RMP",
      "Rampart"
  ],
  [
      "RMQ",
      "Taichung"
  ],
  [
      "RMT",
      "Tatawin"
  ],
  [
      "RNA",
      "Arona"
  ],
  [
      "RNB",
      "Kallinge"
  ],
  [
      "RNL",
      "Rennell"
  ],
  [
      "RNN",
      "Ronne"
  ],
  [
      "RNO",
      "Reno"
  ],
  [
      "RNP",
      "Rongelap Island"
  ],
  [
      "RNS",
      "St-Jacques"
  ],
  [
      "ROA",
      "Roanoke"
  ],
  [
      "ROB",
      "Harbelville"
  ],
  [
      "ROC",
      "Rochester"
  ],
  [
      "ROI",
      "Roi Et"
  ],
  [
      "ROK",
      "Rockhampton"
  ],
  [
      "ROO",
      "Rondonópolis"
  ],
  [
      "ROP",
      "Rota"
  ],
  [
      "ROR",
      "Koror"
  ],
  [
      "ROS",
      "Rosario"
  ],
  [
      "ROT",
      "Rotorua"
  ],
  [
      "ROV",
      "Taganrog"
  ],
  [
      "ROW",
      "Roswell"
  ],
  [
      "RPR",
      "Banarsi"
  ],
  [
      "RRG",
      "Rodrigues Is"
  ],
  [
      "RRS",
      "Roros"
  ],
  [
      "RSA",
      "Santa Rosa"
  ],
  [
      "RSD",
      "Rock Sound"
  ],
  [
      "RSH",
      "Russian Mission"
  ],
  [
      "RSJ",
      "Olga"
  ],
  [
      "RST",
      "Rochester"
  ],
  [
      "RSU",
      "Yeosu-Si"
  ],
  [
      "RSW",
      "Fort Myers"
  ],
  [
      "RTA",
      "Rotuma Island"
  ],
  [
      "RTB",
      "Roatán"
  ],
  [
      "RTG",
      "Ruteng"
  ],
  [
      "RTM",
      "Rotterdam"
  ],
  [
      "RTW",
      "Saratov"
  ],
  [
      "RUA",
      "Arua"
  ],
  [
      "RUD",
      "Oslo"
  ],
  [
      "RUH",
      "Riyadh"
  ],
  [
      "RUK",
      "Rukumkot"
  ],
  [
      "RUM",
      "Rumjatar"
  ],
  [
      "RUN",
      "Sainte-Marie"
  ],
  [
      "RUR",
      "Rurutu"
  ],
  [
      "RUT",
      "North Clarendon"
  ],
  [
      "RVA",
      "Farafangana"
  ],
  [
      "RVE",
      "Saravena"
  ],
  [
      "RVK",
      "Rorvik"
  ],
  [
      "RVN",
      "Saarenkylä"
  ],
  [
      "RVT",
      "Ravensthorpe"
  ],
  [
      "RVV",
      "Rairua"
  ],
  [
      "RXS",
      "Ivisan"
  ],
  [
      "RYG",
      "Rygge"
  ],
  [
      "RYK",
      "Rahim Yar Khan"
  ],
  [
      "RZE",
      "Trzebownisko"
  ],
  [
      "RZP",
      "Taytay Sandoval"
  ],
  [
      "RZR",
      "Ramsar"
  ],
  [
      "RZS",
      "Sawan"
  ],
  [
      "SAB",
      ""
  ],
  [
      "SAH",
      "Ar Raudha"
  ],
  [
      "SAL",
      "San Luis"
  ],
  [
      "SAN",
      "San Diego"
  ],
  [
      "SAP",
      "San Pedro Sula"
  ],
  [
      "SAQ",
      "San Andros"
  ],
  [
      "SAT",
      "San Antonio"
  ],
  [
      "SAV",
      "Savannah"
  ],
  [
      "SAW",
      "Umraniye"
  ],
  [
      "SBA",
      "Goleta"
  ],
  [
      "SBH",
      "Gustavia"
  ],
  [
      "SBL",
      "Santa Ana"
  ],
  [
      "SBN",
      "South Bend"
  ],
  [
      "SBP",
      "San Luis Obispo"
  ],
  [
      "SBR",
      "Saibai Island"
  ],
  [
      "SBW",
      "Sibu"
  ],
  [
      "SBY",
      "Salisbury"
  ],
  [
      "SBZ",
      "Sibiu"
  ],
  [
      "SCC",
      "Prudhoe Bay"
  ],
  [
      "SCE",
      "State College"
  ],
  [
      "SCK",
      "Stockton"
  ],
  [
      "SCL",
      "Lo Amor"
  ],
  [
      "SCM",
      "Scammon Bay"
  ],
  [
      "SCN",
      "Sarrebruck"
  ],
  [
      "SCO",
      "Aktau"
  ],
  [
      "SCQ",
      "Santiago"
  ],
  [
      "SCT",
      "Socotra"
  ],
  [
      "SCU",
      ""
  ],
  [
      "SCV",
      ""
  ],
  [
      "SCW",
      "Syktyvkar"
  ],
  [
      "SCX",
      "Salina Cruz"
  ],
  [
      "SCY",
      "Puerto Baquerizo Moreno"
  ],
  [
      "SCZ",
      "Santa Cruz Is"
  ],
  [
      "SDD",
      "Lubango"
  ],
  [
      "SDE",
      "Frías"
  ],
  [
      "SDF",
      "Louisville"
  ],
  [
      "SDG",
      "Sanandaj"
  ],
  [
      "SDJ",
      "Natori-shi"
  ],
  [
      "SDK",
      "Sandakan"
  ],
  [
      "SDL",
      "Bergeforsen"
  ],
  [
      "SDN",
      "Sandene"
  ],
  [
      "SDP",
      "Sand Point"
  ],
  [
      "SDQ",
      "Santo Domingo"
  ],
  [
      "SDR",
      "Santander"
  ],
  [
      "SDT",
      "Saidu"
  ],
  [
      "SDU",
      "Rio de Janeiro"
  ],
  [
      "SDV",
      "Tel Aviv Yafo"
  ],
  [
      "SDY",
      "Sidney"
  ],
  [
      "SEA",
      "Seattle"
  ],
  [
      "SEB",
      "Sabha"
  ],
  [
      "SEN",
      "Southend on Sea"
  ],
  [
      "SEW",
      "Siwa"
  ],
  [
      "SEZ",
      "Victoria"
  ],
  [
      "SFA",
      "Safaqis"
  ],
  [
      "SFB",
      "Sanford"
  ],
  [
      "SFD",
      "San Fernando de Apure"
  ],
  [
      "SFE",
      "San Fernando"
  ],
  [
      "SFG",
      ""
  ],
  [
      "SFJ",
      "Kangerlussuaq"
  ],
  [
      "SFL",
      "Sao Filipe"
  ],
  [
      "SFN",
      "Santa Fe"
  ],
  [
      "SFO",
      "San Francisco"
  ],
  [
      "SFT",
      "Skelleftea"
  ],
  [
      "SGC",
      "Khanty-Mansiysk"
  ],
  [
      "SGD",
      "Sonderborg"
  ],
  [
      "SGF",
      "Springfield"
  ],
  [
      "SGN",
      "Ho Chi Minh City"
  ],
  [
      "SGO",
      "St George"
  ],
  [
      "SGU",
      "St. George"
  ],
  [
      "SGX",
      "Songea"
  ],
  [
      "SGY",
      "Skagway"
  ],
  [
      "SHA",
      "Shanghai"
  ],
  [
      "SHB",
      "Nakashibetsu-cho"
  ],
  [
      "SHC",
      "Indaselassie"
  ],
  [
      "SHD",
      "Weyers Cave"
  ],
  [
      "SHE",
      "Shenyang"
  ],
  [
      "SHG",
      "Shungnak"
  ],
  [
      "SHH",
      "Shishmaref"
  ],
  [
      "SHJ",
      "Ajman"
  ],
  [
      "SHL",
      "Shillong"
  ],
  [
      "SHM",
      "Shirahama-cho"
  ],
  [
      "SHP",
      "Qinhuangdao"
  ],
  [
      "SHR",
      "Sheridan"
  ],
  [
      "SHV",
      "Shreveport"
  ],
  [
      "SHW",
      "As-Saraura"
  ],
  [
      "SHX",
      "Shageluk"
  ],
  [
      "SHY",
      "Shinyanga"
  ],
  [
      "SIA",
      "Xi'an"
  ],
  [
      "SIC",
      "Sinop"
  ],
  [
      "SID",
      "Santa Maria"
  ],
  [
      "SIF",
      "Simara"
  ],
  [
      "SIG",
      "San Juan"
  ],
  [
      "SIN",
      "Singapore"
  ],
  [
      "SIP",
      "Simferopol'"
  ],
  [
      "SIT",
      "Sitka"
  ],
  [
      "SJC",
      "San Jose"
  ],
  [
      "SJD",
      "S. Jose del Cabo"
  ],
  [
      "SJE",
      "San Jose del Guavuare"
  ],
  [
      "SJI",
      "San Jose"
  ],
  [
      "SJJ",
      "Ilidža"
  ],
  [
      "SJK",
      "Sao Jose dos Campos"
  ],
  [
      "SJO",
      "Heredia"
  ],
  [
      "SJP",
      "São José do Rio Prêto"
  ],
  [
      "SJT",
      "San Angelo"
  ],
  [
      "SJU",
      "Carolina"
  ],
  [
      "SJW",
      "Shijiazhuang"
  ],
  [
      "SJY",
      "Seinajoki"
  ],
  [
      "SJZ",
      "Velas"
  ],
  [
      "SKB",
      "Basseterre"
  ],
  [
      "SKC",
      "Suki"
  ],
  [
      "SKD",
      "Samarkand"
  ],
  [
      "SKE",
      "Skien"
  ],
  [
      "SKG",
      "Thessaloniki"
  ],
  [
      "SKH",
      "Surkhet"
  ],
  [
      "SKK",
      "Shaktoolik"
  ],
  [
      "SKN",
      "Stokkmarknes"
  ],
  [
      "SKO",
      "Shunni"
  ],
  [
      "SKP",
      "Skopje"
  ],
  [
      "SKT",
      "Sialkot"
  ],
  [
      "SKU",
      "Skiros"
  ],
  [
      "SKZ",
      "Sukkur"
  ],
  [
      "SLA",
      "La Caldera"
  ],
  [
      "SLC",
      "Salt Lake City"
  ],
  [
      "SLE",
      "Salem"
  ],
  [
      "SLH",
      "Sola"
  ],
  [
      "SLI",
      "Solwezi"
  ],
  [
      "SLK",
      "Saranac Lake"
  ],
  [
      "SLL",
      "Salalah"
  ],
  [
      "SLM",
      "Villagonzalo de Tormes"
  ],
  [
      "SLN",
      "Salina"
  ],
  [
      "SLP",
      "S. Luis Potosi"
  ],
  [
      "SLQ",
      "Sleetmute"
  ],
  [
      "SLU",
      "St Lucia"
  ],
  [
      "SLV",
      "Jutogh"
  ],
  [
      "SLW",
      "General Cepeda"
  ],
  [
      "SLX",
      "Salt Cay"
  ],
  [
      "SLY",
      "Salekhard"
  ],
  [
      "SLZ",
      "Salvador"
  ],
  [
      "SMA",
      "Vila do Porto"
  ],
  [
      "SMF",
      "Sacramento"
  ],
  [
      "SMI",
      "Pithagorion"
  ],
  [
      "SMK",
      "St Michael"
  ],
  [
      "SML",
      "Simms"
  ],
  [
      "SMN",
      "Salmon"
  ],
  [
      "SMR",
      ""
  ],
  [
      "SMS",
      "Toamasina"
  ],
  [
      "SMX",
      "Santa Maria"
  ],
  [
      "SNA",
      "Santa Ana"
  ],
  [
      "SNC",
      "Salinas"
  ],
  [
      "SNE",
      "Ribeira Brava"
  ],
  [
      "SNN",
      "Shannon Airport"
  ],
  [
      "SNO",
      "Sakon Nakhon"
  ],
  [
      "SNP",
      "Saint Paul Island"
  ],
  [
      "SNR",
      "Montoir-de-Bretagne"
  ],
  [
      "SNU",
      "Esperanza"
  ],
  [
      "SNW",
      "Thandwe"
  ],
  [
      "SOB",
      "Zalavár"
  ],
  [
      "SOC",
      "Surakarta"
  ],
  [
      "SOF",
      "Sofia"
  ],
  [
      "SOG",
      "Kaupanger"
  ],
  [
      "SOJ",
      "Sørkjosen"
  ],
  [
      "SOM",
      "Cantaura"
  ],
  [
      "SON",
      ""
  ],
  [
      "SOO",
      "Soderhamn"
  ],
  [
      "SOQ",
      "Sorong"
  ],
  [
      "SOU",
      "Southampton"
  ],
  [
      "SOV",
      "Seldovia"
  ],
  [
      "SOW",
      "Show Low"
  ],
  [
      "SPB",
      "Charlotte Amalie"
  ],
  [
      "SPC",
      "Breña Alta"
  ],
  [
      "SPD",
      "Dinajpur"
  ],
  [
      "SPI",
      "Springfield"
  ],
  [
      "SPL",
      "Vila do Porto"
  ],
  [
      "SPN",
      "Saipan"
  ],
  [
      "SPP",
      "Menongue"
  ],
  [
      "SPR",
      "San Pedro"
  ],
  [
      "SPS",
      "Wichita Falls"
  ],
  [
      "SPU",
      "Split"
  ],
  [
      "SQO",
      "Storuman"
  ],
  [
      "SRA",
      "Santa Rosa"
  ],
  [
      "SRE",
      "Sucre"
  ],
  [
      "SRG",
      "Semarang"
  ],
  [
      "SRJ",
      "San Borja"
  ],
  [
      "SRP",
      "Skjold"
  ],
  [
      "SRQ",
      "Sarasota"
  ],
  [
      "SRV",
      "Stony River"
  ],
  [
      "SRX",
      "Sert"
  ],
  [
      "SRY",
      "Sary"
  ],
  [
      "SRZ",
      "Santa Cruz de la Sierra"
  ],
  [
      "SSA",
      "Salvador"
  ],
  [
      "SSB",
      "Christiansted"
  ],
  [
      "SSG",
      "Malabo"
  ],
  [
      "SSH",
      "Al Arish"
  ],
  [
      "SSJ",
      "Sandnessjoen"
  ],
  [
      "SSR",
      "Sara"
  ],
  [
      "SSY",
      "M'banza Congo"
  ],
  [
      "STC",
      "St. Cloud"
  ],
  [
      "STD",
      "Santa Ana del Tachira"
  ],
  [
      "STG",
      "St George Island"
  ],
  [
      "STI",
      "La Lomota"
  ],
  [
      "STL",
      "St. Louis"
  ],
  [
      "STM",
      "Santarém"
  ],
  [
      "STN",
      "Stansted Mountfitchet"
  ],
  [
      "STR",
      "Stuttgart"
  ],
  [
      "STS",
      "Windsor"
  ],
  [
      "STT",
      "Charlotte Amalie"
  ],
  [
      "STV",
      "Un"
  ],
  [
      "STW",
      "Stavropol'"
  ],
  [
      "STX",
      "Frederiksted"
  ],
  [
      "SUB",
      "Sidoarjo"
  ],
  [
      "SUF",
      "Case Cervi"
  ],
  [
      "SUG",
      "Surigao City"
  ],
  [
      "SUJ",
      "Satu Mare"
  ],
  [
      "SUN",
      "Hailey"
  ],
  [
      "SUR",
      "Summer Beaver"
  ],
  [
      "SUV",
      "Nausori"
  ],
  [
      "SUX",
      "Sioux City"
  ],
  [
      "SVA",
      "Savoonga"
  ],
  [
      "SVB",
      "Sambava"
  ],
  [
      "SVC",
      "Silver City"
  ],
  [
      "SVD",
      "Kingstown"
  ],
  [
      "SVG",
      "Rage"
  ],
  [
      "SVI",
      "San Vicente del Caguán"
  ],
  [
      "SVJ",
      "Svolvar"
  ],
  [
      "SVL",
      "Savonlinna"
  ],
  [
      "SVO",
      "Zelenograd"
  ],
  [
      "SVQ",
      "Seville"
  ],
  [
      "SVS",
      "Stevens Village"
  ],
  [
      "SVU",
      "Labasa"
  ],
  [
      "SVX",
      "Yekaterinburg"
  ],
  [
      "SVZ",
      "Táriba"
  ],
  [
      "SWA",
      "Chenghai"
  ],
  [
      "SWF",
      "New Windsor"
  ],
  [
      "SWJ",
      "South West Bay"
  ],
  [
      "SWK",
      "Trezzano sul Naviglio"
  ],
  [
      "SWO",
      "Stillwater"
  ],
  [
      "SWQ",
      "Sumbawa Besar"
  ],
  [
      "SWS",
      "Swansea"
  ],
  [
      "SXB",
      "Entzheim"
  ],
  [
      "SXF",
      "Schönefeld"
  ],
  [
      "SXL",
      "Larass"
  ],
  [
      "SXM",
      ""
  ],
  [
      "SXP",
      "Sheldon Point"
  ],
  [
      "SXR",
      "Srinagar"
  ],
  [
      "SYB",
      "Kodiak"
  ],
  [
      "SYD",
      "Sydney"
  ],
  [
      "SYJ",
      "Sirjan"
  ],
  [
      "SYM",
      "Simao"
  ],
  [
      "SYO",
      "Shonai"
  ],
  [
      "SYR",
      "Syracuse"
  ],
  [
      "SYU",
      "Sue Island"
  ],
  [
      "SYX",
      "Sanya"
  ],
  [
      "SYY",
      "Isle of lewis"
  ],
  [
      "SYZ",
      "Shiraz"
  ],
  [
      "SZA",
      "Santo António do Zaire"
  ],
  [
      "SZB",
      "Kampong Baru Subang"
  ],
  [
      "SZD",
      "Sheffield"
  ],
  [
      "SZF",
      "Samsun"
  ],
  [
      "SZG",
      "Salzburg"
  ],
  [
      "SZV",
      "Suzhou"
  ],
  [
      "SZX",
      "Shenzhen"
  ],
  [
      "SZZ",
      "Goleniów"
  ],
  [
      "TAB",
      "Plymouth"
  ],
  [
      "TAC",
      "Tacloban City"
  ],
  [
      "TAE",
      "Daegu"
  ],
  [
      "TAG",
      "Tagbilaran City"
  ],
  [
      "TAH",
      "Isangel"
  ],
  [
      "TAI",
      "Al-Ganad"
  ],
  [
      "TAK",
      "Takamatsu"
  ],
  [
      "TAL",
      "Tanana"
  ],
  [
      "TAM",
      "Tampico"
  ],
  [
      "TAO",
      "Wanggezhuang"
  ],
  [
      "TAP",
      "Tapachula"
  ],
  [
      "TAS",
      "Tashkent"
  ],
  [
      "TAT",
      "Poprad"
  ],
  [
      "TBB",
      "Tuy Hoa"
  ],
  [
      "TBG",
      "Tabubil"
  ],
  [
      "TBH",
      "Alcantara"
  ],
  [
      "TBI",
      "New Bight"
  ],
  [
      "TBJ",
      "Jundobah"
  ],
  [
      "TBN",
      "Fort Leonard Wood"
  ],
  [
      "TBO",
      "Tabora"
  ],
  [
      "TBP",
      "Tumbes"
  ],
  [
      "TBS",
      "Tbilisi"
  ],
  [
      "TBU",
      "Nuku'alofa"
  ],
  [
      "TBW",
      "Tambov"
  ],
  [
      "TBZ",
      "Tabriz"
  ],
  [
      "TCB",
      "Green Turtle Cay"
  ],
  [
      "TCE",
      "Tulcea"
  ],
  [
      "TCH",
      "Tchibanga"
  ],
  [
      "TCO",
      "Tumaco"
  ],
  [
      "TCP",
      "Taba"
  ],
  [
      "TCQ",
      "Tacna"
  ],
  [
      "TCR",
      "Tuticorin"
  ],
  [
      "TCT",
      "Takotna"
  ],
  [
      "TDD",
      "Trinidad"
  ],
  [
      "TDX",
      "Trat"
  ],
  [
      "TEB",
      "Teterboro"
  ],
  [
      "TEE",
      "Tebessa"
  ],
  [
      "TEK",
      "Valdez"
  ],
  [
      "TEN",
      "Tongren"
  ],
  [
      "TER",
      "Praia da Vitória"
  ],
  [
      "TET",
      "Tete"
  ],
  [
      "TEX",
      "Telluride"
  ],
  [
      "TFI",
      "Tufi"
  ],
  [
      "TFN",
      "Tegueste"
  ],
  [
      "TFS",
      "Granadilla"
  ],
  [
      "TGC",
      "Milan"
  ],
  [
      "TGD",
      "Podgorica"
  ],
  [
      "TGG",
      "Kuala Terengganu"
  ],
  [
      "TGH",
      ""
  ],
  [
      "TGJ",
      "Tiga"
  ],
  [
      "TGM",
      ""
  ],
  [
      "TGO",
      "Tongliao"
  ],
  [
      "TGR",
      "Ouargla"
  ],
  [
      "TGU",
      "Tegucigalpa"
  ],
  [
      "TGZ",
      "San Fernando"
  ],
  [
      "THE",
      "Teresina"
  ],
  [
      "THF",
      "Berlin"
  ],
  [
      "THL",
      "Tachilek"
  ],
  [
      "THN",
      "Trollhatan"
  ],
  [
      "THO",
      "Thorshofn"
  ],
  [
      "THR",
      "Tehran"
  ],
  [
      "THS",
      "Sawankhalok"
  ],
  [
      "THU",
      "Pituffik"
  ],
  [
      "TIA",
      "Krna"
  ],
  [
      "TIF",
      ""
  ],
  [
      "TIH",
      "Tikehau Atoll"
  ],
  [
      "TIJ",
      "Tijuana"
  ],
  [
      "TIM",
      "Nabire"
  ],
  [
      "TIN",
      "Tindouf"
  ],
  [
      "TIP",
      "Tripoli"
  ],
  [
      "TIQ",
      "Tinian"
  ],
  [
      "TIR",
      "Renigunta"
  ],
  [
      "TIS",
      "Kubin Village"
  ],
  [
      "TIU",
      "Timaru"
  ],
  [
      "TIV",
      "Teodo"
  ],
  [
      "TIZ",
      "Mendi"
  ],
  [
      "TJA",
      "Tarija"
  ],
  [
      "TJM",
      "Tyumen'"
  ],
  [
      "TJN",
      "Takume"
  ],
  [
      "TJQ",
      "Tanjungpandan"
  ],
  [
      "TKE",
      "Tenakee Springs"
  ],
  [
      "TKG",
      "Tanjungkarang"
  ],
  [
      "TKJ",
      "Tok"
  ],
  [
      "TKK",
      "Weno"
  ],
  [
      "TKN",
      "Amagi-cho"
  ],
  [
      "TKP",
      "Takapoto"
  ],
  [
      "TKQ",
      "Kigoma"
  ],
  [
      "TKS",
      "Matsushige-cho"
  ],
  [
      "TKU",
      "Turku"
  ],
  [
      "TKV",
      "Tatakoto"
  ],
  [
      "TKX",
      "Takaroa"
  ],
  [
      "TLA",
      "Teller"
  ],
  [
      "TLC",
      "Toluca de Lerdo"
  ],
  [
      "TLE",
      "Toliara"
  ],
  [
      "TLH",
      "Tallahassee"
  ],
  [
      "TLJ",
      "Tatalina"
  ],
  [
      "TLL",
      "Tallinn"
  ],
  [
      "TLM",
      "Tlemcen"
  ],
  [
      "TLN",
      "Hyeres"
  ],
  [
      "TLS",
      "Blagnac"
  ],
  [
      "TLT",
      "Tuluksak"
  ],
  [
      "TLV",
      "Petaẖ Tiqwa"
  ],
  [
      "TMC",
      "Tambolaka"
  ],
  [
      "TME",
      "Tame"
  ],
  [
      "TMJ",
      "Termiz"
  ],
  [
      "TML",
      "Savelugu"
  ],
  [
      "TMM",
      "Toamasina"
  ],
  [
      "TMP",
      "Pitkäniemi"
  ],
  [
      "TMR",
      "Tamanrasset"
  ],
  [
      "TMS",
      "Sao Tome"
  ],
  [
      "TMT",
      "Trombetas"
  ],
  [
      "TMU",
      "Lepanto"
  ],
  [
      "TMW",
      "Barry"
  ],
  [
      "TMX",
      "Timimoun"
  ],
  [
      "TNA",
      "Jinan"
  ],
  [
      "TNC",
      "Tin City"
  ],
  [
      "TNG",
      "Tangiers"
  ],
  [
      "TNJ",
      "Tanjungpinang"
  ],
  [
      "TNK",
      "Tununak"
  ],
  [
      "TNN",
      "Tainan City"
  ],
  [
      "TNO",
      "Liberia"
  ],
  [
      "TNR",
      "Ambohidratrimo"
  ],
  [
      "TNZ",
      "Tosontsengel"
  ],
  [
      "TOD",
      "Kampung Genting"
  ],
  [
      "TOE",
      "Tawzar"
  ],
  [
      "TOF",
      "Tomsk"
  ],
  [
      "TOG",
      "Togiak Village"
  ],
  [
      "TOH",
      "Torres"
  ],
  [
      "TOL",
      "Swanton"
  ],
  [
      "TOM",
      ""
  ],
  [
      "TOS",
      "Tromso"
  ],
  [
      "TOU",
      "Touho"
  ],
  [
      "TOY",
      "Toyama-shi"
  ],
  [
      "TPA",
      "Tampa"
  ],
  [
      "TPE",
      "Taoyuan City"
  ],
  [
      "TPJ",
      "Taplejung"
  ],
  [
      "TPP",
      "Tarapoto"
  ],
  [
      "TPQ",
      "Tepic"
  ],
  [
      "TPS",
      "Trapani"
  ],
  [
      "TQR",
      "San Domino Island"
  ],
  [
      "TRC",
      "Torreón"
  ],
  [
      "TRD",
      "Stjordal"
  ],
  [
      "TRE",
      "Crossapol"
  ],
  [
      "TRF",
      "Sandefjord"
  ],
  [
      "TRG",
      "Tauranga"
  ],
  [
      "TRI",
      "Blountville"
  ],
  [
      "TRK",
      "Tarakan"
  ],
  [
      "TRN",
      "Caselle"
  ],
  [
      "TRO",
      "Taree"
  ],
  [
      "TRS",
      "Ronchi"
  ],
  [
      "TRU",
      "Huanchaco"
  ],
  [
      "TRV",
      "Thiruvananthapuram"
  ],
  [
      "TRW",
      "Tarawa"
  ],
  [
      "TRZ",
      "Tiruchirapally"
  ],
  [
      "TSA",
      "Taipei City"
  ],
  [
      "TSE",
      "Aqmola"
  ],
  [
      "TSF",
      "Treviso"
  ],
  [
      "TSJ",
      "Tsushima-shi"
  ],
  [
      "TSL",
      "Ciudad Valles"
  ],
  [
      "TSN",
      "Tanggu"
  ],
  [
      "TSO",
      "Isles Of Scilly"
  ],
  [
      "TSR",
      ""
  ],
  [
      "TSS",
      "New York"
  ],
  [
      "TST",
      "Muang Trang"
  ],
  [
      "TSV",
      "Townsville"
  ],
  [
      "TTA",
      "Tan Tan"
  ],
  [
      "TTE",
      "Ternate"
  ],
  [
      "TTJ",
      "Tottori-shi"
  ],
  [
      "TTQ",
      "Colorado"
  ],
  [
      "TTT",
      "Taitung City"
  ],
  [
      "TTU",
      "Tetouan"
  ],
  [
      "TUA",
      "Urbina"
  ],
  [
      "TUB",
      "Tubuai"
  ],
  [
      "TUC",
      "Banda del Río Salí"
  ],
  [
      "TUD",
      "Tambacounda"
  ],
  [
      "TUF",
      "Tours"
  ],
  [
      "TUG",
      "Tuguegarao"
  ],
  [
      "TUI",
      "Turayf"
  ],
  [
      "TUK",
      "Turbat"
  ],
  [
      "TUL",
      "Tulsa"
  ],
  [
      "TUN",
      "Tunis"
  ],
  [
      "TUO",
      "Taupo"
  ],
  [
      "TUP",
      "Tupelo"
  ],
  [
      "TUR",
      "Tucuruí"
  ],
  [
      "TUS",
      "Tucson"
  ],
  [
      "TUU",
      "Tabuk"
  ],
  [
      "TVC",
      "Traverse City"
  ],
  [
      "TVF",
      "Thief River Falls"
  ],
  [
      "TVU",
      "Taveuni"
  ],
  [
      "TVY",
      "Dawe"
  ],
  [
      "TWA",
      "Twin Hills"
  ],
  [
      "TWB",
      "Toowoomba"
  ],
  [
      "TWF",
      "Twin Falls"
  ],
  [
      "TWT",
      "Tawitawi"
  ],
  [
      "TWU",
      "Tawau"
  ],
  [
      "TXK",
      "Texarkana"
  ],
  [
      "TXL",
      "Berlin"
  ],
  [
      "TXN",
      "Haiyang"
  ],
  [
      "TYF",
      "Torsby"
  ],
  [
      "TYN",
      "Taiyuan"
  ],
  [
      "TYR",
      "Tyler"
  ],
  [
      "TYS",
      "Knoxville"
  ],
  [
      "TZA",
      "Hattieville"
  ],
  [
      "TZN",
      "Mangrove Cay"
  ],
  [
      "TZX",
      "Trabzon"
  ],
  [
      "UAH",
      "Ua Huka"
  ],
  [
      "UAK",
      "Narsarssurk"
  ],
  [
      "UAP",
      "Ua Pou"
  ],
  [
      "UAQ",
      "9 de Julio"
  ],
  [
      "UAS",
      "Samburu"
  ],
  [
      "UBA",
      "Uberaba"
  ],
  [
      "UBJ",
      "Ube-shi"
  ],
  [
      "UBP",
      "Don Mot Daeng"
  ],
  [
      "UCT",
      "Ukhta"
  ],
  [
      "UDI",
      "Uberlandia"
  ],
  [
      "UDJ",
      "Uzhhorod"
  ],
  [
      "UDR",
      "Debari"
  ],
  [
      "UEL",
      "Quelimane"
  ],
  [
      "UEO",
      "Kumejima-cho"
  ],
  [
      "UET",
      ""
  ],
  [
      "UFA",
      "Oufa"
  ],
  [
      "UGB",
      "Pilot Point"
  ],
  [
      "UGC",
      "Urgench"
  ],
  [
      "UGI",
      "Kodiak"
  ],
  [
      "UIB",
      "Quibdó"
  ],
  [
      "UIH",
      "Qui Nhon"
  ],
  [
      "UII",
      "Utila"
  ],
  [
      "UIN",
      "Quincy"
  ],
  [
      "UIO",
      "Quito"
  ],
  [
      "UIP",
      "Plomelin"
  ],
  [
      "UKB",
      "Kobe-shi"
  ],
  [
      "UKK",
      "Ust-kamenogorsk"
  ],
  [
      "ULB",
      "Port-Vato"
  ],
  [
      "ULN",
      "Ulan Bator"
  ],
  [
      "ULO",
      "Ulaangom"
  ],
  [
      "ULP",
      "Quilpie"
  ],
  [
      "ULU",
      "Gulu"
  ],
  [
      "ULY",
      "Dimitrovgrad"
  ],
  [
      "UMD",
      "Uummannaq"
  ],
  [
      "UME",
      "Umea"
  ],
  [
      "UNG",
      "Kiunga"
  ],
  [
      "UNK",
      "Unalakleet"
  ],
  [
      "UNN",
      "Ranong"
  ],
  [
      "UPB",
      "Havana"
  ],
  [
      "UPG",
      "Maros"
  ],
  [
      "URA",
      "Oral"
  ],
  [
      "URC",
      "Urumqi"
  ],
  [
      "URE",
      "Kingissepa"
  ],
  [
      "URG",
      "Uruguaiana"
  ],
  [
      "URJ",
      "Uraj"
  ],
  [
      "URO",
      "St-Pierre"
  ],
  [
      "URS",
      "Kursk"
  ],
  [
      "URT",
      "Phun Phin"
  ],
  [
      "URY",
      "Gurayat"
  ],
  [
      "USH",
      "Ushuaia"
  ],
  [
      "USK",
      "Usinsk"
  ],
  [
      "USM",
      "Ko Samui"
  ],
  [
      "USN",
      "Ulsan"
  ],
  [
      "USQ",
      "Usak"
  ],
  [
      "USU",
      "Coron"
  ],
  [
      "UTH",
      "Changwat Udon Thani; Udon Thani"
  ],
  [
      "UTN",
      "Upington"
  ],
  [
      "UTP",
      "Ban Chang"
  ],
  [
      "UTT",
      "Umtata"
  ],
  [
      "UUD",
      "Kabansk"
  ],
  [
      "UUN",
      "Baruun-Urt"
  ],
  [
      "UUS",
      "Kholmsk"
  ],
  [
      "UVE",
      "Fayaoué"
  ],
  [
      "UVF",
      "Vieux Fort"
  ],
  [
      "UWE",
      "Wiesbaden"
  ],
  [
      "UYL",
      "Nyala"
  ],
  [
      "UYN",
      "Yulin"
  ],
  [
      "VAA",
      "Vaasa"
  ],
  [
      "VAI",
      "Vanimo"
  ],
  [
      "VAK",
      "Hooper Bay"
  ],
  [
      "VAN",
      "Van"
  ],
  [
      "VAO",
      "Suavanao"
  ],
  [
      "VAR",
      "Varna"
  ],
  [
      "VAS",
      "Sivas"
  ],
  [
      "VAV",
      "Neiafu"
  ],
  [
      "VAW",
      "Vardo"
  ],
  [
      "VBM",
      "Blue Mountain"
  ],
  [
      "VBS",
      "Verona"
  ],
  [
      "VBV",
      "Vanuabalavu"
  ],
  [
      "VBY",
      "Visby"
  ],
  [
      "VCE",
      "Venice"
  ],
  [
      "VCL",
      "Tamky"
  ],
  [
      "VCP",
      "Campinas"
  ],
  [
      "VCS",
      "Con Dao"
  ],
  [
      "VCT",
      "Victoria"
  ],
  [
      "VCV",
      "Victorville"
  ],
  [
      "VDA",
      "Ovda"
  ],
  [
      "VDB",
      "Fagernes"
  ],
  [
      "VDC",
      "Vitória da Conquista"
  ],
  [
      "VDE",
      "Valverde"
  ],
  [
      "VDM",
      "Viedma"
  ],
  [
      "VDS",
      "Vadso"
  ],
  [
      "VDZ",
      "Valdez"
  ],
  [
      "VEE",
      "Venetie"
  ],
  [
      "VEL",
      "Vernal"
  ],
  [
      "VER",
      "Teocelo"
  ],
  [
      "VEY",
      "Vestmannaeyjar"
  ],
  [
      "VFA",
      ""
  ],
  [
      "VGA",
      "Manthena"
  ],
  [
      "VGO",
      "Vigo"
  ],
  [
      "VGZ",
      "Villagarzon"
  ],
  [
      "VHC",
      "Saurimo"
  ],
  [
      "VHM",
      "Vilhelmina"
  ],
  [
      "VHZ",
      "Vahitahi"
  ],
  [
      "VIE",
      "Klein-Neusiedl"
  ],
  [
      "VIG",
      "El Vigia"
  ],
  [
      "VII",
      "Vinh City"
  ],
  [
      "VIJ",
      "Virgin Gorda"
  ],
  [
      "VIL",
      "Ad Dakhla"
  ],
  [
      "VIS",
      "Visalia"
  ],
  [
      "VIT",
      "Vitoria"
  ],
  [
      "VIX",
      "Vitoria"
  ],
  [
      "VKG",
      "Kien Giang"
  ],
  [
      "VKO",
      "Podol'sk"
  ],
  [
      "VKT",
      "Ukhta"
  ],
  [
      "VLC",
      "Manises"
  ],
  [
      "VLD",
      "Valdosta"
  ],
  [
      "VLI",
      "Port-Vila"
  ],
  [
      "VLL",
      "Valladolid"
  ],
  [
      "VLN",
      "Valencia"
  ],
  [
      "VLS",
      "Valesdir"
  ],
  [
      "VLV",
      "Siquisique"
  ],
  [
      "VLY",
      "Anglesey"
  ],
  [
      "VNO",
      "Vilnius"
  ],
  [
      "VNS",
      "Baragaon"
  ],
  [
      "VNX",
      "Vilanculos"
  ],
  [
      "VOG",
      "Kamyshin"
  ],
  [
      "VOL",
      "Almiros"
  ],
  [
      "VOZ",
      "Semiluki"
  ],
  [
      "VPE",
      "Ongiva"
  ],
  [
      "VPN",
      "Egilsstadir"
  ],
  [
      "VPS",
      "Eglin Village"
  ],
  [
      "VPY",
      "Chimoio"
  ],
  [
      "VQS",
      "Puerto Ferro"
  ],
  [
      "VRA",
      "Varadero"
  ],
  [
      "VRC",
      "Virac"
  ],
  [
      "VRK",
      "Varkaus"
  ],
  [
      "VRN",
      "Sommacampagna"
  ],
  [
      "VRY",
      "Varoy"
  ],
  [
      "VSA",
      "Villahermosa"
  ],
  [
      "VSG",
      "Luhans'k"
  ],
  [
      "VST",
      "Vasteras"
  ],
  [
      "VTE",
      "Vientiane"
  ],
  [
      "VTU",
      "Becerra"
  ],
  [
      "VTZ",
      "Visakhapatnam"
  ],
  [
      "VUP",
      "Valledupar"
  ],
  [
      "VVC",
      "Villavicencio"
  ],
  [
      "VVI",
      "Santa Cruz"
  ],
  [
      "VVO",
      "Nakhodka"
  ],
  [
      "VVZ",
      "Illizi"
  ],
  [
      "VXC",
      "Lichinga"
  ],
  [
      "VXE",
      "Mindelo"
  ],
  [
      "VXO",
      "Vaxjo"
  ],
  [
      "WAA",
      "Wales"
  ],
  [
      "WAE",
      "Wadi Ad Dawasir"
  ],
  [
      "WAG",
      "Wanganui"
  ],
  [
      "WAQ",
      "Antsalova"
  ],
  [
      "WAT",
      "Waterford"
  ],
  [
      "WAW",
      "Warsaw"
  ],
  [
      "WBB",
      "Stebbins"
  ],
  [
      "WBQ",
      "Beaver"
  ],
  [
      "WDH",
      ""
  ],
  [
      "WEF",
      "Weifang"
  ],
  [
      "WEH",
      "Weihai"
  ],
  [
      "WEI",
      "Weipa"
  ],
  [
      "WGA",
      "Forest Hill"
  ],
  [
      "WGE",
      "Walgett"
  ],
  [
      "WGP",
      "Waingapu"
  ],
  [
      "WHF",
      "Wadi Halfa"
  ],
  [
      "WHK",
      "Whakatane"
  ],
  [
      "WIC",
      "Wick"
  ],
  [
      "WIL",
      "Nairobi"
  ],
  [
      "WIN",
      "Corfield"
  ],
  [
      "WJA",
      "Woja"
  ],
  [
      "WJU",
      "WonJu"
  ],
  [
      "WKA",
      "Wanaka"
  ],
  [
      "WKJ",
      "Wakkanai-shi"
  ],
  [
      "WKK",
      "Aleknagik"
  ],
  [
      "WLG",
      "Wellington"
  ],
  [
      "WLH",
      "Walaha"
  ],
  [
      "WLK",
      "Selawik"
  ],
  [
      "WLS",
      "Wallis Island"
  ],
  [
      "WMK",
      "Meyers Chuck"
  ],
  [
      "WMN",
      "Maroantsetra"
  ],
  [
      "WMO",
      "White Mountain"
  ],
  [
      "WMR",
      "Mananara"
  ],
  [
      "WNA",
      "Napakiak"
  ],
  [
      "WNN",
      "Casummit Lake"
  ],
  [
      "WNP",
      "Pili"
  ],
  [
      "WNR",
      "Tanbar"
  ],
  [
      "WNS",
      "Nawabshah"
  ],
  [
      "WNZ",
      "Wenzhou"
  ],
  [
      "WPM",
      "Wipim"
  ],
  [
      "WRE",
      "Whangarei"
  ],
  [
      "WRG",
      "Wrangell"
  ],
  [
      "WRL",
      "Worland"
  ],
  [
      "WRO",
      "Wroclaw"
  ],
  [
      "WSN",
      "South Naknek"
  ],
  [
      "WST",
      "Westerly"
  ],
  [
      "WSX",
      "Westsound"
  ],
  [
      "WSZ",
      "Westport"
  ],
  [
      "WTK",
      "Noatak"
  ],
  [
      "WTL",
      "Tuntutuliak"
  ],
  [
      "WTS",
      "Tsiroanomandidy"
  ],
  [
      "WUA",
      "Wu Hai"
  ],
  [
      "WUH",
      "Wuhan"
  ],
  [
      "WUN",
      "Wiluna"
  ],
  [
      "WUS",
      "Wuyishan"
  ],
  [
      "WUX",
      "Wuxi"
  ],
  [
      "WVB",
      "Walvis Bay"
  ],
  [
      "WWK",
      "Wewak"
  ],
  [
      "WWP",
      "Whale Pass"
  ],
  [
      "WWT",
      "Newtok"
  ],
  [
      "WXN",
      "Wanxian"
  ],
  [
      "WYA",
      "Whyalla"
  ],
  [
      "WYS",
      "West Yellowstone"
  ],
  [
      "XAD",
      "Oro-Medonte"
  ],
  [
      "XAP",
      "Chapeco"
  ],
  [
      "XAW",
      "Capreol"
  ],
  [
      "XAX",
      "Dorval"
  ],
  [
      "XAZ",
      "Cambellton"
  ],
  [
      "XBE",
      "Casummit Lake"
  ],
  [
      "XBJ",
      "Birjand"
  ],
  [
      "XBR",
      "Brockville"
  ],
  [
      "XCH",
      "The Settlement"
  ],
  [
      "XCI",
      "Oro-Medonte"
  ],
  [
      "XCM",
      "Chatham"
  ],
  [
      "XCR",
      "Coole"
  ],
  [
      "XDB",
      "Lille"
  ],
  [
      "XDD",
      ""
  ],
  [
      "XDG",
      "Halifax"
  ],
  [
      "XDH",
      ""
  ],
  [
      "XDM",
      "Drummondville"
  ],
  [
      "XDP",
      "Moncton"
  ],
  [
      "XDQ",
      "London"
  ],
  [
      "XDS",
      "Oro-Medonte"
  ],
  [
      "XDU",
      ""
  ],
  [
      "XDV",
      "Prince George"
  ],
  [
      "XDW",
      "Prince Rupert"
  ],
  [
      "XDX",
      "Sarina"
  ],
  [
      "XDY",
      "Sudbury"
  ],
  [
      "XDZ",
      "The Pas"
  ],
  [
      "XEA",
      "Vancouver"
  ],
  [
      "XEC",
      "Windsor"
  ],
  [
      "XED",
      "Paris"
  ],
  [
      "XEE",
      "Lac Edouard"
  ],
  [
      "XEF",
      "Winnipeg"
  ],
  [
      "XEG",
      "Kingston"
  ],
  [
      "XEH",
      "Ladysmith"
  ],
  [
      "XEK",
      "Melville"
  ],
  [
      "XEL",
      "New Carlisle"
  ],
  [
      "XER",
      "Strasbourg"
  ],
  [
      "XET",
      "London"
  ],
  [
      "XEV",
      "Stockholm"
  ],
  [
      "XEZ",
      "Sodertalje"
  ],
  [
      "XFD",
      "Stratford"
  ],
  [
      "XFE",
      "Parent"
  ],
  [
      "XFG",
      "Perce"
  ],
  [
      "XFJ",
      "Eskilstuna"
  ],
  [
      "XFK",
      "Senneterre"
  ],
  [
      "XFL",
      "Shawinigan"
  ],
  [
      "XFM",
      "Cowichan Valley"
  ],
  [
      "XFN",
      "Xiangfan"
  ],
  [
      "XFP",
      "Malmo"
  ],
  [
      "XFQ",
      "Weymont"
  ],
  [
      "XFR",
      "Malmo"
  ],
  [
      "XFS",
      "Alexandria"
  ],
  [
      "XFU",
      "Tierp"
  ],
  [
      "XFV",
      "Brantford"
  ],
  [
      "XFW",
      "Hamburg"
  ],
  [
      "XFY",
      "Quebec"
  ],
  [
      "XFZ",
      "Charny"
  ],
  [
      "XGC",
      "Lund"
  ],
  [
      "XGJ",
      "Cobourg"
  ],
  [
      "XGK",
      "Coteau"
  ],
  [
      "XGM",
      "Grantham"
  ],
  [
      "XGR",
      "Chisasibi"
  ],
  [
      "XGY",
      "Grimsby"
  ],
  [
      "XHJ",
      "Aix La Chapelle"
  ],
  [
      "XHK",
      "Valence"
  ],
  [
      "XHM",
      "Georgetown"
  ],
  [
      "XHN",
      "Liege"
  ],
  [
      "XHS",
      "Chemainus"
  ],
  [
      "XIA",
      "Guelph"
  ],
  [
      "XIB",
      "Ingersoll"
  ],
  [
      "XIC",
      "Xichang"
  ],
  [
      "XID",
      "Maxville"
  ],
  [
      "XIF",
      "Napanee"
  ],
  [
      "XIL",
      "Xilin Hot"
  ],
  [
      "XIM",
      "Saint Hyacinthe"
  ],
  [
      "XIO",
      "St Marys"
  ],
  [
      "XIP",
      "Woodstock"
  ],
  [
      "XIT",
      "London"
  ],
  [
      "XIY",
      "Xianyang"
  ],
  [
      "XJL",
      "Joliette"
  ],
  [
      "XJQ",
      "Jonquiere"
  ],
  [
      "XKH",
      "Xieng Khouang"
  ],
  [
      "XKL",
      "Kuala Lumpur"
  ],
  [
      "XKS",
      "Casummit Lake"
  ],
  [
      "XKV",
      "Sackville"
  ],
  [
      "XLB",
      "Lac Brochet"
  ],
  [
      "XLJ",
      "Quebec"
  ],
  [
      "XLM",
      "Montreal"
  ],
  [
      "XLQ",
      "Guildwood"
  ],
  [
      "XLS",
      ""
  ],
  [
      "XLV",
      "Niagara Falls"
  ],
  [
      "XLY",
      "Aldershot"
  ],
  [
      "XLZ",
      "Truro"
  ],
  [
      "XMH",
      "Manihi"
  ],
  [
      "XMN",
      "Xiamen"
  ],
  [
      "XMS",
      "Zúñac"
  ],
  [
      "XMY",
      "Yam Island"
  ],
  [
      "XNA",
      "Bentonville"
  ],
  [
      "XNB",
      "Santo Antônio do Içá"
  ],
  [
      "XNM",
      "Nottingham"
  ],
  [
      "XNN",
      "Xining"
  ],
  [
      "XNO",
      "Northallerton"
  ],
  [
      "XNV",
      "Nuneaton"
  ],
  [
      "XNY",
      "New York"
  ],
  [
      "XOK",
      "Oakville"
  ],
  [
      "XOP",
      "Poitiers"
  ],
  [
      "XPB",
      "Parksville"
  ],
  [
      "XPF",
      "Penrith"
  ],
  [
      "XPG",
      "Paris"
  ],
  [
      "XPJ",
      "Montpellier"
  ],
  [
      "XPN",
      "Brampton"
  ],
  [
      "XPQ",
      "Port Klang"
  ],
  [
      "XPT",
      "Preston"
  ],
  [
      "XPX",
      "Pointe-aux-Trembles"
  ],
  [
      "XQE",
      "London"
  ],
  [
      "XQG",
      "Berwick"
  ],
  [
      "XQH",
      "Nottingham"
  ],
  [
      "XQL",
      "Lancaster"
  ],
  [
      "XQP",
      "Quepos"
  ],
  [
      "XQU",
      "Qualicum Beach"
  ],
  [
      "XRC",
      "Runcorn"
  ],
  [
      "XRF",
      "Marseille"
  ],
  [
      "XRP",
      "Pine Ridge"
  ],
  [
      "XRU",
      "Rugby"
  ],
  [
      "XRY",
      "Jerez"
  ],
  [
      "XSC",
      "Cockburn Harbour"
  ],
  [
      "XSH",
      "Tours"
  ],
  [
      "XSI",
      "Nelson House"
  ],
  [
      "XSP",
      "Singapore"
  ],
  [
      "XSR",
      "Salisbury"
  ],
  [
      "XTG",
      "Thargomindah"
  ],
  [
      "XTK",
      "Thirsk"
  ],
  [
      "XTL",
      "Nelson House"
  ],
  [
      "XTY",
      "Strathroy"
  ],
  [
      "XUZ",
      "Xuzhou"
  ],
  [
      "XVA",
      "Stockport"
  ],
  [
      "XVB",
      "Stafford"
  ],
  [
      "XVC",
      "Crewe"
  ],
  [
      "XVG",
      "Longville"
  ],
  [
      "XVH",
      "Peterborough"
  ],
  [
      "XVJ",
      "Stevenage"
  ],
  [
      "XVU",
      "Durham"
  ],
  [
      "XVV",
      "Belleville"
  ],
  [
      "XVW",
      "Belleville"
  ],
  [
      "XWD",
      "Wakefield Westgate"
  ],
  [
      "XWH",
      "Stroke on Trent"
  ],
  [
      "XWK",
      "Karlskrona"
  ],
  [
      "XWL",
      "Gothenburg"
  ],
  [
      "XWM",
      "Hallsberg"
  ],
  [
      "XWN",
      "Warrington"
  ],
  [
      "XWP",
      "Hassleholm"
  ],
  [
      "XWQ",
      "Enkoping"
  ],
  [
      "XWR",
      "Orebro"
  ],
  [
      "XWS",
      "Swindon"
  ],
  [
      "XWV",
      "Varberg"
  ],
  [
      "XWY",
      "Wyoming"
  ],
  [
      "XWZ",
      "Nykoping"
  ],
  [
      "XXA",
      "Alvesta"
  ],
  [
      "XXD",
      "Degerfors"
  ],
  [
      "XXK",
      "Katrineholm"
  ],
  [
      "XXM",
      "Mjolby"
  ],
  [
      "XXN",
      "Riyadh"
  ],
  [
      "XXO",
      "Leksand"
  ],
  [
      "XXT",
      "Valbonne"
  ],
  [
      "XXU",
      "Hedemora"
  ],
  [
      "XXZ",
      "Sundsvall"
  ],
  [
      "XYA",
      "Yandina"
  ],
  [
      "XYB",
      "Borlänge"
  ],
  [
      "XYC",
      "Herrljunga"
  ],
  [
      "XYD",
      "Lyon"
  ],
  [
      "XYF",
      "Falkoping"
  ],
  [
      "XYH",
      "Helsingborg"
  ],
  [
      "XYI",
      "Flen"
  ],
  [
      "XYK",
      "Norrkoping"
  ],
  [
      "XYN",
      "Kristinehamn"
  ],
  [
      "XYP",
      "Avesta Krylbo"
  ],
  [
      "XYQ",
      "Angelholm/Helsingborg"
  ],
  [
      "XYX",
      "Sala"
  ],
  [
      "XYY",
      "Arvika"
  ],
  [
      "XYZ",
      "Harnosand"
  ],
  [
      "XZB",
      "Casselman"
  ],
  [
      "XZC",
      "Glencoe"
  ],
  [
      "XZJ",
      "Rail (Generic)"
  ],
  [
      "XZL",
      "Edmonton"
  ],
  [
      "XZM",
      "Macau"
  ],
  [
      "XZN",
      "Avignon"
  ],
  [
      "XZO",
      "Oslo"
  ],
  [
      "XZU",
      "Rail (Generic)"
  ],
  [
      "XZV",
      "Toulon"
  ],
  [
      "YAA",
      "Alexis Creek"
  ],
  [
      "YAC",
      "Casummit Lake"
  ],
  [
      "YAG",
      "Fort Frances"
  ],
  [
      "YAK",
      "Yakutat"
  ],
  [
      "YAM",
      "Sault Ste. Marie"
  ],
  [
      "YAO",
      "Yaounde"
  ],
  [
      "YAP",
      "Colonia"
  ],
  [
      "YAT",
      "Winisk"
  ],
  [
      "YAX",
      "Casummit Lake"
  ],
  [
      "YAY",
      "St. Anthony"
  ],
  [
      "YAZ",
      "Lake Cowichan"
  ],
  [
      "YBB",
      "Cambridge Bay"
  ],
  [
      "YBC",
      "Betsiamites"
  ],
  [
      "YBG",
      "Saguenay"
  ],
  [
      "YBI",
      "St. Anthony"
  ],
  [
      "YBK",
      "Chesterfield Inlet"
  ],
  [
      "YBL",
      "Gold River"
  ],
  [
      "YBP",
      "Yibin"
  ],
  [
      "YBR",
      "Killarney"
  ],
  [
      "YBT",
      "Brochet"
  ],
  [
      "YBV",
      "Nelson House"
  ],
  [
      "YBW",
      "Bedwell Harbor"
  ],
  [
      "YBX",
      "Havre-St-Pierre"
  ],
  [
      "YBZ",
      "Toronto"
  ],
  [
      "YCA",
      "Courtenay"
  ],
  [
      "YCB",
      "Cambridge Bay"
  ],
  [
      "YCC",
      "Moose Creek"
  ],
  [
      "YCD",
      "Nanaimo"
  ],
  [
      "YCG",
      "Castlegar"
  ],
  [
      "YCK",
      "Hay River"
  ],
  [
      "YCM",
      "Fonthill"
  ],
  [
      "YCO",
      "Cambridge Bay"
  ],
  [
      "YCR",
      "Nelson House"
  ],
  [
      "YCS",
      "Chesterfield Inlet"
  ],
  [
      "YCU",
      "Chesterfield Inlet"
  ],
  [
      "YCY",
      "Iqaluit"
  ],
  [
      "YDA",
      "Dawson"
  ],
  [
      "YDF",
      "St. Anthony"
  ],
  [
      "YDN",
      "Dauphin"
  ],
  [
      "YDP",
      "Happy Valley-Goose Bay"
  ],
  [
      "YDQ",
      "Dawson Creek"
  ],
  [
      "YEG",
      "Leduc"
  ],
  [
      "YEI",
      "Bursa"
  ],
  [
      "YEK",
      "Chesterfield Inlet"
  ],
  [
      "YER",
      "Casummit Lake"
  ],
  [
      "YES",
      "Yasouj"
  ],
  [
      "YEV",
      "Hay River"
  ],
  [
      "YFA",
      "Winisk"
  ],
  [
      "YFB",
      "Iqaluit"
  ],
  [
      "YFC",
      "Fredericton"
  ],
  [
      "YFH",
      "Marathon"
  ],
  [
      "YFJ",
      "Snare Lake"
  ],
  [
      "YFO",
      "Flin Flon"
  ],
  [
      "YFS",
      "Hay River"
  ],
  [
      "YFX",
      "St. Anthony"
  ],
  [
      "YGB",
      "Gibsons"
  ],
  [
      "YGG",
      "Saltspring Island"
  ],
  [
      "YGH",
      "Hay River"
  ],
  [
      "YGJ",
      "Sakaiminato-shi"
  ],
  [
      "YGK",
      "Kingston"
  ],
  [
      "YGL",
      "Chisasibi"
  ],
  [
      "YGO",
      "Nelson House"
  ],
  [
      "YGP",
      "Gaspé"
  ],
  [
      "YGR",
      "Fatima"
  ],
  [
      "YGT",
      "Iqaluit"
  ],
  [
      "YGV",
      "Havre St Pierre"
  ],
  [
      "YGW",
      "Chisasibi"
  ],
  [
      "YGX",
      "Nelson House"
  ],
  [
      "YGZ",
      "Iqaluit"
  ],
  [
      "YHA",
      "St. Anthony"
  ],
  [
      "YHB",
      "Melfort"
  ],
  [
      "YHD",
      "Dryden"
  ],
  [
      "YHG",
      "St. Anthony"
  ],
  [
      "YHI",
      "Holman"
  ],
  [
      "YHK",
      "Cambridge Bay"
  ],
  [
      "YHM",
      "Beamsville"
  ],
  [
      "YHO",
      "Happy Valley-Goose Bay"
  ],
  [
      "YHP",
      "Casummit Lake"
  ],
  [
      "YHR",
      "Havre-St-Pierre"
  ],
  [
      "YHS",
      "Gibsons"
  ],
  [
      "YHU",
      "St-Hubert"
  ],
  [
      "YHY",
      "Hay River"
  ],
  [
      "YHZ",
      "Fall River"
  ],
  [
      "YIF",
      "Havre-St-Pierre"
  ],
  [
      "YIH",
      "Yichang"
  ],
  [
      "YIK",
      "Chisasibi"
  ],
  [
      "YIN",
      "Yining"
  ],
  [
      "YIO",
      "Iqaluit"
  ],
  [
      "YIP",
      "Ypsilanti"
  ],
  [
      "YIV",
      "Nelson House"
  ],
  [
      "YIW",
      "Yiwu"
  ],
  [
      "YJT",
      "Stephenville"
  ],
  [
      "YKA",
      "Kamloops"
  ],
  [
      "YKF",
      "Kitchener"
  ],
  [
      "YKG",
      "Chisasibi"
  ],
  [
      "YKL",
      "Chisasibi"
  ],
  [
      "YKM",
      "Yakima"
  ],
  [
      "YKQ",
      "Chisasibi"
  ],
  [
      "YKS",
      "Yakutsk"
  ],
  [
      "YKT",
      "Masset"
  ],
  [
      "YKU",
      "Chisasibi"
  ],
  [
      "YLC",
      "Iqaluit"
  ],
  [
      "YLE",
      "Hay River"
  ],
  [
      "YLH",
      "Marathon"
  ],
  [
      "YLL",
      "Vegreville"
  ],
  [
      "YLQ",
      "La Tuque"
  ],
  [
      "YLW",
      "Kelowna"
  ],
  [
      "YMH",
      "St. Anthony"
  ],
  [
      "YMM",
      "Fort Chipewyan"
  ],
  [
      "YMN",
      "Happy Valley-Goose Bay"
  ],
  [
      "YMO",
      "Winisk"
  ],
  [
      "YMT",
      "Métabetchouan"
  ],
  [
      "YMX",
      "Mirabel"
  ],
  [
      "YMY",
      "Montreal"
  ],
  [
      "YNA",
      "Havre-St-Pierre"
  ],
  [
      "YNB",
      "Yanbu al Bahr"
  ],
  [
      "YNC",
      "Chisasibi"
  ],
  [
      "YND",
      "Gatineau"
  ],
  [
      "YNE",
      "Nelson House"
  ],
  [
      "YNG",
      "Vienna"
  ],
  [
      "YNJ",
      "Yanji"
  ],
  [
      "YNO",
      "Casummit Lake"
  ],
  [
      "YNP",
      "Natuashish"
  ],
  [
      "YNS",
      "Chisasibi"
  ],
  [
      "YNT",
      "Yantai"
  ],
  [
      "YNY",
      "Sonyang-Myeon"
  ],
  [
      "YNZ",
      "Yancheng"
  ],
  [
      "YOC",
      "Dawson"
  ],
  [
      "YOG",
      "Marathon"
  ],
  [
      "YOH",
      "Nelson House"
  ],
  [
      "YOJ",
      "Brownvale"
  ],
  [
      "YOO",
      "Oshawa"
  ],
  [
      "YOP",
      "Brownvale"
  ],
  [
      "YOW",
      "Ottawa"
  ],
  [
      "YPB",
      "Lake Cowichan"
  ],
  [
      "YPC",
      "Hay River"
  ],
  [
      "YPE",
      "Brownvale"
  ],
  [
      "YPH",
      "Chisasibi"
  ],
  [
      "YPJ",
      "Chisasibi"
  ],
  [
      "YPL",
      "Casummit Lake"
  ],
  [
      "YPM",
      "Casummit Lake"
  ],
  [
      "YPO",
      "Winisk"
  ],
  [
      "YPR",
      "Prince Rupert"
  ],
  [
      "YPW",
      "Powell River"
  ],
  [
      "YPX",
      "Chisasibi"
  ],
  [
      "YPZ",
      "Smithers"
  ],
  [
      "YQB",
      "Ste-Foy"
  ],
  [
      "YQC",
      "Chisasibi"
  ],
  [
      "YQD",
      "The Pas"
  ],
  [
      "YQF",
      "Sylvan Lake"
  ],
  [
      "YQG",
      "Windsor"
  ],
  [
      "YQK",
      "Kenora"
  ],
  [
      "YQL",
      "Pincher"
  ],
  [
      "YQM",
      "Moncton"
  ],
  [
      "YQN",
      "Marathon"
  ],
  [
      "YQQ",
      "Comox"
  ],
  [
      "YQR",
      "Regina"
  ],
  [
      "YQT",
      "Thunder Bay"
  ],
  [
      "YQU",
      "Brownvale"
  ],
  [
      "YQX",
      "Gander"
  ],
  [
      "YQY",
      "Sydney"
  ],
  [
      "YQZ",
      "Quesnel"
  ],
  [
      "YRA",
      "Hay River"
  ],
  [
      "YRB",
      "Iqaluit"
  ],
  [
      "YRF",
      "St. Anthony"
  ],
  [
      "YRG",
      "Happy Valley-Goose Bay"
  ],
  [
      "YRJ",
      "Roberval"
  ],
  [
      "YRL",
      "Casummit Lake"
  ],
  [
      "YRS",
      "Nelson House"
  ],
  [
      "YRT",
      "Chesterfield Inlet"
  ],
  [
      "YSB",
      "Garson"
  ],
  [
      "YSG",
      "Hay River"
  ],
  [
      "YSH",
      "Smiths Falls"
  ],
  [
      "YSJ",
      "St. John"
  ],
  [
      "YSK",
      "Iqaluit"
  ],
  [
      "YSM",
      "Hay River"
  ],
  [
      "YSO",
      "Happy Valley-Goose Bay"
  ],
  [
      "YSR",
      "Iqaluit"
  ],
  [
      "YST",
      "Meiktila"
  ],
  [
      "YSY",
      "Hay River"
  ],
  [
      "YTD",
      "Nelson House"
  ],
  [
      "YTE",
      "Iqaluit"
  ],
  [
      "YTF",
      "Alma"
  ],
  [
      "YTH",
      "Nelson House"
  ],
  [
      "YTL",
      "Casummit Lake"
  ],
  [
      "YTM",
      "Mont Tremblant"
  ],
  [
      "YTQ",
      "Chisasibi"
  ],
  [
      "YTS",
      "South Porcupine"
  ],
  [
      "YTZ",
      "Toronto"
  ],
  [
      "YUB",
      "Hay River"
  ],
  [
      "YUD",
      "Chisasibi"
  ],
  [
      "YUL",
      "Dorval"
  ],
  [
      "YUM",
      "Yuma"
  ],
  [
      "YUT",
      "Chesterfield Inlet"
  ],
  [
      "YUX",
      "Iqaluit"
  ],
  [
      "YUY",
      "Évain"
  ],
  [
      "YVA",
      "Moroni"
  ],
  [
      "YVB",
      "Percé"
  ],
  [
      "YVM",
      "Iqaluit"
  ],
  [
      "YVO",
      "Val-d'or"
  ],
  [
      "YVP",
      "Chisasibi"
  ],
  [
      "YVQ",
      "Hay River"
  ],
  [
      "YVR",
      "Richmond"
  ],
  [
      "YVZ",
      "Casummit Lake"
  ],
  [
      "YWB",
      "Chisasibi"
  ],
  [
      "YWG",
      "Winnipeg"
  ],
  [
      "YWH",
      "Hay River"
  ],
  [
      "YWJ",
      "Hay River"
  ],
  [
      "YWK",
      "Wabush"
  ],
  [
      "YWL",
      "Williams Lake"
  ],
  [
      "YWM",
      "St. Anthony"
  ],
  [
      "YWP",
      "Marathon"
  ],
  [
      "YWS",
      "Gibsons"
  ],
  [
      "YXC",
      "Cranbrook"
  ],
  [
      "YXE",
      "Saskatoon"
  ],
  [
      "YXH",
      "Medicine Hat"
  ],
  [
      "YXJ",
      "Fort St. John"
  ],
  [
      "YXK",
      "Rimouski"
  ],
  [
      "YXL",
      "Sioux Lookout"
  ],
  [
      "YXN",
      "Chesterfield Inlet"
  ],
  [
      "YXP",
      "Iqaluit"
  ],
  [
      "YXS",
      "Prince George"
  ],
  [
      "YXT",
      "Terrace"
  ],
  [
      "YXU",
      "London"
  ],
  [
      "YXX",
      "Abbotsford"
  ],
  [
      "YXY",
      "Whitehorse"
  ],
  [
      "YYB",
      "North Bay"
  ],
  [
      "YYC",
      "Calgary"
  ],
  [
      "YYD",
      "Smithers"
  ],
  [
      "YYE",
      "Fort Nelson"
  ],
  [
      "YYF",
      "Penticton"
  ],
  [
      "YYG",
      "Charlottetown"
  ],
  [
      "YYH",
      "Cambridge Bay"
  ],
  [
      "YYJ",
      "Sidney"
  ],
  [
      "YYL",
      "Nelson House"
  ],
  [
      "YYQ",
      "Nelson House"
  ],
  [
      "YYR",
      "Happy Valley-Goose Bay"
  ],
  [
      "YYT",
      "St. John's"
  ],
  [
      "YYU",
      "Kapuskasing"
  ],
  [
      "YYY",
      "Mont-Joli"
  ],
  [
      "YYZ",
      "Mississauga"
  ],
  [
      "YZF",
      "Yellowknife"
  ],
  [
      "YZG",
      "Chisasibi"
  ],
  [
      "YZP",
      "Masset"
  ],
  [
      "YZR",
      "Sarnia"
  ],
  [
      "YZS",
      "Chesterfield Inlet"
  ],
  [
      "YZT",
      "Gibsons"
  ],
  [
      "YZV",
      "Sept-Îles"
  ],
  [
      "YZZ",
      "Trail"
  ],
  [
      "ZAC",
      "Nelson House"
  ],
  [
      "ZAD",
      "Zadar"
  ],
  [
      "ZAG",
      "Nagygoricza"
  ],
  [
      "ZAH",
      "Dowzdab"
  ],
  [
      "ZAL",
      "Pelchuquin"
  ],
  [
      "ZAM",
      "Zamboanga"
  ],
  [
      "ZAQ",
      "Nuremberg"
  ],
  [
      "ZAT",
      "Zhaotong"
  ],
  [
      "ZAZ",
      "Zaragoza"
  ],
  [
      "ZBA",
      "Basel"
  ],
  [
      "ZBF",
      "Bathurst"
  ],
  [
      "ZBL",
      "Biloela"
  ],
  [
      "ZBP",
      "Baltimore"
  ],
  [
      "ZBR",
      "Chabahar"
  ],
  [
      "ZCB",
      ""
  ],
  [
      "ZCL",
      "Pánuco"
  ],
  [
      "ZCO",
      "Padre Las Casas"
  ],
  [
      "ZDH",
      "Basel"
  ],
  [
      "ZDN",
      "Sydney"
  ],
  [
      "ZDU",
      "London"
  ],
  [
      "ZEE",
      "Kelsey"
  ],
  [
      "ZEI",
      "Zei"
  ],
  [
      "ZEJ",
      ""
  ],
  [
      "ZEL",
      "Masset"
  ],
  [
      "ZEM",
      "Chisasibi"
  ],
  [
      "ZEP",
      "London"
  ],
  [
      "ZEU",
      "Zeu"
  ],
  [
      "ZFI",
      "Chesterfield"
  ],
  [
      "ZFJ",
      "Rennes"
  ],
  [
      "ZFM",
      "Hay River"
  ],
  [
      "ZFN",
      "Hay River"
  ],
  [
      "ZFQ",
      "Bordeaux"
  ],
  [
      "ZFV",
      "Philadelphia"
  ],
  [
      "ZFZ",
      "Buffalo"
  ],
  [
      "ZGD",
      "Groton"
  ],
  [
      "ZGG",
      "Glasgow"
  ],
  [
      "ZGH",
      "Copenhagen"
  ],
  [
      "ZGI",
      "Nelson House"
  ],
  [
      "ZGN",
      "Zhongshan"
  ],
  [
      "ZGO",
      "Gotha"
  ],
  [
      "ZGS",
      "Havre-St-Pierre"
  ],
  [
      "ZGU",
      "Gaua"
  ],
  [
      "ZHA",
      "Zhanjiang"
  ],
  [
      "ZHO",
      "Houston"
  ],
  [
      "ZIF",
      "Ottawa"
  ],
  [
      "ZIG",
      "Ziguinchor"
  ],
  [
      "ZIH",
      "Petatlán"
  ],
  [
      "ZIV",
      "Inverness"
  ],
  [
      "ZJT",
      "Tanjung Pelepas"
  ],
  [
      "ZKE",
      "Winisk"
  ],
  [
      "ZKG",
      "Havre-St-Pierre"
  ],
  [
      "ZLN",
      "Le Mans"
  ],
  [
      "ZLO",
      "Manzanillo"
  ],
  [
      "ZLS",
      "London"
  ],
  [
      "ZLT",
      "Havre-St-Pierre"
  ],
  [
      "ZLY",
      "Albany"
  ],
  [
      "ZMB",
      "Hamburg"
  ],
  [
      "ZME",
      "Newark"
  ],
  [
      "ZML",
      "Milwaukee"
  ],
  [
      "ZMT",
      "Masset"
  ],
  [
      "ZMU",
      "Munich"
  ],
  [
      "ZMY",
      "Huangpu"
  ],
  [
      "ZNA",
      "Nanaimo"
  ],
  [
      "ZNB",
      "Santo Antônio do Içá"
  ],
  [
      "ZNE",
      "Newman"
  ],
  [
      "ZNV",
      "Santa Elena de Uairén"
  ],
  [
      "ZNZ",
      "Stone Town"
  ],
  [
      "ZOS",
      "Osorno"
  ],
  [
      "ZPB",
      "Casummit Lake"
  ],
  [
      "ZQN",
      "Queenstown"
  ],
  [
      "ZQW",
      "Zweibrucken"
  ],
  [
      "ZRB",
      "Frankfurt"
  ],
  [
      "ZRD",
      "Richmond"
  ],
  [
      "ZRH",
      "Kloten"
  ],
  [
      "ZRJ",
      "Casummit Lake"
  ],
  [
      "ZRL",
      "Lancaster"
  ],
  [
      "ZRP",
      "Newark"
  ],
  [
      "ZRT",
      "Hartford"
  ],
  [
      "ZRU",
      "Boston"
  ],
  [
      "ZRV",
      "Providence"
  ],
  [
      "ZSA",
      "Cockburn Town"
  ],
  [
      "ZSE",
      "St Pierre dela Reunion"
  ],
  [
      "ZSF",
      "Springfield"
  ],
  [
      "ZSJ",
      "Casummit Lake"
  ],
  [
      "ZSN",
      "South Indian Lake"
  ],
  [
      "ZTA",
      "Tureira"
  ],
  [
      "ZTB",
      "Havre-St-Pierre"
  ],
  [
      "ZTD",
      "Schenectady"
  ],
  [
      "ZTE",
      "Rochester"
  ],
  [
      "ZTF",
      "Stamford"
  ],
  [
      "ZTH",
      "Zante"
  ],
  [
      "ZTI",
      "Humen"
  ],
  [
      "ZTJ",
      "Princeton"
  ],
  [
      "ZTM",
      "Nelson House"
  ],
  [
      "ZTN",
      "Philadelphia"
  ],
  [
      "ZTO",
      "Boston"
  ],
  [
      "ZTV",
      "Louisville"
  ],
  [
      "ZTY",
      "Boston"
  ],
  [
      "ZUA",
      "Utica"
  ],
  [
      "ZUG",
      "Harrisburg"
  ],
  [
      "ZUH",
      "Zhuhai"
  ],
  [
      "ZUM",
      "Wabush"
  ],
  [
      "ZUN",
      "Zuni"
  ],
  [
      "ZVE",
      "New Haven"
  ],
  [
      "ZVK",
      "Savannakhet"
  ],
  [
      "ZVR",
      "Hanover"
  ],
  [
      "ZWB",
      "Hampton"
  ],
  [
      "ZWE",
      ""
  ],
  [
      "ZWI",
      "Wilmington"
  ],
  [
      "ZWS",
      "Stuttgart"
  ],
  [
      "ZWU",
      "Washington"
  ],
  [
      "ZWV",
      "Glenview"
  ],
  [
      "ZWW",
      "Hampton"
  ],
  [
      "ZXA",
      "Aberdeen"
  ],
  [
      "ZXE",
      "Edinburgh"
  ],
  [
      "ZYA",
      "Amsterdam"
  ],
  [
      "ZYK",
      "Shekou"
  ],
  [
      "ZYL",
      "Sylhet"
  ],
  [
      "ZYN",
      "Nimes"
  ],
  [
      "ZYP",
      "New York"
  ],
  [
      "ZYQ",
      "Syracuse"
  ],
  [
      "ZYR",
      "Brussels"
  ],
  [
      "ZYZ",
      "Antwerp"
  ],
  [
      "TNM",
      "Teniente R. Marsh"
  ]
]);

