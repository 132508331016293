import moment from "moment";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { currency_symbols } from "../checkout/Index";
import PriceDetailTable from "./PriceDetailTable";
import SeatMap from "./SeatMap";
import toast from "react-hot-toast";
import http from "../../Api";
import SeatMapLoading from "../../components/seatmap/SeatMapLoading";
import { reactLocalStorage } from "reactjs-localstorage";
function FlightDetailsModal({ data }) {
  const [show, setShow] = useState(false);
  const navi = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if (show) console.log(data, "lll");
  if (show) {
    http
      .post("get-fare-upsell", data)
      .then((res) => {
        toast.success("upsell found");
      })
      .catch(({ response }) => {
        if (typeof response.data === "string") {
          toast(response.data);
        }
      });
  }
  return (
    <>
      <Button variant="outline-primary" className="w-full" onClick={handleShow}>
        View Details
      </Button>
      {show && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Flight to ...</Modal.Title>
            <Modal.Title>
              Seat Available {data.numberOfBookableSeats}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Modal.Title>Fees Details</Modal.Title>
            <PriceDetailTable price={data.travelerPricings} />
            <div>
              {data?.travelerPricings[0]?.fareDetailsBySegment?.map(
                (it, ind) => {
                  console.log("data", data);
                  return (
                    <ol>
                      {it?.amenities && (
                        <strong>
                          {data.itineraries.map((itin) => (
                            <>
                              {itin.segments
                                .map(
                                  (seg) => ` ${seg.departure?.iataCode} to 
                          ${seg.arrival?.iataCode} `
                                )
                                .join(" , ")}
                            </>
                          ))}
                        </strong>
                      )}

                      {it?.amenities?.map((amn) => {
                        return (
                          <li className="d-flex justify-content-between font-n">
                            <small className="text-dark">
                              {amn?.description[0] +
                                amn?.description
                                  .slice(1, amn?.description.length)
                                  .toLowerCase()}
                            </small>
                            <small
                              className={
                                amn.isChargeable
                                  ? "text-primary"
                                  : "text-success"
                              }
                            >
                              {amn.isChargeable ? "Chargable" : "Included"}
                            </small>
                          </li>
                        );
                      })}
                    </ol>
                  );
                }
              )}
            </div>
            <div className="d-flex justify-content-between flex-row">
              <div>
                <Modal.Title>Last Date</Modal.Title>
                <p>
                  {moment(data.lastTicketingDate, "YYYY-MM-DD").format("lll")}
                </p>
              </div>
              <div>
                <Modal.Title>Total</Modal.Title>
                <p>
                  {currency_symbols[data.price.currency]} {data.price.total}
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                navi("/checkout", { state: data });
              }}
            >
              view final price
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default FlightDetailsModal;
