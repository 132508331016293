import React from "react";

const SeatMapLoading = () => {
  return (
    <div className="plane">
      {/* <div className="cockpit">
      <h1>DRUA Project Seat Selection</h1>
    </div> */}
      <div className="exit exit--front fuselage"></div>
      <ol className="cabin fuselage">
        {Array.from(Array(9)).map((it) => (
          <li className="row row--2">
            <ol className="seats" type="A">
              {Array.from(Array(6)).map((it) => (
                <p className="seat-load card-text placeholder-glow">
                  <span style={{borderRadius:5,height:'2rem'}} className=" placeholder col-12" />
                </p>
              ))}
            </ol>
          </li>
        ))}
      </ol>
      <div className="exit exit--back fuselage"></div>
    </div>
  );
};

export default SeatMapLoading;
