import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function FilterTabs({ contents = [], fill = true, selectTab }) {
  return (
    <Tabs
      defaultActiveKey={contents[0]}
      id="uncontrolled-tab-example"
      className="mb-3"
      onSelect={(e) =>selectTab(e)}
      fill={fill}
    >
      {contents.map((it) => {
        return <Tab eventKey={it} title={it}></Tab>;
      })}
    </Tabs>
  );
}

export default FilterTabs;
