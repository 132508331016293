import React, { useState } from "react";
import { BiSolidBed } from "react-icons/bi";
import { TbPlane } from "react-icons/tb";
import { FaTruckPlane } from "react-icons/fa6";
import { AiFillCar } from "react-icons/ai";
import { NavLink, useNavigate } from "react-router-dom";
import AttractionIcon from "../../assests/amusement-park.png";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Card } from 'react-bootstrap';
function MenuLebel() {
  const {pathname} = useLocation();
 
  const navigate = useNavigate();
  const menus = [
    {
      id: 1,
      name: "Stays",
      icon: <BiSolidBed size={20} color="white" />,
      to: "/hotels",
    },
    {
      id: 2,
      name: "Flights",
      icon: <TbPlane size={20} color="white" />,
      to: "/flight",
    },
    // {
    //   id: 3,
    //   name: "Flight + Hotel",
    //   icon: <FaTruckPlane size={20} color="white" />,
    //   to: "/hotels",
    // },
    {
      id: 4,
      name: "Car rentals",
      icon: <AiFillCar size={20} color="white" />,
      to: "/car-rental",
    },
    {
      id: 5,
      name: "Attraction",
      icon: (
        <img
          style={{ height: 22, width: 22, filter: "invert(1)" }}
          src={AttractionIcon}
        />
      ),
      to: "/attraction",
    },
  ];
  

  return (
    <Container fluid className=" p-2 navbarstyle">
    <Row>
        {menus.map((menu, i) => (
          <Col className="p-2" lg={3} xs={6}>
          <NavLink
            key={i}
            to={menu.to}
            className={`d-flex py-2 gap-2 justify-content-center align-items-center ${
              pathname === menu.to||pathname.includes(menu.to) ? "nav-active-custom" : ""
            } rounded-pill flex-wrap`}
            style={{
             
              cursor: "pointer",
              minWidth: "140px",
            }}
          >
            <p className="m-0">{menu.icon}</p>
            <p className="text-white m-0">{menu.name}</p>
          </NavLink>
          </Col>
        ))}
    
      </Row>
    </Container>
  );
}

export default MenuLebel;
