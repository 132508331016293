import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { AiOutlineMinus, AiOutlinePlus, AiOutlineUser } from "react-icons/ai";
function HotelDropDown({ getDetails }) {
  const params = new URLSearchParams(window.location.search);
  const [adult, setAdult] = useState(params.get("adults") ?? 1);
  const [child, setChild] = useState(0);
  const [room, setRoom] = useState(1);
  useEffect(() => {
    getDetails(adult, child, room);
  }, [adult, child, room]);
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          
          id="dropdown-basic"
          className="dropdownbtn"
        >
          <div className="d-flex gap-3">
            <div>
              <span>
                <AiOutlineUser color="black" size={20} />
              </span>
            </div>
            <div className="mt-1">
              <p>
                {adult} Adults - {child} Children - 1 Room
              </p>
            </div>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <div className="d-flex mb-2 justify-content-between align-items-center flex-wrap">
              <div className="d-flex justify-content-center align-items-center flex-column flex-wrap gap-3">
                <h5>
                  Adults
                  <span
                    className="d-block"
                    style={{
                      fontSize: "0.8rem",
                      color: "#000",
                    }}
                  >
                    Age 18+
                  </span>
                </h5>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  padding: "5px",
                  borderRadius: "10px",
                }}
              >
                <Button
                  variant="light"
                  style={{
                    margin: "0 10px",
                    border: "none",
                    padding: "5px 15px",
                  }}
                  onClick={() => setAdult((prev) => parseInt(prev) - 1)}
                >
                  <AiOutlineMinus color="black" size={15} />
                </Button>
                <h4 style={{ color: "black", margin: "0" }}>{adult}</h4>
                <Button
                  variant="light"
                  style={{
                    margin: "0 10px",
                    border: "none",
                    padding: "5px 15px",
                  }}
                  onClick={() => setAdult((prev) => parseInt(prev) + 1)}
                >
                  <AiOutlinePlus color="black" size={15} />
                </Button>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-center align-items-center flex-column gap-3">
                <h5>
                  Children
                  <span
                    className="d-block"
                    style={{
                      fontSize: "0.8rem",
                      color: "#000",
                    }}
                  >
                    age 0-17
                  </span>
                </h5>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  padding: "5px",
                  borderRadius: "10px",
                }}
              >
                <Button
                  variant="light"
                  style={{
                    margin: "0 10px",
                    border: "none",
                    padding: "5px 15px",
                  }}
                >
                  <AiOutlineMinus
                    onClick={() => setChild((prev) => prev - 1)}
                    color="black"
                    size={15}
                  />
                </Button>
                <h4 style={{ color: "black", margin: "0" }}>{child}</h4>
                <Button
                  variant="light"
                  style={{
                    margin: "0 10px",
                    border: "none",
                    padding: "5px 15px",
                  }}
                  onClick={() => setChild((prev) => prev + 1)}
                >
                  <AiOutlinePlus color="black" size={15} />
                </Button>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-center align-items-center flex-column gap-3">
                <h5>
                  Rooms
                  {/* <span
                    className="d-block"
                    style={{
                      fontSize: "0.8rem",
                      color: "#000",
                    }}
                  >
                    age 0-17
                  </span> */}
                </h5>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  padding: "5px",
                  borderRadius: "10px",
                }}
              >
                <Button
                  variant="light"
                  style={{
                    margin: "0 10px",
                    border: "none",
                    padding: "5px 15px",
                  }}
                  onClick={() => setRoom((prev) => prev - 1)}
                >
                  <AiOutlineMinus color="black" size={15} />
                </Button>
                <h4 style={{ color: "black", margin: "0" }}>{room}</h4>
                <Button
                  variant="light"
                  style={{
                    margin: "0 10px",
                    border: "none",
                    padding: "5px 15px",
                  }}
                  onClick={() => setRoom((prev) => prev + 1)}
                >
                  <AiOutlinePlus color="black" size={15} />
                </Button>
              </div>
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default HotelDropDown;
