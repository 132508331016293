import React, { useEffect, useState } from "react";
import WithHeaderFooter from "../../components/WithHeaderFooter";
import HotelsHero from "../hotels/HotelsHero";
import http from "../../Api";
import { useLocation } from "react-router-dom";
import HotelDetails from "./HotelDetails";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PriceCard from "./PriceCard";
import { FaHotel } from "react-icons/fa6";
import InfoTab from "./InfoTab";
const HotelInfo = () => {
  const loc = useLocation();
  const { hotelName, editorial_summary } = loc.state.placeDetails;
  const { reviews } = loc.state;
  const params = new URLSearchParams(loc.search);
  const [images, setImages] = useState([]);
  useEffect(() => {
    const imagesString = params.get("images");
    console.log("imagesString", decodeURIComponent(imagesString));

    const imagesArray = imagesString
      ? JSON.parse(decodeURIComponent(imagesString))
      : [];
    setImages(imagesArray);
    getHotelInfo(params);
  }, []);
  const [hotelOfferData, setHotelOfferData] = useState(() => {
    if (loc.state) return loc.state;
  });
  const getHotelInfo = (params) => {
    const payload = {};
    params.forEach((value, key) => {
      payload[key] = value;
    });

    http
      .get("hotel-offer-by-offerId/" + params.get("hotelOfferId"))
      .then((res) => {
        console.log("calling");
        setHotelOfferData(res.data.data);
      });
  };
  console.log("loc", loc);

  if (!hotelOfferData) return <>loading</>;
  return (
    <WithHeaderFooter>
    
      <Container fluid className="my-5">
        <Row>
          <Col sm={4}>
            <Row>
              <h3>
                <FaHotel size={30} className="mx-2" />
                {hotelName}
              </h3>
              <PriceCard
              hotelName={hotelName}
                adults={hotelOfferData?.offers[0].guests.adults}
                price={hotelOfferData?.offers[0].price}
                offerId={hotelOfferData?.offers[0]?.id}
              />
            </Row>{" "}
            <Row className="my-3">
              {" "}
              <p>{hotelOfferData?.offers[0]?.description?.text}</p>
              <small className="mx-2">{editorial_summary}</small>
              <InfoTab reviews={reviews} placeData={loc.state.placeDetails} />
            </Row>
          </Col>
          <Col sm={8}>
            <HotelDetails images={images} />

          </Col>
        </Row>
      </Container>
    </WithHeaderFooter>
  );
};

export default HotelInfo;
