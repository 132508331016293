import React from "react";
import { Card } from "react-bootstrap";
import "./explorecard.scss";
function ExploreCard({
  img,
  width,
  heading = "Heading Here",
  title = "Heading here",
  ...props
}) {
  return (
    <div
      {...props}
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.7483368347338936) 0%, rgba(255,255,255,0) 100%),url(${img})`,
        width: width ? width : "",
      }}
      className="card_exe"
    >
      {/* <img src={img}
        alt
      /> */}
      <div className="card-content">
        <h6>

        {heading}
        </h6>

        <a className="button">{title}</a>
      </div>
    </div>
  );
}

export default ExploreCard;
