import React, { useState } from "react";
import {
  Container,
  Form,
  Button,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";
import { AiOutlineDown, AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import LocationSelect from "./LocationSelect";
import { currency_symbols } from "../checkout/Index";
import moment from "moment";
import ButtonAnimated from "../../components/button/Index";
const heroBackgroundStyle = {
  background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://images.unsplash.com/photo-1506012787146-f92b2d7d6d96?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  position: "relative",
  height: "auto",
  padding: "100px 10px",
};

const locdata = {
  from: {
    value: "",
    label: "",
  },
  to: {
    value: "",
    label: "",
  },
  date: [moment().startOf("day"), moment().add(1, "day").startOf('day')],
};
const HeroSection = ({ title = true }) => {
  const params = window.location.search;
  const search = new URLSearchParams(params).get("data");
  const paramsvalue = search ? JSON.parse(search) : false;
  const navigate = useNavigate();
  const [adults, setAdults] = useState(paramsvalue.adults ?? 1);
  const [childs, setChild] = useState(paramsvalue.childs ?? 0);
  const [currency, setCurrency] = useState(paramsvalue.currency ?? "INR");
  const [flightType, setFlightType] = useState(paramsvalue.flightType ?? "one"); //enum one||round||multi
  const [destarrData, setDesArrData] = useState(() => {
    return paramsvalue.originDestinations ?? [locdata, locdata];
  });
  const getsearchData = (data, index) => {
    let arr = [...destarrData];
    arr[index] = data;
    arr = arr.map((it, ind, ar) => ({
      ...it,
      from: ind > 0 ? ar[ind - 1].to : ar[ind].from,
    }));
    setDesArrData(arr);
    console.log("data", data, index);
  };
  const submit = () => {
    if (flightType === "one" && destarrData.length > 1) destarrData.pop();
    let rounddata = [destarrData[0]];
    rounddata[1] = {
      from: destarrData[0].to,
      to: destarrData[0].from,
      date: [destarrData[0].date[1]],
    };
    let obj = {
      originDestinations: flightType === "round" ? rounddata : destarrData,
      adults,
      childs,
      currency,
      flightType,
    };
    console.log('obj.originDestinations', obj.originDestinations)
    navigate(`/flights-search?data=${JSON.stringify(obj)}`);
  };
  console.log("currency", currency);
  const popover = (
    <Popover id="popover-basic">
      {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
      <Popover.Body
        style={
          {
            // margin:"0",
            // padding:"0"
          }
        }
      >
        <div className="d-flex mb-2 justify-content-between align-items-center flex-wrap">
          <div className="d-flex justify-content-center align-items-center flex-column flex-wrap gap-3">
            <h5>
              Adults
              <span
                className="d-block"
                style={{
                  fontSize: "0.8rem",
                  color: "#000",
                }}
              >
                Age 18+
              </span>
            </h5>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#fff",
              padding: "5px",
              borderRadius: "10px",
            }}
          >
            <Button
              variant="light"
              style={{ margin: "0 10px", border: "none", padding: "5px 15px" }}
              disabled={adults === 1}
              onClick={() => setAdults((prev) => prev - 1)}
            >
              <AiOutlineMinus color="black" size={15} />
            </Button>
            <h4 style={{ color: "black", margin: "0" }}>{adults}</h4>
            <Button
              variant="light"
              style={{ margin: "0 10px", border: "none", padding: "5px 15px" }}
              onClick={() => setAdults((prev) => prev + 1)}
            >
              <AiOutlinePlus color="black" size={15} />
            </Button>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-center align-items-center flex-column gap-3">
            <h5>
              Children
              <span
                className="d-block"
                style={{
                  fontSize: "0.8rem",
                  color: "#000",
                }}
              >
                age 0-17
              </span>
            </h5>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#fff",
              padding: "5px",
              borderRadius: "10px",
            }}
          >
            <Button
              variant="light"
              style={{ margin: "0 10px", border: "none", padding: "5px 15px" }}
              onClick={() =>
                setChild((prev) => {
                  return parseInt(prev) - 1;
                })
              }
            >
              <AiOutlineMinus color="black" size={15} />
            </Button>
            <h4 style={{ color: "black", margin: "0" }}>{childs}</h4>
            <Button
              variant="light"
              style={{ margin: "0 10px", border: "none", padding: "5px 15px" }}
              onClick={() =>
                setChild((prev) => {
                  return parseInt(prev) + 1;
                })
              }
            >
              <AiOutlinePlus color="black" size={15} />
            </Button>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );
  return (
    <div className="hero" style={heroBackgroundStyle}>
      <Container>
        {title && (
          <>
            <h2 className="text-white">
              Efficient Flight Comparison and Booking.
            </h2>

            <h6 className="text-white mb-3">
              Discover your next dream destination
            </h6>
          </>
        )}

        <div className="d-flex justify-content-start align-items-center gap-4 flex-wrap">
          <Form.Check
            inline
            type="radio"
            id="oneWay"
            value="one"
            name="flightType"
            label="One Way"
            checked={flightType === "one"}
            onChange={(e) => {
              e.persist();
              setFlightType((prevFlightType) =>
                e.target.checked ? e.target.value : prevFlightType
              );
            }}
            className="text-white"
          />

          <Form.Check
            inline
            type="radio"
            value="round"
            id="roundTrip"
            name="flightType"
            label="Round-Trip"
            checked={flightType === "round"}
            onChange={(e) => {
              e.persist();
              setFlightType((prevFlightType) =>
                e.target.checked ? e.target.value : prevFlightType
              );
            }}
            className="text-white"
          />

          <Form.Check
            inline
            type="radio"
            value="multi"
            id="multiCity"
            name="flightType"
            label="Multi-City"
            checked={flightType === "multi"}
            onChange={(e) => {
              e.persist();
              if (destarrData.length === 1) {
                destarrData.push(locdata);
              }
              setFlightType((prevFlightType) =>
                e.target.checked ? e.target.value : prevFlightType
              );
            }}
            className="text-white"
          />

          <Form.Select
            style={{
              width: "150px",
            }}
            
            onChange={(e) =>{
              console.log('e', e)
              setCurrency(e.target.value)
            }}
          >
            {Object.entries(currency_symbols).map((it) => (
              <option value={it[0]} selected={it[0] === currency}>
                {it[1]} {it[0]}
              </option>
            ))}
          </Form.Select>
          <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
            <div
              className="d-flex mt-2 justify-content-center gap-2"
              style={{
                cursor: "pointer",
              }}
            >
              <p className="text-white">
                {adults} Adult{adults > 1 && "s"}
              </p>
              <span>
                <AiOutlineDown color="white" size={20} />
              </span>
            </div>
          </OverlayTrigger>

          <Form.Check
            type="checkbox"
            id="directFlights"
            label="Direct Flights Only"
            className="text-white"
          />
        </div>
      </Container>
      <Container>
        {flightType === "multi" ? (
          destarrData.map((it, ind) => (
            <LocationSelect
              id={ind}
              getsearchData={getsearchData}
              flightType={flightType}
              defaultData={it}
            />
          ))
        ) : (
          <LocationSelect
            defaultData={destarrData[0]}
            id={0}
            flightType={flightType}
            getsearchData={getsearchData}
          />
        )}

        <button 
        onClick={submit}
          style={{
            height: 55,
            width: 120,
            borderRadius: 20,
            transition:'0.5s'
          }}
          className="my-2 search-btn"
        >
          Search
        </button>
      </Container>
    </div>
  );
};

export default HeroSection;
