import React, { useEffect, useState } from "react";
import Tickets from "../../pages/flight/Tickets";
import TicketFilter from "./TicketFilter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FilterTabs from "./FilterTabs";
import PaginationComp from "../Pagination";
import LoadingSkel from "./LoadingSkel";
import FilterOffCanVas from "./FilterOffCanvas";
import Card from "../response-card/Card";

export function durationExtr(rawdata) {
  let arr = rawdata.replace("PT", "").split(/[HM]/);
  return parseInt(arr[0]) * 60 + parseInt(arr[1]);
}
function createArray(length) {
  const newArray = [];

  for (let i = 1; i <= length; i++) {
    newArray.push({ id: i });
  }

  return newArray;
}
const tabFilter = ["Best", "Cheapest", "Fastest"];
const Index = ({ flights, loading: load, searchParams }) => {
  const ItemPerPage = 10;
  const [flightdata, setFlightData] = useState([]);
  const [airlinesName, setAirlinesName] = useState([]);
  const [loading, setLoading] = useState(load);
  const [animLoading, setAnimLoading] = useState(load);
  const [pageData, setPageData] = useState({ start: 0, end: ItemPerPage - 1 });
  const selectTab = (filter) => {
    setLoading(true);
    console.log("filter", filter);
    let arr = [...flights];
    if (filter == "Fastest") {
      const sorti = arr.sort((it, next) => {
        return (
          durationExtr(it.itineraries[0].duration) -
          durationExtr(next.itineraries[0].duration)
        );
      });

      setFlightData(sorti);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } else if (filter === "Cheapest") {
      const sorti = arr.sort((it, next) => {
        return parseFloat(it.price.total) - parseFloat(next.price.total);
      });
      setFlightData(sorti);
      setTimeout(() => {
        setLoading(false);
      }, 300);
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    setFlightData(flights);
  }, [flights]);
  useEffect(() => {
    setLoading(load);
  }, [load]);

  const getAirLinesName = (data) => {
    let obj = {};
    data.forEach((element) => {
      obj[element.iataCode] = element.businessName;
    });
    setAirlinesName(obj);
  };
  const [pageArr, setPageArr] = useState([]);
  useEffect(() => {
    setAnimLoading(true);
    setTimeout(() => {
      setAnimLoading((p) => !p);
    }, 0);
    setPageArr(createArray(flightdata.length));
  }, [flightdata]);
  const getOffset = (s, e) => {
    setPageData({ start: s, end: e });
    window.scrollTo({ top: window.innerHeight * 0.8, behavior: "smooth" });
  };

  return (
    <Container fluid className="px-5" style={{ marginTop: "2rem" }}>
      <Row>
        <Col className="filter-shodow p-3 mt-3">
          <FilterOffCanVas placement="top" name="Filter">
            <TicketFilter
              getAirLinesName={getAirLinesName}
              flights={flights}
              setFlightData={setFlightData}
              filterlength={flightdata.length}
            />
          </FilterOffCanVas>
        </Col>
        <Col sm={9} className="mt-3 p-0 search-tab">
          <FilterTabs selectTab={selectTab} contents={tabFilter} />
          {loading ? (
            <>
              <LoadingSkel />
              <LoadingSkel />
              <LoadingSkel />
            </>
          ) : flightdata.length === 0 ? (
            <Card
              next={{
                name: `more flight from ${searchParams.originDestinations[0].to.label}`,
              }}
              label="Flight"
            />
          ) : (
            flightdata
              .slice(pageData.start, pageData.end)
              .map((it, ind) => (
                <Tickets
                  animLoading={animLoading}
                  key={"key" + ind}
                  airlinesName={airlinesName}
                  data={it}
                />
              ))
          )}
          <PaginationComp
            itemsPerPage={ItemPerPage}
            arr={pageArr}
            callback={getOffset}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Index;
