import React, { useEffect, useRef, useState } from "react";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import { AiOutlineSwap } from "react-icons/ai";
import Picker from "./Picker";
import { useNavigate } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";

import http, { getCity } from "../../Api";
import { reactLocalStorage } from "reactjs-localstorage";

const LocationSelect = ({ flightType, defaultData, getsearchData, id }) => {
  const queryParams = window.location.search;
  const search = new URLSearchParams(queryParams);
  const [selectedLocations, setSelectedLocations] = useState(defaultData);
  const apitoref = useRef(null);
  console.log("selectedLocations", selectedLocations);

  const debounce = (func, delay) => {
    clearTimeout(apitoref.current);
    apitoref.current = setTimeout(func, delay);
  };
  const promiseOptions = (inputValue) => {
    if (!inputValue) return;
    return new Promise((resolve) => {
      debounce(() => {
        getCity(inputValue, "AIRPORT").then((data) => {
          const existingSearchHistory = reactLocalStorage.get("search-his")
            ? reactLocalStorage.getObject("search-his")
            : [];
          const newData = data?.data || [];
          console.log("existingSearchHistory", existingSearchHistory);
          const newDataObjects = newData.map((it) => ({
            value: it.iataCode,
            label: {
              cityName: it.address.cityName,
              airPortName: it.name,
              country: it.address.countryName,
            },
          }));

          const filteredDataObjects = newDataObjects.filter((newObj) => {
            return !existingSearchHistory.some(
              (existingObj) => existingObj.value === newObj.value
            );
          });

          const updatedSearchHistory = [
            ...existingSearchHistory,
            ...filteredDataObjects,
          ];

          reactLocalStorage.setObject("search-his", updatedSearchHistory);

          resolve(
            data?.data?.map((it) => ({
              value: it.iataCode,
              label: {
                cityName: it.address.cityName,
                airPortName: it.name,
                country: it.address.countryName,
              },
            }))
          );
        });
      }, 800);
    });
  };
  const navigate = useNavigate();
  const getDate = (date) => {
    console.log("date", date);
    setSelectedLocations({ ...selectedLocations, date: date });
  };

  useEffect(() => {
    getsearchData(selectedLocations, id);
  }, [selectedLocations]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(() => {
      let prev = reactLocalStorage.get("search-his")
        ? reactLocalStorage.getObject("search-his")
        : [];
      if (!selectedLocations.from) {
        return prev;
      }
      return prev.filter((it) => it.value != selectedLocations.from.value);
    });
  }, [selectedLocations.from]);

  return (
    <div className="d-flex mt-4 justify-content-start align-items-center flex-wrap gap-2">
      <AsyncSelect
        placeholder={"From  Where"}
        defaultOptions={reactLocalStorage.getObject("search-his")}
        cacheOptions
        loadOptions={promiseOptions}
        components={{
          Option: ({ innerProps, label }) => (
            <div {...innerProps} className="d-block p-2 opt-airport">
              <div>{label.airPortName}</div>
              <small className="text-muted">
                <FaLocationDot /> {label.cityName}, {label.country}
              </small>
            </div>
          ),
        }}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: "#f39c12",
            borderWidth: "1px",
            borderRadius: 10,
            height: 50,
            width: 300,
            background: "none",
        
          }),
          singleValue: (provided) => ({
            ...provided,
            color: "white", // Set your desired text color
          }),
          input: (provided, state) => ({
            ...provided,
            color: "white", // Change the color to your desired color
          }),
        }}
        isSearchable
        value={selectedLocations.from}
        onChange={({ value, label }) => {
          setSelectedLocations({
            ...selectedLocations,
            from: { value, label: label.airPortName },
          });
        }}
      />
      <span
        style={{
          cursor: "pointer",
        }}
      >
        <AiOutlineSwap size={30} color="white" />
      </span>
      <AsyncSelect
        placeholder={"Where to"}
        cacheOptions
        defaultOptions={options}
        loadOptions={promiseOptions}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: "#f39c12",
            borderWidth: "1px",
            borderRadius: 10,
            height: 50,
            width: 300,
            background: "none",
          }),
          singleValue: (provided) => ({
            ...provided,
            color: 'white', // Set your desired text color
          }),
          input: (provided, state) => ({
            ...provided,
            color: "white", // Change the color to your desired color
          }),
        }}
        components={{
          Option: ({ innerProps, label }) => (
            <div {...innerProps} className="d-block p-2 opt-airport">
              <div>{label.airPortName}</div>
              <small className="text-muted">
                <FaLocationDot /> {label.cityName}, {label.country}
              </small>
            </div>
          ),
        }}
        isSearchable
        value={selectedLocations.to}
        onChange={({ value, label }) => {
          setSelectedLocations({
            ...selectedLocations,
            to: { value, label: label.airPortName },
          });
        }}
      />

      <Picker
        flightType={flightType}
        value={defaultData?.date}
        getDate={getDate}
        
      />
    </div>
  );
};

export default LocationSelect;
