import React from "react";
import "./newsletter.css"
function NewsLetter() {
  return (
    <div className="newsletter">
    <div className="container">
    <div className="row">
    <div className="col-sm-12">
        <div className="content">
            <h3 className="text-light">SUBSCRIBE NOW</h3>
            <h6 className="content-tuned">Subscribe to our newsletter and stay tuned with future updates</h6>
        <div className="input-group">
             <input type="email" className="form-control" placeholder="Enter email to subscribe" />
             <span className="input-group-btn" />
             <button className="btn btn-dark" type="submit">Subscribe Now</button>
    
             
              </div>
              <div className="text-center">
    
                <a className="unsubscribe-link" href="#">Would you like to unsubscribe?</a>
                  
              </div>
               
        </div>
    
    </div>
    </div>
    </div>
    </div>
  );
}

export default NewsLetter;
