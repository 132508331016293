import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import moment from "moment";
// Import AOS styles

import { useMediaQuery } from "../../hook/useMediaQuerry";
import { airport_name } from "../../Api/airportname";
export function convertTimeFormat(input) {
  let d = input.replace("PT", "").split(/[HM]/);

  return (
    d[0] +
    "H" +
    `${d[1] ? (d[1].length == 1 ? ":0" + d[1] + "M" : ":" + d[1] + "M") : ""}`
  );
}
const TicketLeftChild = ({ details, source }) => {
  const rowRef = useRef(null);
  const isMobile = useMediaQuery();
  const [childlnt, setChildLnt] = useState(3);

  useEffect(() => {
    const numberOfChildren = rowRef.current
      ? rowRef.current.children.length
      : 0;
    setChildLnt(numberOfChildren);
    if (numberOfChildren === 5) {
      let fs;
      if (isMobile) fs = "10px";
      else fs = "13px";
      rowRef.current.style.fontSize = fs;
      rowRef.current.style.setProperty("--bs-gutter-x", "2rem");
    } else if (numberOfChildren === 3) {
      rowRef.current.style.fontSize = "13px";
    } else if (numberOfChildren === 7) {
      let fs;
      if (isMobile) fs = "6px";
      else fs = "11px";
      rowRef.current.style.fontSize = fs;
      rowRef.current.style.setProperty("--bs-gutter-x", "2rem");
    }
  }, []);
  return (
    <Row>
      <Container style={{ margin: "20px 5px" }}>
        <Col className="d-flex flex-column align-items-center flight-logo">
          <img
            className=""
            style={{ width: "auto", borderRadius: "50%" }}
            src="https://e1.pngegg.com/pngimages/904/41/png-clipart-gnulinux-yinyang-logo-round-black-bird.png"
          />
          <strong className="text-muted"> {source}</strong>
        </Col>

        <Row ref={rowRef}>
          {details.segments.map((it, ind) => {
            //    const line=
            return (
              <>
                {ind >= 1 ? null : (
                  <Loc
                  childlnt={childlnt}
                    key={it.departure.iataCode + "key" + ind}
                    isDep={true}
                    isMobile={isMobile}
                    iataCode={it.departure.iataCode}
                    date={it.departure.at}
                  />
                )}
                <Col>
                  <div className="horizontal-line  text-center">
                    <span className="mt-1">
                      {" "}
                      {convertTimeFormat(it.duration.replace("PT", ""))}
                    </span>
                  </div>
                </Col>
                <Loc
                childlnt={childlnt}
                  isMobile={isMobile}
                  iataCode={it.arrival.iataCode}
                  date={it.arrival.at}
                />
              </>
            );
          })}
        </Row>
      </Container>
    </Row>
  );
};

const Loc = ({ iataCode, date, isMobile, childlnt }) => {
  return (
    <Col
      style={{
        scale: childlnt === 7 ? (isMobile ? "1.6" : "1") : "1",
        padding: 0,
        margin: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <span>{moment(date).format("hh:mm a")}</span>
      <span> {iataCode}</span>
      <span>{moment(date).format("Do MMM")}</span>
    </Col>
  );
};

export default TicketLeftChild;
