import React, { useEffect, useState } from "react";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import "./login.css";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import http from "../../Api";
import { reactLocalStorage } from "reactjs-localstorage";
import ForgotPassWord from "./ForgotPassWordModal";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [previousPath, setPreviousPath] = useState(null);
  const navi = useNavigate();
  const location = useLocation();
  useEffect(() => {
    // Update the previousPath whenever the route changes
    if (location.state && location.state.previousPath) {
      setPreviousPath(location.state.previousPath);
    }
  }, [location]);
  const submit = async () => {
    const m = email.trim();
    const p = password.trim();
    if (!/^\S+@\S+\.\S+$/.test(email))
      return toast.error("Enter a valid email");

    if (m === "") return toast.error("Enter Email");
    if (p === "") return toast.error("Enter Password");
    login();
  };
  const login = () => {
    setLoading(true);
    http
      .post("log-in", { email, password }, { withCredentials: true })
      .then((res) => {
        reactLocalStorage.set("auth", true);
        reactLocalStorage.setObject("userData", res.data);
        toast.success("You are logged in");
        if (previousPath === "auth") navi("/");
        navi(-1);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err.response.data.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <section className="">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center ">
          <div className="col-md-9 col-lg-6 col-xl-5">
            <img
              src="https://i.pinimg.com/736x/13/88/dc/1388dc2487cb62bb47607cf1809b29ad.jpg"
              className="img-fluid"
              alt="Sample image"
            />
          </div>
          <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
            <form>
              <div className="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
                <p className="lead fw-normal mb-0 me-3">Sign in with</p>
                <button
                  type="button"
                  className="btn btn-primary btn-floating mx-1"
                >
                  <FaFacebook />
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-floating mx-1"
                >
                  <FaTwitter />
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-floating mx-1"
                >
                  <FaLinkedin />
                </button>
              </div>
              <div className="divider d-flex align-items-center my-4">
                <p className="text-center fw-bold mx-3 mb-0">Or</p>
              </div>
              {/* Email input */}
              <div className="form-outline mb-4">
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  id="form3Example3"
                  className="form-control form-control-lg"
                  placeholder="Enter a valid email address"
                />
              </div>
              {/* Password input */}
              <div className="form-outline mb-3">
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  id="form3Example4"
                  className="form-control form-control-lg"
                  placeholder="Enter password"
                />
              </div>
              <div className="d-flex justify-content-between align-items-center">
                {/* Checkbox */}
                <div className="form-check mb-0">
                  <input
                    className="form-check-input me-2"
                    type="checkbox"
                    defaultValue
                    id="form2Example3"
                  />
                  <label className="form-check-label" htmlFor="form2Example3">
                    Remember me
                  </label>
                </div>

                <a
                  style={{ cursor: "pointer" }}
                  className="text-body"
                  onClick={() => setModalShow(true)}
                >
                  Forgot Password?
                </a>

                <ForgotPassWord
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
              </div>
              <div className="text-center text-lg-start mt-4 pt-2">
                <button
                  type="button"
                  className="btn btn-primary btn-lg"
                  style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}
                  onClick={submit}
                >
                  {loading ? (
                    <div className="spinner-border" role="status"></div>
                  ) : (
                    "Login"
                  )}
                </button>
                <p className="small fw-bold mt-2 pt-1 mb-0">
                  Don't have an account?{" "}
                  <Link to="/sign-up" className="link-danger">
                    Register
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 bg-primary">
        {/* Copyright */}
        <div className="text-white mb-3 mb-md-0">
          Copyright © 2020. All rights reserved.
        </div>
        {/* Copyright */}
        {/* Right */}
        <div>
          <a href="#!" className="text-white me-4">
            <i className="fab fa-facebook-f" />
          </a>
          <a href="#!" className="text-white me-4">
            <i className="fab fa-twitter" />
          </a>
          <a href="#!" className="text-white me-4">
            <i className="fab fa-google" />
          </a>
          <a href="#!" className="text-white">
            <i className="fab fa-linkedin-in" />
          </a>
        </div>
        {/* Right */}
      </div>
    </section>
  );
};

export default Login;
