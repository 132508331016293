import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";
import Table from "react-bootstrap/Table";
import moment from 'moment';
import Offcanvas from "react-bootstrap/Offcanvas";
function StatusBoard({ rows, ticketStatus }) {
  console.log('rows', rows)
 
    return (
      <>
        <Offcanvas.Title className="text-center text-light">
          <strong className="d-block">Ticket Status {ticketStatus}</strong>
          <small>
            {/* Flight Number : [{rows.flightDesignator.flightNumber}] - Carrier Code
            : [{rows.flightDesignator.carrierCode}] */}
          </small>
        </Offcanvas.Title>
  
        <Table variant="dark" striped bordered hover>
          <thead>
            <tr>
              <th>Airport Code</th>
              <th>
                <small>Deparature</small>
              </th>
              <th>
                {" "}
                <small>Arrival</small>{" "}
              </th>
  
              <th>Timming </th>
            </tr>
          </thead>
          <tbody>
            {rows?.map((it) => (
              <tr>
                <td>{it.iataCode}</td>
                <td>
                  {it.hasOwnProperty("departure") ? (
                    <TiTick className="text-success" />
                  ) : (
                    <ImCross className="text-danger" />
                  )}
                </td>
                <td>
                  {it.hasOwnProperty("arrival") ? (
                    <TiTick className="text-success" />
                  ) : (
                    <ImCross className="text-danger" />
                  )}
                </td>
  
                <td>
                  <small>
                    {it.hasOwnProperty("departure") &&
                      moment(it.departure.timings[0].value).format(
                        "hh:mm a D,MMM"
                      )}
                    {it.hasOwnProperty("arrival") &&
                      moment(it.arrival.timings[0].value).format("hh:mm a D,MMM")}
                  </small>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  }
  
  export default StatusBoard