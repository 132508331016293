import { create } from "zustand";
import { persist } from "zustand/middleware";

const INITIAL_STATE = {
  hotel_images: {},
  hotel_details: {},
  hotel_images_by_id: {}
};

const store = {
  ...INITIAL_STATE,
};

export const useCache = create(
  persist(
    (set) => ({
      ...store,
      setImages: (id, preview) =>
        set((state) => ({
          hotel_images: { ...state.hotel_images, [id]: preview },
        })),

      setHotelDetails: (id, data) =>
        set((state) => ({
          hotel_details: { ...state.hotel_details, [id]: data },
        })),
      setImagesById: (id, images) =>
        set((state) => ({
          hotel_images_by_id: { ...state.hotel_images_by_id, [id]: images },
        })),
    }),
    {
      name: "image-cache",
      getStorage: () => sessionStorage,
    }
  )
);
