import React from "react";
import { Container, Card, Row, Col, Button } from "react-bootstrap";
import { IoPersonSharp } from "react-icons/io5";
import { FaCarAlt } from "react-icons/fa";
import { TbMessageLanguage } from "react-icons/tb";
import { FaLocationDot } from "react-icons/fa6";
import { useMediaQuery } from "../../hook/useMediaQuerry";
import { IoReaderSharp } from "react-icons/io5";
import { currency_symbols } from "../checkout/Index";
import { useNavigate } from "react-router-dom";
const Result = ({ res }) => {
  const isMobile = useMediaQuery();
  console.log("res", res);
  const navi = useNavigate();
  return (
    <Container className="my-3 ">
      <Row className="car-res-card">
        <Col className="d-flex flex-column justify-content-between" md={4}>
          <Card.Img width="200" src={res?.vehicle?.imageURL} alt="Car" />
          <a href="" target="_blank">
            <IoReaderSharp /> Term & Condition
          </a>
        </Col>

        <Col md={8}>
          <Row>
            <Card.Title className="mb-3 mx-3">
              {res?.vehicle?.description}
            </Card.Title>
          </Row>
          <Row>
            <Col xs={5} className="justify-content-start">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <IoPersonSharp /> {res?.vehicle?.seats[0]?.count} seats
                </li>
                <li className="list-group-item">
                  <FaCarAlt /> {res.vehicle.category}
                </li>
                <li className="list-group-item">
                  <TbMessageLanguage /> {res.language}
                </li>
              </ul>
            </Col>{" "}
            <Col xs={7}>
              <strong className="text-primary d-block text-end">
                {" "}
                <FaLocationDot />
                Dumdum to sealdag
              </strong>
              <h6 className="text-end">
                {currency_symbols[res.converted.currencyCode]}{" "}
                {res?.converted?.monetaryAmount}
              </h6>
              <Button
                onClick={() =>
                  navi("/car-rental-checkout/" + res.id, { state: res })
                }
                style={{ width: "100%" }}
                variant="primary"
              >
                Book Now
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Result;
