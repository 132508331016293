import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FaLocationDot } from "react-icons/fa6";
import { Row, Col } from "react-bootstrap";
import { Rating } from "@smastrom/react-rating";
import "@smastrom/react-rating/style.css";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdGlobe } from "react-icons/io";
import { FaWheelchair } from "react-icons/fa";
import "./review.css";

function InfoTab({
  placeData: {
    website,
    contact_number,
    address,
    icon,
    rating,
    user_ratings_total,
    wheelchair_accessible_entrance,
  },
  reviews,
}) {
  return (
    <Tabs
      defaultActiveKey="about"
      id="uncontrolled-tab-example"
      className="mb-3 mx-2"
    >
      <Tab eventKey="about" title="About">
        <Row>
          <div className="d-flex align-items-start">
            <FaLocationDot size={20} className="mx-2" />{" "}
            <div dangerouslySetInnerHTML={{ __html: address }}></div>
          </div>
        </Row>
        <Row>
          <div className="d-flex align-items-start">
            <FaPhoneAlt className="mx-2" /> {contact_number}
          </div>
        </Row>{" "}
        <Row>
          <div className="d-flex align-items-start">
            <IoMdGlobe size={20} className="mx-2" />
            <a style={{ overflow: "hidden" }} href={website} target="_blank">
              {" "}
              {website}
            </a>
          </div>
        </Row>
        <Row>
          <div className="d-flex align-items-start">
            <FaWheelchair className="mx-2" />
            {wheelchair_accessible_entrance ? "available" : "not available"}
          </div>
        </Row>
        <Row className="mx-2">
          <div className="d-flex align-items-center">
            Rating :
            <Rating style={{ maxWidth: 180 }} readOnly value={rating} />{" "}
            <h3>{rating}</h3>
          </div>
          <div>{user_ratings_total} reviews</div>
        </Row>
      </Tab>
      <Tab eventKey="reviews" title="Reviews">
        <div className="container justify-content-center  border-left border-right">
          {reviews.map((it) => (
            <Review data={it} />
          ))}
        </div>
      </Tab>
    </Tabs>
  );
}

export default InfoTab;

const Review = ({ data }) => {
  return (
    <div className="d-flex justify-content-center py-2">
      <div className="second py-2 px-2">
        {" "}
        <span className="text1">{data.text}</span>
        <div className="d-flex justify-content-between py-1 pt-2">
          <div>
            <a href={data.author_url}> 
              <img src={data.profile_photo_url} width={18} />
            <span className="text2">{data.author_name}</span>    
            </a>
          
          </div>
          <div>
            <span className="text3">
             
              <Rating style={{ maxWidth: 70 }} readOnly value={data.rating} />
              {data.relative_time_description}
            </span>

          </div>
        </div>
      </div>
    </div>
  );
};
