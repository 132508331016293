import React, { useState } from "react";
import WithHeaderFooter from "../../components/WithHeaderFooter";
import FormTab from "../checkout/FormTab";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import PricingInfo from "./PricingInfo";
import PaymentForm from "./PaymentForm";
import FlightNumberDropdown from "./FlightNumberDropdown";
import http from "../../Api";
import toast from "react-hot-toast";
const CheckOut = () => {
  const { state } = useLocation();
  const { id: rentalId } = useParams();
  const [passengers] = useState(Array.from(Array(1)));
  const [agency, setAgency] = useState(null);
  const [loading, setLoading] = useState(false);
  const [corporation, setCorporation] = useState(null);
  const [extraServices, setExtraServices] = useState([
    {
      code: "EWT",
      itemId: "EWT0291",
    },
  ]);

  const [paymentMethod] = useState(state?.methodsOfPaymentAccepted[0]);
  const [flightNumber, setFlightNumber] = useState(null);
  const [startConnectedSegment, setStartConnectedSegment] = useState(null);
  const [paymentData, setPaymentData] = useState({
    methodOfPayment: paymentMethod,
    creditCard: {
      number: "4111111111111111",
      holderName: "Surajit Maity",
      vendorCode: "VI",
      expiryDate: "1018",
      cvv: "111",
    },
  });
  const [isFlightNoReq] = useState(
    state?.serviceProvider?.settings &&
      state.serviceProvider.settings.includes("FLIGHT_NUMBER_REQUIRED")
  );

  const [guests, setGuests] = useState([]);

  console.log("state", isFlightNoReq, state);
  const setPassenger = (data, pid) => {
    const { line, zip, countryCode, cityName } = data.billingAddress;

    let userDetails = {
      firstName: data.name.firstName,
      lastName: data.name.lastName,
      title: data.name.title,
      contacts: {
        email: data.contact.emailAddress,
        phone: `${data.contact.phones[0].countryCallingCode}${data.contact.phones[0].number}`,
      },
      billingAddress: {
        line,
        zip,
        countryCode,
        cityName,
      },
    };

    const arr = [...guests];
    arr[pid - 1] = userDetails;
    setGuests(arr);
    setAgency({
      contacts: [
        {
          email: {
            address: data.contact.emailAddress,
          },
        },
      ],
    });
    setCorporation({
      address: {
        line,
        zip,
        countryCode,
        cityName,
      },
      info: {
        AU: "FHOWMD024",
        CE: "280421GH",
      },
    });
  };

  const booking = async () => {
    setLoading(true);
    toast.loading("booking...")
    const body = {
      data: {
        note: "Note to driver",
        passengers: guests,
        payment: paymentData,
        flightNumber,
        agency,
        extraServices,
        equipment: [
          {
            code: "BBS",
          },
        ],
        corporation,
      },
    };
    if (isFlightNoReq) {
      body.data.startConnectedSegment = startConnectedSegment;
    }
    console.log("body", body);

    try {
      toast.dismiss()
      const { data } = await http.post(`book-transport/${rentalId}`, body, {
        withCredentials: true,
      });
      console.log("data", data);
      toast.success("booking successfull");
    } catch (error) {
      toast.dismiss()
      toast.error(error.response.data.msg);
    } finally {
      setLoading(false);
    }
  };
  return (
    <WithHeaderFooter>
      <Container>
        <Row>
          <FormTab
            dob={false}
            gender={false}
            label="Passenger"
            passengers={passengers}
            setPassenger={setPassenger}
            billingAdress
          />
          <Col className="py-5 " md={4}>
            <div className="form-border">
              <FlightNumberDropdown
                flightNumber={flightNumber}
                setFlightNumber={setFlightNumber}
                setStartConnectedSegment={setStartConnectedSegment}
              />
            </div>

            <div className="form-border">
              <Row>
                <h5>Payment Info </h5>
              </Row>
              <PaymentForm
                paymentData={paymentData}
                setPaymentData={setPaymentData}
                paymentMethod={paymentMethod}
              />
            </div>
            <PricingInfo loading={loading} onClick={booking} res={state} />
          </Col>
        </Row>
      </Container>
    </WithHeaderFooter>
  );
};

export default CheckOut;
