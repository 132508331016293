import React from "react";
import Accordion from "react-bootstrap/Accordion";

function ViewMore({ children }) {
  let Header, Info;
console.log('children.props', children)

  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>{children[0]}</Accordion.Header>
        <Accordion.Body>{children[1]}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default ViewMore;
