import React, { useEffect, useState } from "react";
import http from "../../Api";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { reactLocalStorage } from "reactjs-localstorage";
const TokenPage = () => {
  const search = window.location.search;
  const token = new URLSearchParams(search).get("token");
  const [v, setV] = useState(false);
  const [laoding, setLoading] = useState(true);
  const navi = useNavigate();
  const [p, setP] = useState(null);
  const [cp, setCp] = useState(null);
  useEffect(() => {
    http
      .get("verify-reset-token?token=" + token)
      .then((res) => {
        if (res.status === 200 && res.data.verified) {
          setV(true);
          setLoading(false);
        }
      })
      .then((err) => {
        navi(-1);
      });
  }, [search]);
  const updatePassword = () => {
    if (p !== cp) return toast.error("password not matched");
    if (p.length < 5)
      return toast.error("Enter a longer password. Atleast 5 character long");

    http
      .get(`update-password?token=${token}&password=${p}`,{withCredentials:true})
      .then((res) => {
        reactLocalStorage.set("auth", true);
        reactLocalStorage.setObject("userData", res.data);
        navi("/");
      })
      .catch((err) => {
        toast.error("some thing went wrong");
      });
  };
  if (laoding) {
    return <h1>Loading</h1>;
  }

  return (
    <>
      <div
        className="modal show"
        style={{ display: "block", position: "initial" }}
      >
        <Modal.Dialog>
          <Modal.Header closeButton>
            <Modal.Title>Reset Password</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="2">
                  Password
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    value={p}
                    onChange={(e) => setP(e.target.value)}
                    type="password"
                    placeholder="Password"
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="2">
                  Confirm Password
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    value={cp}
                    onChange={(e) => setCp(e.target.value)}
                    type="password"
                    placeholder="Password"
                  />
                </Col>
              </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button disabled={!v} onClick={updatePassword} variant="primary">
              Update Password
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </div>
    </>
  );
};

export default TokenPage;
