import Table from "react-bootstrap/Table";
import { currency_symbols } from "../checkout/Index";

function PriceDetailTable({ price = [] }) {
  return (
    <Table
      striped
      style={{ "--bs-table-bg": "#8EC5FC", "--bs-table-striped-bg": "#a3d1ff" }}
      hover
      variant="light"
    >
      <thead>
        <tr>
          <th>Traveler</th>
          <th>Base amount</th>
          <th>Tax & Charges</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        {price.map((it) => (
          <tr>
            <td scope="col" className="text-muted col-1  f-13">
              {it.travelerType[0] +
                it.travelerType
                  .slice(1, it.travelerType.length)
                  .toLowerCase()}{" "}
            </td>

            <td scope="col" className="text-muted col-4 f-13">
              {currency_symbols[it.price.currency]} {it.price.base}
            </td>
            <td scope="col" className="text-muted col-4 f-13">
              {currency_symbols[it.price.currency]}{" "}
              {(parseFloat(it.price.total) - parseFloat(it.price.base)).toFixed(
                2
              )}
            </td>
            <td scope="col" className="text-muted col-4 f-13">
              {currency_symbols[it.price.currency]} {it.price.total}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default PriceDetailTable;
