import React from "react";
import { Container, Row } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import DestCard from "./DestCard";
const CarHireDest = () => {
  return (
    <Container>
      <Row className="text-center">
        <p
          style={{ fontWeight: "bold", margin: "1rem 0rem" }}
          className="text-uppercase p-0"
        >
          popular car hire destination
        </p>
        <p className="p-0" style={{ fontWeight: "lighter" }}>
          Explore more options to hire a car for cheap
        </p>
      </Row>
  
      <Row>
        {
           Array.from(Array(20)).map(it=>( <DestCard key={it}/>))
        }
       
        </Row>
    </Container>
  );
};

export default CarHireDest;
