import { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useMediaQuery } from "../../hook/useMediaQuerry";
import { MdFilterList } from "react-icons/md";
import ButtonAnimated from "../button/Index";

function FilterOffCanVas({ name, children, buttonDisabled, ...props }) {
  const [show, setShow] = useState(false);
  const isMobileView = useMediaQuery();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if (!isMobileView) return <>{children}</>;

  return (
    <>
      <ButtonAnimated onClick={handleShow} className="me-2">
        {name} <MdFilterList />
      </ButtonAnimated>
      <Offcanvas
        backdropClassName="canvas-height-80"
        show={show}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter Flight Results</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-3">{children}</Offcanvas.Body>
        <Button onClick={handleClose} className="mx-2" variant="danger">
          {" "}
          close{" "}
        </Button>
      </Offcanvas>
   
    </>
  );
}

export default FilterOffCanVas;
