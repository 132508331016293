import React from "react";
import { Form } from "react-bootstrap";
const cardTypes = [
  { code: "CA", value: "MasterCard" },
  { code: "VI", value: "Visa" },
  { code: "AX", value: "American Express" },
  { code: "DC", value: "Diners Club" },
  { code: "AU", value: "Carte Aurore" },
  { code: "CG", value: "Cofinoga" },
  { code: "DS", value: "Discover" },
  { code: "GK", value: "Lufthansa GK Card" },
  { code: "JC", value: "Japanese Credit Bureau" },
  { code: "TC", value: "Torch Club" },
  { code: "TP", value: "Universal Air Travel Card" },
  { code: "BC", value: "Bank Card" },
  { code: "DL", value: "Delta" },
  { code: "MA", value: "Maestro" },
  { code: "UP", value: "China UnionPay" },
  { code: "VE", value: "Visa Electron" },
];



const CreditCard = ({ paymentData, setPaymentData }) => {
  return (
    <>
      {" "}
      <p className=" py-3">Credit Card Info</p>
      <div className="row gx-3">
        <div className="col-12">
          <div className="d-flex flex-column">
            <p className="text mb-1">Card Number</p>
            <input
                value={paymentData[0].card.cardNumber}
              className="form-control mb-3"
              type="text"
              placeholder="xxxx xxxx xxxx xxxx"
            />
          </div>
        </div>
        <div className="col-6">
          <div className="d-flex flex-column">
            <p className="text mb-1">Expiry</p>
            <input
               value={paymentData[0].card.expiryDate}
              className="form-control mb-3"
              type="text"
              placeholder="MM/YY"
            />
          </div>
        </div>
        <div className="col-6">
          <Form.Group controlId="cardTypeSelect">
          <p className="text mb-1">Select Card Type</p>
           
            <Form.Control as="select" custom>

              {cardTypes.map((card) => (
                <option selected={card.code===paymentData[0].card.
                vendorCode} key={card.code} value={card.code}>
                  {card.value}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </div>
      </div>
    </>
  );
};

export default CreditCard;
