
import { useState } from "react";
import toast from "react-hot-toast";
import http from "../../Api";
import EmailModal from "./EmailModal";
function ForgotPassWord(props) {
  const [email, setEmail] = useState(null);
  const submit = () => {
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      return toast.error("Enter a valid email");
    }
    http
      .get("reset-password?email=" + email)
      .then((res) => {
        toast.success("Check your mail to get reset link");
      })
      .catch((err) => {
        console.log("err", err);
        if (err?.response?.status === 404) {
          return toast.error(
            `Email isn't found. Please enter a registered email!`
          );
        }
        toast.error("Something Went Wrong Please Try Again");
      });
  };
  return <EmailModal {...props} email={email} setEmail={setEmail} submit={submit} />;
}
export default ForgotPassWord;


