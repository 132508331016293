import React from "react";
import { Container, Row, Col } from "react-bootstrap";
const DestCard = () => {
  return (
    <div style={{ width: "auto", display: "flex" }}>
      <div>
        <img
          className="dest-card-img"
          src="https://q-xx.bstatic.com/xdata/images/city/square100/976755.jpg?k=9393732a057dcf5a85687db3be404e40c9e4829a45f3fcb35dff63dc18743de3&o="
        />
      </div>
      <div
        style={{
          margin: "0.5rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <span style={{ fontWeight: "bold" }}> Phoenix</span>
        <span className="">69 car hire location</span>
        <span className="">Normal weight text.</span>
      </div>
    </div>
  );
};

export default DestCard;
