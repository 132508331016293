import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
const Document = () => {
  return (
    <>
      <Row>
       
        <Col>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Select aria-label="Select Document">
              <option>Select Document</option>
              <option value="passport">Passport</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Select aria-label="Nationality">
              <option>Nationality</option>
              <option value="passport">India</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Issuance Date</Form.Label>
            <Form.Control type="date" />
          </Form.Group>
        </Col>{" "}
        <Col>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Expiry Date</Form.Label>
            <Form.Control type="date" />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default Document;
