import React from "react";
import "./creditcard.css";

const CreditCard = ({ paymentData, setPaymentData }) => {
  return (
    <>
      <p className=" py-3">Credit Card </p>
      <div className="row gx-3">
        <div className="col-12">
          <div className="d-flex flex-column">
            <p className="text mb-1">Person Name</p>
            <input
              className="form-control mb-3"
              type="text"
              placeholder="Enter Your Name"
              value={paymentData.creditCard.holderName}
            />
          </div>
        </div>
        <div className="col-12">
          <div className="d-flex flex-column">
            <p className="text mb-1">Card Number</p>
            <input
              value={paymentData.creditCard.number}
              className="form-control mb-3"
              type="text"
              placeholder="xxxx xxxx xxxx xxxx"
            />
          </div>
        </div>
        <div className="col-6">
          <div className="d-flex flex-column">
            <p className="text mb-1">Expiry</p>
            <input
              value={paymentData.creditCard.expiryDate}
              className="form-control mb-3"
              type="text"
              placeholder="MM/YY"
            />
          </div>
        </div>
        <div className="col-6">
          <div className="d-flex flex-column">
            <p className="text mb-1">CVV/CVC</p>
            <input
              value={paymentData.creditCard.cvv}
              className="form-control mb-3 pt-2 "
              type="password"
              placeholder="***"
            />
          </div>
        </div>

      </div>
    </>
  );
};

export default CreditCard;
