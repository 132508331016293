import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Map from "../../components/seatmap/Index";
import SeatMapLoading from "../../components/seatmap/SeatMapLoading";
function UncontrolledExample({
  selectedSeatNo,
  tabdata,
  pIndex,
  SeatSelected,
  currentTab,
}) {
  const SeatSel = (data) => {
    console.log("data", data);
    SeatSelected(data);
  };
  console.log("tabdata", tabdata);
  return (
    <Tabs
      defaultActiveKey="flight1"
      id="uncontrolled-tab-example"
      className="my-3"
      onSelect={(e) => currentTab(parseInt(e.replace("flight", "")) - 1)}
    >
      {tabdata &&
        tabdata.map((it, ind) => (
          <Tab eventKey={`flight${ind + 1}`} title={`flight${ind + 1}`}>
            <Map
              selectedSeatNo={selectedSeatNo}
              key={`flight-${ind}`}
              flightId={ind}
              pIndex={pIndex}
              SeatSelected={SeatSelected}
              data={it.decks[0].seats}
            />
          </Tab>
        ))}
    </Tabs>
  );
}

export default UncontrolledExample;
