import moment from "moment";
import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";
import { MdFlightTakeoff } from "react-icons/md";
import { currency_symbols } from "../checkout/Index";
function OrderList({ data }) {
  console.log("data", data);
  return (
    <ListGroup className="m-2" as="ol">
      {data.map((it) => (
        <ListGroup.Item
          as="li"
          className="d-flex justify-content-between align-items-start"
        >
          <div
            style={{ width: "100%" }}
            className="d-flex justify-content-between"
          >
            <div>
             <p style={{fontSize:"0.7rem"}}> {it.hotelName}</p> 
             
            </div>
            <div>
              <Badge
                bg={`${
                  it.bookingStatus === "booked"
                    ? "success"
                    : it.bookingStatus === "failed"
                    ? "danger"
                    : "warning"
                }`}
                className="mx-1"
                pill
              >
                {it.bookingStatus}
                
              </Badge>
              <br/>
              <span style={{fontSize:"0.7rem"}}>
              {moment(it.createdAt).fromNow()}</span>
            </div>
            <div>
              {currency_symbols[it.currency]}
              {it.amount}
            </div>
          </div>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}

export default OrderList;
