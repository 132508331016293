import React from "react";
import "./card.css";
import { BiSolidError } from "react-icons/bi";
import { Link } from "react-router-dom";

const Card = ({
  content = <BiSolidError color="white" size={50} />,
  label,
  next = { name: "next action", link: "#" },
}) => {
  return (
    <div className="container-res m-5">
      <div className="card-res w-full">
        <div className="face face1 w-full">
          <div className="content justify-content-center d-flex flex-column align-items-center">
            {content}
            <h3>No {label} Found</h3>
          </div>
        </div>
        <div className="face face2 w-full">
          <div className="content">
            <Link to={next.link}>{next.name}</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
