import React, { useEffect, useState } from "react";
import http from "../../Api";
import WithHeaderFooter from "../../components/WithHeaderFooter";
import OrderListFlights from "./List";
import OrderListHotel from "./List2";
import Container from "react-bootstrap/Container";
import { useParams } from "react-router-dom";
import Loading from "../attraction/Loading";
const Booking = () => {
  const { type } = useParams();
  const [bookingHistory, setBookingHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let url;
    if (type === "flights") {
      url = "get-order";
    } else if (type === "hotels") {
      url = "hotel-booking-orders";
    }
    getData(url)
  }, [type]);
  const getData = (url) => {
    setLoading(true)
    http.get(url, { withCredentials: true }).then((res) => {
      setBookingHistory(res.data);
    }).finally(()=>{
      setLoading(false)
    })
  };
  return (
    <WithHeaderFooter>
      <Container>
        {loading?<Loading/>:type === "flights" ? (
          <OrderListFlights  data={bookingHistory}  callBack={getData} />
        ) : type === "hotels" ? (
          <OrderListHotel data={bookingHistory} />
        ) : null}
      </Container>
    </WithHeaderFooter>
  );
};

export default Booking;
