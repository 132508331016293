import React from "react";
import "./button.css";
const ButtonAnimated = ({ varient = "B", children, ...props }) => {
  return (
    <button  {...props} className="container-btn">
      <a className={`button type--${varient}`}>
        <div  className="button__line" />
        <div className="button__line" />
        <span className="button__text">{children}</span>
        <div className="button__drow1" />
        <div className="button__drow2" />
      </a>
    </button>
  );
};

export default ButtonAnimated;
