import React from "react";
import { useParams } from "react-router-dom";
const Index = () => {
  const { status, orderId } = useParams();
  return (
    <div>
      payment is {status} for order id {orderId}
    </div>
  );
};

export default Index;
