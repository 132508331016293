import Flight from "./pages/flight";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import "./App.css";

import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import Hotels from "./pages/hotels";
import Attraction from "./pages/attraction/Index";
import AttractionInfo from "./pages/attractionInfo/Index";
import CarRental from "./pages/carrental/Index";
import FlightSearch from "./pages/flightsearch/FlightSearch";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Checkout from "./pages/checkout/Index";
import PublicRoute from "./Layout/PublicRoute";
import PrivateRoute from "./Layout/PrivateRoute";
import Booking from "./pages/BookingHistory/Index";
import HotelResult from "./pages/hotelresult/Index";
import HotelInfo from "./pages/hotelresult/HotelInfo";
import HoteCheckout from "./pages/hotelresult/HoteCheckout";
import PaymentStatus from "./pages/paymentstatus/Index";
import TokenPage from "./pages/auth/TokenPage";
import VerifyEmail from "./pages/auth/VerifyEmail";
import CarRentalCheckOut from "./pages/carrental/CheckOut";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="/log-in" element={<Login />} />
          <Route path="/sign-up" element={<Register />} />
        </Route>

        <Route element={<PrivateRoute />}>
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/hotel-checkout" element={<HoteCheckout />} />
          <Route
            path="/car-rental-checkout/:id"
            element={<CarRentalCheckOut />}
          />
          <Route path="/bookings/:type" element={<Booking />} />
          <Route
            path="/payment-status/:status/:orderId"
            element={<PaymentStatus />}
          />
        </Route>

        <Route path="/" element={<Navigate to="/flight" />} />
        <Route path="/flight" element={<Flight />} />
        <Route path="/hotels" element={<Hotels />} />
        <Route path="/hotels-search" element={<HotelResult />} />
        <Route path="/hotel-info" element={<HotelInfo />} />
        <Route path="/car-rental" element={<CarRental />} />
        <Route path="/cars-search" element={<CarRental />} />
        <Route path="/attraction" element={<Attraction />} />
        <Route path="/attraction/:name" element={<AttractionInfo />} />
        <Route path="/flights-search" element={<FlightSearch />} />
        <Route path="/reset-password" element={<TokenPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
