import React, { useEffect, useRef, useState } from "react";
import "./card.css";
import http from "../../Api";
import { useCache } from "../../state";
import { useNavigate } from "react-router-dom";
const DestCard = ({
  stopClick,
  withImage,
  title,
  extraa,
  subtitle = "",
  img,
  width = null,
  name,
  nameId,
  latitude,
  longitude,
  ...rest
}) => {
  const {
    setImagesById,
    hotel_images_by_id: attraction_images_by_id,
    setImages: setImagesPrevs,
    hotel_images: attraction_images,
    setHotelDetails: setAtrractionDetails,
    hotel_details: attraction_details,
  } = useCache();
  const observerRef = useRef(null);
  const thumbnail = useRef(
    attraction_images.hasOwnProperty(nameId) ? attraction_images[nameId] : null
  );
  const navi = useNavigate();
  const [placeDetails, setPlaceDetails] = useState(() => {
    if (attraction_details.hasOwnProperty(nameId)) {
      return attraction_details[nameId].hotelDetails;
    }
    return {
      hotelName: "",
      website: "",
      contact_number: "",
      map_link: "",
      address: "",
      editorial_summary: "",
      icon: "",
      rating: "",
      user_ratings_total: "",
      wheelchair_accessible_entrance: false,
    };
  });
  const [reviews, setReviews] = useState(() => {
    if (attraction_details.hasOwnProperty(nameId)) {
      return attraction_details[nameId].reviews;
    }
    return [];
  });
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState(() => {
    if (attraction_images_by_id.hasOwnProperty(nameId)) {
      setLoading(false);
      return attraction_images_by_id[nameId];
    }
    return [];
  });

  useEffect(() => {
    const opt = {
      root: null,
      rootMargin: "0px",
      threshold: 0.8,
    };
    const observer = new IntersectionObserver(handleObserv, opt);
    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, []);
  useEffect(() => {
    if (Array.isArray(images) && images.length === 0) return;
    setImagesById(nameId, images);
  }, [images]);
  const handleObserv = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        console.log("prsssev", thumbnail);

        if (!thumbnail.current && withImage) {
          getImage(name, latitude, longitude);
        }
      }
    });
  };
  const getImage = async (placeName, lat, lng) => {
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

    const latitude = lat;
    const longitude = lng;

    http
      .get("place-details", {
        params: {
          placeName,
          latitude,
          longitude,
        },
      })
      .then((response) => {
        const resp_result = response.data?.result;
        const details = {
          address: resp_result?.adr_address,
          map_link: resp_result?.url,
          hotelName: resp_result?.name,
          contact_number: resp_result?.formatted_phone_number,
          editorial_summary: resp_result?.editorial_summary?.overview,
          icon: resp_result?.icon,
          rating: resp_result?.rating,
          user_ratings_total: resp_result?.user_ratings_total,
          website: resp_result?.website,
          wheelchair_accessible_entrance:
            resp_result.wheelchair_accessible_entrance,
        };
        setPlaceDetails(details);
        setAtrractionDetails(nameId, {hotelDetails:details,reviews:resp_result.reviews});
        setReviews(resp_result.reviews);

        const photoReference =
          response?.data?.result?.photos[0]?.photo_reference;
        response.data?.result?.photos?.forEach((it) =>
          setImages((prev) => [
            ...prev,
            `https://maps.googleapis.com/maps/api/place/photo?maxwidth=${maxWidth}&photoreference=${it.photo_reference}&key=${apiKey}`,
          ])
        );
        const maxWidth = 400;
        const photoUrl = `https://maps.googleapis.com/maps/api/place/photo?maxwidth=${maxWidth}&photoreference=${photoReference}&key=${apiKey}`;

        setLoading(false);

        thumbnail.current = photoUrl;
        setImagesPrevs(nameId, photoUrl);
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };
  console.log('placeDetails', placeDetails)
  return (
    <div
      onClick={() => {
        if (stopClick) return;
        const imgs = encodeURIComponent(JSON.stringify(images.map((it) => it)));

        navi(
          "/attraction/" +
            name +
            `?lat=${latitude}&lng=${longitude}&images=${imgs}`,
          {
            state: { placeDetails, reviews },
          }
        );
      }}
      ref={observerRef}
      {...rest}
    >
      <a style={{ width: width }} className="card">
        {withImage ? (
          loading ? (
            <div
              style={{ width: "100%", height: 240 }}
              className="card m-0"
              aria-hidden="true"
            >
              <p className="card-text placeholder-glow  p-0">
                <span
                  style={{ height: 240, width: 280 }}
                  className="placeholder col-7"
                />
              </p>
            </div>
          ) : (
            <img src={thumbnail.current} alt="" className="card__img" />
          )
        ) : (
          <img src={img} alt="" className="card__img" />
        )}
        <span className="card__footer">
          <span>{title.toUpperCase()}</span>
          {extraa}
          <small dangerouslySetInnerHTML={{ __html: subtitle }}></small>
        </span>
      </a>
    </div>
  );
};

export default DestCard;
