import { useState } from "react";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import moment from "moment";
function DatePickerComp({label,value,onChange}) {
  console.log('value', value)

  return (
    <div  className="date-p">
      <DateTimePicker
      style={{border:"none"}}
       className={"text-light "}
        calendarIcon={
          <small className="text-light"  style={{ border: "none", background: "none" }}>
           {label}
          </small>
        }
        onChange={onChange}
        value={moment(value).toDate()}
      />
    </div>
  );
}
export default DatePickerComp;
