import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const LoadSkeleton = () => {
  return (
    <Container>
      <Row className="hotel-card my-3 mx-0 p-0">
        <Col className="" xs={4}>
          <div
           style={{width:'100%', height: 240}}
            className="card m-0"
            aria-hidden="true"
          >
            <p className="card-text placeholder-glow  p-0">
              <span
                style={{height:240, width: 280}}
                className="placeholder col-7"
              />
            </p>
          </div>
        </Col>
        <Col xs={8}>
          <div style={{width:'90%',height:240}} className="card" aria-hidden="true">
            <div style={{width:'100%'}} className="card-body">
              <h5 className="card-title placeholder-glow">
                <span className="placeholder col-6" />
              </h5>
              <p className="card-text placeholder-glow">
                <span className="placeholder col-12" />
                <span className="placeholder col-9" />
                <span className="placeholder col-9" />
                <span className="placeholder col-11" />
                <span className="placeholder col-8" />
              </p>
              <a
                href="#"
                tabIndex={-1}
                className="btn btn-success disabled placeholder col-6"
              />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LoadSkeleton;
