import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import DestCard from "./DestCard";
import http from "../../Api/index";
import { getClientCityInfo } from "../flight";
import { useEffect, useState } from "react";
import Loading from "./Loading";
import Slider from "react-slick";
import { MdExpandMore, MdExpandLess } from "react-icons/md";

export const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
function Destination() {
  useEffect(() => {
    getDestinations();
  }, []);
  const [dest, setDest] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mode, setmode] = useState("x");

  const getDestinations = () => {
    getClientCityInfo((err, data) => {
      setLoading(true);
      if (err) console.log("err", err);
      http
        .get("point-of-interests/location", {
          params: {
            lat: 41.397158 ?? data.latitude,
            long: 2.160873 ?? data.longitude,
          },
        })
        .then((res) => {
          setDest(res.data.data);
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          setLoading(false);
        });
    });
    return;
  };
  useEffect(() => {
    if (mode === "y") return;
    const viewportHeight = window.innerHeight;
    const targetPosition = 0.4 * viewportHeight;
    window.scrollTo({
      top: targetPosition,
      behavior: "smooth",
    });
  }, [mode]);

  return (
    <Container>
      <Row className="text-center">
        <p
          style={{ fontWeight: "bold", marginTop: "1rem" }}
          class="text-uppercase text-center"
        >
          Point Of Interest
        </p>
        {loading ? (
          <Loading />
        ) : mode === "x" ? (
          <Slider {...settings} className="slick-flight">
            {dest.map((it, ind) => (
              <DestCard
                withImage
                img={
                  "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/15/70/02/97/digha-beach.jpg?w=1200&h=-1&s=1"
                }
                title={it.name}
                subtitle={`${it.category} / rank : ${it.rank}`}
                name={it.name}
                nameId={it.id}
                latitude={it.geoCode.latitude}
                longitude={it.geoCode.longitude}
              />
            ))}
          </Slider>
        ) : (
          dest.map((it, ind) => (
            <Col style={{ margin: "1rem" }}>
              {" "}
              <DestCard
                withImage
                img={
                  "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/15/70/02/97/digha-beach.jpg?w=1200&h=-1&s=1"
                }
                title={it.name}
                subtitle={`${it.category} / rank : ${it.rank}`}
                name={it.name}
                nameId={it.id}
                latitude={it.geoCode.latitude}
                longitude={it.geoCode.longitude}
              />
            </Col>
          ))
        )}
      </Row>
      <h1
        onClick={() => setmode((prev) => (prev === "x" ? "y" : "x"))}
        className="text-center"
      >
        {mode === "x" ? <MdExpandMore /> : <MdExpandLess />}
      </h1>
    </Container>
  );
}

export default Destination;
