import React, { useState, useEffect } from "react";
import Pagination from "react-bootstrap/Pagination";

function PaginationComp({ arr, callback, itemsPerPage = 10 }) {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    // Reset to the first page when the array changes
    setCurrentPage(1);
  }, [arr]);

  const totalPages = Math.ceil(arr.length / itemsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage - 1, arr.length - 1);
    callback(startIndex, endIndex);
  };

  const generatePageNumbers = () => {
    const startPage = Math.max(1, currentPage - 3);
    const endPage = Math.min(totalPages, startPage + 6);

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    return pages;
  };

  return (
    <Pagination style={{ margin: "20px" }}>
      <Pagination.First onClick={() => handlePageClick(1)} />
      <Pagination.Prev
        onClick={() => handlePageClick(Math.max(1, currentPage - 1))}
      />

      {generatePageNumbers()}

      <Pagination.Next
        onClick={() => handlePageClick(Math.min(totalPages, currentPage + 1))}
      />
      <Pagination.Last onClick={() => handlePageClick(totalPages)} />
    </Pagination>
  );
}

export default PaginationComp;
