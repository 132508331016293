import React from "react";
import Form from "react-bootstrap/Form";


const CheackGroup = ({name,iatacode, label = "",count,onChange }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div>
        <Form>
          <Form.Check // prettier-ignore
            type={"checkbox"}
            id={`default-${label.split(" ").join('-')}`}
            label={label}
            name={name}
            value={iatacode}
            onChange={onChange}
          />
        </Form>
      </div>

      <div>
        <span>{count}</span>
      </div>
    </div>
  );
};

export default CheackGroup;
