import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Country, State, City } from "country-state-city";
import Select from "react-select";
const BillingAdress = ({ setBillingDetails }) => {
  const [curr_country, setCurrent_country] = useState(null);
  const [cities, setCities] = useState([]);
  const [zipcode, setZipcode] = useState("");
  useEffect(() => {
    if (!curr_country) return;
    setCities(City.getCitiesOfCountry(curr_country));
  }, [curr_country]);

  const handleCountryChange = (selectedOption) => {
    if (selectedOption) {
      setCurrent_country(selectedOption.value);
      setBillingDetails((prev) => ({
        ...prev,
        billingAddress: {
          ...prev.billingAddress,
          countryCode: selectedOption.value,
        },
      }));
    }
  };
  const handleCityChange = (selectedOption) => {
    if (selectedOption) {
      setBillingDetails((prev) => ({
        ...prev,
        billingAddress: {
          ...prev.billingAddress,
          cityName: selectedOption.value,
        },
      }));
    }
  };

  const countryOptions = Country.getAllCountries().map((cn) => ({
    value: cn.isoCode,
    label: `${cn.flag} ${cn.name}`,
  }));
  const cityOptions = cities.map((city) => ({
    value: city.name,
    label: city.name,
  }));
  return (
    <>
      <Row>
        <Col sm={6}>
          {" "}
          <Form.Group className="mb-3 " controlId="formBasicfName">
            <Form.Label>Adress Line</Form.Label>
            <Form.Control
              type="text"
              placeholder="st name,st number..."
              onChange={(e) =>
                setBillingDetails((prev) => ({
                  ...prev,
                  billingAddress: {
                    ...prev.billingAddress,
                    line: e.target.value,
                  },
                }))
              }
            />
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group className="mb-3" controlId="formBasiclName">
            <Form.Label>Country</Form.Label>
            <Select
              styles={{
                input: (provided, state) => ({
                  ...provided,
                  height: "45px", // Change the color to your desired color
                }),
              }}
              className="custom-select"
              options={countryOptions}
              isSearchable={true}
              onChange={handleCountryChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          {" "}
          <Form.Group className="mb-3" controlId="formBasiclName">
            <Form.Label>City</Form.Label>
            <Select
              styles={{
                input: (provided, state) => ({
                  ...provided,
                  height: "45px", // Change the color to your desired color
                }),
              }}
              options={cityOptions}
              isSearchable={true}
              onChange={handleCityChange}
            />
          </Form.Group>
        </Col>
        <Col sm={6}>
          {" "}
          <Form.Group className="mb-3 " controlId="formBasicfName">
            <Form.Label>Zip Code</Form.Label>
            <Form.Control
              type="text"
              value={zipcode}
              onChange={(e) => {
                if (e.target.value.length > 6) return;
                setZipcode(e.target.value);
                setBillingDetails((prev) => ({
                  ...prev,
                  billingAddress: {
                    ...prev.billingAddress,
                    zip: e.target.value,
                  },
                }));
              }}
              placeholder="x x x x x x"
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default BillingAdress;
