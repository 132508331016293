import moment from "moment";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { currency_symbols } from "../checkout/Index";

const Basedetailviewdd = ({ data,currency }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle
        style={{ background: "none", border: "none", minHeight: "30px" }}
        className="p-0 mx-2"
        variant="secondary"
        id="dropdown-basic"
      ></Dropdown.Toggle>

      <Dropdown.Menu>
        {data?.map((it) => (
          <Dropdown.Item>
            <div className="d-flex justify-content-between text-muted">
              <div className="mx-3">
                {moment(it.startDate, "YYYY-MM-DD").format(" DD")}-{" "}
                {moment(it.endDate, "YYYY-MM-DD").format(" DD ,MMM")}
              </div>
              <div>{currency_symbols[currency]}{it.base}/day</div>
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Basedetailviewdd;
