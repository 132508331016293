import React, { useEffect, useState } from "react";

import http from "../../Api";
import toast from "react-hot-toast";
import SeatMapLoading from "../../components/seatmap/SeatMapLoading";
import UncontrolledExample from "./Tab";
const SeatMap = ({
  selectedSeatNo,
  flightData,
  pIndex,
  SeatSelected,
  currentTab,
}) => {
  const [seatMap, setSeatMap] = useState(null);

  useEffect(() => {
    toast.loading("Getting SeatMap Data!");
    http
      .post("flight-seatmap", flightData)
      .then((res) => {
        toast.dismiss();
        setSeatMap(res.data.data);
      })
      .catch(({ response }) => {
        toast.dismiss();
        toast.error("Cant get Seatmap");
        if (response.data?.description.length > 0) {
          return toast.error(
            response.data?.description[0].detail ??
              response.data?.description[0].title
          );
        }
      });
  }, [flightData]);

  return (
    <>
      {seatMap ? (
        <UncontrolledExample
          selectedSeatNo={selectedSeatNo}
          currentTab={currentTab}
          pIndex={pIndex}
          SeatSelected={SeatSelected}
          tabdata={seatMap}
        />
      ) : (
        <SeatMapLoading />
      )}
    </>
  );
};

export default SeatMap;
