import { useState } from "react";
import Accordion from "react-bootstrap/Accordion";

function AddOn({ children }) {
  console.log("children", children);
  //   <AddOn> AddOn Props format
  //     <div>
  //       <Title />
  //       <Body />
  //     </div>
  //   </AddOn>;
  const [currTab, setCurrTab] = useState(null);
  return (
    <Accordion
      onSelect={(e) => {
        setCurrTab(e);
      }}
    >
      {children.map((child, key) => {
        return (
          <Accordion.Item tabId={key} eventKey={key}>
            <Accordion.Header>{child.props.children[0]}</Accordion.Header>
            {currTab === key && (
              <Accordion.Body>{child.props.children[1]}</Accordion.Body>
            )}
          </Accordion.Item>
        );
      })}
    </Accordion>
  );
}

export default AddOn;
