import React, { useEffect, useState } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
const PrivateRoute = () => {
  const path = useLocation();
  const [auth, setAuth] = useState(reactLocalStorage.get("auth"));

  useEffect(() => {
    setAuth(reactLocalStorage.get("auth"));
  }, [path.pathname]);
  console.log('auth', auth)
  return auth ? <Outlet /> : <Navigate to="/log-in" />;
};
export default PrivateRoute;
