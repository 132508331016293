import React, { lazy, Suspense } from "react";

import WithHeaderFooter from "../../components/WithHeaderFooter";
import NewsLetter from "../../components/newsletter/NewsLetter";
import FallbackComp from "../../components/Fallback/Index";

const HotelsHero = lazy(() => import("./HotelsHero"));
const HotelOffers = lazy(() => import("./HotelOffers"));
const PopularCities = lazy(() => import("./PopularCities"));
const UniqueProperties = lazy(() => import("./UniqueProperties"));
const GuestsHomeLove = lazy(() => import("./GuestsHomeLove"));
const ExploreProperties = lazy(() => import("./ExploreProperties"));
const TripPlanner = lazy(() => import("./TripPlanner"));
const PropertyType = lazy(() => import("./PropertyType"));
const DestinationLove = lazy(() => import("./DestinationLove"));
const FAQsection = lazy(() => import("../flight/FAQsection"));

function Hotels() {
  return (
    <WithHeaderFooter>
      <Suspense fallback={<FallbackComp />}>
        <HotelsHero />
        <HotelOffers />
        <PopularCities />
        <UniqueProperties />
        <GuestsHomeLove />
        <ExploreProperties />
        <TripPlanner />
        
      
        <div
          style={{
            width: "100%",
          }}
        >
          <NewsLetter />
        </div>
        <FAQsection type="hotel" />
      </Suspense>
    </WithHeaderFooter>
  );
}

export default Hotels;
