import { useEffect, useState } from "react";

export const useMediaQuery = (maxWidth = "(max-width: 600px)") => {
  const [matches, setMatches] = useState(
    () => window.matchMedia(maxWidth).matches
  );

  useEffect(() => {
    const mediaQueryList = window.matchMedia(maxWidth);

    const updateMatches = () => {
      setMatches(mediaQueryList.matches);
    };

    // Initial check
    updateMatches();

    // Attach listener for changes
    mediaQueryList.addListener(updateMatches);

    // Cleanup function
    return () => {
      mediaQueryList.removeListener(updateMatches);
    };
  }, [maxWidth]);

  return matches;
};
