import React, { useEffect, useMemo, useState } from "react";
import Stack from "react-bootstrap/Stack";
import Form from "react-bootstrap/Form";
import CheackGroup from "./CheackGroup";
import http from "../../Api";
import { MdOutlineWbSunny } from "react-icons/md";
import { IoPartlySunnyOutline } from "react-icons/io5";
import { CiCloudMoon } from "react-icons/ci";
import { TbMoonStars } from "react-icons/tb";
import moment from "moment";

const TicketFilter = ({
  setFlightData,
  flights = [],
  getAirLinesName,
  filterlength,
}) => {
  const [directonlycnt, setdirectonlycnt] = useState(0);
  const [onestop, setonestop] = useState(0);
  const [airlines, setAirlines] = useState([]);
  const [timerangedep, setTimerangedep] = useState(null);
  const [timerangearr, setTimerangearr] = useState(null);
  const [stopData, setstopData] = useState([]);
  const [stopandLineData, setstopandLineData] = useState([]);
  const params = window.location.search;
  const searchs = new URLSearchParams(params).get("data");
  useEffect(() => {
    getAirLinesName(airlines);
  }, [airlines]);
  useEffect(() => {
    setstopandLineData(flights);
    setstopData(flights);
  }, [flights]);

  useEffect(() => {
    let dirOnly = 0;
    let onstp = 0;
    flights.forEach((it) => {
      if (it.itineraries[0].segments.length === 1) dirOnly++;
      else if (it.itineraries[0].segments.length === 2) onstp++;
    });
    setdirectonlycnt(dirOnly);
    setonestop(onstp);
    if (flights.length > 0) getAirLines();
  }, [flights]);

  const filterbystop = (stopType) => {
    if (stopType === "Any") {
      setFlightData(flights);
      setstopData(flights);
    }
    if (stopType === "Direct only") {
      return setFlightData((prev) => {
        let fill = flights.filter(
          (it) => it.itineraries[0].segments.length === 1
        );
        setstopData(fill);
        return fill;
      });
    }
    if (stopType === "1 stop max") {
      return setFlightData((prev) => {
        let fill = flights.filter(
          (it) => it.itineraries[0].segments.length === 2
        );
        setstopData(fill);

        return fill;
      });
    }
  };

  const getAirLines = () => {
    http
      .get(
        "airlines-name/" +
          [...new Set(flights.map((it) => it.validatingAirlineCodes[0]))].join(
            ","
          )
      )
      .then((res) => {
        let data = flights.reduce((prev, curr) => {
          prev[curr.validatingAirlineCodes[0]] =
            (prev[curr.validatingAirlineCodes[0]] || 0) + 1;
          return prev;
        }, {});

        let arr = res.data.data.map((it) => {
          return { ...it, count: data[it.iataCode] };
        });
        setAirlines(arr);
      });
  };
  const [airlinescheck, setAirLinesChecked] = useState([]);

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;
    if (isChecked) {
      setAirLinesChecked((prevValues) => [...prevValues, value]);
    } else {
      setAirLinesChecked((prevValues) => prevValues.filter((v) => v !== value));
    }
  };
  useEffect(() => {
    if (airlinescheck.length === 0) {
      console.log("stopData", stopData);
      return setFlightData(stopData);
    }
    setFlightData((prev) => {
      let secondlayerdata = [...stopData].filter((it) =>
        airlinescheck.includes(it.validatingAirlineCodes[0])
      );
      setstopandLineData(secondlayerdata);
      return secondlayerdata;
    });
  }, [airlinescheck, stopData]);

  const getTimeRange = useMemo(
    () => (obj, name) => {
      if (name === "dep") {
        setTimerangedep(obj);
      } else if (name === "arr") {
        setTimerangearr(obj);
      }
    },
    []
  );

  useEffect(() => {
    setFlightData((prev) => {
      return [...stopandLineData].filter((it) => {
        let deptime = parseInt(
          moment(it?.itineraries[0]?.segments[0]?.departure?.at).format("HH")
        );
        let seg = it?.itineraries[0]?.segments[1]
          ? it?.itineraries[0]?.segments[1]
          : it?.itineraries[0]?.segments[0];
        let arrtime = parseInt(moment(seg.arrival?.at).format("HH"));
        console.log("deptime, arrtime", deptime, arrtime);
        return (
          (timerangedep
            ? deptime >= parseInt(timerangedep.start) &&
              deptime <= parseInt(timerangedep.end)
            : true) &&
          (timerangearr
            ? arrtime >= parseInt(timerangearr.start) &&
              arrtime <= parseInt(timerangearr.end)
            : true)
        );
      });
    });
  }, [timerangedep, timerangearr, flights]);

  return (
    <Stack gap={3}>
      <div className="p-2">
        <span style={{ fontSize: 20, fontWeight: 600 }}></span>
        <p>
          Showing {filterlength} of {flights.length} results
        </p>
      </div>

      <div className="p-2">
        <span style={{ fontSize: 20, fontWeight: 500 }}> Stops</span>
        <Stops
          callback={filterbystop}
          id={1}
          label={`Any`}
          count={flights.length}
          amount={10354.24}
        />
        <Stops
          callback={filterbystop}
          id={2}
          label={`Direct only`}
          count={directonlycnt}
          amount={133574.24}
        />
        <Stops
          callback={filterbystop}
          id={3}
          label={`1 stop max`}
          count={onestop}
          amount={11359.24}
        />
      </div>

      <div className="p-2">
        <Heading title="Airlines" />
        {airlines.map((it) => (
          <CheackGroup
            onChange={handleCheckboxChange}
            iatacode={it.iataCode}
            name="airlines"
            count={it.count}
            label={it.businessName}
          />
        ))}
      </div>

      <div className="">
        <Heading
          className
          title={`Departure From ${
            JSON.parse(searchs).originDestinations[0].from.label
          }`}
          fontSize={13}
        />
        <TimeCard
          getRange={getTimeRange}
          name={`dep`}
          selected={timerangedep?.id ?? -1}
        />
        <Heading
          title={`Arrival At ${
            JSON.parse(searchs).originDestinations[0].to.label
          }`}
          fontSize={13}
        />
        <TimeCard
          getRange={getTimeRange}
          name={`arr`}
          selected={timerangearr?.id ?? -1}
        />
      </div>

      <div>
        <Heading title="Duration" />

        <Form.Range onChange={(e) => console.log("e", e.target.value)} />
      </div>
    </Stack>
  );
};

export default TicketFilter;

const Stops = ({ label, count, amount, id, callback }) => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          {/* <Form> */}
          <Form.Check // prettier-ignore
            type={"radio"}
            id={`default-${id}`}
            label={label}
            value={label}
            name="stop"
            onChange={(e) => callback(e.target.value)}
          />
          {/* </Form> */}
          <p style={{ marginLeft: "1.5rem", color: "grey" }}>
            From INR {amount}{" "}
          </p>
        </div>

        <div>
          <span>{count}</span>
        </div>
      </div>
    </>
  );
};

const Heading = ({ title, fontSize = 20 }) => (
  <span style={{ fontSize, fontWeight: 500 }} className="my-5">
    {" "}
    {title}
  </span>
);

const TimeCard = ({ getRange, selected, name }) => (
  <div className="time-cards d-flex justify-content-between my-3">
    <div
      onClick={() => {
        if (selected === 0) return getRange(null, name);
        getRange(
          {
            id: 0,
            start: "06:00",
            end: "12:00",
          },
          name
        );
      }}
      className={`time-card ${selected === 0 && "active"}`}
    >
      <MdOutlineWbSunny />
      <small className="text-dark">6am -12pm</small>
    </div>
    <div
      onClick={() => {
        if (selected === 1) return getRange(null, name);
        getRange(
          {
            id: 1,
            start: "12:00",
            end: "18:00",
          },
          name
        );
      }}
      className={`time-card ${selected === 1 && "active"}`}
    >
      <IoPartlySunnyOutline />
      <small className="text-dark">12pm-6pm</small>
    </div>
    <div
      onClick={() => {
        if (selected === 2) return getRange(null, name);
        getRange(
          {
            id: 2,
            start: "18:00",
            end: "24:00",
          },
          name
        );
      }}
      className={`time-card ${selected === 2 && "active"}`}
    >
      <CiCloudMoon />
      <small className="text-dark">6pm - 12am</small>
    </div>
    <div
      onClick={() => {
        if (selected === 3) return getRange(null, name);
        getRange(
          {
            id: 3,
            start: "00:00",
            end: "06:00",
          },
          name
        );
      }}
      className={`time-card ${selected === 3 && "active"}`}
    >
      <TbMoonStars />
      <small className="text-dark">midnight</small>
    </div>
  </div>
);
