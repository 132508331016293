import React, { useEffect, useState } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
const PublicRoute = () => {
  const path = useLocation();
  const [auth, setAuth] = useState(reactLocalStorage.get("auth"));

  useEffect(() => {
    setAuth(reactLocalStorage.get("auth"));
  }, [path.pathname]);

  return auth ? <Navigate to={"/"} /> : <Outlet />;
};

export default PublicRoute;
