import React from "react";
import { currency_symbols } from "../checkout/Index";
import "./creditcard.css";
const PricingInfo = ({loading, res, ...rest }) => {
  return (
    <div className="col-12">
      <button {...rest} disabled={loading} className="btn btn-primary mb-3">
        <span className="ps-3">Confirm Booking</span>{" "}
        <span className="ps-3">
          {currency_symbols[res.converted.currencyCode]}{" "}
          {res?.converted?.monetaryAmount}
        </span>
        <span className="fas fa-arrow-right" />
      </button>
    </div>
  );
};

export default PricingInfo;
