import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import http from "../../Api";
import { MdFlightTakeoff } from "react-icons/md";
import { IoTime } from "react-icons/io5";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
const FlightNumberDropdown = ({
  flightNumber,
  setFlightNumber,
  setStartConnectedSegment,
}) => {
  const [segments, setSegments] = useState([]);
  useEffect(() => {
    getSegments();
  }, []);

  const getSegments = async () => {
    try {
      const { data } = await http.get("get-flight-by-userid", {
        withCredentials: true,
      });
      setSegments(data);
    } catch (error) {}
  };
  return (
    <Dropdown>
      <Dropdown.Toggle
        style={{ backgroundColor: "#5c636a00", color: "black" }}
        id="dropdown-basic"
      >
        {"Flight Number " + flightNumber ?? ""}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {segments.map(
          ({
            flightNumber,
            arrival_date,
            arrival_to,
            departure_from,
            departure_date,
          }) => (
            <Dropdown.Item
              onClick={() => {
                setFlightNumber(flightNumber);
                setStartConnectedSegment({
                  transportationType: "FLIGHT",
                  transportationNumber: flightNumber,
                  departure: {
                    iataCode: departure_from,
                    localDateTime: departure_date,
                  },
                  arrival: {
                    iataCode: arrival_to,
                    localDateTime: arrival_date,
                  },
                });
              }}
            >
              <Row
                className="p-1 m-1"
                style={{
                  boxShadow: `rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;`,
                }}
              >
                <Col xs={6}>Flight No : {flightNumber} </Col>
                <Col xs={2}>
                  {departure_from} <MdFlightTakeoff />
                  {arrival_to}
                </Col>
                <Col>
                  {" "}
                  <IoTime /> {moment(arrival_date).format(
                    "DD  MMM,hh:mm a"
                  )}{" "}
                  (arrival)
                </Col>
              </Row>{" "}
            </Dropdown.Item>
          )
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default FlightNumberDropdown;
