import React, { useEffect, useState } from "react";
import { useParams, useSearchParams, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import WithHeaderFooter from "../../components/WithHeaderFooter";
import { MdPlace } from "react-icons/md";

import HotelDetails from "../hotelresult/HotelDetails";
import InfoTab from "../hotelresult/InfoTab";

const Index = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { name } = useParams();
  console.log('useLocation()', useLocation())

  
  const {
    state: { placeDetails, reviews },
  } = useLocation();
  const { editorial_summary } = placeDetails;
  const [images, setImages] = useState([]);

  useEffect(() => {
    const imagesString = searchParams.get("images");
    const imagesArray = imagesString
      ? JSON.parse(decodeURIComponent(imagesString))
      : [];
    setImages(imagesArray);
  }, []);

  useEffect(() => {
    // getPlaceDetails();
  }, [searchParams]);

  return (
    <WithHeaderFooter>
      <Container fluid className="my-5">
        <Row>
          <Col sm={3}>
            <Row>
              <h3>
                <MdPlace />
                {name}
              </h3>
            </Row>{" "}
            <Row className="my-3">
              <small className="mx-2">{editorial_summary}</small>
              <InfoTab reviews={reviews} placeData={placeDetails} />
            </Row>
          </Col>
          <Col sm={9}>
            <HotelDetails images={images} />
          </Col>
        </Row>
      </Container>
    </WithHeaderFooter>
  );
};

export default Index;
