import { loadStripe } from "@stripe/stripe-js";
import http from ".";
import toast from "react-hot-toast";

export const payment = async ({
  currency,
  name,
  price,
  flightName,
  qty = 1,
  id,
}) => {
  try {
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

    const bookingData = [
      {
        currency,
        name,
        price,
        qty: 1,
        flightName,
      },
    ];

    const { data } = await http.post(
      "create-order-session",
      {
        bookingData,
        id,
      },
      { withCredentials: true }
    );
    const result = stripe.redirectToCheckout({
      sessionId: data.id,
    });
  } catch (error) {
    Promise.reject("Failed to Order")
   
  }
};

