import React, { useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Button } from "react-bootstrap";
import Picker from "../flight/Picker";
import DatePicker from "react-date-picker";
import moment from "moment";
const animatedComponents = makeAnimated();

const locationOptions = [
  { label: "New York", value: "NY" },
  { label: "Los Angeles", value: "LA" },
  { label: "Chicago", value: "CHI" },
  // Add more location options here
];

const LocationSelect = () => {
  const [selectedLocations, setSelectedLocations] = useState([]);

  const handleLocationChange = (selectedLocations) => {
    setSelectedLocations(selectedLocations);
  };
  const getDate = (date) => {
    console.log("date", date);
  };
  return (
    <div className="d-flex mt-4 justify-content-start align-items-center flex-wrap gap-2">
      <Select
        isMulti
        placeholder={"Where are you going?"}
        options={locationOptions}
        components={animatedComponents}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: "#f39c12",
            borderWidth: "3px",
            borderRadius: "6px",
            width: "300px",
            height: "50px",
          }),
        }}
        isSearchable
        value={selectedLocations}
        onChange={handleLocationChange}
      />
      {/* <OverlayTrigger trigger="click" placement="bottom" overlay={popoverClick}>
        <Button>Click</Button>
      </OverlayTrigger> */}
      <DatePicker
        className={"text-white date-p"}
        onChange={(date) => getDate([date])}
        value={moment()}
      />
      <Button
        variant="primary"
        style={{
          border: "3px solid #f39c12",
          height: "50px",
          width: "100px",
        }}
      >
        Search
      </Button>{" "}
    </div>
  );
};

export default LocationSelect;
