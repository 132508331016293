import React, { useEffect, useRef, useState } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import CustomNavbar from "../../components/Navbar/Navbar";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import http from "../../Api";
import toast from "react-hot-toast";
import FormTab from "./FormTab";
import SeatMap from "../flight/SeatMap";
import { BsLuggage } from "react-icons/bs";
import { CiDiscount1 } from "react-icons/ci";
import { MdAirlineSeatReclineExtra } from "react-icons/md";
import _ from "lodash";
import AnimatedNumbers from "react-animated-numbers";
import { payment } from "../../Api/payment";
import AddOn from "./AddOn.js";
import { ImCross } from "react-icons/im";
import Form from "react-bootstrap/Form";
export var currency_symbols = {
  USD: "$", // US Dollar
  EUR: "€", // Euro
  CRC: "₡", // Costa Rican Colón
  GBP: "£", // British Pound Sterling
  ILS: "₪", // Israeli New Sheqel
  INR: "₹", // Indian Rupee
  JPY: "¥", // Japanese Yen
  KRW: "₩", // South Korean Won
  NGN: "₦", // Nigerian Naira
  PHP: "₱", // Philippine Peso
  PLN: "zł", // Polish Zloty
  PYG: "₲", // Paraguayan Guarani
  THB: "฿", // Thai Baht
  UAH: "₴", // Ukrainian Hryvnia
  VND: "₫", // Vietnamese Dong
};
const Index = () => {
  const loc = useLocation();
  const navi = useNavigate();
  console.log("loc", loc);
  const [data] = useState(loc.state);
  const [travellers, setTravellers] = useState([]);
  const [pricing, setPricing] = useState();
  const [finalPrice, setFinalPrice] = useState();
  const [pricingres, setPricingres] = useState();
  const [loading, setLoading] = useState(false);
  const [currTab, setCurrTab] = useState(0);
  const [selectedSeatNo, setSelectedSeatNo] = useState([]);
  const [currPassengerIndx, setCurrPassengerIndx] = useState(0);
  const [included, setInCluded] = useState(null);
  const [couponCode, setCouponCode] = useState(null);
  const [originalPrice, setOriginalPrice] = useState(null);

  const [chargedBags, setChargedBags] = useState({
    qty: null,
    price: null,
    currency: null,
  });

  const couponDebounceRef = useRef(null);
  useEffect(() => {
    let flightDataWithSeatNumber = _.cloneDeep(data);
    // flightDataWithSeatNumber["additionalServices"] = null;
    console.log("flightDataWithSeatNumber", flightDataWithSeatNumber);
    selectedSeatNo.forEach((it, ind) => {
      flightDataWithSeatNumber.travelerPricings[
        currPassengerIndx
      ].fareDetailsBySegment[it.fId].additionalServices = {
        ...flightDataWithSeatNumber.travelerPricings[currPassengerIndx]
          .fareDetailsBySegment[it.fId].additionalServices,
        chargeableSeatNumber: it.no,
      };
    });
    if (chargedBags.qty !== null) {
      flightDataWithSeatNumber.travelerPricings[
        currPassengerIndx
      ].fareDetailsBySegment.forEach((it, ind) => {
        flightDataWithSeatNumber.travelerPricings[
          currPassengerIndx
        ].fareDetailsBySegment[ind].additionalServices = {
          ...flightDataWithSeatNumber.travelerPricings[currPassengerIndx]
            .fareDetailsBySegment[ind].additionalServices,
          chargeableCheckedBags: { quantity: chargedBags.qty },
        };
      });
    } else {
      flightDataWithSeatNumber.travelerPricings[
        currPassengerIndx
      ].fareDetailsBySegment.forEach((it, ind) => {
        if (
          flightDataWithSeatNumber.travelerPricings[
            currPassengerIndx
          ].fareDetailsBySegment[ind].hasOwnProperty("additionalServices")
        ) {
          delete flightDataWithSeatNumber.travelerPricings[currPassengerIndx]
            .fareDetailsBySegment[ind].additionalServices.chargeableCheckedBags;
        }
      });
    }

    if (chargedBags.qty === null) {
      getPriceData(flightDataWithSeatNumber, true);
    } else {
      getPriceData(flightDataWithSeatNumber, couponCode);
    }
  }, [selectedSeatNo, chargedBags, couponCode]);

  if (!data) return <Navigate to="/" />;

  const getPriceData = (flightdata, incl = false) => {
    toast.loading("pricing...");
    setLoading(true);
    http
      .post("get-pricing", {
        flightdata,
        incl,
      })
      .then((res) => {
        console.log("res", res);
        setInCluded(res.data.included);
        toast.dismiss();
        let flightoffer = res.data.data;

        if (res?.data?.warnings && res.data.warnings.length > 0) {
          res.data.warnings.forEach((warn) => {
            toast.error(warn.detail, {
              icon: "⚠️",
              iconTheme: "colored",
            });
          });
        }

        setPricing(flightoffer.flightOffers[0]);

        calculateDiscount(flightoffer?.flightOffers[0]?.price?.grandTotal);
        setPricingres(flightoffer);
      })
      .catch((err) => {
        console.log("err", err);
        toast.dismiss();

        toast.error(`Cant't get price Info . try again `);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setPassenger = (data, pid) => {
    const arr = [...travellers];
    arr[pid - 1] = data;
    setTravellers(arr);
  };

  const order = async () => {
    setLoading(true);
    let payload = {
      type: "flight-order",
      flightOffers: pricingres?.flightOffers,
      travelers: travellers,
      paymentStatus: "not paid",
    };
    http
      .post(
        "create-order",
        { data: payload, couponCode },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Order successfull");

        const flightName = [];
        res?.data?.orderDetails?.flightOffers.forEach((it) => {
          it.itineraries.forEach((itin) => {
            itin.segments.forEach((seg) => {
              flightName.push(seg.departure?.iataCode);
              flightName.push(seg.arrival?.iataCode);
            });
          });
        });

        const prodData = {
          currency: res?.data?.currency,
          name: res?.data?.bookingId,
          price: res?.data?.amount,
          flightName: flightName.join(" > "),
          id: res?.data?.orderDetails?.id,
        };
        toast.loading("Redirecting to Payment. Do Not Refresh!");
        payment(prodData)
          .catch((err) => {
            toast.error("Failed to Order");
            setTimeout(() => {
              navi("/bookings/flights");
            }, 1000);
          })
          .finally(() => {
            toast.dismiss();
          });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.msg);
        err?.response?.data?.description?.forEach((element) => {
          toast.error(element.detail);
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const travelercnt = (data) => {
    let child = 0;
    let adult = 0;
    data.forEach((it) => {
      if (it.travelerType === "ADULT") adult++;
      if (it.travelerType === "CHILD") child++;
    });
    return `${adult} ${adult > 1 ? "adults" : "adult"}  ${
      child > 0 ? child + (child > 1 ? "childs" : "child") : ""
    }`;
  };

  const getActivePassenger = (passengerIndex) => {
    setCurrPassengerIndx(passengerIndex);
  };

  const seatSelected = (seatNumber) => {
    let fId = currTab;
    const existingSeatIndex = selectedSeatNo.findIndex(
      (seat) => seat.fId === fId
    );

    if (existingSeatIndex === -1) {
      toast.loading(`Requesting  For Seat No. ${seatNumber}`);
      setSelectedSeatNo((prevState) => [...prevState, { fId, no: seatNumber }]);
    } else {
      if (selectedSeatNo[existingSeatIndex].no === seatNumber) {
        setSelectedSeatNo((prevState) =>
          prevState.filter((seat) => seat.fId !== fId)
        );
      } else {
        toast.loading(`Requesting  For Seat No. ${seatNumber}`);
        setSelectedSeatNo((prevState) => {
          const updatedSeats = [...prevState];
          updatedSeats[existingSeatIndex].no = seatNumber;
          return updatedSeats;
        });
      }
    }
  };

  const currentTab = (tabIndex) => {
    console.log("tabIndex", tabIndex);
    setCurrTab(tabIndex);
  };

  const calculateDiscount = async (price) => {
    if (!couponCode) return setFinalPrice(price);
    setOriginalPrice(price);
    toast.loading("applying coupon");
    try {
      const { data } = await http.get("get-discounted-price", {
        params: {
          price,
          couponCode,
        },
        withCredentials: true,
      });
      toast.dismiss();
      setFinalPrice(data.final_price);
    } catch (error) {
      setCouponCode(null);
      toast.dismiss();
      toast.error(error.response?.data.msg);
    }
  };

  const handleCoupon = (coupon) => {
    if (couponDebounceRef.current) clearTimeout(couponDebounceRef.current);
    couponDebounceRef.current = setTimeout(() => {
      setCouponCode(coupon);
    }, 800);
  };

  return (
    <>
      <CustomNavbar />{" "}
      <Container>
        <Row>
          <FormTab
            currentPassenger={getActivePassenger}
            passengers={loc.state.travelerPricings}
            setPassenger={setPassenger}
          />
          <Col className="py-5 mt-5" sm={4}>
            {pricing ? (
              <>
                <Row className="my-3">
                  <Col className="justify-content-start">
                    {" "}
                    <strong>
                      Ticket {travelercnt(loc.state.travelerPricings)}
                    </strong>
                  </Col>
                  <Col className="justify-content-end">
                    <strong>
                      {currency_symbols[pricing.price?.currency]}{" "}
                      {pricing.price?.base
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </strong>
                  </Col>
                </Row>
                <Row>
                  <Col className="justify-content-start">Base Fare </Col>
                  <Col className="justify-content-end">
                    {currency_symbols[pricing.price?.currency]}
                    {pricing.travelerPricings.reduce((acc, curr) => {
                      return acc + parseFloat(curr.price.base);
                    }, 0)}
                  </Col>
                </Row>
                <Row>
                  <Col className="justify-content-start">Taxes & Charges </Col>
                  <Col className="justify-content-end">
                    {currency_symbols[pricing.price?.currency]}
                    {pricing.travelerPricings.reduce((acc, curr) => {
                      return acc + parseFloat(curr.price.refundableTaxes);
                    }, 0)}
                  </Col>
                </Row>{" "}
                {pricing.price?.additionalServices?.map((service) => {
                  return (
                    <>
                      <Row>
                        {" "}
                        <Col className="justify-content-start">
                          {service.type === "SEATS"
                            ? service.type +
                              `(${selectedSeatNo
                                .map((it) => it.no)
                                .join(` / `)})`
                            : service.type}
                        </Col>{" "}
                        <Col className="justify-content-end">
                          {" "}
                          {currency_symbols[pricing.price?.currency]}
                          {service.amount}
                        </Col>
                      </Row>
                    </>
                  );
                })}
                <Row className="mt-3">
                  <Col
                    className="justify-content-start"
                    style={{ fontSize: 25, fontWeight: 900 }}
                  >
                    Total{" "}
                  </Col>
                  <Col
                    className="align-items-end  flex-column"
                    style={{ fontSize: 25, fontWeight: 900 }}
                  >
                    {" "}
                    <div
                      style={{
                        fontSize: "0.8rem",
                        color: "GrayText",
                        textDecoration: "line-through",
                      }}
                    >
                      {originalPrice !== finalPrice && originalPrice}
                    </div>
                    <div>
                      {currency_symbols[pricing.price?.currency]}
                      {finalPrice
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                  </Col>
                </Row>
                {/* // Children Must be In Proper Format . View AddOn Comp To see
                  Format */}
                <AddOn>
                  <Row>
                    <small>
                      <BsLuggage /> Addon Bags
                    </small>
                    {included ? (
                      <>
                        <Table striped hover>
                          <thead>
                            <tr>
                              <th>Qty</th>
                              <th>Charge</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.values(included.bags).map((bags) => (
                              <tr>
                                <td> {bags.quantity} </td>
                                <td>
                                  {" "}
                                  {currency_symbols[bags.price.currencyCode]}
                                  {bags.price.amount}
                                </td>
                                <td>
                                  <Button
                                    size="sm"
                                    variant="success"
                                    disabled={
                                      !bags.travelerIds.includes(
                                        `${currPassengerIndx + 1}`
                                      ) ||
                                      !bags.segmentIds.includes(
                                        `${pricing.itineraries[currPassengerIndx]?.segments[currTab].id}`
                                      ) ||
                                      loading
                                    }
                                    onClick={() => {
                                      setChargedBags({
                                        qty: bags.quantity,
                                        price: bags.price.amount,
                                        currency: bags.price.currencyCode,
                                      });
                                    }}
                                  >
                                    add {bags.quantity}
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </>
                    ) : chargedBags.qty !== null ? (
                      <Table striped hover>
                        <thead>
                          <tr>
                            <th>Qty</th>
                            <th>Charge</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td> {chargedBags.qty} </td>
                            <td>
                              {currency_symbols[chargedBags.currency]}
                              {chargedBags.price}
                            </td>
                            <td>
                              <button
                                style={{
                                  border: "none",
                                  background: "none",
                                }}
                                className="h-shadow"
                                onClick={() => {
                                  toast.loading("removing bags");
                                  setChargedBags({
                                    qty: null,
                                    price: null,
                                    currency: null,
                                  });
                                }}
                              >
                                <ImCross className="text-danger " />{" "}
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    ) : (
                      <small>No Addon Offer Found</small>
                    )}
                  </Row>
                  <Row>
                    <small>
                      <CiDiscount1 /> Add Coupon Code
                    </small>
                    <div>
                      <Form.Control
                        type="text"
                        placeholder="Enter  code"
                        onChange={(e) => {
                          handleCoupon(e.target.value);
                        }}
                        // value={couponCode ?? ""}
                      />
                      <Button
                        onClick={() => {
                          setCouponCode(null);
                        }}
                        className="m-2"
                        size="small"
                        variant="primary"
                        disabled={loading}
                      >
                        {couponCode ? "remove" : "apply"}
                      </Button>
                    </div>
                  </Row>
                  <Row>
                    <small>
                      <MdAirlineSeatReclineExtra /> Check & Choose Seat
                    </small>
                    <SeatMap
                      selectedSeatNo={selectedSeatNo}
                      currentTab={currentTab}
                      SeatSelected={seatSelected}
                      pIndex={currPassengerIndx}
                      flightData={data}
                    />
                  </Row>
                </AddOn>
                <Row>
                  <Col className="mt-3" style={{ flexDirection: "column" }}>
                    <Button
                      disabled={loading}
                      onClick={order}
                      variant="primary"
                      type="submit"
                    >
                      {loading ? (
                        <>
                          <div className="spinner-border" role="status"></div>
                        </>
                      ) : (
                        "Book"
                      )}
                    </Button>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <div className="my-3">
                  <p className="card-text placeholder-glow">
                    <span className="placeholder col-7" />
                    <span className="placeholder col-4" />
                    <span className="placeholder col-4" />
                    <span className="placeholder col-6" />
                    <span className="placeholder col-8" />
                  </p>
                  <a
                    href="#"
                    tabIndex={-1}
                    className=" my-1 btn btn-primary disabled placeholder col-12"
                  />
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
