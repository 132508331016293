import React, { useState } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  Dropdown,
  Button,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";
import { AiOutlineDown, AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import LocationSelect from "./LocationSelect";

const HeroSection = ({ title = true }) => {
  const [selected, setSelected] = useState();
  const heroBackgroundStyle = {
    background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://images.unsplash.com/photo-1506012787146-f92b2d7d6d96?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    height: "auto",
    padding: "100px 10px",
  };



  return (
    <div className="hero" style={heroBackgroundStyle}>
      <Container>
        {title && (
          <>
            <h2 className="text-white">
             lorem ipdum dil que
            </h2>

            <h6 className="text-white mb-3">
              Discover your next dream destination
            </h6>
          </>
        )}
      </Container>
      <Container>
        <LocationSelect />
      </Container>
    </div>
  );
};

export default HeroSection;
