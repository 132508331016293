import React, { useEffect, useRef, useState } from "react";
import EmailModal from "./EmailModal";
import { reactLocalStorage } from "reactjs-localstorage";
import http from "../../Api";
import OtpInput from "react-otp-input";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
const VerifyEmail = () => {
  const [email] = useState(
    reactLocalStorage.getObject("userData").userData.email
  );
  const [modalShow, setModalShow] = useState(true);
  const [seconds, setSeconds] = useState(60);
  const [isOtp, setIsOtp] = useState(false);
  const [counter, setCounter] = useState(false);
  const [resend, setResend] = useState(false);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const navi = useNavigate();

  const intervalId = useRef(null);
  useEffect(() => {
    if (counter) {
      intervalId.current = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId.current);
  }, [counter]);

  useEffect(() => {
    if (seconds === 0) {
      clearInterval(intervalId.current);
      setResend(false);
      setSeconds(null);
    }
  }, [seconds]);
  const submit = () => {
    setSeconds(null);
    setResend(true);

    setLoading(true);
    http
      .get("send-otp", { params: { email } })
      .then((res) => {
        toast.dismiss();

        if (res.status === 200)
          toast.success("An Otp send your Mail.Check Your Inbox");
        setIsOtp(true);
        setSeconds(60);
        setCounter(true);
      })
      .catch((err) => {
        toast.dismiss();
        if (typeof err.response?.data?.msg === "string") {
          return toast.error(err.response.data.msg);
        }
        toast.error("Something Went Wrong . Please Try Again ");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {}, []);

  const handlePaste = (event) => {
    const data = event.clipboardData.getData("text");
    setOtp(data);
  };
  useEffect(() => {
    if (otp.length < 6) return;
    clearInterval(intervalId.current);
    setLoading(true);
    setSeconds(null);
    http
      .get("verify-otp", {
        params: {
          email,
          otp,
        },
      })
      .then((res) => {
        let data = reactLocalStorage.getObject("userData").userData;
        data.isVerified = 1;
        setLoading(false);
        reactLocalStorage.setObject("userData", { userData: data });
        toast.success("Email verified successfully");
        navi(-1);
      })
      .catch((err) => {
        setOtp("");
        toast.dismiss();
        setResend(false);
        setSeconds(null);
        setLoading(false);
        if (typeof err.response.data?.err === "string")
          toast.error(err.response.data?.err);
        else toast.error("Email verification failed");
      })
      .finally(() => {
        setCounter(false);
        setLoading(false);
      });
  }, [otp]);
  useEffect(() => {
    if (loading) {
      toast.dismiss();
      toast.loading("loading...");
    }
  }, [loading]);

  return (
    <>
      {" "}
      {isOtp ? (
        <EmailModal
          btnText="resend otp"
          show={true}
          onHide={() => setModalShow(true)}
          submit={submit}
          btnDisabled={resend}
        >
          <OtpInput
            value={otp}
            numInputs={6}
            onChange={(e) => setOtp(e)}
            onPaste={handlePaste}
            containerStyle={{ justifyContent: "space-between" }}
            inputStyle={{ width: "2rem" }}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
          />
          {seconds === null ? null : <p>Resend opt in {seconds} seconds</p>}
        </EmailModal>
      ) : (
        <EmailModal
          closeBtn
          show={modalShow}
          onHide={() => setModalShow(true)}
          email={email}
          //   setEmail={setEmail}
          submit={submit}
          readOnly
        />
      )}
    </>
  );
};

export default VerifyEmail;
