import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import TicketLeftChild from "../../components/Ticket/TicketLeftChild";
import FlightDetailsModal from "./FlighDetailsModal";
import { useMediaQuery } from "../../hook/useMediaQuerry";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import { currency_symbols } from "../checkout/Index";
function Tickets({ data, airlinesName, animLoading }) {
  const [load, setLoad] = useState(false);
  const isMobile = useMediaQuery();
  let bags =
    data?.travelerPricings[0]?.fareDetailsBySegment[0]?.includedCheckedBags;

  useEffect(() => {
    AOS.init({
      duration: 300, // Set your desired duration
      easing: "ease-in-out",
    });
  }, []);
  useEffect(() => {
    AOS.refresh();
  }, [animLoading]);
  if (animLoading) return;
  return (
    <Container style={{ marginBottom: "2rem" }}>
      <Row>
        <Col style={{ alignItems: "center" }} className="Ticket-left" sm={8}>
          <Container>
            {data.itineraries.map((it, ind) => (
              <div data-aos="zoom-in" data-aos-offset="50">
                <TicketLeftChild
                  key={ind}
                  source={airlinesName[data.validatingAirlineCodes[0]]}
                  details={it}
                />
              </div>
            ))}
          </Container>
        </Col>
        <Col
          style={{
            justifyContent: "space-between",
            alignItems: isMobile ? "center" : "end",
          }}
          className="Ticket-right"
          sm={4}
        >
          {bags && (
            <p> Included Checkbags {bags?.weight ??""+ " " + bags?.weightUnit??""}</p>
          )}

          <div>
            <h6 className="text-center">
              {currency_symbols[data.price.currency]} {data.price.total}
            </h6>
            <p>Total price for all travellers</p>
          </div>
          <FlightDetailsModal data={data} />
        </Col>
      </Row>
    </Container>
  );
}

export default Tickets;
