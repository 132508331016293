import React, { useEffect, useState } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  Dropdown,
  Button,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";
import {AiOutlineDown,AiOutlinePlus,AiOutlineMinus} from "react-icons/ai"
import LocationSelect from "../flight/LocationSelect";
import LocationSelect2 from "./LocationSelect2";
import TextTransition, { presets } from 'react-text-transition';


const HotelsHero = () => {
  const TEXTS = ['Hotels', 'Apartments', 'Villas', 'Holiday homes'];
  const [index, setIndex] = useState(0);

   useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      800, // every 3 seconds
    );
    return () => clearInterval(intervalId);
  }, []);
  const heroBackgroundStyle = {
    background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://images.unsplash.com/photo-1584132967334-10e028bd69f7?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  position: "relative",
  height: "auto",
  padding:"100px 10px"
  };

  return (
    <div className="hero" style={heroBackgroundStyle}>
      <Container>
        <h2 className="text-white mb-4">Discover <span style={{
          borderBottom:"2px solid yellow",
          display:"inline"
        }}>
          <TextTransition springConfig={presets.wobbly}>{TEXTS[index % TEXTS.length]}</TextTransition>
          </span> for an exceptional stay.</h2>

        <h6 className="text-white">Search hotels, homes and much more</h6>

        
      </Container>
      <Container>
        <LocationSelect2 />
      </Container>
    </div>
  );
};

export default HotelsHero;
