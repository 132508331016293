import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const LoadingSkel = () => {
  return (
    <Container >
      <Row>
        <Col
          style={{
            alignItems: "center",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            borderRadius: 5,
          }}
          sm={8}
        >
          <p className="card-text placeholder-glow my-5">
            <div>
              <span className="placeholder col-6" />
              <span className="placeholder w-75" />
              <span className="placeholder" style={{ width: "25%" }} />
            </div>
          </p>{" "}
          <p className="my-5 card-text placeholder-glow">
            <div>
              <span className="placeholder col-6" />
              <span className="placeholder w-75" />
              <span className="placeholder" style={{ width: "25%" }} />
            </div>
          </p>
        </Col>
        <Col
          style={{ justifyContent: "space-between", alignItems: "start" }}
          className="Ticket-right"
          sm={4}
        >
          <div className="card" style={{ border: "none" }} aria-hidden="true">
            <div className="card-body">
              <h5 className="card-title placeholder-glow">
                <span className="placeholder col-6" />
              </h5>
              <p className="card-text placeholder-glow">
                <span className="placeholder col-7" />
                <span className="placeholder col-4" />
                <span className="placeholder col-4" />
                <span className="placeholder col-6" />
                <span className="placeholder col-8" />
              </p>
              <a
                href="#"
                tabIndex={-1}
                className="btn btn-primary disabled placeholder col-6"
              />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LoadingSkel;
