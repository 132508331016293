import React, { useEffect, useState } from "react";

import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Document from "./Document";
import cncode from "./Countrycode";
import BillingAdress from "../../components/form/BillingAdress";
import FlightNumberDropdown from "../carrental/FlightNumberDropdown";
import PaymentForm from "../carrental/PaymentForm";
const titles = ["MR", "MS"];
const defaultCC = cncode.findIndex((it) => it.name === "India");
const UserDetails = ({
  pId,
  setPassenger,
  dob = true,
  gender = true,
  label = "Passengers",
  billingAdress = false,
}) => {
  const datz = {
    id: pId,
    dateOfBirth: "",
    name: {
      firstName: "",
      lastName: "",
    },
    gender: "",
    contact: {
      emailAddress: "",
      phones: [
        {
          deviceType: "MOBILE",
          countryCallingCode: gender
            ? cncode[defaultCC].code
            : cncode[defaultCC].dial_code,
          number: "",
        },
      ],
    },
    billingAddress: {
      line: "",
      zip: "",
      countryCode: "",
      cityName: "",
    },
  };
  if (!gender) datz.name.title = titles[0];
  const [userData, setUserData] = useState(datz);

  useEffect(() => {
    setPassenger(userData, pId);
  }, [userData]);

  return (
    <Container>
      <Form className="checkout-form">
        <div className="form-border">
          <Row>
            <h5>
              {label} {pId}{" "}
            </h5>
          </Row>
          <Row>
            {!gender && (
              <Col md={2}>
                {" "}
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Title</Form.Label>
                  <Form.Select
                    style={{ height: 50 }}
                    onChange={(e) =>
                      setUserData((prev) => ({
                        ...prev,
                        name: {
                          ...prev.name,
                          title: e.target.value,
                        },
                      }))
                    }
                    aria-label="Default select example"
                  >
                    {titles.map((it, ind) => (
                      <option selected={ind === 0} key={it} value={it}>
                        {it}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            )}
            <Col sm={gender ? 6 : 5}>
              {" "}
              <Form.Group className="mb-3 " controlId="formBasicfName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter  Your First Name"
                  onChange={(e) =>
                    setUserData((prev) => ({
                      ...prev,
                      name: {
                        ...prev.name,
                        firstName: e.target.value,
                      },
                    }))
                  }
                />
              </Form.Group>
            </Col>
            <Col sm={gender ? 6 : 5}>
              <Form.Group className="mb-3" controlId="formBasiclName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  onChange={(e) =>
                    setUserData((prev) => ({
                      ...prev,
                      name: {
                        ...prev.name,
                        lastName: e.target.value,
                      },
                    }))
                  }
                  type="text"
                  placeholder="Enter  Your Last Name"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            {dob && (
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Date of Birth</Form.Label>
                  <Form.Control
                    onChange={(e) =>
                      setUserData((prev) => ({
                        ...prev,
                        dateOfBirth: e.target.value,
                      }))
                    }
                    type="date"
                  />
                </Form.Group>
              </Col>
            )}
            {gender && (
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Gender</Form.Label>
                  {["radio"].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                      <Form.Check
                        inline
                        label="male"
                        name="group1"
                        type={type}
                        id={`inline-${type}-1`}
                        onClick={(e) =>
                          setUserData((prev) => ({
                            ...prev,
                            gender: "MALE",
                          }))
                        }
                      />
                      <Form.Check
                        inline
                        label="female"
                        name="group1"
                        type={type}
                        id={`inline-${type}-2`}
                        onClick={(e) =>
                          setUserData((prev) => ({
                            ...prev,
                            gender: "FEMALE",
                          }))
                        }
                      />
                    </div>
                  ))}
                </Form.Group>
              </Col>
            )}
          </Row>
        </div>

        <div className="form-border">
          <Row>
            <h5>Contact Details </h5>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Phone Number</Form.Label>
                <Row>
                  <Col className="p-0" xs={4}>
                    {" "}
                    <Form.Select
                      style={{ height: 50, padding: 5 }}
                      onChange={(e) => {
                        console.log("e.target.value", e.target.value);
                        setUserData((prev) => ({
                          ...prev,
                          contact: {
                            ...prev.contact,
                            phones: [
                              {
                                ...prev.contact.phones[0],
                                countryCallingCode: e.target.value,
                              },
                            ],
                          },
                        }));
                      }}
                      aria-label="Default select example"
                    >
                      {cncode.map((it, ind) => (
                        <option
                          selected={ind === defaultCC}
                          key={it.name}
                          value={gender ? it.code : it.dial_code}
                        >
                          <small>
                            {it.flag} {it.dial_code}
                          </small>
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col>
                    <Form.Control
                      type="number"
                      placeholder="Enter Phone Number"
                      onChange={(e) =>
                        setUserData((prev) => ({
                          ...prev,
                          contact: {
                            ...prev.contact,
                            phones: [
                              {
                                ...prev.contact.phones[0],
                                number: e.target.value,
                              },
                            ],
                          },
                        }))
                      }
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  onChange={(e) =>
                    setUserData((prev) => ({
                      ...prev,
                      contact: {
                        ...prev.contact,

                        emailAddress: e.target.value,
                      },
                    }))
                  }
                  type="email"
                  placeholder="Enter email"
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
        {billingAdress && (
          <div className="form-border">
            <Row>
              <h5>Billing Adress </h5>
            </Row>
            <BillingAdress setBillingDetails={setUserData} />
          </div>
        )}
      </Form>
    </Container>
  );
};

export default UserDetails;
