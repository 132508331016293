import React, { useEffect, useState } from "react";
import WithHeaderFooter from "../../components/WithHeaderFooter";
import HeroSection from "./HeroSection";
import Faq from "./Faq";
import CarHireDest from "./ CarHireDest";
import Result from "./Result";
import { Container, Row, Col } from "react-bootstrap";
import http from "../../Api";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import FAQsection from "../flight/FAQsection";
const Index = () => {
  const loc = useLocation();
  const search = window.location.search;
  const query = new URLSearchParams(search);
  const [carResults, setCarResults] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (query.size === 0) return;
    let payload = JSON.parse(query.get("data"));
    const { airportName, from, to, ...rest } = payload;

    transferSearch(rest);
  }, [loc.search]);

  const transferSearch = (body) => {
    setLoading(true);
    http
      .post("search-transport", body)
      .then((result) => {
        setCarResults(result.data.data);
      })
      .catch((err) => {
        return toast.error(err.msg || "something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <WithHeaderFooter>
      <HeroSection query={query} />
      {query.size > 0 && (
        <Container>
          <Row>
            <Col md={4}>Filter Comp ....</Col>
            <Col md={8}>
              {loading ? (
                <h1>loading...</h1>
              ) : (
                carResults.map((it) => {
                  return <Result res={it} />;
                })
              )}
            </Col>
          </Row>
        </Container>
      )}

      <CarHireDest />
      <FAQsection type="transport" />
    </WithHeaderFooter>
  );
};

export default Index;
