import React, { useEffect, useState } from "react";
import "./seatmap.scss";
const Index = ({ selectedSeatNo, data, SeatSelected, pIndex, flightId }) => {
  const [mapData, setMapData] = useState([]);

  useEffect(() => {
    let arr = data.reduce((acc, curr) => {
      let key = `x${curr.coordinates.x}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push({ y: curr.coordinates.y, obj: curr });
      return acc;
    }, {});
    setMapData(Object.entries(arr));
  }, [data]);

  return (
    <>
      <div className="plane">
        {/* ... */}
        <ol className="cabin fuselage">
          {mapData.map((it) => (
            <li className={`row row--${it[0].replace("x", "")}`} key={it[0]}>
              <ol className="seats" type="A">
                {it[1].map((col) => {
                  const notAvl = !(
                    col.obj.travelerPricing[pIndex].seatAvailabilityStatus ===
                    "AVAILABLE"
                  );
                  // ? col.obj.characteristicsCodes.includes("A")
                  //   ? false
                  //   : true
                  // : true;
                  return (
                    <li
                      className={`seat ${
                        selectedSeatNo.some(
                          (obj) =>
                            obj["no"] === col.obj.number &&
                            obj["fId"] === flightId
                        ) && "active"
                      } ${notAvl ? "" : "available"} `}
                      key={col.obj.number}
                    >
                      <input
                        disabled={notAvl}
                        value={col.obj.number}
                        type="checkbox"
                        onClick={(e) => {
                          SeatSelected(e.target.value);
                        }}
                        id={col.obj.number}
                      />
                      <label htmlFor={col.obj.number}>{col.obj.number}</label>
                    </li>
                  );
                })}
              </ol>
            </li>
          ))}
        </ol>
        <div className="exit exit--back fuselage"></div>
      </div>
    </>
  );
};

export default Index;
