import { useState } from "react";
import moment from "moment";
import Offcanvas from "react-bootstrap/Offcanvas";
import http from "../../Api";
import { useEffect } from "react";
import toast from "react-hot-toast";
import StatusBoard from "./StatusBoard";

function FlightStatus({ name, datas, children, ticketStatus, ...props }) {
  const [show, setShow] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [load, setLoad] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const getStatus = async (flightData) => {
    return http
      .get(`/flight-status`, {
        params: {
          carrierCode: flightData.carrierCode,
          flightNumber: flightData.number,
          scheduledDepartureDate: moment(flightData.departure?.at).format(
            "YYYY-MM-DD"
          ),
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  };
  useEffect(() => {
    if (!show) return;
    console.log("datas", datas);
    const FlightsPromises = [];
    datas.forEach((element) => {
      element?.segments.forEach((segment) => {
        FlightsPromises.push(getStatus(segment));
      });
    });
    Promise.all(FlightsPromises)
      .then((results) => {
        setLoad(false);
        console.log("results", results);
        const stat = [];
        results.forEach((it) => {
          it.data.forEach((prom) => {
            prom.flightPoints.forEach((data) => stat.push(data));
          });
        });
        console.log("stat", stat);
        setStatuses(stat);
      })
      .catch((error) => {
        toast.error("Not Found");
        console.error("Error in one or more API calls:", error.message);
      });
  }, [show]);

  console.log("statuses", statuses);
  return (
    <>
      <span onClick={handleShow} className="me-2">
        {children}
      </span>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Body className="bg-dark">
          {load ? (
            <div
              className="spinner-border  text-light text-center"
              role="status"
            ></div>
          ) : (
            <StatusBoard ticketStatus={ticketStatus} rows={statuses} />
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default FlightStatus;
