import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { currency_symbols } from "../checkout/Index";
import toast from "react-hot-toast";
import http from "../../Api";
import { useNavigate } from "react-router-dom";
var flattenedObject = {};

function flatten(obj, prefix = "", ind = 1) {
  for (const key in obj) {
    if (typeof obj[key] === "object" && Object.keys(obj[key]).length > 0) {
      flatten(obj[key], `${prefix}${key}.`,ind);
    } else {
      flattenedObject[`${ind}${prefix}${key}`] = obj[key];
    }
  }
}

const CheckoutPriceCard = ({
  pricing,
  passengers,
  setLoading,
  loading,
  bookingData,
  hotelName,
}) => {
  const navi = useNavigate();
  const pay = () => {
    const valid = validate();
    if (valid.err) {
      return toast.error(valid.code);
    }
    book(bookingData);
  };
  console.log("hotelNassme", hotelName);
  const book = (data) => {
    setLoading(true);
    http
      .post(
        "hotel-booking",
        { data, pricing, hotelName },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Booking Successfull");
        setTimeout(() => {
          navi("/bookings/hotels");
        }, 1000);
      })
      .catch(({ response, message }) => {
        toast.error(message || response?.msg || "something went wrong");
      })
      .finally(() => setLoading(false));
  };
  const validate = () => {
    bookingData.guests.forEach((element,ind) => {
      flatten(element,"",ind);
    });
   

    const userdataValidate = Object.entries(flattenedObject).every(
      (it) => it[1] && it[1].toString().length > 0
    );
    console.log("userdataValidate", userdataValidate);
    if (!userdataValidate)
      return { err: true, code: "Enter All Neccessary Information" };
    flattenedObject = {};
    flatten(bookingData.payments[0]);

    const paymentDataValidate = Object.entries(flattenedObject).every(
      (it) => it[1] && it[1].toString().length > 0
    );
    console.log("paymentDataValidate", paymentDataValidate);
    if (!paymentDataValidate)
      return { err: true, code: "Enter neccessary Payment Data" };
    flattenedObject = {};
    return { err: false };
  };

  return (
    <Col className="py-5 my-2  mt-5 flex-column" style={{ width: "100%" }}>
      <Container>
        <Row>
          <Col className="justify-content-start">
            {" "}
            <strong>Ticket {`(${passengers.length} adult)`}</strong>
          </Col>
          <Col className="justify-content-end p-0">
            <strong>
              {currency_symbols[pricing.price?.currency]} {pricing.price?.total}
            </strong>
          </Col>
        </Row>
        <Row>
          <Col className="justify-content-start">Base Fare </Col>
          <Col className="justify-content-end p-0">
            {currency_symbols[pricing.price?.currency]}
            {pricing.price?.base}
          </Col>
        </Row>
        <Row>
          <Col className="justify-content-start">Taxes & Charges </Col>
          <Col className="justify-content-end">
            {pricing.price?.taxes?.map((it) => (
              <Row>
                {currency_symbols[it.currency]}
                {it.amount}
              </Row>
            ))}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col
            className="justify-content-start"
            style={{ fontSize: 25, fontWeight: 900 }}
          >
            Total{" "}
          </Col>
          <Col
            className="justify-content-end p-0"
            style={{ fontSize: 25, fontWeight: 900 }}
          >
            {" "}
            {currency_symbols[pricing.price?.currency]} {pricing.price?.total}{" "}
          </Col>
        </Row>
        <Row>
          <Button
            //   disabled={loading}
            onClick={() => {
              pay();
            }}
            variant="warning"
            type="submit"
          >
            {loading ? (
              <>
                <div className="spinner-border" role="status"></div>
              </>
            ) : (
              <>book</>
            )}
          </Button>
        </Row>
      </Container>
    </Col>
  );
};

export default CheckoutPriceCard;
