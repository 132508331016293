import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";

import { useMediaQuery } from "../../hook/useMediaQuerry";
import { ListHeader, ListBody } from "./ListData";

import ViewMore from "./ViewMore";


function OrderList({ data, callBack }) {
  const isMobileView = useMediaQuery();
  console.log("isMobileView", isMobileView);
  return (
    <ListGroup
      className={`${isMobileView ? "m-2" : "m-5"}`}
      style={{ fontSize: isMobileView && 7 }}
      as="ol"
    >
      {data?.map((it) => (
        <ListGroup.Item
          as="li"
          className="d-flex justify-content-between align-items-start"
        >
          {isMobileView ? (
            <ViewMore>
              <ListHeader callBack={callBack} it={it} />
              <ListBody callBack={callBack} it={it} />
            </ViewMore>
          ) : (
            <>
              <ListHeader callBack={callBack} it={it} />
            <ListBody callBack={callBack} it={it} />
            </>
          
          )}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}

export default OrderList;
