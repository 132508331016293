import React, { useState } from "react";
import toast from "react-hot-toast";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import country from "./country";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import http from "../../Api";
import { reactLocalStorage } from "reactjs-localstorage";
const baseData = {
  firstName: "",
  lastName: "",
  email: "",
  country: "",
  password: "",
};

const Register = () => {
  const [userData, setUserData] = useState(baseData);
  const [loading, setLoading] = useState(false);
  const navi = useNavigate();
  const apply = () => {
    console.log("userData", userData);
    const data = {};
    Object.entries(userData).forEach((it) => (data[it[0]] = it[1].trim()));

    if (data.firstName === "") return toast.error("Enter First Name");
    if (data.lastName === "") return toast.error("Enter Last Name");
    if (data.email === "") return toast.error("Enter Email Address");
    if (data.country === "") return toast.error("Enter Your Country");
    if (data.password === "") return toast.error("Enter Password");
    if (!/^\S+@\S+\.\S+$/.test(data.email))
      return toast.error("Enter a valid email");
    setLoading(true);
    http
      .post("sign-up", userData, { withCredentials: true })
      .then((res) => {
        reactLocalStorage.set("auth", true);
        reactLocalStorage.setObject("userData", res.data);
        toast.success("You Account Has Been Register");
        navi(-1);
      })
      .catch((err) => {
        console.log("err.response", err.response);
        toast.error(err.response.data.msg || err.response.data);
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <Container>
        <Row>
          <Col sm={7}>
            <img
              src="https://i.pinimg.com/736x/13/88/dc/1388dc2487cb62bb47607cf1809b29ad.jpg"
              className="img-fluid"
              alt="Sample image"
            />
          </Col>
          <Col sm={5}>
            {" "}
            <form>
              <Row>
                <Col className="p-1">
                  {" "}
                  <div className="form-outline mb-4">
                    <input
                      value={userData.firstName}
                      onChange={(e) =>
                        setUserData((prev) => {
                          return { ...prev, firstName: e.target.value };
                        })
                      }
                      type="text"
                      id="form1Example1"
                      className="form-control form-control-lg"
                      placeholder="First Name"
                    />
                  </div>
                </Col>
                <Col className="p-1">
                  {" "}
                  <div className="form-outline mb-4">
                    <input
                      value={userData.lastName}
                      onChange={(e) =>
                        setUserData((prev) => {
                          return { ...prev, lastName: e.target.value };
                        })
                      }
                      type="text"
                      id="form2Example2"
                      className="form-control form-control-lg"
                      placeholder="Last Name"
                    />
                  </div>
                </Col>
              </Row>

              {/* Country */}
              <div className="form-outline mb-4">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, country: e.target.value };
                    })
                  }
                >
                  <option value={""} selected>
                    Select Country
                  </option>
                  {country.map((it) => (
                    <option key={it.code} value={it.name}>
                      {it.name}
                    </option>
                  ))}
                </select>
              </div>
              {/* Email input */}
              <div className="form-outline mb-4">
                <input
                  value={userData.email}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, email: e.target.value };
                    })
                  }
                  type="email"
                  id="form3Example3"
                  className="form-control form-control-lg"
                  placeholder="Enter a valid email address"
                />
              </div>
              {/* Password input */}
              <div className="form-outline mb-3">
                <input
                  value={userData.password}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, password: e.target.value };
                    })
                  }
                  type="password"
                  id="form3Example4"
                  className="form-control form-control-lg"
                  placeholder="Enter password"
                />
              </div>
              <div className="d-flex justify-content-between align-items-center">
                {/* Checkbox */}
                <div className="form-check mb-0">
                  <input
                    className="form-check-input me-2"
                    type="checkbox"
                    defaultValue
                    id="form2Example3"
                  />
                  <label className="form-check-label" htmlFor="form2Example3">
                    Remember me
                  </label>
                </div>
              </div>
              <div className="text-center text-lg-start mt-4 pt-2">
                <button
                  type="button"
                  className="btn btn-primary btn-lg"
                  style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}
                  onClick={apply}
                >
                  {loading ? (
                    <div className="spinner-border" role="status"></div>
                  ) : (
                    "Sign Up"
                  )}
                  
                </button>
                <p className="small fw-bold mt-2 pt-1 mb-0">
                  Already have an account?{" "}
                  <Link to="/log-in" state={{ previousPath: "auth" }} className="link-danger">
                    Log in
                  </Link>
                </p>
              </div>
            </form>
          </Col>
        </Row>
        <Row className="text-center">
          <div className="text-dark mb-3 mb-md-0">
            Copyright © 2024. All rights reserved.
          </div>

          <div>
            <a href="#!" className="text-dark me-4">
              <i className="fab fa-facebook-f" />
            </a>
            <a href="#!" className="text-dark me-4">
              <i className="fab fa-twitter" />
            </a>
            <a href="#!" className="text-dark me-4">
              <i className="fab fa-google" />
            </a>
            <a href="#!" className="text-dark">
              <i className="fab fa-linkedin-in" />
            </a>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Register;
