import React from "react";

const HotelDetails = ({ images = [] }) => {
  const imgStyle = {
   
    objectFit: "cover",
   
  };

  return (
    <div className="row">
      {images.map((it, ind) => {
        if (ind % 2 !== 0) return;
        return (
          <div
            className={`col-lg-4  mb-4 mb-lg-0 ${ind === 0 && "col-md-12"} `}
          >
            <img
            style={imgStyle}
              src={images[ind]}
              className="w-100 shadow-1-strong rounded mb-4"
              alt="Boat on Calm Water"
            />
            <img
            style={imgStyle}
              src={images.length >= ind + 1 && images[ind + 1]}
              className="w-100 shadow-1-strong rounded mb-4"
              alt="Wintry Mountain Landscape"
            />
          </div>
        );
      })}
    </div>
  );
};

export default HotelDetails;
