import React from "react";
import { Container, Row } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import { cities } from "./City";
import DestCard from "./DestCard";
const countries = [
  "North America",
  "Europe",
  "Asia",
  "Africa",
  "Oceania",
  "Middle East",
];
const MoreDestination = () => {
  return (
    <Container>
      <Row className="text-center mb-1">
        <span style={{ fontWeight: "bold", margin: "1rem 2rem" }} className=" ">
          explore more destination
        </span>
        <span style={{ fontWeight: "lighter", marginLeft: "2rem" }}>
          Find things to do in cities around the world
        </span>
      </Row>
      <Row>
        <Tabs
          style={{ margin: "0rem" }}
          defaultActiveKey={countries[0]}
          id="uncontrolled-tab-example"
          className="mb-3"
          fill
        >
          {countries.map((it) => {
            return (
              <Tab eventKey={it} title={it}>
                <Container>
                  <Row>
                    {cities.map((it, ind) => {
                      return (
                        <>
                          <Col md={3} sm={4} xs={6}>
                            <DestCard
                              width={"100%"}
                              img={it.imageUrl}
                              title={it.ufiCity}
                              subtitle={it.productCount + " places to go"}
                            />
                          </Col>
                        </>
                      );
                    })}
                  </Row>
                </Container>
              </Tab>
            );
          })}
        </Tabs>
      </Row>
    </Container>
  );
};

export default MoreDestination;
