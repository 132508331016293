import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import DatePicker from "react-date-picker";
import { useState } from "react";
// import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/entry.nostyle";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import moment from "moment";

function Picker({ getDate, value, flightType }) {
  const [date, setDate] = useState([new Date(), new Date()]);

  const onChange = (date) => {
    setDate(date);
    getDate(date);
  };

  return (
    <div className="">
      {flightType === "round" ? (
        <DateRangePicker
          onChange={onChange}
          value={
            value.length < 2
              ? [...value, moment(value[0]).add(3, "day")]
              : value
          }
          className={"text-white date-p"}
          style={{
            calendar: {
              color: "white", 
            },
          }}
        />
      ) : (
        <DatePicker
          className={"text-white date-p"}
          onChange={(date) => getDate([date])}
          value={[value[0]]}
c customInput={<CustomInput />} // You need to create a custom input component
calendarContainer={({ children }) => <div style={{ color: 'white' }}>{children}</div>}
          // dayPickerProps={{
          //   styles: {
          //     color: 'white', // Set your desired text color
          //   },
          // }}
        />
      )}
    </div>
  );
}

export default Picker;

const CustomInput = ({ value, onClick }) => (
  <input
    type="text"
    value={value}
    onClick={onClick}
    readOnly={true}
    style={{ color: 'white' }} // Set your desired text color
  />
);