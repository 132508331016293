import React from "react";
import "./pricecard.css";
import Button from "react-bootstrap/Button";
import { currency_symbols } from "../checkout/Index";
import Basedetailviewdd from "./Basedetailviewdd";
import { useNavigate } from "react-router-dom";


const PriceCard = ({ price, adults, offerId,hotelName }) => {
  const navi = useNavigate();
  return (
    <div className="container">
    
        <div
          className="card_pr bg-c-blue order-card"
         
        >
          <div className="card-block">
            <h6 className="m-b-20">
              Total <small style={{ fontSize: 14 }}>({adults} adult)</small>{" "}
            </h6>
            <h2 className="text-right">
              <i className="fa fa-cart-plus f-left" />
              <span>
                {currency_symbols[price.currency]}
                {price?.total}
              </span>
            </h2>
            {price.taxes && (
              <p className="m-b-0">
                Tax
                <span className="f-right">
                  {price.taxes.map((it) => (
                    <div className="d-flex">
                      <div>
                        {currency_symbols[it.currency]}
                        {it.amount}
                      </div>
                    </div>
                  ))}
                </span>
              </p>
            )}
            <p className="m-b-0">
              Base
              <span className="f-right">
                <div className="d-flex">
                  <div>
                    {currency_symbols[price.currency]}
                    {price.base}
                  </div>
                  <Basedetailviewdd
                    currency={price.currency}
                    data={price?.variations?.changes}
                  />
                </div>
              </span>
            </p>
            <p className="m-b-0 d-flex flex-column">
              <Button
                onClick={() =>
                  navi("/hotel-checkout" + `?hotelOfferId=${offerId}&hotelName=${hotelName}`)
                }
                variant="outline-warning"
              >
                Book
              </Button>
            </p>
          </div>
        </div>
     
      {/* <div className="col-md-4 col-xl-3">
          <div className="card bg-c-green order-card">
            <div className="card-block">
              <h6 className="m-b-20">Orders Received</h6>
              <h2 className="text-right">
                <i className="fa fa-rocket f-left" />
                <span>486</span>
              </h2>
              <p className="m-b-0">
                Completed Orders<span className="f-right">351</span>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-xl-3">
          <div className="card bg-c-yellow order-card">
            <div className="card-block">
              <h6 className="m-b-20">Orders Received</h6>
              <h2 className="text-right">
                <i className="fa fa-refresh f-left" />
                <span>486</span>
              </h2>
              <p className="m-b-0">
                Completed Orders<span className="f-right">351</span>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-xl-3">
          <div className="card bg-c-pink order-card">
            <div className="card-block">
              <h6 className="m-b-20">Orders Received</h6>
              <h2 className="text-right">
                <i className="fa fa-credit-card f-left" />
                <span>486</span>
              </h2>
              <p className="m-b-0">
                Completed Orders<span className="f-right">351</span>
              </p>
            </div>
          </div>
        </div> */}
    </div>
  );
};

export default PriceCard;
