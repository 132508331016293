export const cities = [
  {
    continentId: 7,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/xphoto/720x405/72203688.jpg?k=882dfd9985340ffb2a8553b56580d2139455c38e310899b3949b6dff9a8590df&o=",
    ufi: -782831,
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/ae/dubai.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    url: {
      prefix: "attractions",
      city: "dubai",
      country: "ae",
      __typename: "AttractionLocationUrl",
    },
    productCount: 686,
    ufiCity: "Dubai",
  },
  {
    cityUrl:
      "https://booking.com/attractions/city/gb/london.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/xphoto/720x405/72204269.jpg?k=dda35f09d4db949aa6786bc25686bddd016cdd12a2603193e90df0d44a51a1ac&o=",
    ufi: -2601889,
    continentId: 6,
    productCount: 1590,
    ufiCity: "London",
    url: {
      __typename: "AttractionLocationUrl",
      country: "gb",
      city: "london",
      prefix: "attractions",
    },
  },
  {
    productCount: 280,
    ufiCity: "Istanbul",
    url: {
      prefix: "attractions",
      city: "istanbul",
      country: "tr",
      __typename: "AttractionLocationUrl",
    },
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/tr/istanbul.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    continentId: 6,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/977232.jpg?k=1d8370dbfb1bddbff0fc98c1fd877025ab2651d7f46bf92676023f3ef376c41b&o=",
    ufi: -755070,
  },
  {
    url: {
      city: "new-york",
      prefix: "attractions",
      country: "us",
      __typename: "AttractionLocationUrl",
    },
    ufiCity: "New York",
    productCount: 1160,
    continentId: 1,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/976784.jpg?k=717a6a83ea61edb06017bb8c9bd3d36511ec0e1aef59ac94235584d4fd1709cb&o=",
    ufi: 20088325,
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/us/new-york.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
  },
  {
    ufiCity: "Las Vegas",
    productCount: 377,
    url: {
      prefix: "attractions",
      city: "las-vegas",
      country: "us",
      __typename: "AttractionLocationUrl",
    },
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/us/las-vegas.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    continentId: 1,
    ufi: 20079110,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/977068.jpg?k=60e8c40ecdd20063f9c429d6e3837cb35f972446db199ddc23a67f28a01c6c68&o=",
  },
  {
    url: {
      country: "fr",
      city: "paris",
      prefix: "attractions",
      __typename: "AttractionLocationUrl",
    },
    ufiCity: "Paris",
    productCount: 1812,
    ufi: -1456928,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/xphoto/720x405/72204347.jpg?k=2028e72e4ea4eb18da986b8a60fd841f65fe0575db5012d723233b4427939b4a&o=",
    continentId: 6,
    cityUrl:
      "https://booking.com/attractions/city/fr/paris.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
  },
  {
    url: {
      country: "de",
      city: "hamburg",
      prefix: "attractions",
      __typename: "AttractionLocationUrl",
    },
    ufiCity: "Hamburg",
    productCount: 136,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/977037.jpg?k=d0690d167e3368fd984e6e0f68a5befa8e73ac33e52c64655419dfa6b422ad32&o=",
    ufi: -1785434,
    continentId: 6,
    cityUrl:
      "https://booking.com/attractions/city/de/hamburg.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
  },
  {
    ufiCity: "Key West",
    productCount: 115,
    url: {
      prefix: "attractions",
      city: "key-west",
      country: "us",
      __typename: "AttractionLocationUrl",
    },
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/us/key-west.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    continentId: 1,
    ufi: 20022831,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/689606.jpg?k=17b5ce4f5f1a09ff065b22dadf33a5983a9fe692c36be28240abbf2681b083a7&o=",
  },
  {
    url: {
      prefix: "attractions",
      city: "san-diego",
      country: "us",
      __typename: "AttractionLocationUrl",
    },
    ufiCity: "San Diego",
    productCount: 132,
    continentId: 1,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/976722.jpg?k=913ea3edbe998126d628492a999328bc0edac37023abeeec51744253c85bda5c&o=",
    ufi: 20015725,
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/us/san-diego.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
  },
  {
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/nl/amsterdam.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    continentId: 6,
    ufi: -2140479,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/xphoto/720x405/71365537.jpg?k=aab55a712d088602738a94f84e75bcf9954b5b6208343eef1e9f2f2a2a75b75e&o=",
    productCount: 710,
    ufiCity: "Amsterdam",
    url: {
      country: "nl",
      city: "amsterdam",
      prefix: "attractions",
      __typename: "AttractionLocationUrl",
    },
  },
  {
    ufiCity: "Lisbon",
    productCount: 450,
    url: {
      __typename: "AttractionLocationUrl",
      prefix: "attractions",
      city: "lisbon",
      country: "pt",
    },
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/pt/lisbon.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    continentId: 6,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/977233.jpg?k=63950c08afe8dc52194d609a60de94848aec8e88475798f58df35c5cc655129c&o=",
    ufi: -2167973,
  },
  {
    ufiCity: "Rome",
    productCount: 926,
    url: {
      country: "it",
      city: "rome",
      prefix: "attractions",
      __typename: "AttractionLocationUrl",
    },
    cityUrl:
      "https://booking.com/attractions/city/it/rome.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    ufi: -126693,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/xphoto/720x405/72203988.jpg?k=28ea5ce3cfe20aa8732059ec8cadede64c0964848fc0f436594ba04c4939d0d9&o=",
    continentId: 6,
  },
  {
    cityUrl:
      "https://booking.com/attractions/city/gr/athens.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    ufi: -814876,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/971376.jpg?k=50407f01dd99763f769e4b109453e912511a522d6ee5c9ffc7ef0674e44b4e27&o=",
    continentId: 6,
    ufiCity: "Athens",
    productCount: 257,
    url: {
      city: "athens",
      prefix: "attractions",
      country: "gr",
      __typename: "AttractionLocationUrl",
    },
  },
  {
    url: {
      __typename: "AttractionLocationUrl",
      city: "berlin",
      prefix: "attractions",
      country: "de",
    },
    productCount: 506,
    ufiCity: "Berlin",
    continentId: 6,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/xphoto/720x405/72204120.jpg?k=111c39b79aac9a364b9a993a86890d94abdb83916c15422c95151fa8b2391273&o=",
    ufi: -1746443,
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/de/berlin.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
  },
  {
    ufiCity: "Barcelona",
    productCount: 1119,
    url: {
      __typename: "AttractionLocationUrl",
      prefix: "attractions",
      city: "barcelona",
      country: "es",
    },
    cityUrl:
      "https://booking.com/attractions/city/es/barcelona.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    ufi: -372490,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/xphoto/720x405/72203920.jpg?k=3ac5c879aed7f63f8cdd5cff59e76ff9c4335c86b783aa6a32e2d3ecdc6a7fe9&o=",
    continentId: 6,
  },
  {
    url: {
      __typename: "AttractionLocationUrl",
      prefix: "attractions",
      city: "venice",
      country: "it",
    },
    productCount: 294,
    ufiCity: "Venice",
    continentId: 6,
    ufi: -132007,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/626991.jpg?k=2e39cf151988e12f16bff255da7e0f2900498f12070771d0183499504775614d&o=",
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/it/venice.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
  },
  {
    continentId: 5,
    ufi: -1217214,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/674552.jpg?k=d6b82405173e3e4fd614a5c11cbd4539375edf8f22550d260a941d050d60fac6&o=",
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/za/cape-town.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    url: {
      country: "za",
      prefix: "attractions",
      city: "cape-town",
      __typename: "AttractionLocationUrl",
    },
    ufiCity: "Cape Town",
    productCount: 49,
  },
  {
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/au/gold-coast.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    continentId: 9,
    ufi: -1575736,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/971406.jpg?k=b2f2045c0da2dbe4f94fcb0619a3ce00f002ad4e8eea927adfa404a3b1b04b7a&o=",
    productCount: 91,
    ufiCity: "Gold Coast",
    url: {
      country: "au",
      city: "gold-coast",
      prefix: "attractions",
      __typename: "AttractionLocationUrl",
    },
  },
  {
    ufiCity: "Málaga",
    productCount: 147,
    url: {
      __typename: "AttractionLocationUrl",
      city: "malaga",
      prefix: "attractions",
      country: "es",
    },
    cityUrl:
      "https://booking.com/attractions/city/es/malaga.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/645573.jpg?k=1c5dc57affb79a3639450bd342af1f690edb00bc1feb791f0c96b44ddb1ab1d8&o=",
    ufi: -390787,
    continentId: 6,
  },
  {
    productCount: 245,
    ufiCity: "Miami",
    url: {
      __typename: "AttractionLocationUrl",
      city: "miami",
      prefix: "attractions",
      country: "us",
    },
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/us/miami.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    continentId: 1,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/689586.jpg?k=970c12bc9abce21e6043c5ad0ba8bedaf27b105be29277b0215359a07f8192e3&o=",
    ufi: 20023181,
  },
  {
    ufi: -1995499,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/xphoto/720x405/72203757.jpg?k=9bf4f4950e983ccc08599179cfd5b00ca5f0f0926c31791b6fdcaf5c98b157da&o=",
    continentId: 6,
    cityUrl:
      "https://booking.com/attractions/city/at/vienna.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    url: {
      __typename: "AttractionLocationUrl",
      city: "vienna",
      prefix: "attractions",
      country: "at",
    },
    ufiCity: "Vienna",
    productCount: 226,
  },
  {
    productCount: 201,
    ufiCity: "Porto",
    url: {
      __typename: "AttractionLocationUrl",
      country: "pt",
      city: "porto",
      prefix: "attractions",
    },
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/pt/porto.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    continentId: 6,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/971988.jpg?k=3307f9d2767ddb45c8700e64be47ca6672c12ecba384b93abc2e6ef0d27abcc7&o=",
    ufi: -2173088,
  },
  {
    continentId: 6,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/685794.jpg?k=770497bb53a16f5147bcaaa237590544b006a3b357c8defe13f86c1c3c1c5264&o=",
    ufi: -2524279,
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/se/stockholm.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    url: {
      country: "se",
      prefix: "attractions",
      city: "stockholm",
      __typename: "AttractionLocationUrl",
    },
    productCount: 110,
    ufiCity: "Stockholm",
  },
  {
    url: {
      country: "mx",
      city: "mexico",
      prefix: "attractions",
      __typename: "AttractionLocationUrl",
    },
    productCount: 178,
    ufiCity: "Mexico City",
    continentId: 1,
    ufi: -1658079,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/682586.jpg?k=a9a574f667a643f29efbe74c42171e07a97f6ae83f752a404ff74d94f5577538&o=",
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/mx/mexico.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
  },
  {
    ufiCity: "New Orleans",
    productCount: 303,
    url: {
      __typename: "AttractionLocationUrl",
      country: "us",
      prefix: "attractions",
      city: "new-orleans",
    },
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/us/new-orleans.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    continentId: 1,
    ufi: 20050264,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/976882.jpg?k=52665926e3d712cefc8b7b76938964551a3f7d81dd66600bf93a5ee238e5e90c&o=",
  },
  {
    ufi: -1451964,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/348716.jpg?k=78ed50dc24ec4105225059a673a47bb1abda69ea403abfc8e32490c014a5d239&o=",
    continentId: 6,
    cityUrl:
      "https://booking.com/attractions/city/mc/monaco.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    url: {
      __typename: "AttractionLocationUrl",
      city: "monaco",
      prefix: "attractions",
      country: "mc",
    },
    ufiCity: "Monte Carlo",
    productCount: 5,
  },
  {
    continentId: 6,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/971356.jpg?k=bdef546ebb2e168e31b85186b72bc09874bc8960cd35d4c909dd325d53c3e8ca&o=",
    ufi: -390625,
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/es/madrid.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    url: {
      __typename: "AttractionLocationUrl",
      prefix: "attractions",
      city: "madrid",
      country: "es",
    },
    productCount: 752,
    ufiCity: "Madrid",
  },
  {
    url: {
      country: "pt",
      prefix: "attractions",
      city: "armacao-de-pera",
      __typename: "AttractionLocationUrl",
    },
    ufiCity: "Armacao de Pera",
    productCount: 202,
    continentId: 6,
    ufi: -2158624,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/685101.jpg?k=eb80793d434f68f0c0452ccb5f209f07a0c85c08f4eb34c4cd902bd778cbe032&o=",
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/pt/armacao-de-pera.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
  },
  {
    productCount: 216,
    ufiCity: "Seville",
    url: {
      __typename: "AttractionLocationUrl",
      country: "es",
      city: "sevilla",
      prefix: "attractions",
    },
    cityUrl:
      "https://booking.com/attractions/city/es/sevilla.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    ufi: -402849,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/645979.jpg?k=875ad7054c79913698aa188c90cb705256b43ebec865e596f98cb9a00b57868b&o=",
    continentId: 6,
  },
  {
    ufi: -510625,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/977210.jpg?k=c3032483b621644fb0a3bb84f9b84111dd5fb204cfbfeacd0f29c5e95f4a8112&o=",
    continentId: 6,
    cityUrl:
      "https://booking.com/attractions/city/pl/krakow.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    url: {
      __typename: "AttractionLocationUrl",
      country: "pl",
      city: "krakow",
      prefix: "attractions",
    },
    productCount: 167,
    ufiCity: "Kraków",
  },
  {
    continentId: 6,
    ufi: -122902,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/641091.jpg?k=6b4c99444b439d2ce4dbf5a479674965a4f196f4cab39bf7dd34e214f1d30b83&o=",
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/it/naples.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    url: {
      country: "it",
      city: "naples",
      prefix: "attractions",
      __typename: "AttractionLocationUrl",
    },
    ufiCity: "Naples",
    productCount: 316,
  },
  {
    ufi: -378765,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/971913.jpg?k=4fb9a4a41b53b5a6e0764e4494290fb3170a60d1850e332e7f1b4d430655085e&o=",
    continentId: 6,
    cityUrl:
      "https://booking.com/attractions/city/es/cordoba.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    url: {
      country: "es",
      city: "cordoba",
      prefix: "attractions",
      __typename: "AttractionLocationUrl",
    },
    productCount: 70,
    ufiCity: "Cordoba",
  },
  {
    url: {
      __typename: "AttractionLocationUrl",
      prefix: "attractions",
      city: "milan",
      country: "it",
    },
    productCount: 197,
    ufiCity: "Milan",
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/619659.jpg?k=ec58465fcfb01d330f07c63696f1ed0508d5ec7d1e7c900efed841c508828146&o=",
    ufi: -121726,
    continentId: 6,
    cityUrl:
      "https://booking.com/attractions/city/it/milan.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
  },
  {
    continentId: 9,
    ufi: -1563537,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/682193.jpg?k=98c95f614e901f685c378016a7cec05b198f23452928ddc27dd03354ea8d2706&o=",
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/au/cairns.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    url: {
      __typename: "AttractionLocationUrl",
      country: "au",
      city: "cairns",
      prefix: "attractions",
    },
    productCount: 91,
    ufiCity: "Cairns",
  },
  {
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/pt/faro.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    continentId: 6,
    ufi: -2165075,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/971589.jpg?k=7b66e8c47dc672c4405b7b452f20ece440d3ed6b4a8289ce9321e9f88365bae5&o=",
    ufiCity: "Faro",
    productCount: 114,
    url: {
      __typename: "AttractionLocationUrl",
      city: "faro",
      prefix: "attractions",
      country: "pt",
    },
  },
  {
    continentId: 1,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/976919.jpg?k=b4d2dd3f87340b547a0e1aa9fc7e89b47ebe9539086c7f5f4e637e5e2137be7c&o=",
    ufi: 20023488,
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/us/orlando.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    url: {
      __typename: "AttractionLocationUrl",
      country: "us",
      prefix: "attractions",
      city: "orlando",
    },
    ufiCity: "Orlando",
    productCount: 154,
  },
  {
    url: {
      __typename: "AttractionLocationUrl",
      country: "gb",
      city: "liverpool",
      prefix: "attractions",
    },
    productCount: 79,
    ufiCity: "Liverpool",
    ufi: -2601422,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/976973.jpg?k=136eb573320ef7ab6cccb8633cd0402da9f4a151080b5a19b12d2bb9e771fe9d&o=",
    continentId: 6,
    cityUrl:
      "https://booking.com/attractions/city/gb/liverpool.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
  },
  {
    continentId: 6,
    ufi: -130938,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/643451.jpg?k=7c0474f6a73cf464fd49da324eae6b960bbd72a05ea7b380a86c79d39da613d6&o=",
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/it/torino.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    url: {
      __typename: "AttractionLocationUrl",
      prefix: "attractions",
      city: "torino",
      country: "it",
    },
    ufiCity: "Turin",
    productCount: 39,
  },
  {
    ufiCity: "Munich",
    productCount: 118,
    url: {
      country: "de",
      prefix: "attractions",
      city: "munich",
      __typename: "AttractionLocationUrl",
    },
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/de/munich.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    continentId: 6,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/977007.jpg?k=ccacafe1f346e6a312605bf6f5fae2b97620a5269a6d004690f96709bc24d22c&o=",
    ufi: -1829149,
  },
  {
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/619612.jpg?k=47805d7e8e1e157ca53eb51b60de77389765e795cd6329b0b676dc9ce1e3592f&o=",
    ufi: -117543,
    continentId: 6,
    cityUrl:
      "https://booking.com/attractions/city/it/florence.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    url: {
      country: "it",
      prefix: "attractions",
      city: "florence",
      __typename: "AttractionLocationUrl",
    },
    ufiCity: "Florence",
    productCount: 427,
  },
  {
    ufiCity: "Marrakech",
    productCount: 442,
    url: {
      __typename: "AttractionLocationUrl",
      country: "ma",
      prefix: "attractions",
      city: "marrakech",
    },
    cityUrl:
      "https://booking.com/attractions/city/ma/marrakech.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/579743.jpg?k=4757ea973e597d2b1642c02c88ff31aa0b8366f5f118f0326f06ddd3cde8d87c&o=",
    ufi: -38833,
    continentId: 5,
  },
  {
    url: {
      city: "bologna",
      prefix: "attractions",
      country: "it",
      __typename: "AttractionLocationUrl",
    },
    ufiCity: "Bologna",
    productCount: 111,
    ufi: -111742,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/972657.jpg?k=de2952320067ff56cd53d4e7bd1bb6fa8f3b1416eb9f27b219c77a0a86e5f018&o=",
    continentId: 6,
    cityUrl:
      "https://booking.com/attractions/city/it/bologna.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
  },
  {
    productCount: 16,
    ufiCity: "Gothenburg",
    url: {
      city: "goteborg",
      prefix: "attractions",
      country: "se",
      __typename: "AttractionLocationUrl",
    },
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/se/goteborg.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    continentId: 6,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/685812.jpg?k=cc7f332cf1196cae2f36d233ec6601f1e69423a7dd79f9d0e7c9634d70986ff9&o=",
    ufi: -2482986,
  },
  {
    ufi: 20061717,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/976704.jpg?k=bc9e0ddd4556e32ea1bb61a0e5ebca7e6f4e76ff5b6c4f466cb9dad21092996d&o=",
    continentId: 1,
    cityUrl:
      "https://booking.com/attractions/city/us/boston.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    url: {
      __typename: "AttractionLocationUrl",
      country: "us",
      prefix: "attractions",
      city: "boston",
    },
    productCount: 156,
    ufiCity: "Boston",
  },
  {
    ufiCity: "Budapest",
    productCount: 502,
    url: {
      country: "hu",
      prefix: "attractions",
      city: "budapest",
      __typename: "AttractionLocationUrl",
    },
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/hu/budapest.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    continentId: 6,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/977217.jpg?k=e7126a320f526bdd201f8855a6ad06ecd5297d57869986aebd7890c78a1f1a06&o=",
    ufi: -850553,
  },
  {
    url: {
      prefix: "attractions",
      city: "granada",
      country: "es",
      __typename: "AttractionLocationUrl",
    },
    ufiCity: "Granada",
    productCount: 95,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/645290.jpg?k=1c28f4eba0ce561379ec3a5087e7223c844bc1ebdb5ecdea61cf49668aef08ce&o=",
    ufi: -384328,
    continentId: 6,
    cityUrl:
      "https://booking.com/attractions/city/es/granada.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
  },
  {
    url: {
      __typename: "AttractionLocationUrl",
      city: "san-francisco",
      prefix: "attractions",
      country: "us",
    },
    productCount: 267,
    ufiCity: "San Francisco",
    continentId: 1,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/976715.jpg?k=874476db313c6a9244bbce7420167e944ae5a4b5478e2c46d90dace257ed4599&o=",
    ufi: 20015732,
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/us/san-francisco.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
  },
  {
    ufiCity: "Vancouver",
    productCount: 32,
    url: {
      city: "vancouver",
      prefix: "attractions",
      country: "ca",
      __typename: "AttractionLocationUrl",
    },
    cityUrl:
      "https://booking.com/attractions/city/ca/vancouver.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/653284.jpg?k=d13763f9e3d479235428b7cead615a81cbb2d1f5459f2f9effa1bc59ae56eeb3&o=",
    ufi: -575268,
    continentId: 1,
  },
  {
    cityUrl:
      "https://booking.com/attractions/city/au/sydney.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/682540.jpg?k=fc3655b3d7c5c4fd712aa84739e16d04fe7c68e7c1133fa4f6a7511d21fc50d7&o=",
    ufi: -1603135,
    continentId: 9,
    productCount: 175,
    ufiCity: "Sydney",
    url: {
      __typename: "AttractionLocationUrl",
      country: "au",
      prefix: "attractions",
      city: "sydney",
    },
  },
  {
    ufiCity: "Marseille",
    productCount: 74,
    url: {
      __typename: "AttractionLocationUrl",
      prefix: "attractions",
      city: "marseille",
      country: "fr",
    },
    cityUrl:
      "https://booking.com/attractions/city/fr/marseille.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    ufi: -1449947,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/977176.jpg?k=47343653b5a4669020b55848a5a7dd7fdbf019b5d351efe5fd6584e03f2c99f1&o=",
    continentId: 6,
  },
  {
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/cz/prague.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    continentId: 6,
    ufi: -553173,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/xphoto/720x405/72204076.jpg?k=b0eb04ff512e2e41e6cc1718c0478f009cff711e071b95dbd0486c77a380b672&o=",
    ufiCity: "Prague",
    productCount: 324,
    url: {
      country: "cz",
      city: "prague",
      prefix: "attractions",
      __typename: "AttractionLocationUrl",
    },
  },
  {
    continentId: 6,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/973060.jpg?k=5c98665e08cb26b3a808a1358f54df0faf046a73b2cc04e38d54ff35445a20c8&o=",
    ufi: -132092,
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/it/verona.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    url: {
      __typename: "AttractionLocationUrl",
      city: "verona",
      prefix: "attractions",
      country: "it",
    },
    productCount: 92,
    ufiCity: "Verona",
  },
  {
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/si/ljubljana.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    continentId: 6,
    ufi: -87271,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/638117.jpg?k=e820e95bde49db620302c24b0da19af4a4f114d2498696f561239694a4384969&o=",
    ufiCity: "Ljubljana",
    productCount: 89,
    url: {
      __typename: "AttractionLocationUrl",
      country: "si",
      prefix: "attractions",
      city: "ljubljana",
    },
  },
  {
    continentId: 6,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/684030.jpg?k=f8ab37cd8e4b0674d0e71f901c92d3388b80d2143aded80de75de187a6d90edc&o=",
    ufi: -1981445,
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/at/innsbruck.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    url: {
      country: "at",
      prefix: "attractions",
      city: "innsbruck",
      __typename: "AttractionLocationUrl",
    },
    productCount: 32,
    ufiCity: "Innsbruck",
  },
  {
    productCount: 31,
    ufiCity: "Tromso",
    url: {
      __typename: "AttractionLocationUrl",
      prefix: "attractions",
      city: "tromso",
      country: "no",
    },
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/no/tromso.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    continentId: 6,
    ufi: -284406,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/644312.jpg?k=3cad7bb554fa30e112edf5d529e4114c189a4888cbc1e2519a1560d50ea6ab63&o=",
  },
  {
    continentId: 5,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/674549.jpg?k=ba96c4ef4592f26cb0f597339a240c4f86476b9ad846a48c23066e5145d4715e&o=",
    ufi: -1224926,
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/za/durban.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    url: {
      city: "durban",
      prefix: "attractions",
      country: "za",
      __typename: "AttractionLocationUrl",
    },
    ufiCity: "Durban",
    productCount: 7,
  },
  {
    ufiCity: "Valencia",
    productCount: 97,
    url: {
      __typename: "AttractionLocationUrl",
      country: "es",
      city: "valencia",
      prefix: "attractions",
    },
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/es/valencia.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    continentId: 6,
    ufi: -406131,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/977198.jpg?k=f9c304cc9a0868dfcfb582fd656481c1e91a9e85f68e1612bd4988693dd383f0&o=",
  },
  {
    continentId: 6,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/976536.jpg?k=5c09896d94bdfca18bb04e6fb8d58e7cd6bbb26af869bd7ea52a812070b15ff2&o=",
    ufi: -2152921,
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/nl/den-haag.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    url: {
      __typename: "AttractionLocationUrl",
      country: "nl",
      city: "den-haag",
      prefix: "attractions",
    },
    productCount: 86,
    ufiCity: "The Hague",
  },
  {
    url: {
      country: "ch",
      prefix: "attractions",
      city: "zurich",
      __typename: "AttractionLocationUrl",
    },
    ufiCity: "Zürich",
    productCount: 33,
    ufi: -2554935,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/686021.jpg?k=c76eecf385aa53a09aa64d752d78dfe7c6d6963a3be49e983c450a4cefe9b785&o=",
    continentId: 6,
    cityUrl:
      "https://booking.com/attractions/city/ch/zurich.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
  },
  {
    url: {
      city: "pompei",
      prefix: "attractions",
      country: "it",
      __typename: "AttractionLocationUrl",
    },
    ufiCity: "Pompei",
    productCount: 339,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/641614.jpg?k=c5237a7967f53d9f34a6078b034a86eb252e4b53e5dd3139990dd58f560d645b&o=",
    ufi: -125275,
    continentId: 6,
    cityUrl:
      "https://booking.com/attractions/city/it/pompei.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
  },
  {
    cityUrl:
      "https://booking.com/attractions/city/ae/abu-dhabi.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/349043.jpg?k=27b0df5e787834e4e17cbfbfbffbdcdbdeea45e2495037460b8885d3641fd5e9&o=",
    ufi: -782066,
    continentId: 7,
    productCount: 686,
    ufiCity: "Abu Dhabi",
    url: {
      __typename: "AttractionLocationUrl",
      country: "ae",
      prefix: "attractions",
      city: "abu-dhabi",
    },
  },
  {
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/641316.jpg?k=3217d1512aa7b1e149352a0bdc09dce9402d449e3a9d0f1faf182fd3afc66202&o=",
    ufi: -124918,
    continentId: 6,
    cityUrl:
      "https://booking.com/attractions/city/it/pisa.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    url: {
      city: "pisa",
      prefix: "attractions",
      country: "it",
      __typename: "AttractionLocationUrl",
    },
    productCount: 64,
    ufiCity: "Pisa",
  },
  {
    continentId: 8,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/685539.jpg?k=0aa470eba1e06ad0af26eb86afe6c6b7a5a19716e688e0755c4c9bc290514e48&o=",
    ufi: -2403010,
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/my/kuala-lumpur.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    url: {
      __typename: "AttractionLocationUrl",
      country: "my",
      city: "kuala-lumpur",
      prefix: "attractions",
    },
    productCount: 363,
    ufiCity: "Kuala Lumpur",
  },
  {
    productCount: 115,
    ufiCity: "Amman",
    url: {
      __typename: "AttractionLocationUrl",
      city: "amman",
      prefix: "attractions",
      country: "jo",
    },
    cityUrl:
      "https://booking.com/attractions/city/jo/amman.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    ufi: -970362,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/664053.jpg?k=b8487dac4c18aba3e8e0ca69b64cd3a93e4cd6060769d3191ac740d537d2627c&o=",
    continentId: 7,
  },
  {
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/fr/lyon.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    continentId: 6,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/977109.jpg?k=9fca91817b2a6ef12986e44a206920b3a94e7aa2e7c4bfb2144116ffa59b82f1&o=",
    ufi: -1448468,
    ufiCity: "Lyon",
    productCount: 56,
    url: {
      country: "fr",
      prefix: "attractions",
      city: "lyon",
      __typename: "AttractionLocationUrl",
    },
  },
  {
    cityUrl:
      "https://booking.com/attractions/city/it/sorrento.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    ufi: -129972,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/642084.jpg?k=52995fc3564a157aa35b7fd2def4dc99dea61e4c8382a2237ea38e49ab3b4108&o=",
    continentId: 6,
    productCount: 336,
    ufiCity: "Sorrento",
    url: {
      __typename: "AttractionLocationUrl",
      country: "it",
      city: "sorrento",
      prefix: "attractions",
    },
  },
  {
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/om/muscat.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    continentId: 7,
    ufi: -787987,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/654901.jpg?k=ad07314cf25d93ea3be84fea985906d6f25d0243e016fff9358a44254be4996e&o=",
    ufiCity: "Muscat",
    productCount: 67,
    url: {
      __typename: "AttractionLocationUrl",
      country: "om",
      city: "muscat",
      prefix: "attractions",
    },
  },
  {
    cityUrl:
      "https://booking.com/attractions/city/qa/doha.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/654899.jpg?k=e74c568f9d127c2310136ab8725425900ab5c59b340acc8e7c46ef6ab549aff6&o=",
    ufi: -785169,
    continentId: 7,
    ufiCity: "Doha",
    productCount: 12,
    url: {
      city: "doha",
      prefix: "attractions",
      country: "qa",
      __typename: "AttractionLocationUrl",
    },
  },
  {
    ufi: -2745636,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/688281.jpg?k=2b0e3bfff81f04899bce25e7e7d3d5aa3900c81c756b8b64e326ee833f9d6cfd&o=",
    continentId: 6,
    cityUrl:
      "https://booking.com/attractions/city/dk/copenhagen.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    url: {
      __typename: "AttractionLocationUrl",
      country: "dk",
      prefix: "attractions",
      city: "copenhagen",
    },
    ufiCity: "Copenhagen",
    productCount: 81,
  },
  {
    continentId: 6,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/641176.jpg?k=0e7f9c9e0d7b57eb2739658e6c8a0b0fa3e8155fe99c709a4ef18334097aea57&o=",
    ufi: -123798,
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/it/palermo.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    url: {
      country: "it",
      prefix: "attractions",
      city: "palermo",
      __typename: "AttractionLocationUrl",
    },
    productCount: 19,
    ufiCity: "Palermo",
  },
  {
    cityUrl:
      "https://booking.com/attractions/city/pl/warsaw.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/653083.jpg?k=136967b321aa41edb9838e6e937f9df81763006cdf955321951f38c8839815b8&o=",
    ufi: -534433,
    continentId: 6,
    ufiCity: "Warsaw",
    productCount: 93,
    url: {
      prefix: "attractions",
      city: "warsaw",
      country: "pl",
      __typename: "AttractionLocationUrl",
    },
  },
  {
    cityUrl:
      "https://booking.com/attractions/city/no/oslo.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/644292.jpg?k=840f25bc211406d0f332785d3086c45cd4d4e12d8f9935a1e07416aee6aa2af2&o=",
    ufi: -273837,
    continentId: 6,
    productCount: 50,
    ufiCity: "Oslo",
    url: {
      __typename: "AttractionLocationUrl",
      country: "no",
      city: "oslo",
      prefix: "attractions",
    },
  },
  {
    cityUrl:
      "https://booking.com/attractions/city/hr/zadar.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    ufi: -101499,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/972767.jpg?k=31e51a7b1def132ffb64814d989838f220ac7b8d2140e8c6d064ad5993d01409&o=",
    continentId: 6,
    productCount: 22,
    ufiCity: "Zadar",
    url: {
      country: "hr",
      city: "zadar",
      prefix: "attractions",
      __typename: "AttractionLocationUrl",
    },
  },
  {
    url: {
      prefix: "attractions",
      city: "bratislava",
      country: "sk",
      __typename: "AttractionLocationUrl",
    },
    ufiCity: "Bratislava",
    productCount: 61,
    ufi: -840999,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/656154.jpg?k=561e0807eeb20dc31410e356fa89d8ec66a862ebe5a181b16c34bd63c916ff24&o=",
    continentId: 6,
    cityUrl:
      "https://booking.com/attractions/city/sk/bratislava.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
  },
  {
    continentId: 1,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/682594.jpg?k=9571cf0f6d188e30e921f7027ffdf15c1bf51b060517d494097e2d37cf410ac7&o=",
    ufi: -1669612,
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/mx/guadalajara.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    url: {
      __typename: "AttractionLocationUrl",
      country: "mx",
      prefix: "attractions",
      city: "guadalajara",
    },
    productCount: 32,
    ufiCity: "Guadalajara",
  },
  {
    url: {
      country: "za",
      city: "johannesburg",
      prefix: "attractions",
      __typename: "AttractionLocationUrl",
    },
    productCount: 11,
    ufiCity: "Johannesburg",
    continentId: 5,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/674643.jpg?k=87f31dd4df5f06e23e7cad7ce7b34a828b4c35f3b32d4ad8319780eae25a8773&o=",
    ufi: -1240261,
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/za/johannesburg.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
  },
  {
    cityUrl:
      "https://booking.com/attractions/city/hr/zagreb.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    ufi: -101579,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/639277.jpg?k=c725a79fba4a3d1c6220d1a6f6e93d30f16c6c5ba364fc589092ebf1087806fc&o=",
    continentId: 6,
    productCount: 71,
    ufiCity: "Zagreb",
    url: {
      prefix: "attractions",
      city: "zagreb",
      country: "hr",
      __typename: "AttractionLocationUrl",
    },
  },
  {
    ufi: -96492,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/638613.jpg?k=c2cb69afe86fe2051e6f8b50bfb0198d461358b420497c046a05136222634e6e&o=",
    continentId: 6,
    cityUrl:
      "https://booking.com/attractions/city/hr/split.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    url: {
      __typename: "AttractionLocationUrl",
      country: "hr",
      prefix: "attractions",
      city: "split",
    },
    productCount: 68,
    ufiCity: "Split",
  },
  {
    continentId: 6,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/687752.jpg?k=3e6ad26db50adc1b3d34bf2deba9e9e06b92e37a0cdc11afd340ffb3a89a36a5&o=",
    ufi: -2625660,
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/ee/tallinn.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    url: {
      __typename: "AttractionLocationUrl",
      country: "ee",
      city: "tallinn",
      prefix: "attractions",
    },
    productCount: 42,
    ufiCity: "Tallinn",
  },
  {
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/635811.jpg?k=0eff5bf70b0d9dcf3af5e9263f5d1b0e6f769e0242232f5c8aac9b8bc4b3e1b1&o=",
    ufi: -28159,
    continentId: 5,
    cityUrl:
      "https://booking.com/attractions/city/ma/casablanca.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    url: {
      prefix: "attractions",
      city: "casablanca",
      country: "ma",
      __typename: "AttractionLocationUrl",
    },
    productCount: 36,
    ufiCity: "Casablanca",
  },
  {
    productCount: 110,
    ufiCity: "Modena",
    url: {
      prefix: "attractions",
      city: "modena",
      country: "it",
      __typename: "AttractionLocationUrl",
    },
    cityUrl:
      "https://booking.com/attractions/city/it/modena.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    ufi: -121865,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/640996.jpg?k=b53d8fb8d9516aee115963d25d73e8d1cf2a610cbaa78e0d2e81f8679b3f6412&o=",
    continentId: 6,
  },
  {
    url: {
      __typename: "AttractionLocationUrl",
      prefix: "attractions",
      city: "stavanger",
      country: "no",
    },
    ufiCity: "Stavanger",
    productCount: 5,
    ufi: -280816,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/671737.jpg?k=55162c051613087e79819420e554c16f057de72d68ad002b154702c23a10979a&o=",
    continentId: 6,
    cityUrl:
      "https://booking.com/attractions/city/no/stavanger.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
  },
  {
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/635821.jpg?k=d78d65dfa67dc3ad1f6521ff310a78775911af7c88eb177fa13cd1849764e61d&o=",
    ufi: -20029,
    continentId: 5,
    cityUrl:
      "https://booking.com/attractions/city/ma/agadir.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    url: {
      __typename: "AttractionLocationUrl",
      prefix: "attractions",
      city: "agadir",
      country: "ma",
    },
    productCount: 78,
    ufiCity: "Agadir",
  },
  {
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/fr/nice.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    continentId: 6,
    ufi: -1454990,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/977138.jpg?k=812ea613d8f1517a71398daa060cf37b5da022e3c1486805cdff52e5ab34db41&o=",
    ufiCity: "Nice",
    productCount: 140,
    url: {
      country: "fr",
      city: "nice",
      prefix: "attractions",
      __typename: "AttractionLocationUrl",
    },
  },
  {
    ufiCity: "Helsinki",
    productCount: 57,
    url: {
      __typename: "AttractionLocationUrl",
      prefix: "attractions",
      city: "helsinki",
      country: "fi",
    },
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/fi/helsinki.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    continentId: 6,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/674847.jpg?k=9cbb97e5d951f899030f0e9479a06023fe2a4216a95a0fe784e0d4be27f7bbec&o=",
    ufi: -1364995,
  },
  {
    cityUrl:
      "https://booking.com/attractions/city/gb/edinburgh.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/xphoto/720x405/72203844.jpg?k=ac13b56ed122f9105ca861ba3045f55ed0f61c1d2732b382dcacf631f15e3166&o=",
    ufi: -2595386,
    continentId: 6,
    productCount: 303,
    ufiCity: "Edinburgh",
    url: {
      __typename: "AttractionLocationUrl",
      country: "gb",
      city: "edinburgh",
      prefix: "attractions",
    },
  },
  {
    continentId: 1,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/653240.jpg?k=6b015a87c8443039a685038e97dd58dab6a8748078948b27cca7d1a5fcef308d&o=",
    ufi: -570141,
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/ca/niagara-falls.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    url: {
      __typename: "AttractionLocationUrl",
      country: "ca",
      prefix: "attractions",
      city: "niagara-falls",
    },
    productCount: 22,
    ufiCity: "Niagara Falls",
  },
  {
    url: {
      __typename: "AttractionLocationUrl",
      prefix: "attractions",
      city: "phoenix",
      country: "us",
    },
    productCount: 80,
    ufiCity: "Phoenix",
    continentId: 1,
    ufi: 20006890,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/976763.jpg?k=046457ef6942e4538477f4011601cb32c582ec1921eba19243cc622eaee5d06d&o=",
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/us/phoenix.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
  },
  {
    url: {
      country: "ca",
      city: "victoria",
      prefix: "attractions",
      __typename: "AttractionLocationUrl",
    },
    productCount: 9,
    ufiCity: "Victoria",
    continentId: 1,
    ufi: -575350,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/653283.jpg?k=8f625d3ee6d894c07cdac1d47fb431d13047b3b51fe7b951e99756736c66ef67&o=",
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/ca/victoria.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
  },
  {
    ufi: -2552151,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/685872.jpg?k=0cd3ab67d612ffa6f86107387355f7655f86f157f2a11d88ba1a964915b31780&o=",
    continentId: 6,
    cityUrl:
      "https://booking.com/attractions/city/ch/geneva.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    url: {
      __typename: "AttractionLocationUrl",
      country: "ch",
      city: "geneva",
      prefix: "attractions",
    },
    ufiCity: "Geneva",
    productCount: 26,
  },
  {
    cityUrl:
      "https://booking.com/attractions/city/au/melbourne.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/967880.jpg?k=1f8836731f0812dda2cd68669b48154ce9f6fc5c204bb5e2c62093bae35d48ed&o=",
    ufi: -1586844,
    continentId: 9,
    ufiCity: "Melbourne",
    productCount: 202,
    url: {
      __typename: "AttractionLocationUrl",
      prefix: "attractions",
      city: "melbourne",
      country: "au",
    },
  },
  {
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/740868.jpg?k=fcb4081400104b2ed2e6e58dd8ee0987ee367d4ae7b777a90b4f4416ac1705ef&o=",
    ufi: -85070,
    continentId: 6,
    cityUrl:
      "https://booking.com/attractions/city/si/koper.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
    url: {
      __typename: "AttractionLocationUrl",
      prefix: "attractions",
      city: "koper",
      country: "si",
    },
    productCount: 25,
    ufiCity: "Koper",
  },
  {
    url: {
      __typename: "AttractionLocationUrl",
      country: "pt",
      prefix: "attractions",
      city: "lagos",
    },
    ufiCity: "Lagos",
    productCount: 155,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/685178.jpg?k=85fcde717aa30981026095b62d0863b788e1019ace76528b082cc2bab65cbbd0&o=",
    ufi: -2167534,
    continentId: 6,
    cityUrl:
      "https://booking.com/attractions/city/pt/lagos.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
  },
  {
    continentId: 6,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/350631.jpg?k=31b2463ac5df0ec4181d68cabbd35f1a1765c2b7f2bed44f51bde3b80b1722e9&o=",
    ufi: -401497,
    __typename: "AttractionCity",
    cityUrl:
      "https://booking.com/attractions/city/es/san-sebastian.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    url: {
      country: "es",
      city: "san-sebastian",
      prefix: "attractions",
      __typename: "AttractionLocationUrl",
    },
    productCount: 51,
    ufiCity: "San Sebastian",
  },
  {
    url: {
      city: "cannes",
      prefix: "attractions",
      country: "fr",
      __typename: "AttractionLocationUrl",
    },
    ufiCity: "Cannes",
    productCount: 140,
    ufi: -1416533,
    imageUrl:
      "https://q-xx.bstatic.com/xdata/images/city/720x405/977110.jpg?k=3dffa74898f689891cfd42ec1c8dd3e976508234dd93322a084db0443e91067e&o=",
    continentId: 6,
    cityUrl:
      "https://booking.com/attractions/city/fr/cannes.en-gb.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARfIAQzYAQHoAQH4AQyIAgGoAgO4As6n-KoGwAIB0gIkZjFjMDNiNTUtNmE4YS00MmQ2LTk5NGMtNTgwNTdlODE1YmMy2AIG4AIB&source=attractions_index_open_shop&url_code=",
    __typename: "AttractionCity",
  },
];
