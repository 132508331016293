import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CheckoutHotel from "../../pages/checkout/Index";
import FormTab from "../checkout/FormTab";
import http from "../../Api";
import CheckoutPriceCard from "./CheckoutPriceCard";
import WithHeaderFooter from "../../components/WithHeaderFooter";
import CreditCard from "./CreditCard";
const HoteCheckout = () => {
  const [hoteldata, setHotelOfferData] = useState(null);
  const [passengers, setPassengerCnt] = useState(Array.from(Array(1)));
  const [guests, setGuests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState([
    {
      id: 1,
      method: "creditCard",
      card: {
        vendorCode: "VI",
        cardNumber: "4151289722471370",
        expiryDate: "2026-01",
      },
    },
  ]);
  const setCardDetails = (data) => {
    console.log("data", data);
  };
  const params = new URLSearchParams(window.location.search);
  useEffect(() => {
    http
      .get("hotel-offer-by-offerId/" + params.get("hotelOfferId"))
      .then((res) => {
        console.log("calling", res.data.data);
        setPassengerCnt(
          Array.from(
            Array(parseInt(res?.data?.data?.offers[0]?.guests?.adults))
          )
        );
        setHotelOfferData(res.data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  const setPassenger = (data, pid) => {
    
    let userDetails = {
      id: pid,
      name: data.name,
      contact: {
        email: data.contact.emailAddress,
        phone: `${data.contact.phones[0].countryCallingCode}${data.contact.phones[0].number}`,
      },
    };
    console.log("userDetails", userDetails);
    const arr = [...guests];
    arr[pid - 1] = userDetails;
    setGuests(arr);
  };
const currentPassenger=(id)=>{
  console.log('id', id)
}
  return (
    <>
      <WithHeaderFooter>
        <Container>
          <Row>
            <FormTab
              dob={false}
              gender={false}
              label="Guests"
              passengers={passengers}
              setPassenger={setPassenger}
              currentPassenger={currentPassenger}
            />

            <Col className="py-5 " md={4}>
            <CreditCard
                paymentData={payments}
                setPaymentData={setCardDetails}
              />
              {hoteldata ? (
                <CheckoutPriceCard
                  passengers={passengers}
                  pricing={hoteldata.offers[0]}
                  loading={loading}
                  setLoading={setLoading}
                  hotelName={params.get("hotelName")}
                  bookingData={{
                    offerId: params.get("hotelOfferId"),
                    guests,
                    payments,
                  }}
                />
              ) : (
                <>loading</>
              )}
            
            </Col>
          </Row>
        </Container>
      </WithHeaderFooter>
    </>
  );
};

export default HoteCheckout;
