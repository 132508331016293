import React from "react";
import WithHeaderFooter from "../../components/WithHeaderFooter";
import HeroSection from "./AttractionHero";
import Destination from "./Destination";
import MoreDestination from "./MoreDestination";
import FAQsection from "../flight/FAQsection";
import ToursAndActivities from "./ToursAndActivities";

const Index = () => {
  return (
    <WithHeaderFooter>
      <HeroSection />
      <Destination />
      <ToursAndActivities/>
      <MoreDestination />
      <FAQsection type="destination" />
    </WithHeaderFooter>
  );
};

export default Index;
