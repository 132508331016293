import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CityCard from "./CityCard";
import ExploreCard from "./ExploreCard";

function PopularCities() {
  const columns = Array.from({ length: 7 }, (_, index) => index + 1);

  return (
    <div>
      <Container className="py-5">
        <div className="text-center m-3">
          {" "}
          <h3>Trending destinations</h3>
          <p>Most popular choices for travellers from India</p>
        </div>

        <Row
          style={{ height: "80vh", overflow: "auto",borderBottom:"1px solid black" }}
          className="justify-content-center mt-2 align-items-center gy-4"
        >
          {columns.map((colNumber) => (
            <Col className="d-flex justify-content-center" key={colNumber} sm={3} lg={4} md={6}>
              <ExploreCard img={'https://static.toiimg.com/photo/58807609.cms'} width={"15rem"}/>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default PopularCities;
