import axios from "axios";
import toast from "react-hot-toast";

const http = axios.create({
  baseURL: `${process.env.REACT_APP_HOST}/api/user/`,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 90000,
});

const retry = async (config, tries = 3) => {
  try {
    const response = await axios(config);
    return response;
  } catch (error) {
    if (tries === 0) {
      if (tries > 0 && error?.response?.data?.info?.code === "ECONNRESET") {
        return retry(config, tries - 1);
      }
    }
  }
};

http.interceptors.response.use(
  // Called on successful responses
  function (response) {
    // Do something with the response data
    return Promise.resolve(response);
  },
  // Called when an error occurs or the request is unsuccessful
  function (error) {
    console.log("error", error);
    if (error.code === "ECONNABORTED") {
      console.log("bal");
      toast.error("reques timeout! try again");
    }
    if (error?.response?.data?.info?.code === "ECONNRESET"){
      return retry(error.config)
    }
      return Promise.reject(error);
  }
);

export const getCity = async (data, subType) => {
  console.log("data", data);
  try {
    const res = await http.get(`get-city/${data}/${subType}`);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const FlightOffers = (data) => {
  return new Promise((resolve, reject) => {
    const params = new URLSearchParams(data);
    const payload = {};
    params.forEach((value, key) => {
      payload[key] = value;
    });
    // return console.log('payload', payload)

    http
      .post("flight-search/one-way", payload)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export default http;
