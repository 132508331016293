import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import DestCard from "./DestCard";
import http from "../../Api/index";
import { getClientCityInfo } from "../flight";
import { useEffect, useState } from "react";
import { currency_symbols } from "../checkout/Index";
import { FaLongArrowAltRight } from "react-icons/fa";
import AttractionInfo from "./AttractionDetails";
import Loading from "./Loading";
import Slider from "react-slick";
import { settings } from "./Destination";
import { MdExpandMore, MdExpandLess } from "react-icons/md";

function ToursAndActivities() {
  const [activites, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mode, setmode] = useState("x");
  useEffect(() => {
    getActivites();
  }, []);

  const getActivites = () => {
    setLoading(true);
    getClientCityInfo((err, data) => {
      if (err) console.log("err", err);
      http
        .get("tour-and-activites/location", {
          params: {
            lat: 41.397158 ?? data.latitude,
            long: 2.160873 ?? data.longitude,
          },
        })
        .then((res) => {
          setActivities(res.data.data);
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          setLoading(false);
        });
    });
    return;
  };
  console.log("activites", activites);
  return (
    <Container>
      <Row className="text-center">
        <p
          style={{ fontWeight: "bold", marginTop: "1rem" }}
          class="text-uppercase "
        >
          Tour and Activites{" "}
        </p>
        {loading ? (
          <Loading />
        ) : mode === "x" ? (
          <Slider {...settings} className="slick-flight">
            {activites.map((it, ind) => (
              // <Col style={{ margin: "1rem" }}>
              <DestCard
                stopClick
                extraa={
                  <AttractionInfo
                    header={it.name}
                    images={it.pictures}
                    body={
                      <>
                        <div
                          dangerouslySetInnerHTML={{ __html: it.description }}
                        ></div>
                        {it.bookingLink ? (
                          <a target="_blank" href={it.bookingLink}>
                            book for {currency_symbols[it?.price?.currencyCode]}{" "}
                            {it?.price?.amount}
                          </a>
                        ) : (
                          <a
                            href={`https://www.google.com/maps?q=${it.name}@${it.geoCode.latitude},${it.geoCode.longitude}`}
                            target="_blank"
                          >
                            Open in Google Maps
                          </a>
                        )}
                      </>
                    }
                  >
                    {currency_symbols[it?.price?.currencyCode]}{" "}
                    {it?.price?.amount} <FaLongArrowAltRight />
                  </AttractionInfo>
                }
                img={it.pictures[0]}
                title={it.name.slice(0, 30)}
                subtitle={it.description}
                name={it.name}
                nameId={it.id}
                latitude={it.geoCode.latitude}
                longitude={it.geoCode.longitude}
              />
            ))}
          </Slider>
        ) : (
          activites.map((it, ind) => (
            <Col style={{ margin: "1rem" }}>
              <DestCard
                stopClick
                extraa={
                  <AttractionInfo
                    header={it.name}
                    images={it.pictures}
                    body={
                      <>
                        <div
                          dangerouslySetInnerHTML={{ __html: it.description }}
                        ></div>
                        {it.bookingLink ? (
                          <a target="_blank" href={it.bookingLink}>
                            book for {currency_symbols[it?.price?.currencyCode]}{" "}
                            {it?.price?.amount}
                          </a>
                        ) : (
                          <a
                            href={`https://www.google.com/maps?q=${it.name}@${it.geoCode.latitude},${it.geoCode.longitude}`}
                            target="_blank"
                          >
                            Open in Google Maps
                          </a>
                        )}
                      </>
                    }
                  >
                    {currency_symbols[it?.price?.currencyCode]}{" "}
                    {it?.price?.amount} <FaLongArrowAltRight />
                  </AttractionInfo>
                }
                img={it.pictures[0]}
                title={it.name.slice(0, 30)}
                subtitle={it.description}
                name={it.name}
                nameId={it.id}
                latitude={it.geoCode.latitude}
                longitude={it.geoCode.longitude}
              />
            </Col>
          ))
        )}
      </Row>
      <h1
        onClick={() => setmode((prev) => (prev === "x" ? "y" : "x"))}
        className="text-center"
      >
        {mode === "x" ? <MdExpandMore /> : <MdExpandLess />}
      </h1>
    </Container>
  );
}

export default ToursAndActivities;
