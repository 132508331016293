import Placeholder from 'react-bootstrap/Placeholder';

function Loading() {
  return (
    <div className="m-2">
         <Placeholder className="w-75 m-2" /> <Placeholder style={{ width: '25%' }} />
      <Placeholder xs={6} />
      <Placeholder className="w-75 m-2" /> <Placeholder style={{ width: '25%' }} />
    </div>
  );
}

export default Loading;