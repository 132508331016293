import React, { useEffect, useRef, useState } from "react";
import { FaLocationDot } from "react-icons/fa6";
import axios from "axios";
import { Button } from "react-bootstrap";
import DatePickerComp from "./DatePickerComp";
import AsyncSelect from "react-select/async";
import http, { getCity } from "../../Api";
import Badge from "react-bootstrap/Badge";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { reactLocalStorage } from "reactjs-localstorage";

const LocationSelect = ({ query }) => {
  const navi = useNavigate();
  const [selectedLocations, setSelectedLocations] = useState({
    startLocationCode:
      JSON.parse(query.get("data"))?.startLocationCode ?? moment(),
    airportName: JSON.parse(query.get("data"))?.airportName,
    startDateTime: JSON.parse(query.get("data"))?.startDateTime ?? moment(),
    endLocPid: JSON.parse(query.get("data"))?.endLocPid,
    passengers: JSON.parse(query.get("data"))?.passengers,
    from: JSON.parse(query.get("data"))?.from ?? { value: null, label: null },
    to: JSON.parse(query.get("data"))?.to ?? { value: null, label: null },
  });
  const [endLocPid, setEndLocPid] = useState(null);
  const apitoref = useRef(null);
  const destRef = useRef(null);
  const debounce = (func, delay) => {
    clearTimeout(apitoref.current);
    apitoref.current = setTimeout(func, delay);
  };
  const debounceDest = (func, delay) => {
    clearTimeout(destRef.current);
    destRef.current = setTimeout(func, delay);
  };
  const promiseOptions = (inputValue) => {
    if (!inputValue) return;
    return new Promise((resolve) => {
      debounce(() => {
        getCity(inputValue, "AIRPORT").then((data) => {
          const existingSearchHistory = reactLocalStorage.get("search-his")
            ? reactLocalStorage.getObject("search-his")
            : [];
          const newData = data?.data || [];
          console.log("existingSearchHistory", existingSearchHistory);
          const newDataObjects = newData.map((it) => ({
            value: it.iataCode,
            label: {
              cityName: it.address.cityName,
              airPortName: it.name,
              country: it.address.countryName,
            },
          }));

          const filteredDataObjects = newDataObjects.filter((newObj) => {
            return !existingSearchHistory.some(
              (existingObj) => existingObj.value === newObj.value
            );
          });

          const updatedSearchHistory = [
            ...existingSearchHistory,
            ...filteredDataObjects,
          ];

          reactLocalStorage.setObject("search-his", updatedSearchHistory);

          resolve(
            data?.data?.map((it) => ({
              value: it.iataCode,
              label: {
                cityName: it.address.cityName,
                airPortName: it.name,
                country: it.address.countryName,
              },
            }))
          );
        });
      }, 800);
    });
  };

  const destinationPromiseOpts = (inputValue) => {
    if (!inputValue) return;

    return new Promise((resolve) => {
      debounceDest(async () => {
        http
          .get("place-autocomplete", {
            params: {
              inputValue,
            },
          })
          .then((response) => {
            resolve(
              response.data?.predictions?.map((place) => {
                return {
                  value: place.place_id,
                  label: place.description,
                };
              })
            );
          })
          .catch((error) => {
            console.error("Error:", error.message);
          });
      }, 800);
    });
  };
  console.log("selectedLocations", selectedLocations);

  const getDate = (date) => {
    console.log("date", moment(date));

    setSelectedLocations({
      ...selectedLocations,
      startDateTime: moment(date),
    });
  };
  return (
    <div className="d-flex mt-4 justify-content-start align-items-center flex-wrap gap-2">
      <AsyncSelect
        placeholder={"Pickup airport name"}
        defaultOptions={reactLocalStorage.getObject("search-his")}
        cacheOptions
        loadOptions={promiseOptions}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: "#f39c12",
            borderWidth: "1px",
            borderRadius: 10,
            height: 50,
            width: 300,
            background: "none",
          }),
          singleValue: (provided) => ({
            ...provided,
            color: "white", // Set your desired text color
          }),
          input: (provided, state) => ({
            ...provided,
            color: "white", // Change the color to your desired color
          }),
        }}
        components={{
          Option: ({ innerProps, label }) => (
            <div {...innerProps} className="d-block p-2 opt-airport">
              <div> {label.airPortName}</div>
              <small className="text-muted d-flex align-items-start">
                <FaLocationDot className="mx-1" /> {label.cityName},{" "}
                {label.country}{" "}
                <Badge bg="info" className="mx-1" pill>
                  Airport
                </Badge>
              </small>
            </div>
          ),
        }}
        isSearchable
        // defaultInputValue={selectedLocations.from}
        value={selectedLocations.from.label ? selectedLocations.from : null}
        onChange={({ value, label }) => {
          console.log("label", label, value);
          setSelectedLocations({
            ...selectedLocations,
            startLocationCode: value,

            from: {
              label: label.airPortName,
            },
          });
        }}
      />
      <DatePickerComp
        onChange={getDate}
        value={selectedLocations.startDateTime}
        label={"Pick-up time"}
      />
      <AsyncSelect
        placeholder={"Where to drop?"}
        cacheOptions
        defaultOptions
        loadOptions={destinationPromiseOpts}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: "#f39c12",
            borderWidth: "1px",
            borderRadius: 10,
            height: 50,
            width: 300,
            background: "none",
          }),
          singleValue: (provided) => ({
            ...provided,
            color: "white", // Set your desired text color
          }),
          input: (provided, state) => ({
            ...provided,
            color: "white", // Change the color to your desired color
          }),
        }}
        value={selectedLocations.to.label ? selectedLocations.to : null}
        components={{
          Option: ({ innerProps, label }) => (
            <div
              {...innerProps}
              className="d-flex align-items-center p-2 opt-airport"
            >
              <FaLocationDot size={30} className="mx-2" />
              <small className="text-muted d-flex align-items-start">
                {label}
              </small>
            </div>
          ),
        }}
        isSearchable
        onChange={({ value, label }) => {
          setSelectedLocations((prev) => {
            return {
              ...prev,
              to: { value, label },
              endLocPid: value,
            };
          });
        }}
      />
      <Button
        onClick={() =>
          navi(
            `/cars-search?data=${JSON.stringify({
              startLocationCode: selectedLocations.startLocationCode,
              from: selectedLocations.from,
              to: selectedLocations.to,
              endLocPid: selectedLocations.endLocPid,
              endGeoCode: selectedLocations.endGeoCode,
              startDateTime: selectedLocations.startDateTime,
              passengers: 2,
            })}`
          )
        }
        variant="primary"
        style={{
          border: "3px solid #f39c12",
          height: "50px",
          width: "100px",
        }}
      >
        Search
      </Button>{" "}
    </div>
  );
};

export default LocationSelect;
