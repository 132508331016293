import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Col from "react-bootstrap/Col";
import UserDetails from "./UserDetails";
const FormTab = ({
  label = "passenger",
  passengers,
  setPassenger,
  dob,
  gender,
  currentPassenger,
  billingAdress
}) => {
  console.log("billingAdress", billingAdress);
  return (
    <Col md={8} xs={12}>
      {" "}
      <Tabs
        onSelect={(e) => {
          currentPassenger(parseInt(e.replace(label, "")) - 1);
        }}
        defaultActiveKey={`${label}${1}`}
        id="uncontrolled-tab-example"
        className="my-5"
      >
        {passengers.map((it, ind) => (
          <Tab eventKey={`${label}${ind + 1}`} title={`${label}${ind + 1}`}>
            <UserDetails
              label={label}
              dob={dob}
              gender={gender}
              setPassenger={setPassenger}
              pId={ind + 1}
              billingAdress={billingAdress}
            />
          </Tab>
        ))}
      </Tabs>
    </Col>
  );
};

export default FormTab;
